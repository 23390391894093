import React from "react";
import { Card, styled } from "@mui/material";
import { Grid } from "@mui/material";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import Spinner from "../../../components/spinner/Spinner";

const StyledGrid = styled(Grid)`
  padding: 5px;
  place-self: flex-start;
`;

const StyledTitle = styled("p")`
  font-family: "Patua One";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 1.8px;
  text-align: center;
  color: #ffffff;
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const StyledMidTitle = styled("p")`
  text-align: center;
  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 44px;
  margin-top: -10px;
`;

const StyledValue = styled("p")`
  margin-top: -30px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  color: #ffffff;
  text-align: center;
`;

const StyledPeopleAltIcon = styled(PeopleAltIcon)`
  border-color: #ffffff;
  color: #ffffff;
`;

const StyledSpinner = styled(Spinner)`
  margin-top: -200px !important;
`;

const StyledBlueCard = styled(Card)`
  background: linear-gradient(246.59deg, #324873 7.62%, #19243a 169.51%);
  border-radius: 15px;
  height: 143px;
`;
const CardDetalhesRateio = ({ title, icon, value, loading }) => {
  if (loading) {
    return (
      <StyledGrid item xs={12} md={3}>
        <StyledBlueCard>
          <StyledSpinner />
        </StyledBlueCard>
      </StyledGrid>
    );
  }

  return (
    <StyledGrid item xs={12} md={3}>
      <StyledBlueCard>
        <StyledTitle>{title}</StyledTitle>
        <StyledMidTitle>
          {!icon ? "R$" : <StyledPeopleAltIcon />}
        </StyledMidTitle>
        <StyledValue>
          {!icon ? Number(value).toFixed(2).replace(".", ",") : value}
        </StyledValue>
      </StyledBlueCard>
    </StyledGrid>
  );
};
export default CardDetalhesRateio;
