import { Grid, Tooltip } from "@mui/material";
import { styled } from "@mui/system";
import Card from "../../../../components/Card";
import {
  DeleteOutlined,
  ModeEditOutlined,
  PersonOutline,
  AttachFile,
  LibraryBooksOutlined,
  AttachMoneyOutlined,
  EditOffOutlined,
  TableView,
} from "@mui/icons-material";
import formatters from "../../../../utils/formatters";
import { ReactComponent as IconRecover } from "../../../../styles/icons/recover.svg";

const StyledGrid = styled(Grid)`
  text-align: left;
`;

const IconsGrid = styled(Grid)`
  text-align: right;
  margin-top: 20px;
  align-items: center;
`;

const StyledCardActive = styled(Card)`
  height: auto;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 0px;
  background-color: #415781ff;

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const StyledCardInactive = styled(Card)`
  height: auto;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 25px;
  background-color: ${({ theme }) => theme.cards.inactive};

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const CardTitle = styled("div")`
  font-family: nunito;
  font-size: 16px;
  font-weight: 700;
  font-spacing: 0.5px;
  color: #ffb59b;
`;

const NomeCliente = styled("div")`
  font-family: nunito;
  font-size: 20px;
  font-weight: 300;
  font-spacing: 0.25px;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CnpjCliente = styled("div")`
  font-family: nunito;
  font-size: 12px;
  font-weight: 400;
  font-spacing: 0.4px;
  color: #ffffff;
`;

const StyledDeleteOutlined = styled(DeleteOutlined)`
  width: 1.2em;
  height: 1.2em;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
  cursor: pointer;
`;

const StyledModeEdit = styled(ModeEditOutlined)`
  width: 1.2em;
  height: 1.2em;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
  cursor: pointer;
`;

const StyledPersonOutline = styled(PersonOutline)`
  width: 1.2em;
  height: 1.2em;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
  cursor: pointer;
`;

const StyledAttachFile = styled(AttachFile)`
  width: 1.2em;
  height: 1.2em;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
  cursor: pointer;
`;

const StyledTableView = styled(TableView)`
  width: 1.2em;
  height: 1.2em;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
  cursor: pointer;
`;

const StyledLibraryBooks = styled(LibraryBooksOutlined)`
  width: 1.2em;
  height: 1.2em;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
  cursor: pointer;
`;

const StyledAttachMoneyOutlined = styled(AttachMoneyOutlined)`
  width: 1.2em;
  height: 1.2em;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
  cursor: pointer;
`;

const StyledEditOffOutlined = styled(EditOffOutlined)`
  width: 1.2em;
  height: 1.2em;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
  cursor: pointer;
`;

const StyledIconRecover = styled(IconRecover)`
  width: 1.2em;
  height: 1.2em;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
  cursor: pointer;
`;

const CardCliente = ({
  cliente,
  handleEditCliente,
  handleOpenExclusaoCliente,
  handleOpenReativacaoCliente,
  handleClickApolice,
  handleClickRateio,
  handleClickBeneficiarios,
  handleClickFaturamentos,
  handleClickEditDocumetos,
}) => {
  if (cliente.situacao === "A")
    return (
      <StyledCardActive key={cliente.id}>
        <StyledGrid item xs={12} md={12}>
          <CardTitle>
            {cliente.clienteMatriz ? "Sub-Estipulante" : "Cliente"}
          </CardTitle>
        </StyledGrid>
        <StyledGrid item xs={12} md={12}>
          <NomeCliente>{cliente?.nomeCliente}</NomeCliente>
        </StyledGrid>
        <StyledGrid item xs={12} md={12}>
          <CnpjCliente>{formatters.strings.cnpj(cliente?.cnpj)}</CnpjCliente>
        </StyledGrid>
        <IconsGrid item xs={12} md={12}>
          <Tooltip title={"Faturamentos"}>
            <StyledAttachMoneyOutlined
              onClick={() => handleClickFaturamentos(cliente.id)}
            />
          </Tooltip>
          <Tooltip title={"Rateios"}>
            <StyledTableView onClick={() => handleClickRateio(cliente.id)} />
          </Tooltip>
          <Tooltip title={"Apólices"}>
            <StyledLibraryBooks
              onClick={() => handleClickApolice(cliente.id)}
            />
          </Tooltip>
          <Tooltip title={"Documentos"}>
            <StyledAttachFile
              onClick={() => handleClickEditDocumetos(cliente.id)}
            />
          </Tooltip>
          <Tooltip title={"Beneficiários"}>
            <StyledPersonOutline
              onClick={() => handleClickBeneficiarios(cliente.id)}
            />
          </Tooltip>
          <Tooltip title={"Editar"}>
            <StyledModeEdit onClick={() => handleEditCliente(cliente.id)} />
          </Tooltip>
          <Tooltip title={"Desativar"}>
            <StyledDeleteOutlined
              onClick={() => handleOpenExclusaoCliente(cliente)}
            />
          </Tooltip>
        </IconsGrid>
      </StyledCardActive>
    );
  else
    return (
      <StyledCardInactive key={cliente.id}>
        <StyledGrid item xs={12} md={12}>
          <CardTitle style={{ color: "#3A3005" }}>Cliente</CardTitle>
        </StyledGrid>
        <StyledGrid item xs={12} md={12}>
          <NomeCliente>{cliente.nomeCliente}</NomeCliente>
        </StyledGrid>
        <StyledGrid item xs={12} md={12}>
          <CnpjCliente>{formatters.strings.cnpj(cliente.cnpj)}</CnpjCliente>
        </StyledGrid>
        <IconsGrid item xs={12} md={12}>
          <StyledEditOffOutlined
            onClick={() => handleEditCliente(cliente.id)}
          />
          <StyledIconRecover
            onClick={() => handleOpenReativacaoCliente(cliente)}
          />
        </IconsGrid>
      </StyledCardInactive>
    );
};

export default CardCliente;
