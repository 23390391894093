import { actions } from "./rateio.actions";
import { types as routes } from "./rotas.actions";

const initialState = {
  rateios: {
    loading: false,
    page: 1,
    count: 0,
    cliente: "",
    seguradora: "",
    competencia: "",
    data: [],
    filtros: "",
  },
  rateio: {
    loading: false,
    data: [],
  },
  valores: {
    loading: false,
    data: [],
  },
  rateioOutros: {
    loading: false,
    data: [],
  },
  rateioBeneficiarios: {
    loading: false,
    data: [],
  },
  situacao: {
    loading: false,
    data: "N",
  },
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.obterRateios.REQUEST:
    case actions.obterRateios.SUCCESS:
    case actions.obterRateios.FAILURE:
      return {
        ...state,
        rateios: {
          ...state.rateios,
          loading: action.type === actions.obterRateios.REQUEST,
          data:
            action.type === actions.obterRateios.SUCCESS
              ? action.payload.response.data.resultado
              : [],
          cliente:
            action.type === actions.obterRateios.SUCCESS
              ? action.payload.response.data.filter?.cliente ?? ""
              : [],
          seguradora:
            action.type === actions.obterRateios.SUCCESS
              ? action.payload.response.data.filter?.seguradora ?? ""
              : [],
          competencia:
            action.type === actions.obterRateios.SUCCESS
              ? action.payload.response.data.filter?.competencia ?? ""
              : [],
          page:
            action.type === actions.obterRateios.SUCCESS
              ? action.payload.response.data.paginaAtual
              : [],
          count:
            action.type === actions.obterRateios.SUCCESS
              ? action.payload.response.data.total
              : [],
          filtros: action.type === actions.obterRateios.REQUEST
            ? action.payload.filtro : state.rateios.filtros
        },
      };
    case actions.editarRateio.REQUEST:
    case actions.editarRateio.SUCCESS:
    case actions.editarRateio.FAILURE:
      return {
        ...state,
        rateio: {
          ...state.rateio,
          loading: action.type === actions.editarRateio.REQUEST,
          data:
            action.type === actions.editarRateio.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };

    case routes.EDIT_USUARIOS:
      return {
        ...state,
        id: action.payload.id,
      };

    case actions.mostrarRateio.REQUEST:
    case actions.mostrarRateio.SUCCESS:
    case actions.mostrarRateio.FAILURE:
      return {
        ...state,
        rateio: {
          ...state.rateio,
          loading: action.type === actions.mostrarRateio.REQUEST,
          data:
            action.type === actions.mostrarRateio.SUCCESS
              ? action.payload.response.data
              : [],
        },
        situacao: {
          ...state.situacao,
          loading: action.type === actions.mostrarRateio.REQUEST,
          data:
            action.type === actions.mostrarRateio.SUCCESS
              ? action.payload.response.data.situacao
              : [],
        },
      };
    case actions.uploadRateioDocumentos.REQUEST:
    case actions.uploadRateioDocumentos.SUCCESS:
    case actions.uploadRateioDocumentos.FAILURE:
      return {
        ...state,
        rateio: {
          ...state.rateio,
          loading: action.type === actions.uploadRateioDocumentos.REQUEST,
          data:
            action.type === actions.uploadRateioDocumentos.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.criarRateio.REQUEST:
    case actions.criarRateio.SUCCESS:
    case actions.criarRateio.FAILURE:
      return {
        ...state,
        rateio: {
          ...state.rateio,
          loading: action.type === actions.criarRateio.REQUEST,
          data:
            action.type === actions.criarRateio.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.deletarRateio.REQUEST:
    case actions.deletarRateio.SUCCESS:
    case actions.deletarRateio.FAILURE:
      return {
        ...state,
        rateio: {
          ...state.rateio,
          loading: action.type === actions.deletarRateio.REQUEST,
        },
      };
    case actions.obterValores.REQUEST:
    case actions.obterValores.SUCCESS:
    case actions.obterValores.FAILURE:
      return {
        ...state,
        valores: {
          ...state.valores,
          loading: action.type === actions.obterValores.REQUEST,
          data:
            action.type === actions.obterValores.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.obterRateioOutros.REQUEST:
    case actions.obterRateioOutros.SUCCESS:
    case actions.obterRateioOutros.FAILURE:
      return {
        ...state,
        rateioOutros: {
          ...state.rateioOutros,
          loading: action.type === actions.obterRateioOutros.REQUEST,
          data:
            action.type === actions.obterRateioOutros.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.obterRateioBeneficiarios.REQUEST:
    case actions.obterRateioBeneficiarios.SUCCESS:
    case actions.obterRateioBeneficiarios.FAILURE:
      return {
        ...state,
        rateioBeneficiarios: {
          ...state.rateioBeneficiarios,
          loading: action.type === actions.obterRateioBeneficiarios.REQUEST,
          data:
            action.type === actions.obterRateioBeneficiarios.SUCCESS
              ? action.payload.response.data.data
              : [],
          planos:
            action.type === actions.obterRateioBeneficiarios.SUCCESS
              ? action.payload.response.data.planos
              : [],
        },
      };
    case actions.atualizarSituacao.REQUEST:
    case actions.atualizarSituacao.SUCCESS:
    case actions.atualizarSituacao.FAILURE:
      return {
        ...state,
        rateio: {
          ...state.rateio,
          loading: action.type === actions.atualizarSituacao.REQUEST,
        },
        situacao: {
          ...state.situacao,
          loading: action.type === actions.atualizarSituacao.REQUEST,
          data:
            action.type === actions.atualizarSituacao.SUCCESS
              ? action.payload.response.data.situacao
              : [],
        },
      };
    case actions.aprovarSituacao.REQUEST:
    case actions.aprovarSituacao.SUCCESS:
    case actions.aprovarSituacao.FAILURE:
      return {
        ...state,
        rateio: {
          ...state.rateio,
          loading: action.type === actions.aprovarSituacao.REQUEST,
        },
        situacao: {
          ...state.situacao,
          loading: action.type === actions.aprovarSituacao.REQUEST,
          data:
            action.type === actions.aprovarSituacao.SUCCESS
              ? action.payload.response.data.situacao
              : [],
        },
      };

    default:
      return state;
  }
};

export default reducer;
