import React from "react";
import { Card, styled } from "@mui/material";
import { Grid } from "@mui/material";
import {
  ControlledSelect,
  ControlledTextField,
} from "../../../components/inputs";
import { MenuItem } from "../../../components";
import { getUsuarios } from "../../../selectors/dominios.selectors";
import { useSelector } from "react-redux";

const StyledCard = styled(Card)`
  height: auto;
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 15px;
  background-color: #415781ff;

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const StyledGrid = styled(Grid)`
  padding: 5px;
  place-self: flex-start;
`;

const StyledGridContainer = styled(Grid)`
  margin-bottom: 0px;
`;

const StyledH2 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.white};
  text-align: left;
  letter-spacing: 1.8px;
  font-size: 28px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
`;

const CardUsuario = ({ formProps, disabled }) => {
  const { data } = useSelector(getUsuarios);

  return (
    <StyledCard>
      <StyledH2>Usuário</StyledH2>
      <StyledGridContainer container>
        <StyledGrid item xs={12} md={6}>
          <ControlledTextField
            formProps={formProps}
            fullWidth
            disabled={!!formProps.getValues("id")}
            name="usuario"
            label="Usuário"
            inputProps={{
              maxLength: 60,
            }}
          />
        </StyledGrid>
        <StyledGrid item xs={12} md={6}>
          {!!data && data.length > 0 && (
            <ControlledSelect
              name={"usuarioResponsavel"}
              formProps={formProps}
              label={"Usuário Responsável"}
              margin={"none"}
              disabled={disabled}
            >
              {data
                .filter((i) => i.perfil !== "F")
                .map((item) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={item.id}
                    value={item.id}
                  >
                    {item.nome}
                  </MenuItem>
                ))}
            </ControlledSelect>
          )}
        </StyledGrid>
      </StyledGridContainer>
    </StyledCard>
  );
};

export default CardUsuario;
