export const estadoCivil = [
  { id: "SOLTEIRO", descricao: "SOLTEIRO", uniaoEstavel: true, conjuge: false },
  { id: "CASADO", descricao: "CASADO", uniaoEstavel: false, conjuge: true },
  {
    id: "DESQUITADO",
    descricao: "DESQUITADO",
    uniaoEstavel: true,
    conjuge: false,
  },
  {
    id: "DIVORCIADO",
    descricao: "DIVORCIADO",
    uniaoEstavel: true,
    conjuge: false,
  },
  { id: "VIUVO", descricao: "VIUVO", uniaoEstavel: true, conjuge: false },
  {
    id: "SEPARADO JUDICIALMENTE",
    descricao: "SEPARADO JUDICIALMENTE",
    uniaoEstavel: true,
    conjuge: false,
  },
  {
    id: "CASADO NO EXTERIOR",
    descricao: "CASADO NO EXTERIOR",
    uniaoEstavel: false,
    conjuge: true,
  },
];

export const regimeComunhao = [
  { id: "COMUNHÃO PARCIAL DE BENS", descricao: "COMUNHÃO PARCIAL DE BENS" },
  { id: "COMUNHÃO UNIVERSAL DE BENS", descricao: "COMUNHÃO UNIVERSAL DE BENS" },
  { id: "SEPARAÇÃO DE BENS", descricao: "SEPARAÇÃO DE BENS" },
  { id: "AQUESTO", descricao: "AQUESTO" },
  {
    id: "SEPARAÇÃO DE BENS OBRIGATÓRIA POR LEI",
    descricao: "SEPARAÇÃO DE BENS OBRIGATÓRIA POR LEI",
  },
];

const unidadesFederativa = [
  {
    codigo: "RO",
    nome: "RONDÔNIA",
    regiao: { id: 1, sigla: "N", nome: "Norte" },
  },
  { codigo: "AC", nome: "ACRE", regiao: { id: 1, sigla: "N", nome: "Norte" } },
  {
    codigo: "AM",
    nome: "AMAZONAS",
    regiao: { id: 1, sigla: "N", nome: "Norte" },
  },
  {
    codigo: "RR",
    nome: "RORAIMA",
    regiao: { id: 1, sigla: "N", nome: "Norte" },
  },
  { codigo: "PA", nome: "PARÁ", regiao: { id: 1, sigla: "N", nome: "Norte" } },
  { codigo: "AP", nome: "AMAPÁ", regiao: { id: 1, sigla: "N", nome: "Norte" } },
  {
    codigo: "TO",
    nome: "TOCANTINS",
    regiao: { id: 1, sigla: "N", nome: "Norte" },
  },
  {
    codigo: "MA",
    nome: "MARANHÃO",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    codigo: "PI",
    nome: "PIAUÍ",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    codigo: "CE",
    nome: "CEARÁ",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    codigo: "RN",
    nome: "RIO GRANDE DO NORTE",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    codigo: "PB",
    nome: "PARAÍBA",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    codigo: "PE",
    nome: "PERNAMBUCO",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    codigo: "AL",
    nome: "ALAGOAS",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    codigo: "SE",
    nome: "SERGIPE",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    codigo: "BA",
    nome: "BAHIA",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    codigo: "MG",
    nome: "MINAS GERAIS",
    regiao: { id: 3, sigla: "SE", nome: "Sudeste" },
  },
  {
    codigo: "ES",
    nome: "ESPÍRITO SANTO",
    regiao: { id: 3, sigla: "SE", nome: "Sudeste" },
  },
  {
    codigo: "RJ",
    nome: "RIO DE JANEIRO",
    regiao: { id: 3, sigla: "SE", nome: "Sudeste" },
  },
  {
    codigo: "SP",
    nome: "SÃO PAULO",
    regiao: { id: 3, sigla: "SE", nome: "Sudeste" },
  },
  { codigo: "PR", nome: "PARANÁ", regiao: { id: 4, sigla: "S", nome: "Sul" } },
  {
    codigo: "SC",
    nome: "SANTA CATARINA",
    regiao: { id: 4, sigla: "S", nome: "Sul" },
  },
  {
    codigo: "RS",
    nome: "RIO GRANDE DO SUL",
    regiao: { id: 4, sigla: "S", nome: "Sul" },
  },
  {
    codigo: "MS",
    nome: "MATO GROSSO DO SUL",
    regiao: { id: 5, sigla: "CO", nome: "Centro-Oeste" },
  },
  {
    codigo: "MT",
    nome: "MATO GROSSO",
    regiao: { id: 5, sigla: "CO", nome: "Centro-Oeste" },
  },
  {
    codigo: "GO",
    nome: "GOIÁS",
    regiao: { id: 5, sigla: "CO", nome: "Centro-Oeste" },
  },
  {
    codigo: "DF",
    nome: "DISTRITO FEDERAL",
    regiao: { id: 5, sigla: "CO", nome: "Centro-Oeste" },
  },
];

export const uf = unidadesFederativa.sort((a, b) => {
  if (a.nome > b.nome) return 1;
  else return -1;
});

export const tipoPessoa = [
  {
    id: "F",
    nome: "FÍSICA",
  },
  {
    id: "J",
    nome: "JURÍDICA",
  },
  {
    id: "N",
    nome: "AMBAS",
  },
];

export const genero = [
  {
    id: "M",
    nome: "MASCULINO",
  },
  {
    id: "F",
    nome: "FEMININO",
  },
  {
    id: "N",
    nome: "NEUTRO",
  },
];

export const motivoInativacaoBeneficiario = [
  {
    id: "D",
    nome: "DESLIGADO",
  },
  {
    id: "O",
    nome: "OPÇÃO DO SEGURADO",
  },
  {
    id: "E",
    nome: "EXTENSÃO DO BENEFICIÁRIO",
  },
];

export const relacaoParentesco = [
  {
    id: "F",
    nome: "FILHO(A)",
  },
  {
    id: "P",
    nome: "PAI",
  },
  {
    id: "M",
    nome: "MÃE",
  },
  {
    id: "A",
    nome: "AVÔ(Ó)",
  },
  {
    id: "C",
    nome: "CÔNJUGE/COMPANHEIRO",
  },
];

export const meses = [
  { mes: "jan", ref: 1 },
  { mes: "fev", ref: 2 },
  { mes: "mar", ref: 3 },
  { mes: "abr", ref: 4 },
  { mes: "mai", ref: 5 },
  { mes: "jun", ref: 6 },
  { mes: "jul", ref: 7 },
  { mes: "ago", ref: 8 },
  { mes: "set", ref: 9 },
  { mes: "out", ref: 10 },
  { mes: "nov", ref: 11 },
  { mes: "dez", ref: 12 },
];

export const situacaoManutencaoCliente = [
  {
    id: "N",
    nome: "Nova",
  },
  {
    id: "A",
    nome: "Aguardando Conclusão",
  },
  {
    id: "F",
    nome: "Finalizada",
  },
];
