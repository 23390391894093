import basicFlow from "./asyncHandler";
import { authenticatedRequest } from "../utils/api";
import { routeWatcher } from "./rotas.saga";
import { types as routes } from "../reducers/rotas.actions";
import { actions as faturamentoActions } from "../reducers/faturamento.actions";
import { saveAs } from "file-saver";

import { put, select } from "redux-saga/effects";
import { getPayload } from "../selectors/routes.selectors";

const ListarFaturamentossApi = (values) => {
  return authenticatedRequest({
    url: `/faturamentos`,
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const ListarFaturamentos = basicFlow({
  actionGenerator: faturamentoActions.obterFaturamentos,
  api: ListarFaturamentossApi,
});

function* ListarFaturamentosRouteWatcher() {
  yield routeWatcher(routes.FATURAMENTOS, function* () {
    const cliente = yield select(getPayload);
    if (cliente.idCliente) {
      yield put(
        faturamentoActions.obterFaturamentos.request({
          filtro: {
            cliente: cliente.idCliente,
            saude: true,
            odonto: true,
            vida: true,
          },
        })
      );
    }
  });
}

const BaixarArtefatoApi = (values) => {
  return authenticatedRequest({
    url: `/documento/artefato/filters`,
    isResourceService: true,
    method: "post",
    body: {
      ...values,
    },
  });
};

const BaixarArtefato = basicFlow({
  actionGenerator: faturamentoActions.baixarArtefato,
  api: BaixarArtefatoApi,
  postSuccess: ({ response, original, values }) => {
    downloadFile(
      response.data.file,
      response.data.type,
      512,
      values.filename ?? response.data.name
    );
  },
});

const downloadFile = (b64Data, contentType = "", sliceSize = 512, filename) => {
  const byteCharacters = window.atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  saveAs(blob, filename);
};

export const sagas = [
  ListarFaturamentosRouteWatcher(),
  ListarFaturamentos.watcher(),
  BaixarArtefato.watcher(),
];
