import React from "react";
import { Card, styled } from "@mui/material";
import { Grid } from "@mui/material";
import {
  ControlledTextField,
  ZipCodeTextField,
} from "../../../components/inputs";
import { MenuItem } from "../../../components";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { actions } from "../../../reducers/endereco.actions";
import { uf } from "../../../utils/parametros";
import ControlledSelect from "../../../components/inputs/ControlledSelect";

const StyledCard = styled(Card)`
  height: auto;
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 15px;
  background-color: #415781ff;

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const StyledGrid = styled(Grid)`
  padding: 5px;
  place-self: flex-start;
`;

const StyledGridContainer = styled(Grid)`
  margin-bottom: 0px;
`;

const StyledH2 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.white};
  text-align: left;
  letter-spacing: 1.8px;
  font-size: 28px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
`;

const CardEndereco = ({ formProps, editavel }) => {
  const dispatch = useDispatch();

  const successCallback = useCallback(
    ({ data }, value) => {
      formProps.setValue("logradouro", data?.logradouro || "");
      formProps.setValue("bairro", data?.bairro || "");
      formProps.setValue("uf", data?.uf || "");
      formProps.setValue("nomeMunicipio", data?.localidade || "");
    },
    [formProps]
  );

  const consultarCep = () => {
    if (
      !!formProps.getValues("cep") &&
      formProps.getValues("cep").length === 9
    ) {
      dispatch(
        actions.consultarCepCorreios.request({
          value: formProps.getValues("cep"),
          successCallback,
        })
      );
    }
  };

  return (
    <StyledCard>
      <StyledH2>Endereço</StyledH2>
      <StyledGridContainer container>
        <StyledGrid item xs={12} md={3}>
          <ControlledTextField
            formProps={formProps}
            fullWidth
            name="cep"
            label="CEP"
            onBlur={consultarCep}
            InputProps={{
              inputComponent: ZipCodeTextField,
            }}
            disabled={!editavel}
          />
        </StyledGrid>
        <StyledGrid item xs={12} md={6}>
          <ControlledTextField
            formProps={formProps}
            fullWidth
            name="logradouro"
            label="Endereço"
            inputProps={{ maxLength: 40 }}
            disabled={!editavel}
          />
        </StyledGrid>
        <StyledGrid item xs={12} md={3}>
          <ControlledTextField
            formProps={formProps}
            fullWidth
            name="numeroLogradouro"
            label="Número"
            inputProps={{ maxLength: 5 }}
            disabled={!editavel}
          />
        </StyledGrid>
        <StyledGrid item xs={12} md={3}>
          <ControlledTextField
            formProps={formProps}
            fullWidth
            name="complemento"
            label="Complemento"
            inputProps={{
              maxLength: 30,
            }}
            disabled={!editavel}
          />
        </StyledGrid>
        <StyledGrid item xs={12} md={3}>
          <ControlledTextField
            formProps={formProps}
            fullWidth
            name="bairro"
            label="Bairro"
            inputProps={{
              maxLength: 40,
            }}
            disabled={!editavel}
          />
        </StyledGrid>
        <StyledGrid item xs={12} md={3}>
          <ControlledTextField
            formProps={formProps}
            fullWidth
            name="nomeMunicipio"
            label="Município"
            inputProps={{
              maxLength: 60,
            }}
            disabled={!editavel}
          />
        </StyledGrid>
        <StyledGrid item xs={12} md={3}>
          <ControlledSelect
            name={"uf"}
            formProps={formProps}
            label={"UF"}
            margin={"none"}
            disabled={!editavel}
          >
            {uf.map((item) => (
              <MenuItem
                className={"select-submenu-item"}
                key={item?.codigo}
                value={item?.codigo}
              >
                {item.nome}
              </MenuItem>
            ))}
          </ControlledSelect>
        </StyledGrid>
      </StyledGridContainer>
    </StyledCard>
  );
};

export default CardEndereco;
