import React from "react";
import { Box, Card, styled } from "@mui/material";
import { Grid } from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
import FileInput from "../../components/inputs/FileInput";

const StyledCard = styled(Card)`
  background-color: white;
  width: 100%;
  margin: auto;
  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  box-shadow: 0px 0px !important;
  border: 1.5px solid #b4cfe7ff;
  margin-bottom: 0px;
`;

const StyledGrid = styled(Grid)`
  padding: 0px;
`;

const StyledH2 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.blueTitle};
  letter-spacing: 1.8px;
  font-size: 20px;
  margin-left: 15px;
  margin-bottom: -10px;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
`;

const StyledH4 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  letter-spacing: 1.2px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 12px;
  }
`;

const StyledBox = styled(Box)`
  width: 100% !important;
  height: 200px !important;
  text-align: center;
  background: repeating-linear-gradient(
    135deg,
    #ffffffff,
    #ffffffff 30px,
    #ebf2f8ff 30px,
    #ebf2f8ff 60px
  );

  border: 2px dashed #b4cfe7ff;
  border-radius: 15px;
`;

const StyledFileContainer = styled(Box)`
  padding: 20px;
`;

const OutrosDocumentosVazio = ({
  formProps,
  isFile,
  setIsFile,
  file,
  setFile,
  setIsFileImplantacao,
  isFileImplantacao,
  fileImplantacao,
  setFileImplantacao,
  editFileImplantacao,
  setEditFileImplantacao,
  editFile,
  setEditFile,
  handleDownloadArtefato,
  setIsFileOD,
  isFileOD,
  fileOD,
  setFileOD,
  arrayFileOD,
  setArrayFileOD,
  index,
}) => {
  return (
    <StyledGrid item xs={6}>
      <StyledCard>
        <StyledH2>Outros Documentos</StyledH2>
        <StyledFileContainer>
          <FileInput
            name={"fileSeguradoras"}
            formProps={formProps}
            maxFiles={1}
            accept={".pdf"}
            multiple={false}
            onDrop={(acceptedFiles) => {
              setFileOD([...fileOD, acceptedFiles]);
              setArrayFileOD([
                ...arrayFileOD,
                { name: acceptedFiles[0].name, artefato: "XXXXXXXX" },
              ]);
            }}
          >
            <StyledBox
              sx={{
                cursor: "pointer",
                width: "100%",
                height: "100%",
                "&:hover": {
                  opacity: [0.9, 0.8, 0.7],
                },
              }}
            >
              <StyledH4 style={{ marginTop: "50px" }}>
                Arraste o arquivo para cá
              </StyledH4>
              <StyledH4>ou clique para selecioná-lo</StyledH4>
              <StyledH4>
                <CloudUpload style={{ fontSize: "30px", marginTop: "-5px" }} />
              </StyledH4>
            </StyledBox>
          </FileInput>
        </StyledFileContainer>
      </StyledCard>
    </StyledGrid>
  );
};

export default OutrosDocumentosVazio;
