import React from "react";
import Popover from "@mui/material/Popover";
import { useState } from "react";
import { styled, Tooltip } from "@mui/material";
import { MenuItem } from "../../../components";
import Button from "../../../components/buttons/PrimaryButton";
import { Select } from "../../../components/inputs";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRateioSituacao } from "../../../selectors/rateio.selectors";
import { actions } from "../../../reducers/rateio.actions";
import { getPayload } from "../../../selectors/routes.selectors";

const SyledContainer = styled("div")`
  min-width: 200px;
  padding: 10px;
  display: flex;
  flex-flow: column wrap;
  background-color: #415781ff;
`;

const StyledSelect = styled(Select)`
  min-width: 200px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const StyledButton = styled(Button)`
  margin-top: 60px;
  background-color: ${({ theme, buttoncolor }) =>
    buttoncolor === "P" ? "#219653" : theme.buttons.primary.background};

  &:hover {
    background-color: ${({ theme, buttoncolor }) =>
      buttoncolor === "P" ? "#018E06" : theme.buttons.primary.backgroundHover};
  }
`;

export default function SituacaoPopover() {
  const [anchorEl, setAnchorEl] = useState(false);
  const [value, setValue] = useState("N");

  const dispatch = useDispatch();

  const { data: situacao, loading } = useSelector(getRateioSituacao);
  const { idRateio } = useSelector(getPayload);

  useEffect(() => {
    setValue(situacao);
  }, [situacao]);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    dispatch(
      actions.atualizarSituacao.request({
        situacao: e.target.value,
        id: idRateio,
      })
    );
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Tooltip title="Situação">
        <StyledButton
          loading={loading}
          buttoncolor={situacao}
          aria-describedby={"idPopover"}
          onClick={handleClick}
        >
          {situacao === "N"
            ? "Pendente"
            : situacao === "P"
            ? "Pago"
            : "Aprovado"}
        </StyledButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <SyledContainer>
          <StyledSelect label="Situação" value={value} onChange={handleChange}>
            <MenuItem value={"N"}>Pendente</MenuItem>
            <MenuItem value={"A"}>Aprovado</MenuItem>
            <MenuItem value={"P"}>Pago</MenuItem>
          </StyledSelect>
        </SyledContainer>
      </Popover>
    </>
  );
}
