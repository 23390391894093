import { createAsyncAction } from "../utils/actionCreators";

export const types = {
  CONSULTAR_CEP_CORREIOS: "@@/propostas/CONSULTAR_CEP_CORREIOS",
};

export const actions = {
  consultarCepCorreios: createAsyncAction(types.CONSULTAR_CEP_CORREIOS),
};



