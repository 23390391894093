import BasePage from "../BasePage";
import React from "react";
import { styled } from "@mui/material";
import { Grid } from "@mui/material";
import CardFaturamento from "./CardFaturamento";
import Spinner from "../../components/spinner/Spinner";
import OutlinedButton from "../../components/buttons/OutlinedButton";
import { FilterChip, Pagination } from "../../components";
import { ControlledPesquisa } from "../../components/inputs";
import SearchButton from "../../components/buttons/SearchButton";
import { useDispatch } from "react-redux";
import { actions } from "../../reducers/faturamento.actions";

const StyledH1 = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.blueTitle};
  letter-spacing: 4.8px;
  font-size: 45px;
  margin-bottom: 0px;
  @media (max-width: 900px) {
    font-size: 22px;
  }
`;

const StyledH4 = styled("h4")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  color: ${({ theme }) => theme.colors.subtitle};
  letter-spacing: 4.8px;
  margin-bottom: 15px;
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 20px;
  margin-top: 0px;

  display: flex;
  align-items: flex-end;
  letter-spacing: 0.25px;

  @media (max-width: 900px) {
    font-size: 22px;
  }
`;

const StyledContainer = styled(Grid)`
  margin-top: 1px;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 900px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;
const StyledBasePage = styled(BasePage)`
  padding: 3px;
`;

const ClientesPage = ({
  data,
  nomeCliente,
  filtrarFaturamento,
  loading,
  handleClickEdit,
  handleClickVoltar,
  formProps,
  count,
  page,
  handleDownloadArtefato,
  saude,
  odonto,
  vida,
  competenciaInicial,
  competenciaFinal,
  setUpdate,
  update,
}) => {
  const dispatch = useDispatch();

  const handleFiltro = (filtro, value) => {
    const filtrosTela = {
      saude,
      odonto,
      vida,
      competenciaInicial,
      competenciaFinal,
    };

    filtrosTela[filtro] = value;

    dispatch(actions.mudarFiltro({ ...filtrosTela }));
    setUpdate(update + 1);
  };

  return (
    <StyledBasePage isLoggedIn={true} isLightBlue={true}>
      <form onSubmit={formProps.handleSubmit(filtrarFaturamento)}>
        <StyledContainer container spacing={2}>
          <Grid item xs={12}>
            <StyledH1>Histórico de Faturamento</StyledH1>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: "0px" }}>
            <StyledH4>{nomeCliente}</StyledH4>
          </Grid>
          {!!loading && (
            <Grid item xs={12}>
              <Spinner />
            </Grid>
          )}
          {!loading && (
            <>
              <Grid
                item
                xs={7}
                style={{ justifyContent: "left", display: "flex" }}
              >
                <FilterChip
                  active={saude}
                  label={"SAÚDE"}
                  onClick={() => {
                    handleFiltro("saude", !saude);
                  }}
                />
                <FilterChip
                  active={odonto}
                  label={"ODONTO"}
                  onClick={() => {
                    handleFiltro("odonto", !odonto);
                  }}
                />
                <FilterChip
                  active={vida}
                  label={"VIDA"}
                  onClick={() => {
                    handleFiltro("vida", !vida);
                  }}
                />
              </Grid>
              <Grid
                item
                xs={2}
                style={{ justifyContent: "right", display: "flex" }}
              >
                <ControlledPesquisa
                  formProps={formProps}
                  fullWidth={false}
                  name="competenciaInicial"
                  label="Data Início"
                  style={{ width: "100%" }}
                  type="date"
                  InputProps={{
                    max: `${new Date().getFullYear()}-${
                      new Date().getMonth() > 8
                        ? new Date().getMonth() + 1
                        : "0" + (new Date().getMonth() + 1)
                    }`,
                    min: "2022-01",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={2}
                style={{ justifyContent: "right", display: "flex" }}
              >
                <ControlledPesquisa
                  formProps={formProps}
                  fullWidth={false}
                  name="competenciaFinal"
                  label="Data Fim"
                  style={{ width: "100%" }}
                  type="date"
                  InputProps={{
                    max: `${new Date().getFullYear()}-${
                      new Date().getMonth() > 8
                        ? new Date().getMonth() + 1
                        : "0" + (new Date().getMonth() + 1)
                    }`,
                    min: "2022-01",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={1}
                style={{ justifyContent: "right", display: "flex" }}
              >
                <SearchButton type={"submit"} />
              </Grid>
            </>
          )}

          {!loading &&
            data?.length > 0 &&
            data?.map((item, index) => {
              return (
                <>
                  <Grid
                    key={"index"}
                    item
                    xs={12}
                    md={4}
                    style={{ marginTop: "10px" }}
                  >
                    <CardFaturamento
                      handleClickEdit={handleClickEdit}
                      item={item}
                      handleDownloadArtefato={handleDownloadArtefato}
                    />
                  </Grid>
                </>
              );
            })}
          {!loading && data?.length === 0 && (
            <Grid item xs={12}>
              Não existem faturamentos cadastrados na base
            </Grid>
          )}
        </StyledContainer>
        {!loading && (
          <>
            <StyledContainer
              container
              spacing={2}
              direction="row"
              justifyContent="flex-start"
            >
              <Grid item xs={12}>
                <Pagination count={Math.ceil(count / 9)} page={page} />
              </Grid>
            </StyledContainer>
            <StyledContainer
              container
              spacing={2}
              direction="row"
              justifyContent="center"
            >
              <Grid item xs={4} style={{ marginTop: "20px" }}>
                <OutlinedButton onClick={handleClickVoltar}>
                  Voltar
                </OutlinedButton>
              </Grid>
            </StyledContainer>
          </>
        )}
      </form>
    </StyledBasePage>
  );
};

export default ClientesPage;
