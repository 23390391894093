import React from "react";
import BasePage from "../BasePage";
import TrocarSenhaForm from "./TrocarSenhaForm.connected";
import EsqueciSenhaForm from "./EsqueciSenhaForm.connected";
import Loader from "../../components/loaders";
import { ReactComponent as Logo } from "../../assets/Pick-curvas.svg";
import { Grid } from "@mui/material";
import { styled } from "@mui/system";
import Card from "../../components/Card";

const StyledLogo = styled(Logo)`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 30%;
  height: 30%;
`;

const StyledLogoGrid = styled(Grid)`
  text-align: center;
`;
const StyledGrid = styled(Grid)`
  margin: auto;
  max-width: 350px;
`;

const StyledCard = styled(Card)`
  margin-top: 40px;
  height: auto;
  padding: 25px;
  margin-bottom: 25px;
  background-color: #415781ff;

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const StyledH1 = styled("h2")`
  text-align: center;
  margin-top: -10px;
  font-family: ${({ theme }) => theme.fonts.family.title};
  letter-spacing: 1.8px;
  text-align: left;
  margin-bottom: 10px;

  color: #ffffff;
  font-size: 18px;
  @media (max-width: 900px) {
    font-size: 15px;
    margin-bottom: 10px;
  }
`;
const StyledH2 = styled("h2")`
  text-align: center;
  margin-top: 5px;
  font-family: ${({ theme }) => theme.fonts.family.title};
  letter-spacing: 1.8px;
  font-weight: normal;
  text-align: left;
  margin-bottom: 15px;

  color: #ffffff;
  font-size: 14px;
  @media (max-width: 900px) {
    font-size: 12px;
    margin-bottom: 10px;
  }
`;

const TrocarSenhaPage = ({ loading, type, valid }) => {
  return (
    <BasePage isLightBlue={true}>
      <StyledGrid alignItems="center" justifyContent="center" container>
        <StyledCard>
          <StyledLogoGrid item xs={12} md={12}>
            <StyledLogo />
          </StyledLogoGrid>
          {!!loading && <Loader type={"dots"} color={"brand"} size={80} />}
          {!loading && (
            <>
              <StyledH1 style={{ textAlign: "center" }}>
                {type === "P" ? "Primeiro acesso" : "Redefinição de senha"}
              </StyledH1>
            </>
          )}
          {!loading && !!valid && <TrocarSenhaForm />}
          {!loading && !valid && (
            <>
              <StyledH2 style={{ textAlign: "left" }}>
                O link de redefinição de sua senha não é válido ou já foi
                utilizado. Se necessário, solicite outro link abaixo.
              </StyledH2>
              <EsqueciSenhaForm internal={true} type={type} />
            </>
          )}
        </StyledCard>
      </StyledGrid>
    </BasePage>
  );
};

export default TrocarSenhaPage;
