import React from "react";
import { Dialog, DialogContent, Grid, styled } from "@mui/material";
import { HighlightOffSharp } from "@mui/icons-material";
import Card from "../../../components/Card";
import { Pagination } from "../../../components";
import Spinner from "../../../components/spinner/Spinner";
import TabelaRateios from "./TabelaRateios";

const IconsGrid = styled(Grid)`
  text-align: right;
  align-items: center;
`;

const CardTitle = styled("p")`
  font-family: Patua One;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: left;
  margin-top: -10px;
  margin-bottom: -10px;

  letter-spacing: 1.8px;

  color: #ffffff;
`;

const StyledCloseSharp = styled(HighlightOffSharp)`
  width: 35px;
  height: 32px;
  fill: ${({ theme }) => theme.colors.black};
  margin-left: 10px;
  cursor: pointer;
`;

const StyledCard = styled(Card)`
  height: auto;
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 25px;
  background-color: #415781ff;

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const StyledSpinnerContainer = styled("div")`
  height: 350px;
  width: 1000px;
  border-radius: 25px;
  padding: -40px;
  margin-top: -20px;
`;

const ModalRateio = ({
  handleClose,
  open,
  handleGoToRateios,
  rateio,
  count,
  page,
  data,
  handlePagination,
  loading,
}) => {
  return (
    <Dialog
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "1000px",
          },
        },
      }}
      onClose={handleClose}
      open={open}
      rateio={rateio}
    >
      {!!open && (
        <DialogContent>
          <IconsGrid item xs={12} md={12}>
            <StyledCloseSharp onClick={handleClose} />
          </IconsGrid>
          {!loading ? (
            <StyledCard>
              <CardTitle>Rateio/Faturamento no mês</CardTitle>
              <TabelaRateios
                handleGoToRateios={handleGoToRateios}
                data={data}
              />
            </StyledCard>
          ) : (
            <StyledSpinnerContainer>
              <Spinner />
            </StyledSpinnerContainer>
          )}
          {!loading && (
            <Pagination
              onChange={handlePagination}
              count={Math.ceil(count / 5)}
              page={page}
            />
          )}
        </DialogContent>
      )}
    </Dialog>
  );
};

export default ModalRateio;
