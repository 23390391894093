import { actions, types } from "./cliente.actions";
import { types as routeActions } from "./rotas.actions";

const initialState = {
  clientes: {
    loading: false,
    page: 1,
    pesquisa: "",
    filtros: {
      ativos: true,
      inativos: true,
      cliente: true,
      subestipulante: true,
    },
  },
  cliente: {
    loading: false,
    data: {
      cnpj: "",
      nomeCliente: "",
      nomeClienteErp: "",
      emailContato: "",
      emailsOpcionais: "",
      telefoneContato: "",
      telefoneOpcional: "",
      logradouro: "",
      numeroLogradouro: "",
      complemento: "",
      bairro: "",
      nomeMunicipio: "",
      uf: "",
      usuario: "",
      usuarioResponsavel: "",
    },

    subestipulantes: [],
  },
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.obterClientes.REQUEST:
    case actions.obterClientes.SUCCESS:
    case actions.obterClientes.FAILURE:
      return {
        ...state,
        clientes: {
          ...state.clientes,
          pesquisa:
            action.type === actions.obterClientes.REQUEST &&
            !!action.payload.pesquisa
              ? action.payload.pesquisa
              : state.clientes.pesquisa,
          loading: action.type === actions.obterClientes.REQUEST,
          data:
            action.type === actions.obterClientes.SUCCESS
              ? action.payload.response.data.resultado
              : [],
          page:
            action.type === actions.obterClientes.SUCCESS
              ? action.payload.response.data.paginaAtual
              : state.clientes.page,
          count:
            action.type === actions.obterClientes.SUCCESS
              ? action.payload.response.data.total
              : [],
        },
      };
    case actions.editarCliente.REQUEST:
    case actions.editarCliente.SUCCESS:
    case actions.editarCliente.FAILURE:
      return {
        ...state,
        cliente: {
          ...state.cliente,
          loading: action.type === actions.editarCliente.REQUEST,
          data:
            action.type === actions.editarCliente.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };

    case actions.mostrarCliente.REQUEST:
    case actions.mostrarCliente.SUCCESS:
    case actions.mostrarCliente.FAILURE:
      return {
        ...state,
        cliente: {
          ...state.cliente,
          loading: action.type === actions.mostrarCliente.REQUEST,
          data:
            action.type === actions.mostrarCliente.SUCCESS
              ? {
                  ...action.payload.response.data,
                  usuario: action.payload.response.data.Usuario?.codigo,
                  usuarioResponsavel:
                    action.payload.response.data.UsuarioResponsavel?.id,
                }
              : [],
        },
      };
    case actions.deletarCliente.REQUEST:
    case actions.deletarCliente.SUCCESS:
    case actions.deletarCliente.FAILURE:
      return {
        ...state,
        clientes: {
          ...state.clientes,
          loading: action.type === actions.deletarCliente.REQUEST,
        },
      };

    case actions.ativarCliente.REQUEST:
    case actions.ativarCliente.SUCCESS:
    case actions.ativarCliente.FAILURE:
      return {
        ...state,
        clientes: {
          ...state.clientes,
          loading: action.type === actions.ativarCliente.REQUEST,
        },
      };

    case routeActions.NEW_CLIENTE:
      return {
        ...state,
        cliente: {
          ...state.cliente,
          data: {
            nomeCliente: "",
            emailContato: "",
            cep: "",
            emailsOpcionais: "",
            uf: "",
            complemento: "",
            logradouro: "",
            flagCalculaSinistralidade: false,
            clienteMatriz: "",
            usuario: "",
            usuarioResponsavel: "",
            bairro: "",
            nomeClienteErp: "",
            nomeMunicipio: "",
            cnpj: "",
            numeroLogradouro: "",
            telefoneContato: "",
            telefoneOpcional: "",
            situacao: "A",
          },
        },
      };
    case routeActions.EDIT_CLIENTE:
      return {
        ...state,
        cliente: {
          ...state.cliente,
          loading: true,
        },
      };
    case actions.obterSubestipulantes.REQUEST:
    case actions.obterSubestipulantes.SUCCESS:
    case actions.obterSubestipulantes.FAILURE:
      return {
        ...state,
        cliente: {
          ...state.cliente,
          subestipulantes:
            action.type === actions.obterSubestipulantes.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.listarApolices.REQUEST:
    case actions.listarApolices.SUCCESS:
    case actions.listarApolices.FAILURE:
      return {
        ...state,
        cliente: {
          ...state.cliente,
          apolices: {
            data:
              action.type === actions.listarApolices.SUCCESS
                ? action.payload.response.data
                : [],
            loading: action.type === actions.listarApolices.REQUEST,
          },
        },
      };
    case routeActions.NEW_SUB:
      return {
        ...state,
        cliente: {
          ...state.cliente,
          data: {
            nomeCliente: "",
            emailContato: "",
            cep: "",
            emailsOpcionais: "",
            uf: "",
            complemento: "",
            logradouro: "",
            flagCalculaSinistralidade: false,
            clienteMatriz: action.payload.idCliente,
            usuario: "",
            usuarioResponsavel: "",
            bairro: "",
            nomeClienteErp: "",
            nomeMunicipio: "",
            cnpj: "",
            numeroLogradouro: "",
            telefoneContato: "",
            telefoneOpcional: "",
            situacao: "A",
          },
        },
      };
    case types.SET_FILTROS:
      return {
        ...state,
        clientes: {
          ...state.clientes,
          filtros: {
            ativos: action.payload.ativos,
            inativos: action.payload.inativos,
            cliente: action.payload.cliente,
            subestipulante: action.payload.subestipulante,
          },
          pesquisa: action.payload?.pesquisa ?? "",
          page: action.payload?.page ?? 1,
        },
      };
    default:
      return state;
  }
};

export default reducer;
