import React from "react";
import { Box, Card, IconButton, styled } from "@mui/material";
import { Grid } from "@mui/material";
import {
  CloudUpload,
  Delete,
  GetApp,
  InsertDriveFile,
} from "@mui/icons-material";
import formatters from "../../../utils/formatters";
import FileInput from "../../../components/inputs/FileInput";

const StyledCard = styled(Card)`
  background-color: white;
  width: 100%;
  margin: auto;
  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px !important;
  border: 1.5px solid #b4cfe7ff;
`;

const StyledGrid = styled(Grid)``;

const StyledGridContainer = styled(Grid)`
  margin-bottom: 0px;
`;

const StyledH2 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.blueTitle};
  letter-spacing: 1.8px;
  font-size: 20px;
  margin-left: 15px;
  margin-bottom: -10px;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
`;

const StyledH4 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  letter-spacing: 1.2px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 12px;
  }
`;

const StyledBox = styled(Box)`
  width: 100% !important;
  height: 200px !important;
  text-align: center;
  background: repeating-linear-gradient(
    135deg,
    #ffffffff,
    #ffffffff 30px,
    #ebf2f8ff 30px,
    #ebf2f8ff 60px
  );

  border: 2px dashed #b4cfe7ff;
  border-radius: 15px;
`;
const StyledBoxFile = styled(Box)`
  width: 100% !important;
  height: 200px !important;
  text-align: center;
  background-color: #ebf2f8ff;

  border: 2px solid #b4cfe7ff;
  border-radius: 15px;
`;

const StyledFileContainer = styled(Box)`
  padding: 20px;
`;

const filesize = (size) => {
  if (size > 1024 * 1024) {
    // Mb
    return `${formatters.numbers.nFixed(
      Math.round(size / (1024 * 1024), 1),
      1
    )} Mb`;
  }
  if (size > 1024) {
    // Mb
    return `${formatters.numbers.nFixed(size / 1024, 1)} Kb`;
  }
  return `${size} bytes`;
};

const FormDocumentosSinistro = ({
  formProps,
  isFileAS,
  setIsFileAS,
  fileAS,
  setFileAS,
  editFileAS,
  setEditFileAS,
  handleDownloadArtefato,
}) => {
  return (
    <StyledGridContainer container spacing={2}>
      <StyledGrid item xs={8}>
        <StyledCard>
          <StyledH2>Arquivo Sinistralidade</StyledH2>
          <StyledFileContainer>
            {editFileAS && (
              <StyledBoxFile
                sx={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <>
                  <StyledH4>
                    <InsertDriveFile
                      style={{
                        fontSize: "60px",
                        marginTop: "15px",
                        color: "#415781ff",
                      }}
                    />
                  </StyledH4>
                  <StyledH4
                    style={{
                      marginLeft: "15px",
                      marginRight: "15px",
                      fontSize: `${
                        editFileAS?.nome?.length > 25 ? "10px" : "12px"
                      }`,
                    }}
                  >
                    {editFileAS?.nome}
                  </StyledH4>
                  <IconButton
                    onClick={(e) => {
                      setEditFileAS(false);
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <Delete
                      style={{
                        color: "#f00e0e",
                        cursor: "pointer",
                        fontSize: "30px",
                      }}
                    />
                  </IconButton>
                  <IconButton
                    onClick={(e) => {
                      handleDownloadArtefato({
                        artefato: editFileAS.artefato,
                        filename: editFileAS.name,
                      });

                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <GetApp
                      style={{
                        color: "#33c481",
                        cursor: "pointer",
                        fontSize: "30px",
                      }}
                    />
                  </IconButton>
                </>
              </StyledBoxFile>
            )}
            {!editFileAS && (
              <FileInput
                name={"fileSeguradoras"}
                formProps={formProps}
                maxFiles={1}
                multiple={false}
                onDrop={(acceptedFiles) => {
                  console.log(acceptedFiles);
                  setFileAS(acceptedFiles);
                  setIsFileAS(true);
                }}
              >
                {isFileAS && fileAS && (
                  <StyledBoxFile
                    sx={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <>
                      <StyledH4>
                        <InsertDriveFile
                          style={{
                            fontSize: "60px",
                            marginTop: "15px",
                            color: "#415781ff",
                          }}
                        />
                      </StyledH4>
                      <StyledH4
                        style={{
                          marginLeft: "15px",
                          marginRight: "15px",
                          fontSize: `${
                            fileAS[0].name.length > 25 ? "10px" : "12px"
                          }`,
                        }}
                      >
                        {fileAS[0].name}
                        <br />
                        {filesize(fileAS[0].size)}
                      </StyledH4>
                      <IconButton
                        onClick={(e) => {
                          setFileAS([]);
                          setIsFileAS(false);
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <Delete
                          style={{
                            color: "#f00e0e",
                            cursor: "pointer",
                            fontSize: "30px",
                          }}
                        />
                      </IconButton>
                    </>
                  </StyledBoxFile>
                )}
                {!isFileAS && (
                  <StyledBox
                    sx={{
                      cursor: "pointer",
                      width: "100%",
                      height: "100%",
                      "&:hover": {
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                  >
                    <StyledH4 style={{ marginTop: "50px" }}>
                      Arraste o arquivo para cá
                    </StyledH4>
                    <StyledH4>ou clique para selecioná-lo</StyledH4>
                    <StyledH4>
                      <CloudUpload
                        style={{ fontSize: "30px", marginTop: "-5px" }}
                      />
                    </StyledH4>
                  </StyledBox>
                )}
              </FileInput>
            )}
          </StyledFileContainer>
        </StyledCard>
      </StyledGrid>
    </StyledGridContainer>
  );
};

export default FormDocumentosSinistro;
