import basicFlow from "./asyncHandler";
import { authenticatedRequest } from "../utils/api";
import { routeWatcher } from "./rotas.saga";
import { types as routes } from "../reducers/rotas.actions";
import { actions as painel } from "../reducers/dominios.actions";
import { actions as relatoriosActions } from "../reducers/relatorios.actions";
import { saveAs } from "file-saver";
import { put } from "redux-saga/effects";
import formatters from "../utils/formatters";

function* RelatoriosRouteWatcher() {
  yield routeWatcher(routes.RELATORIOS, function* () {
    yield put(painel.listarClientes.request());
  });
}

const obterRelatoriosApi = (values) => {
  return authenticatedRequest({
    url: `/relatorio`,
    isResourceService: true,
    method: "post",
    responseType: "arraybuffer",
    body: {
      ...values,
    },
  });
};

const ListarValores = basicFlow({
  actionGenerator: relatoriosActions.obterRelatorio,
  api: obterRelatoriosApi,
  postSuccess: ({ response, original, values }) => {
    downloadPlanilha(response, original);
  },
});

const downloadPlanilha = (response, original) => {
  const bytes = new Uint8Array(response.data);

  const blob = new Blob([bytes], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  saveAs(
    blob,
    `relatorio-${original.nome}-${formatters.dates.withHoursNoSpace(
      new Date()
    )}.xlsx`
  );
};

export const sagas = [RelatoriosRouteWatcher(), ListarValores.watcher()];
