import { Badge, Grid, Tooltip } from "@mui/material";
import { styled } from "@mui/system";
import Card from "../../components/Card";
import { EditOffOutlined, ModeEditOutlined } from "@mui/icons-material";
import { ReactComponent as IconRecover } from "../../styles/icons/recover.svg";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";

const StyledGrid = styled(Grid)`
  text-align: left;
`;

const IconsGrid = styled(Grid)`
  text-align: right;
  margin-top: 20px;
  align-items: center;
`;

const StyledCard = styled(Card)`
  height: auto;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 0px;
  background-color: #415781ff;

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const TipoChips = styled("div")`
  font-family: Nunito;
  font-size: 14px;
  font-weight: 500;
  font-spacing: 0.5px;
  background-color: #fff;
  width: 87px;
  border-radius: 25px;
  text-align: center;
  margin: auto;
  margin-top: 20px;
`;

const SituacaoChips = styled("div")`
  font-family: Nunito;
  font-size: 14px;
  font-weight: 500;
  font-spacing: 0.5px;
  background-color: ${({ situacaocolor }) =>
    situacaocolor === "P" ? "#219653" : "#F24F13"};
  width: 87px;
  border-radius: 20px;
  text-align: center;
  margin: auto;
  color: #ffffff;
  margin-top: -10px;
`;

const NomeCliente = styled("div")`
  font-family: Nunito;
  font-size: 18px;
  font-weight: 300;
  font-spacing: 0.25px;
  margin: auto;
  margin-left: 5px;

  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const NomeApolice = styled("div")`
  font-family: Nunito;
  font-size: 14px;
  font-weight: 300;
  font-spacing: 0.25px;
  margin: auto;
  margin-left: 5px;

  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const NomeCompetencia = styled("div")`
  font-family: Nunito;
  font-size: 12px;
  font-weight: 300;
  font-spacing: 0.25px;
  margin: auto;
  margin-left: 5px;

  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledDownloadOutlinedIcon = styled(DownloadOutlinedIcon)`
  width: 35px;
  height: 35px;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
`;

const StyledAttachFileOutlinedIcon = styled(AttachFileOutlinedIcon)`
  width: 35px;
  height: 35px;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
`;

const StyledModeEdit = styled(ModeEditOutlined)`
  width: 35px;
  height: 35px;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
`;

const StyledEditOffOutlined = styled(EditOffOutlined)`
  width: 35px;
  height: 32px;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
  cursor: pointer;
`;

const StyledIconRecover = styled(IconRecover)`
  width: 35px;
  height: 32px;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
  cursor: pointer;
`;

const StyledLibraryIcon = styled(LibraryBooksOutlinedIcon)`
  width: 24px;
  height: 24px;
  margin-right: 6px;
  margin-bottom: -6px;
  color: #ffffff;
`;

const StyledBadge = styled(Badge)`
  margin-top: -30px;

  .MuiBadge-badge {
    margin-top: 2px;
    font-size: 12px !important;
  }

  & .MuiBadge-colorPrimary {
    background-color: #852500;
  }
`;

const CardApolice = ({
  item,
  handleClickEdit,
  handleClickDownload,
  handleOpenReativacao,
  handleClickEditDocumetos,
}) => {
  if (item.status === "N") {
    return (
      <StyledCard>
        <Grid container>
          <StyledGrid item xs={1} md={1}>
            <StyledLibraryIcon />
          </StyledGrid>
          <StyledGrid item xs={11} md={11}>
            <NomeCliente>{item.cliente}</NomeCliente>
          </StyledGrid>
          <StyledGrid item xs={1} md={1} />
          <StyledGrid item xs={11} md={11}>
            <NomeApolice>Apólice #{item.apolice}</NomeApolice>
          </StyledGrid>
          <StyledGrid item xs={1} md={1} />
          <StyledGrid item xs={7} md={7}>
            <NomeCompetencia>{item.competencia}</NomeCompetencia>
          </StyledGrid>
          <StyledGrid item xs={4} md={4}>
            <SituacaoChips situacaocolor={item.status}>
              {item.status === "N"
                ? "Pendente"
                : item.status === "A"
                ? "Aprovado"
                : "Pago"}
            </SituacaoChips>
          </StyledGrid>
        </Grid>
        <Grid container>
          <StyledGrid item xs={2} md={2}>
            <TipoChips>
              {item.tipoProduto === "D"
                ? "Dental"
                : item.tipoProduto === "V"
                ? "Vida"
                : "Saúde"}
            </TipoChips>
          </StyledGrid>

          <IconsGrid item xs={10} md={10}>
            <Tooltip title="Download">
              <StyledDownloadOutlinedIcon
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  handleClickDownload(item.id, item.apolice, item.cliente)
                }
              />
            </Tooltip>
            <Tooltip title="Documentos">
              <StyledBadge
                badgeContent={item.badge}
                color="primary"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <StyledAttachFileOutlinedIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleClickEditDocumetos(item.id, item.cliente)}
                />
              </StyledBadge>
            </Tooltip>
            <Tooltip title="Editar">
              <StyledModeEdit
                sx={{ cursor: "pointer" }}
                onClick={() => handleClickEdit(item.id, item.cliente)}
              />
            </Tooltip>
          </IconsGrid>
        </Grid>
      </StyledCard>
    );
  } else if (item.status === "A") {
    return (
      <StyledCard>
        <Grid container>
          <StyledGrid item xs={1} md={1}>
            <StyledLibraryIcon />
          </StyledGrid>
          <StyledGrid item xs={11} md={11}>
            <NomeCliente>{item.cliente}</NomeCliente>
          </StyledGrid>
          <StyledGrid item xs={1} md={1} />
          <StyledGrid item xs={11} md={11}>
            <NomeApolice>Apólice #{item.apolice}</NomeApolice>
          </StyledGrid>
          <StyledGrid item xs={1} md={1} />
          <StyledGrid item xs={7} md={7}>
            <NomeCompetencia>{item.competencia}</NomeCompetencia>
          </StyledGrid>
          <StyledGrid item xs={4} md={4}>
            <SituacaoChips situacaocolor={item.status}>
              {item.status === "N"
                ? "Pendente"
                : item.status === "A"
                ? "Aprovado"
                : "Pago"}
            </SituacaoChips>
          </StyledGrid>
        </Grid>
        <Grid container>
          <StyledGrid item xs={2} md={2}>
            <TipoChips>
              {item.tipoProduto === "D"
                ? "Dental"
                : item.tipoProduto === "V"
                ? "Vida"
                : "Saúde"}
            </TipoChips>
          </StyledGrid>

          <IconsGrid item xs={10} md={10}>
            <Tooltip title="Download">
              <StyledDownloadOutlinedIcon
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  handleClickDownload(item.id, item.apolice, item.cliente)
                }
              />
            </Tooltip>
            <Tooltip title="Desaprovar">
              <StyledIconRecover
                sx={{ cursor: "pointer" }}
                onClick={() => handleOpenReativacao(item)}
              />
            </Tooltip>
            <Tooltip title="Documentos">
              <StyledBadge
                badgeContent={item.badge}
                color="primary"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <StyledAttachFileOutlinedIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleClickEditDocumetos(item.id)}
                />
              </StyledBadge>
            </Tooltip>
            <Tooltip title="Consultar">
              <StyledEditOffOutlined
                sx={{ cursor: "pointer" }}
                onClick={() => handleClickEdit(item.id)}
              />
            </Tooltip>
          </IconsGrid>
        </Grid>
      </StyledCard>
    );
  } else {
    return (
      <StyledCard>
        <Grid container>
          <StyledGrid item xs={1} md={1}>
            <StyledLibraryIcon />
          </StyledGrid>
          <StyledGrid item xs={11} md={11}>
            <NomeCliente>{item.cliente}</NomeCliente>
          </StyledGrid>
          <StyledGrid item xs={1} md={1} />
          <StyledGrid item xs={11} md={11}>
            <NomeApolice>Apólice #{item.apolice}</NomeApolice>
          </StyledGrid>
          <StyledGrid item xs={1} md={1} />
          <StyledGrid item xs={7} md={7}>
            <NomeCompetencia>{item.competencia}</NomeCompetencia>
          </StyledGrid>
          <StyledGrid item xs={4} md={4}>
            <SituacaoChips situacaocolor={item.status}>
              {item.status === "N"
                ? "Pendente"
                : item.status === "A"
                ? "Aprovado"
                : "Pago"}
            </SituacaoChips>
          </StyledGrid>
        </Grid>
        <Grid container>
          <StyledGrid item xs={2} md={2}>
            <TipoChips>
              {item.tipoProduto === "D"
                ? "Dental"
                : item.tipoProduto === "V"
                ? "Vida"
                : "Saúde"}
            </TipoChips>
          </StyledGrid>

          <IconsGrid item xs={10} md={10}>
            <Tooltip title="Download">
              <StyledDownloadOutlinedIcon
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  handleClickDownload(item.id, item.apolice, item.cliente)
                }
              />
            </Tooltip>
            <Tooltip title="Documentos">
              <StyledBadge
                badgeContent={item.badge}
                color="primary"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <StyledAttachFileOutlinedIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleClickEditDocumetos(item.id)}
                />
              </StyledBadge>
            </Tooltip>
            <Tooltip title="Consultar">
              <StyledEditOffOutlined
                sx={{ cursor: "pointer" }}
                onClick={() => handleClickEdit(item.id)}
              />
            </Tooltip>
          </IconsGrid>
        </Grid>
      </StyledCard>
    );
  }
};

export default CardApolice;
