import BeneficiariosPage from "./BeneficiariosPage";
import { getBeneficiarios } from "../../selectors/beneficiario.selectors";
import { getPayload } from "../../selectors/routes.selectors";
import { useSelector, useDispatch } from "react-redux";
import {
  actions as routeActions,
  types as routes,
} from "../../reducers/rotas.actions";
import { actions } from "../../reducers/beneficiario.actions";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import validators from "../../utils/validators";

const BeneficiariosPageConnected = () => {
  const [open, setOpen] = useState(false);

  const initialValues = {
    pesquisa: "",
  };

  const rules = {
    pesquisa: validators.string({ required: false }),
  };

  const formProps = {
    ...useForm({ defaultValues: initialValues }),
    rules,
  };

  const deleteFormProps = {
    ...useForm({ defaultValues: { situacao: "" } }),
    rules: { situacao: validators.string({ required: true }) },
  };

  const dispatch = useDispatch();
  const { loading, data, page, count, filter, pesquisa } = useSelector(
    getBeneficiarios
  );
  const payload = useSelector(getPayload);
  const { subestipulante } = useSelector(getPayload);

  const handleNewBeneficiario = useCallback(() => {
    dispatch(
      routeActions.redirectTo(routes.NEW_BENEFICIARIO, {
        idCliente: payload.idCliente,
      })
    );
  }, [dispatch, payload]);

  const handleCargaBeneficiarios = useCallback(() => {
    dispatch(
      routeActions.redirectTo(routes.CARGA_BENEFICIARIOS, {
        idCliente: payload.idCliente,
      })
    );
  }, [dispatch, payload]);

  const handleMigrarPlano = useCallback(() => {
    dispatch(
      routeActions.redirectTo(routes.MIGRAR_PLANO, {
        idCliente: payload.idCliente,
      })
    );
  }, [dispatch, payload]);

  const handleEditBeneficiario = useCallback(
    (id, tab) => {
      dispatch(
        routeActions.redirectTo(routes.EDIT_BENEFICIARIO, {
          idBeneficiario: id,
          idCliente: payload.idCliente,
          tab,
        })
      );
    },
    //eslint-disable-next-line
    [dispatch]
  );

  const handleDeleteBeneficiario = useCallback(
    (id, values) => {
      dispatch(
        actions.deletarBeneficiario.request({
          beneficiario: id,
          situacao: values.situacao,
          manutencao: values.manutencao,
        })
      );
      //eslint-disable-next-line
    },
    //eslint-disable-next-line
    [dispatch, payload]
  );

  const handleAtivarBeneficiario = useCallback(
    (id) => {
      dispatch(actions.ativarBeneficiario.request(id));
    },
    [dispatch]
  );

  const filtrarBeneficiarios = useCallback(
    (values) => {
      dispatch(
        actions.obterBeneficiarios.request({
          pesquisa,
          filtro: values,
          page: 1,
        })
      );
    },
    //eslint-disable-next-line
    [dispatch]
  );

  const handlePaginacao = useCallback(
    (filtro, page) => {
      dispatch(actions.obterBeneficiarios.request({ pesquisa, filtro, page }));
    },
    [dispatch, pesquisa]
  );

  const handlePesquisa = useCallback(
    (values, filtro) => {
      dispatch(
        actions.obterBeneficiarios.request({
          pesquisa: values.pesquisa,
          filtro: filtro,
          page: 1,
        })
      );
    },
    [dispatch]
  );

  const handleClickVoltar = useCallback(
    () => {
      if (subestipulante) {
        dispatch(
          routeActions.redirectTo(routes.EDIT_CLIENTE, {
            id: subestipulante,
            isSubestipulante: true,
            tab: 1,
          })
        );
      } else {
        dispatch(routeActions.redirectTo(routes.CLIENTES));
      }
    },
    //eslint-disable-next-line
    [dispatch]
  );

  useEffect(() => {
    if (payload.isBeneficiario) {
      setOpen(true);
    }
    payload.isBeneficiario = false;
  }, [payload]);

  const handleIncluir = () => {
    dispatch(
      routeActions.redirectTo(routes.NEW_DEPENDENTE, {
        idBeneficiario: payload.idBeneficiario,
        idCliente: payload.idCliente,
        tab: 0,
      })
    );
  };

  return (
    <BeneficiariosPage
      filtro={filter}
      loading={loading}
      data={data}
      page={page}
      count={count}
      handleNewBeneficiario={handleNewBeneficiario}
      handleEditBeneficiario={handleEditBeneficiario}
      handleDeleteBeneficiario={handleDeleteBeneficiario}
      handleAtivarBeneficiario={handleAtivarBeneficiario}
      filtrarBeneficiarios={filtrarBeneficiarios}
      handlePesquisa={handlePesquisa}
      formProps={formProps}
      handleClickVoltar={handleClickVoltar}
      handleCargaBeneficiarios={handleCargaBeneficiarios}
      handlePaginacao={handlePaginacao}
      deleteFormProps={deleteFormProps}
      handleMigrarPlano={handleMigrarPlano}
      open={open}
      handleClose={() => setOpen(false)}
      handleIncluir={handleIncluir}
    />
  );
};

export default BeneficiariosPageConnected;
