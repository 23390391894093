import basicFlow from "./asyncHandler";
import { authenticatedRequest } from "../utils/api";
import { routeWatcher } from "./rotas.saga";
import {
  actions as routeActions,
  types as routes,
} from "../reducers/rotas.actions";
import { actions as sinistroActions } from "../reducers/sinistro.actions";
import { actions as domainsActions } from "../reducers/dominios.actions";
import { saveAs } from "file-saver";

import { put, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import { getPayload } from "../selectors/routes.selectors";
import formatters from "../utils/formatters";

const ListarSinistrossApi = (values) => {
  return authenticatedRequest({
    url: `/sinistros`,
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const ListarSinistros = basicFlow({
  actionGenerator: sinistroActions.obterSinistros,
  api: ListarSinistrossApi,
});

function* ListarSinistrosRouteWatcher() {
  yield routeWatcher(routes.SINISTROS, function* () {
    yield put(domainsActions.listarSeguradoras.request());
    yield put(domainsActions.listarClientes.request());

    const cliente = yield select(getPayload);
    yield put(
      sinistroActions.obterSinistros.request({
        filtro: {
          cliente: cliente.idCliente
            ? {
                id: cliente.idCliente,
              }
            : null,
        },
      })
    );
  });
}

const EditarSinistrosApi = (values) => {
  return authenticatedRequest({
    url: `/sinistro/${values.get("id")}`,
    isResourceService: true,
    headers: { "Content-Type": "multipart/form-data" },
    method: "put",
    body: values,
  });
};

const EditarSinistros = basicFlow({
  actionGenerator: sinistroActions.editarSinistro,
  transform: function (payload) {
    const form = new FormData();
    const {
      valorSinistro,
      valorReceitaLiquida,
      fileAS,
      editFileAS,
      id,
      indice,
      reajuste,
      existeIndice,
    } = payload;

    if (indice && indice !== "") {
      form.append("indice", indice);
    }

    form.append("editFileAS", JSON.stringify(editFileAS));
    form.append("id", id);
    form.append("valorSinistro", valorSinistro);
    form.append("reajuste", reajuste);
    form.append("valorReceitaLiquida", valorReceitaLiquida);
    form.append("existeIndice", existeIndice);

    if (fileAS[0]) {
      form.append("arquivos", fileAS[0], "AS" + fileAS[0].name);
    }
    return form;
  },
  api: EditarSinistrosApi,
  postSuccess: function* ({ response }) {
    yield toast.success(response?.data?.message, {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    const cliente = yield select(getPayload);
    yield put(
      routeActions.redirectTo(routes.SINISTROS, {
        filtro: {
          cliente: {
            id: cliente.idCliente,
          },
        },
      })
    );
  },
});

const BaixarArtefatoApi = (values) => {
  return authenticatedRequest({
    url: `/documento/artefato/${values.artefato}`,
    isResourceService: true,
    method: "get",
  });
};

const BaixarArtefato = basicFlow({
  actionGenerator: sinistroActions.baixarArtefato,
  api: BaixarArtefatoApi,
  postSuccess: ({ response, original, values }) => {
    downloadFile(response.data.file, response.data.type, 512, values.filename);
  },
});

const downloadFile = (b64Data, contentType = "", sliceSize = 512, filename) => {
  const byteCharacters = window.atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  saveAs(blob, filename);
};

const criarSinistrosApi = (values) => {
  return authenticatedRequest({
    url: `/sinistro`,
    isResourceService: true,
    headers: { "Content-Type": "multipart/form-data" },
    method: "post",
    body: values,
  });
};

const CriarSinistros = basicFlow({
  actionGenerator: sinistroActions.criarSinistro,
  transform: function (payload) {
    const form = new FormData();

    const {
      cliente,
      numero,
      seguradora,
      tipo,
      dataAniversario,
      dataVencimento,
      diasCorte,
      dataContratacao,
      dataNegociacao,
      situacao,
      file,
      fileAS,
      precificacoes,
    } = payload;

    form.append("cliente", cliente);
    form.append("precificacoes", JSON.stringify(precificacoes));
    form.append("numero", numero);
    form.append("seguradora", seguradora);
    form.append("tipo", tipo);
    form.append("dataAniversario", dataAniversario);
    form.append("dataVencimento", dataVencimento);
    form.append("diasCorte", diasCorte);
    form.append("dataContratacao", dataContratacao);
    form.append("dataNegociacao", dataNegociacao);
    form.append("situacao", situacao);
    if (file[0]) {
      form.append("arquivos", file[0], "CG" + file[0].name);
    }
    if (fileAS[0]) {
      form.append("arquivos", fileAS[0], "CI" + fileAS[0].name);
    }

    return form;
  },
  api: criarSinistrosApi,
  postSuccess: function* () {
    yield toast.success("Apólice criada com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    const cliente = yield select(getPayload);
    routeActions.redirectTo(routes.SINISTROS, {
      cliente: cliente.idCliente,
      filtro: {
        cliente: {
          id: cliente.idCliente,
        },
      },
    });
  },
});

const deletarSinistrosApi = (values) => {
  return authenticatedRequest({
    url: `/sinistro/delete`,
    isResourceService: true,
    method: "post",
    body: {
      id: values,
    },
  });
};

const DeletarSinistros = basicFlow({
  actionGenerator: sinistroActions.deletarSinistro,
  api: deletarSinistrosApi,
  postSuccess: function* () {
    yield toast.success("Apólice inativada com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    const cliente = yield select(getPayload);
    yield put(
      sinistroActions.obterSinistros.request({
        filtro: {
          cliente: {
            id: cliente.idCliente,
          },
        },
      })
    );
  },
});

const MostrarSinistroApi = (values) => {
  return authenticatedRequest({
    url: `/sinistro/${values.id}`,
    isResourceService: true,
    method: "get",
  });
};

const MostrarSinistro = basicFlow({
  actionGenerator: sinistroActions.mostrarSinistro,
  api: MostrarSinistroApi,
});

const AtualizarSituacaoSinistroApi = (values) => {
  return authenticatedRequest({
    url: `/sinistro-situacao/${values.id}`,
    isResourceService: true,
    method: "PUT",
    body: {
      situacao: values.situacao,
    },
  });
};

const AtualizarSituacaoSinistro = basicFlow({
  actionGenerator: sinistroActions.atualizarSituacao,
  api: AtualizarSituacaoSinistroApi,
});

const AprovarSituacaoSinistroApi = (values) => {
  return authenticatedRequest({
    url: `/sinistro-situacao/${values.id}`,
    isResourceService: true,
    method: "PUT",
    body: {
      situacao: values.situacao,
    },
  });
};

const AprovarSituacaoSinistro = basicFlow({
  actionGenerator: sinistroActions.aprovarSituacao,
  api: AprovarSituacaoSinistroApi,
  postSuccess: function* () {
    yield toast.success("Sinistro aprovado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
  },
});

const ListarValoresApi = (values) => {
  return authenticatedRequest({
    url: `/sinistro/plano-valor/listar`,
    isResourceService: true,
    method: "get",
    queryParams: {
      ...values,
    },
  });
};

const ListarValores = basicFlow({
  actionGenerator: sinistroActions.obterValores,
  api: ListarValoresApi,
});

function* MostrarSinistroRouteWatcher() {
  yield routeWatcher(routes.EDIT_SINISTRO, function* () {
    let apolice = yield select(getPayload);
    yield put(
      sinistroActions.mostrarSinistro.request({ id: apolice.idSinistro })
    );
  });
}

const DownloadPlanilhaSinistroApi = (values) => {
  return authenticatedRequest({
    url: `/sinistro-exportacao/${values.id}`,
    isResourceService: true,
    method: "get",
    responseType: "arraybuffer",
  });
};

const DownloadPlanilhaSinistro = basicFlow({
  actionGenerator: sinistroActions.downloadSinistro,
  api: DownloadPlanilhaSinistroApi,
  postSuccess: ({ response, original }) => {
    downloadPlanilha(response, original);
  },
});

const downloadPlanilha = (response, original) => {
  var bytes = new Uint8Array(response.data);

  var blob = new Blob([bytes], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  saveAs(
    blob,
    `sinistro-#${original.numeroApolice}-${original.nomeCliente.replaceAll(
      " ",
      "_"
    )}-${formatters.dates.withHoursNoSpace(new Date())}.xlsx`
  );
};

export const sagas = [
  MostrarSinistroRouteWatcher(),
  ListarSinistrosRouteWatcher(),
  ListarSinistros.watcher(),
  MostrarSinistro.watcher(),
  EditarSinistros.watcher(),
  CriarSinistros.watcher(),
  DeletarSinistros.watcher(),
  ListarValores.watcher(),
  BaixarArtefato.watcher(),
  DownloadPlanilhaSinistro.watcher(),
  AtualizarSituacaoSinistro.watcher(),
  AprovarSituacaoSinistro.watcher(),
];
