import "./App.scss";
import Routes from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const App = () => {
  return (
    <>
      <ToastContainer position="bottom-left" />
      <Routes />
    </>
  );
};
export default App;
