import React from "react";
import { Box, Card, IconButton, styled } from "@mui/material";
import { Grid } from "@mui/material";
import { Delete, GetApp, InsertDriveFile } from "@mui/icons-material";

const StyledCard = styled(Card)`
  background-color: white;
  width: 100%;
  margin: auto;
  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  box-shadow: 0px 0px !important;
  border: 1.5px solid #b4cfe7ff;
  margin-bottom: 0px;
`;

const StyledGrid = styled(Grid)`
  padding: 0px;
`;

const StyledH2 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.blueTitle};
  letter-spacing: 1.8px;
  font-size: 20px;
  margin-left: 15px;
  margin-bottom: -10px;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
`;

const StyledH4 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  letter-spacing: 1.2px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 12px;
  }
`;

const StyledBoxFile = styled(Box)`
  width: 100% !important;
  height: 200px !important;
  text-align: center;
  background-color: #ebf2f8ff;

  border: 2px solid #b4cfe7ff;
  border-radius: 15px;
`;

const StyledFileContainer = styled(Box)`
  padding: 20px;
`;

const OutrosDocumentos = ({
  formProps,
  isFile,
  setIsFile,
  file,
  setFile,
  setIsFileImplantacao,
  isFileImplantacao,
  fileImplantacao,
  setFileImplantacao,
  editFileImplantacao,
  setEditFileImplantacao,
  editFile,
  setEditFile,
  handleDownloadArtefato,
  setIsFileOD,
  isFileOD,
  fileOD,
  setFileOD,
  arrayFileOD,
  setArrayFileOD,
  index,
  el,
}) => {
  return (
    <StyledGrid key={index} item xs={6}>
      <StyledCard>
        <StyledH2>Outros Documentos</StyledH2>
        <StyledFileContainer>
          <StyledBoxFile
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            <>
              <StyledH4>
                <InsertDriveFile
                  style={{
                    fontSize: "60px",
                    marginTop: "15px",
                    color: "#415781ff",
                  }}
                />
              </StyledH4>
              <StyledH4
                style={{
                  marginLeft: "15px",
                  marginRight: "15px",
                  fontSize: `${
                    arrayFileOD[index].name.length > 25 ? "10px" : "12px"
                  }`,
                }}
              >
                {arrayFileOD[index].name}
              </StyledH4>
              <IconButton
                onClick={(e) => {
                  let files = arrayFileOD.filter((file) => file !== el);
                  setArrayFileOD(files);
                  if (arrayFileOD[index].artefato === "XXXXXXXX") {
                    let filesToUpload = fileOD.filter(
                      (file) => file[0].name !== el.name
                    );
                    setFileOD(filesToUpload);
                  }

                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <Delete
                  style={{
                    color: "#f00e0e",
                    cursor: "pointer",
                    fontSize: "30px",
                  }}
                />
              </IconButton>
              {arrayFileOD[index].artefato !== "XXXXXXXX" && (
                <IconButton
                  onClick={(e) => {
                    handleDownloadArtefato({
                      artefato: arrayFileOD[index].artefato,
                      filename: arrayFileOD[index].name,
                    });

                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <GetApp
                    style={{
                      color: "#33c481",
                      cursor: "pointer",
                      fontSize: "30px",
                    }}
                  />
                </IconButton>
              )}
            </>
          </StyledBoxFile>
        </StyledFileContainer>
      </StyledCard>
    </StyledGrid>
  );
};

export default OutrosDocumentos;
