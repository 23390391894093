import React, { useState, useCallback } from "react";
import CargaBeneficiariosPage from "./MigrarPlanoPage";
import { useDispatch, useSelector } from "react-redux";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";
import { actions } from "../../../reducers/beneficiario.actions";
import { getPlanilhaMigrarPlano } from "../../../selectors/beneficiario.selectors";
import { getPayload } from "../../../selectors/routes.selectors";
import { toast } from "react-toastify";

const MigrarPlanoPageConnected = () => {
  const dispatch = useDispatch();
  const [file, setFile] = useState([]);
  const [isFile, setIsFile] = useState(false);
  const payload = useSelector(getPayload);

  const planilhaMigrarPlano = useSelector(getPlanilhaMigrarPlano);

  const handleDownload = useCallback(() => {
    dispatch(actions.baixarPlanilhaMigrarPlano.request(payload.idCliente));
  }, [dispatch, payload]);

  const handleUpload = useCallback(() => {
    if (file.length === 0) {
      toast.error("Favor selecionar um arquivo para processamento", {
        theme: "colored",
        icon: false,
        style: { backgroundColor: "#990a26" },
      });
    } else {
      dispatch(
        actions.uploadPlanilhaMigrarPlano.request({
          file: file[0],
          idCliente: payload.idCliente,
        })
      );
    }
  }, [dispatch, payload, file]);

  const handleVoltar = useCallback(() => {
    dispatch(
      routeActions.redirectTo(routes.BENEFICIARIOS, {
        idCliente: payload.idCliente,
      })
    );
  }, [dispatch, payload]);

  return (
    <CargaBeneficiariosPage
      handleDownload={handleDownload}
      handleUpload={handleUpload}
      file={file}
      setFile={setFile}
      setIsFile={setIsFile}
      isFile={isFile}
      handleVoltar={handleVoltar}
      loadingDownload={planilhaMigrarPlano.loadingDownload}
      loadingUpload={planilhaMigrarPlano.loadingUpload}
    />
  );
};

export default MigrarPlanoPageConnected;
