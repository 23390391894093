import React, { useCallback, useState } from "react";
import { Card, styled } from "@mui/material";
import { Grid } from "@mui/material";
import {
  ControlledTextField,
  ControlledSelect,
  CurrencyTextField,
} from "../../../components/inputs";
import { MenuItem } from "../../../components";
import { getCliente } from "../../../selectors/cliente.selectors";
import { useSelector } from "react-redux";
import OutlinedButton from "../../../components/buttons/OutlinedButton";
import TabelaDadosSeguro from "./tables/TabelaDadosSeguro";
import { getBeneficiario } from "../../../selectors/beneficiario.selectors";

const StyledCard = styled(Card)`
  height: auto;
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 15px;
  background-color: #415781ff;

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const StyledGrid = styled(Grid)`
  padding: 5px;
  place-self: flex-start;
`;

const StyledGridContainer = styled(Grid)`
  margin-bottom: 0px;
`;

const StyledH2 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.white};
  text-align: left;
  letter-spacing: 1.8px;
  font-size: 28px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
`;

const StyledControlledCustomTextField = styled(ControlledTextField)`
  .MuiOutlinedInput-input {
    color-scheme: dark;
  }
`;

const StyledOutlinedButton = styled(OutlinedButton)`
  border-color: #ffffff;
  color: #ffffff;
  margin-top: 15px;

  &:focus {
    outline: 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.buttons.secondary.backgroundHover};
    border-color: #ffffff;
  }
`;

const CardDadosSeguro = ({
  formProps,
  handleAddPrecificacao,
  buttonTitle,
  handleDeletePlano,
  planos,
  editavel,
  preencherValorPremioPorPlano,
  setButtonTitle,
}) => {
  const { apolices } = useSelector(getCliente);

  const { data } = useSelector(getBeneficiario);

  const [listaPlanos, setListaPlanos] = useState([]);

  const handleEditPlano = (values) => {
    setButtonTitle("Salvar");
    formProps.setValue("apolice", values.apolice);
    setListaPlanos(apolices.data.filter((i) => i.id === values.apolice));
    formProps.setValue("plano", values.clienteApoliceValor);
    formProps.setValue("numero", values.numero);
    formProps.setValue(
      "valorPremioSeguro",
      String(values.valorPremioSeguro).replace(".", ",")
    );
    formProps.setValue("dataVigencia", values.dataVigencia);
    formProps.setValue(
      "valorCapitalSegurado",
      String(values.valorCapitalSegurado).replace(".", ",")
    );
  };

  const handleListaPlanos = useCallback(
    (values) => {
      setListaPlanos(apolices.data.filter((i) => i.id === values.target.value));
    },
    [apolices]
  );

  return (
    <StyledCard>
      <StyledH2>Dados do Seguro</StyledH2>
      {editavel && (
        <>
          <StyledGridContainer container>
            <StyledGrid item xs={12} md={6}>
              <ControlledSelect
                name={"apolice"}
                formProps={formProps}
                label={"Apólice"}
                margin={"none"}
                loading={apolices.loading}
                onChange={handleListaPlanos}
                disabled={!editavel}
              >
                {data.BeneficiarioPrincipal
                  ? data.BeneficiarioPrincipal.BeneficiarioApolice?.map(
                      (item) => (
                        <MenuItem
                          className={"select-submenu-item"}
                          key={item.ClienteApoliceValor.ClienteApolice.id}
                          value={item.ClienteApoliceValor.ClienteApolice.id}
                        >
                          {item.ClienteApoliceValor.ClienteApolice.tipo === "S"
                            ? "SAÚDE - " +
                              item.ClienteApoliceValor.ClienteApolice.numero
                            : item.ClienteApoliceValor.ClienteApolice.tipo ===
                              "V"
                            ? "VIDA - " +
                              item.ClienteApoliceValor.ClienteApolice.numero
                            : "DENTAL - " +
                              item.ClienteApoliceValor.ClienteApolice.numero}
                        </MenuItem>
                      )
                    )
                  : apolices?.data.map((item) => (
                      <MenuItem
                        className={"select-submenu-item"}
                        key={item.id}
                        value={item.id}
                      >
                        {item.tipo === "S"
                          ? "SAÚDE - " + item.numero
                          : item.tipo === "V"
                          ? "VIDA - " + item.numero
                          : "DENTAL - " + item.numero}
                      </MenuItem>
                    ))}
              </ControlledSelect>
            </StyledGrid>
            <StyledGrid item xs={12} md={6}>
              <ControlledSelect
                name={"plano"}
                formProps={formProps}
                label={"Plano"}
                margin={"none"}
                loading={apolices.loading}
                disabled={!editavel}
                onChange={preencherValorPremioPorPlano}
              >
                {listaPlanos.length > 0 &&
                  listaPlanos[0].ClienteApoliceValors.map((item) => (
                    <MenuItem
                      className={"select-submenu-item"}
                      key={item.id}
                      value={item.id}
                    >
                      {item.SeguradoraPlano.NM_PLANO +
                        " - " +
                        (item.tipoAcomodacao === "A"
                          ? "Apartamento"
                          : "Enfermaria") +
                        " - " +
                        (item.tipoAdesao === "C" ? "Compulsória" : "Livre") +
                        " - " +
                        (item.tipoCoparticipacao === "S"
                          ? "Com Copart"
                          : "Sem Copart")}
                    </MenuItem>
                  ))}
              </ControlledSelect>
            </StyledGrid>
            <StyledGrid item xs={12} md={6}>
              <ControlledTextField
                formProps={formProps}
                fullWidth
                name="numero"
                label="Carteirinha"
                inputProps={{
                  maxLength: 20,
                }}
                disabled={!editavel}
              />
            </StyledGrid>
            <StyledGrid item xs={12} md={6}>
              <ControlledTextField
                formProps={formProps}
                fullWidth
                name="valorPremioSeguro"
                label="Valor do Prêmio"
                InputProps={{
                  inputComponent: CurrencyTextField,
                }}
                disabled={!editavel}
              />
            </StyledGrid>
            <StyledGrid item xs={12} md={6}>
              <StyledControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="dataVigencia"
                label="Data de Início Vigência"
                type="date"
                disabled={!editavel}
              />
            </StyledGrid>
            {listaPlanos.length > 0 && listaPlanos[0].tipo === "V" && (
              <StyledGrid item xs={12} md={6}>
                <ControlledTextField
                  formProps={formProps}
                  fullWidth
                  name="valorCapitalSegurado"
                  label="Valor Capital Segurado para Vida"
                  InputProps={{
                    inputComponent: CurrencyTextField,
                  }}
                  disabled={!editavel}
                />
              </StyledGrid>
            )}
          </StyledGridContainer>
          <StyledGridContainer
            container
            style={{ display: "flex", justifyContent: "right" }}
          >
            <StyledGrid item xs={12} md={3}>
              <StyledOutlinedButton
                onClick={() => handleAddPrecificacao()}
                disabled={!editavel}
              >
                {buttonTitle}
              </StyledOutlinedButton>
            </StyledGrid>
          </StyledGridContainer>
        </>
      )}
      <TabelaDadosSeguro
        handleDeletePrecificacao={handleDeletePlano}
        handleEdit={handleEditPlano}
        data={planos}
        apolices={apolices}
        disabled={!editavel}
      />
    </StyledCard>
  );
};

export default CardDadosSeguro;
