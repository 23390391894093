import basicFlow from "./asyncHandler";
import { authenticatedRequest } from "../utils/api";
import { routeWatcher } from "./rotas.saga";
import {
  actions as routeActions,
  types as routes,
} from "../reducers/rotas.actions";
import { actions as filaActions } from "../reducers/fila.actions";
import { actions as domainsActions } from "../reducers/dominios.actions";

import { put, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import { getPayload } from "../selectors/routes.selectors";

const ListarFilassApi = (values) => {
  return authenticatedRequest({
    url: `/filas`,
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const ListarFilas = basicFlow({
  actionGenerator: filaActions.obterFilas,
  api: ListarFilassApi,
});

function* ListarFilasRouteWatcher() {
  yield routeWatcher(routes.FILAS, function* () {
    yield put(domainsActions.listarSeguradoras.request());
    yield put(domainsActions.listarClientes.request());
    yield put(
      filaActions.obterFilas.request({
        filtro: {
          enviado: true,
          novo: true,
          aprovado: true,
        },
      })
    );
  });
}

const EditarFilasApi = (values) => {
  return authenticatedRequest({
    url: `/fila/${values.id}`,
    isResourceService: true,
    method: "put",
    body: values,
  });
};

const EditarFilas = basicFlow({
  actionGenerator: filaActions.editarFila,
  api: EditarFilasApi,
  postSuccess: function* ({ response }) {
    yield toast.success(response?.data?.message, {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    yield put(routeActions.redirectTo(routes.FILAS, {}));
  },
});

const MostrarFilaApi = (values) => {
  return authenticatedRequest({
    url: `/fila/${values.id}`,
    isResourceService: true,
    method: "get",
  });
};

const MostrarFila = basicFlow({
  actionGenerator: filaActions.mostrarFila,
  api: MostrarFilaApi,
});

function* MostrarFilaRouteWatcher() {
  yield routeWatcher(routes.EDIT_FILA, function* () {
    let apolice = yield select(getPayload);
    yield put(filaActions.mostrarFila.request({ id: apolice.idFila }));
  });
}

export const sagas = [
  MostrarFilaRouteWatcher(),
  ListarFilasRouteWatcher(),
  ListarFilas.watcher(),
  MostrarFila.watcher(),
  EditarFilas.watcher(),
];
