import React from "react";
import { styled } from "@mui/material";
import { Grid } from "@mui/material";
import Button from "../../../components/buttons/PrimaryButton";
import { useDispatch } from "react-redux";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";

const StyledCardSubtitle = styled("p")`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 1.8px;
  color: #ffffff;
  text-align: left;
  margin-bottom: -20px;
`;

const StyledCardCount = styled("p")`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;

  letter-spacing: 4.8px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 20px;
  margin-left: -10px;
`;

const StyledCardTitle = styled("p")`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 300;
  font-size: 29px;
  line-height: 28px;
  margin-top: -10px;
  margin-bottom: -10px;

  letter-spacing: 0.02em;
  text-align: left;

  color: #ffffff;
`;

const StyledGrid = styled(Grid)`
  margin-top: 15px;
`;

const StyledButton = styled(Button)`
  max-width: 270px;
  max-height: 40px;
  margin-left: -10px;
`;

const StyledBar = styled("div")`
  background-color: #d14a13;
  border-radius: 25px 0px 0px 0px;
  max-width: 12px;
  min-height: 203px;

  @media (max-width: 900px) {
    max-width: 100%;
    min-height: 12px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
`;

const AtualizacoesVidaCard = ({ atualizacao }) => {
  const dispatch = useDispatch();

  const handleGoToAtualizacoesVida = () => {
    dispatch(
      routeActions.redirectTo(routes.LISTAR_ATUALIZACOES, {
        isPainel: true,
        aguardando: true,
      })
    );
  };

  return (
    <Grid item xs={12} md={4}>
      <Grid container>
        <Grid item xs={12} md={1}>
          <StyledBar> </StyledBar>
        </Grid>
        <StyledGrid item xs={12} md={11}>
          <StyledCardTitle>Atualização de Vidas</StyledCardTitle>
          <StyledCardSubtitle>Aguardando Conclusão</StyledCardSubtitle>
          <StyledCardCount>{atualizacao ?? 0}</StyledCardCount>
          <StyledButton
            onClick={() => {
              handleGoToAtualizacoesVida();
            }}
          >
            Abrir Fila
          </StyledButton>
        </StyledGrid>
      </Grid>
    </Grid>
  );
};

export default AtualizacoesVidaCard;
