import { authenticatedRequest } from "../utils/api";
import basicFlow from "./asyncHandler";
import { actions } from "../reducers/dominios.actions";

const usuariosDominioApi = () => {
  return authenticatedRequest({
    url: `/dominios/usuario`,
    isResourceService: true,
    method: "get",
  });
};

const usuarios = basicFlow({
  actionGenerator: actions.listarUsuarios,
  api: usuariosDominioApi,
});

const planosDominioApi = (values) => {
  return authenticatedRequest({
    url: `/dominios/plano/${values.id}`,
    isResourceService: true,
    method: "get",
  });
};

const planos = basicFlow({
  actionGenerator: actions.listarPlanos,
  api: planosDominioApi,
});

const seguradorasDominioApi = () => {
  return authenticatedRequest({
    url: `/dominios/seguradora`,
    isResourceService: true,
    method: "get",
  });
};

const seguradoras = basicFlow({
  actionGenerator: actions.listarSeguradoras,
  api: seguradorasDominioApi,
});

const clientesDominioApi = () => {
  return authenticatedRequest({
    url: `/dominios/cliente`,
    isResourceService: true,
    method: "get",
  });
};

const clientes = basicFlow({
  actionGenerator: actions.listarClientes,
  api: clientesDominioApi,
});

const manutencaoClienteDominioApi = (values) => {
  return authenticatedRequest({
    url: `/dominios/cliente-manutencao`,
    isResourceService: true,
    method: "post",
    body: values
  });
};

const manutencoes = basicFlow({
  actionGenerator: actions.listarManutencaoCliente,
  api: manutencaoClienteDominioApi,
});

const apolicesDominioApi = () => {
  return authenticatedRequest({
    url: `/dominios/apolice`,
    isResourceService: true,
    method: "get",
  });
};

const apolices = basicFlow({
  actionGenerator: actions.listarApolices,
  api: apolicesDominioApi,
});

export const sagas = [
  usuarios.watcher(),
  planos.watcher(),
  seguradoras.watcher(),
  clientes.watcher(),
  manutencoes.watcher(),
  apolices.watcher(),
];
