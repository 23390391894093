import BasePage from "../../BasePage";
import React, { useState } from "react";
import { Skeleton, styled, Tab, Tabs } from "@mui/material";
import { Grid } from "@mui/material";
import Button from "../../../components/buttons/PrimaryButton";
import OutlinedButton from "../../../components/buttons/OutlinedButton";
import { useDispatch } from "react-redux";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";
import FormCliente from "../tabs/FormCliente";
import FormDocumentosCliente from "../tabs/FormDocumentosCliente";

const StyledH1 = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.blueTitle};
  font-weight: 400;
  letter-spacing: 4.8px;
  font-size: 45px;
  margin-bottom: -30px;
  @media (max-width: 900px) {
    font-size: 22px;
    margin-bottom: 10px;
  }
`;

const StyledContainer = styled(Grid)`
  margin-top: 10px;
  padding-left: 25px;
  padding-right: 20px;
  @media (max-width: 900px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const StyledGrid = styled(Grid)`
  padding: 5px !important;

  .MuiGrid-item {
    padding-top: 5px;
  }
`;

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 500px;
  margin-bottom: -65px;
`;

const StyledTab = styled(Tabs)`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  & .Mui-selected {
    color: ${({ theme }) => theme.buttons.primary.background};
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;

const StyledTabLabel = styled(Tab)`
  align-items: flex-start;
  min-width: 50%;
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: gray;
  font-weight: bold;
  text-transform: none;
  margin-bottom: -60px;
  font-size: 16px;
`;

const FormClientePage = ({
  loading,
  formProps,
  isFile,
  setIsFile,
  isFileImplantacao,
  setIsFileImplantacao,
  file,
  setFile,
  fileImplantacao,
  setFileImplantacao,
  handleSubmit,
  isFileOD,
  fileOD,
  setFileOD,
  setIsFileOD,
  arrayFileOD,
  setArrayFileOD,
}) => {
  const [value, setValue] = useState(0);

  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickVoltar = () => {
    dispatch(routeActions.redirectTo(routes.CLIENTES));
  };

  if (loading) {
    return (
      <BasePage isLoggedIn={true} isLightBlue={true}>
        <StyledSkeleton />
      </BasePage>
    );
  }

  return (
    <form onSubmit={formProps.handleSubmit(handleSubmit)}>
      <BasePage isLoggedIn={true} isLightBlue={true}>
        <StyledContainer container spacing={4}>
          <Grid item xs={12}>
            <StyledH1>Cadastro do Cliente</StyledH1>
          </Grid>
          <Grid item xs={12}>
            <StyledTab
              sx={{ marginBottom: "10px" }}
              value={value}
              onChange={handleChange}
              TabIndicatorProps={{
                style: {
                  background: "#F24F13",
                  color: "#F24F13",
                  minHeight: "6px",
                  marginRight: "20px",
                  borderRadius: "5px",
                },
              }}
              textColor={"inherit"}
            >
              <StyledTabLabel label="Dados do Cliente" />
              <StyledTabLabel label="Documentos" />
            </StyledTab>
          </Grid>
        </StyledContainer>
        <StyledContainer>
          {value === 0 && (
            <FormCliente loading={loading} formProps={formProps} />
          )}
          {value === 1 && (
            <FormDocumentosCliente
              loading={loading}
              formProps={formProps}
              isFile={isFile}
              setIsFile={setIsFile}
              isFileImplantacao={isFileImplantacao}
              setIsFileImplantacao={setIsFileImplantacao}
              file={file}
              setFile={setFile}
              fileImplantacao={fileImplantacao}
              setFileImplantacao={setFileImplantacao}
              isFileOD={isFileOD}
              setIsFileOD={setIsFileOD}
              fileOD={fileOD}
              setFileOD={setFileOD}
              setArrayFileOD={setArrayFileOD}
              arrayFileOD={arrayFileOD}
            />
          )}
        </StyledContainer>
        <StyledContainer
          container
          spacing={2}
          style={{
            display: "flex",
            justifyContent: "Center",
            marginTop: "10px",
          }}
        >
          <StyledGrid item xs={4}>
            <OutlinedButton onClick={handleClickVoltar}>Voltar</OutlinedButton>
          </StyledGrid>
          <StyledGrid item xs={4}>
            <Button type={"submit"} loading={loading}>
              Confirmar
            </Button>
          </StyledGrid>
        </StyledContainer>
      </BasePage>
    </form>
  );
};

export default FormClientePage;
