import { createSelector } from "reselect";

export const getSinistroState = (state) => state.sinistro;

export const getSinistros = createSelector(
  getSinistroState,
  (state) => state.sinistros
);

export const getSinistro = createSelector(
  getSinistroState,
  (state) => state.sinistro
);

export const getValores = createSelector(
  getSinistroState,
  (state) => state.valores
);
export const getSinistroOutros = createSelector(
  getSinistroState,
  (state) => state.sinistroOutros
);

export const getSinistroBeneficiarios = createSelector(
  getSinistroState,
  (state) => state.sinistroBeneficiarios
);

export const getSinistroSituacao = createSelector(
  getSinistroState,
  (state) => state.situacao
);

export const getBreakeven = createSelector(
  getSinistroState,
  (state) => state.sinistro.breakeven
);
