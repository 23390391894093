import { actions } from "./painel.actions";
import formatters from "../utils/formatters";

const initialState = {
  painel: {
    loading: false,
    data: [],
  },
  apolices: {
    loading: false,
    page: 1,
    count: 0,
    data: [],
  },
  rateios: {
    loading: false,
    page: 1,
    count: 0,
    data: [],
    situacao: "",
  },
  graficoSinistralidade: {
    loading: false,
    data: "",
  }
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.obterApolices.REQUEST:
    case actions.obterApolices.SUCCESS:
    case actions.obterApolices.FAILURE:
      return {
        ...state,
        apolices: {
          ...state.apolices,
          loading: action.type === actions.obterApolices.REQUEST,
          data:
            action.type === actions.obterApolices.SUCCESS
              ? action.payload.response.data.resultado
              : [],
          page:
            action.type === actions.obterApolices.SUCCESS
              ? action.payload.response.data.paginaAtual
              : [],
          count:
            action.type === actions.obterApolices.SUCCESS
              ? action.payload.response.data.total
              : [],
        },
      };
    case actions.obterRateios.REQUEST:
    case actions.obterRateios.SUCCESS:
    case actions.obterRateios.FAILURE:
      return {
        ...state,
        rateios: {
          ...state.rateios,
          loading: action.type === actions.obterRateios.REQUEST,
          data:
            action.type === actions.obterRateios.SUCCESS
              ? action.payload.response.data.resultado
              : [],
          page:
            action.type === actions.obterRateios.SUCCESS
              ? action.payload.response.data.paginaAtual
              : [],
          count:
            action.type === actions.obterRateios.SUCCESS
              ? action.payload.response.data.total
              : [],
          situacao:
            action.type === actions.obterRateios.SUCCESS
              ? action.payload.response.data.situacao
              : [],
        },
      };
    case actions.obterDados.REQUEST:
    case actions.obterDados.SUCCESS:
    case actions.obterDados.FAILURE:
      return {
        ...state,
        painel: {
          ...state.painel,
          loading: action.type === actions.obterDados.REQUEST,
          data:
            action.type === actions.obterDados.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.graficoSinistralidade.REQUEST:
    case actions.graficoSinistralidade.SUCCESS:
    case actions.graficoSinistralidade.FAILURE:
      return {
        ...state,
        graficoSinistralidade: {
          ...state.graficoSinistralidade,
          loading: action.type === actions.graficoSinistralidade.REQUEST,
          data:
            action.type === actions.graficoSinistralidade.SUCCESS
              ? action.payload.response.data.map((i) => {
                return {
                  ...i,
                  dataMesReferencia: formatters.dates.longMonth(new Date(i.dataMesReferencia)),
                  sinistralidade: i.valorSinistro/i.valorReceitaLiquida*100
                }
              })
              : ""
        }
      };
    default:
      return state;
  }
};

export default reducer;
