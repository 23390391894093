import React from 'react';
import Select from './Select';
import { Controller } from 'react-hook-form';
import { getValueFromObject } from "../../utils/basic";

const ControlledSelect = ({ formProps, name, children, ...otherProps }) => {
  const {
    control,
    formState: { errors },
    rules,
    initialValues,
  } = formProps;
  const isError = getValueFromObject(errors, name) !== undefined;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules[name]}
      defaultValue={getValueFromObject(initialValues, name)}
      render={({ field: { onChange, onBlur, value } }) => (
        <Select
          {...otherProps}
          value={value}
          error={isError}
          multiple={otherProps.multiple}
          helperText={getValueFromObject(errors, name)?.message}
          onChange={(v) => {
            onChange(v);
            if (!!otherProps.onChange) {
              otherProps.onChange(v);
            }
          }}
          onBlur={() => {
            onBlur();
            if (!!otherProps.onBlur) {
              otherProps.onBlur();
            }
          }}
        >
          {children}
        </Select>
      )}
    />
  );
};

export default ControlledSelect;
