import { createSelector } from "reselect";

export const getApoliceState = (state) => state.apolice;

export const getApolices = createSelector(
  getApoliceState,
  (state) => state.apolices
);

export const getApolice = createSelector(
  getApoliceState,
  (state) => state.apolice
);

export const getValores = createSelector(
  getApoliceState,
  (state) => state.valores
);
