import { createSelector } from "reselect";

export const getBeneficiarioState = (state) => state.beneficiario;

export const getBeneficiarios = createSelector(
  getBeneficiarioState,
  (state) => state.beneficiarios
);

export const getBeneficiario = createSelector(
  getBeneficiarioState,
  (state) => state.beneficiario
);

export const getDependentes = createSelector(
  getBeneficiarioState,
  (state) => state.beneficiario.dependentes
);

export const getPlanilhaBeneficiario = createSelector(
  getBeneficiarioState,
  (state) => state.planilhaBeneficiario
);

export const getPlanilhaMigrarPlano = createSelector(
  getBeneficiarioState,
  (state) => state.planilhaMigrarPlano
);
