import { Grid, Tooltip } from "@mui/material";
import { styled } from "@mui/system";
import Card from "../../components/Card";
import { Receipt } from "@mui/icons-material";

import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

import BarCode from "../../assets/barcode.png";
import { getMesAno } from "../../utils/basic";

const StyledGrid = styled(Grid)`
  text-align: left;
`;

const IconsGrid = styled(Grid)`
  text-align: right;
  margin-top: 20px;
  align-items: center;
`;

const StyledCard = styled(Card)`
  height: auto;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 0px;
  background-color: #415781ff;

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const TipoChips = styled("div")`
  font-family: Nunito;
  font-size: 14px;
  font-weight: 500;
  font-spacing: 0.5px;
  background-color: #fff;
  width: 87px;
  border-radius: 25px;
  text-align: center;
  margin: auto;
  margin-top: 0px;
`;

const NomeApolice = styled("div")`
  font-family: Nunito;
  font-size: 14px;
  font-weight: 300;
  font-spacing: 0.25px;
  margin: auto;
  margin-left: 5px;

  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const NomeCompetencia = styled("div")`
  font-family: Nunito;
  font-size: 18px;
  font-weight: 700;
  font-spacing: 0.25px;
  margin: auto;
  margin-left: 5px;

  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledNFTitle = styled("span")`
  margin: auto;
  margin-right: 20px;
  color: ${({ theme }) => theme.colors.iconsFill};
  font-weight: 400;
  font-size: 22px;
`;
const StyledNFContainer = styled("div")`
  margin-left: 10px;
  display: flex;
  justify-content: flex-end;
`;

const StyledReceipt = styled(Receipt)`
  width: 35px;
  height: 35px;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
`;

const StyledLibraryIcon = styled(MonetizationOnIcon)`
  width: 24px;
  height: 24px;
  margin-right: 6px;
  color: #ffffff;
`;

const StyledImg = styled("img")`
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  height: 30px;
  width: 55px;
  margin-right: 10px;
`;

const CardApolice = ({ item, handleDownloadArtefato }) => {
  return (
    <StyledCard>
      <Grid container>
        <StyledGrid item xs={1} md={1}>
          <StyledLibraryIcon />
        </StyledGrid>
        <StyledGrid item xs={6} md={6}>
          <NomeCompetencia>
            {getMesAno(item.ClienteRateios[0].dataMesReferencia)}
          </NomeCompetencia>
        </StyledGrid>
        <StyledGrid style={{ justifyContent: "right" }} item xs={5} md={5}>
          <TipoChips>
            {item.tipo === "D"
              ? "Dental"
              : item.tipo === "V"
              ? "Vida"
              : "Saúde"}
          </TipoChips>
        </StyledGrid>

        <StyledGrid item xs={1} md={1} />
        <StyledGrid item xs={11} md={11}>
          <NomeApolice>Apólice #{item.numero}</NomeApolice>
        </StyledGrid>
        <StyledGrid item xs={1} md={1} />
        <StyledGrid item xs={11} md={11}>
          <NomeApolice>
            {item.ClienteRateios[0].situacao === "P" ? "Quitado" : "Pendente"}
          </NomeApolice>
        </StyledGrid>
      </Grid>
      <Grid container>
        <IconsGrid item xs={12} md={12}>
          <StyledNFContainer>
            <Tooltip title="Nota Fiscal">
              <StyledNFTitle
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  handleDownloadArtefato({
                    tipo: "NF",
                    idReferencia: item.ClienteRateios[0].id,
                    tipoReferencia: "R",
                  });
                }}
              >
                NF-e
              </StyledNFTitle>
            </Tooltip>
            <Tooltip title="Boleto">
              <StyledImg
                src={BarCode}
                onClick={() => {
                  handleDownloadArtefato({
                    tipo: "BO",
                    idReferencia: item.ClienteRateios[0].id,
                    tipoReferencia: "R",
                  });
                }}
              />
            </Tooltip>
            <Tooltip title="Recibo">
              <StyledReceipt
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  handleDownloadArtefato({
                    tipo: "CP",
                    idReferencia: item.ClienteRateios[0].id,
                    tipoReferencia: "R",
                  });
                }}
              />
            </Tooltip>
          </StyledNFContainer>
        </IconsGrid>
      </Grid>
    </StyledCard>
  );
};

export default CardApolice;
