import basicFlow from "./asyncHandler";
import { authenticatedRequest } from "../utils/api";
import { routeWatcher } from "./rotas.saga";
import {
  actions as routeActions,
  types as routes,
} from "../reducers/rotas.actions";
import { actions as beneficiarioActions } from "../reducers/beneficiario.actions";
import { actions as clienteActions } from "../reducers/cliente.actions";
import { actions as dominiosActions } from "../reducers/dominios.actions";
import { put, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import { getPayload, getCurrentRoute } from "../selectors/routes.selectors";
import { getBeneficiarios } from "../selectors/beneficiario.selectors";
import { saveAs } from "file-saver";
import formatters from "../utils/formatters";

const listarBeneficiariosApi = (values) => {
  return authenticatedRequest({
    url: `/beneficiarios`,
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const listarBeneficiarios = basicFlow({
  actionGenerator: beneficiarioActions.obterBeneficiarios,
  api: listarBeneficiariosApi,
  transform: function* (values) {
    const cliente = yield select(getPayload);
    const beneficiarios = yield select(getBeneficiarios);
    return {
      pagina: values.page ?? beneficiarios.page,
      filtros: values.filtro,
      pesquisa: values.pesquisa ?? beneficiarios.pesquisa,
      cliente: Number.parseInt(cliente.idCliente),
    };
  },
});

function* listarBeneficiariosRouteWatcher() {
  yield routeWatcher(routes.BENEFICIARIOS, function* () {
    const { idCliente } = yield select(getPayload);
    yield put(dominiosActions.listarManutencaoCliente.request({ idCliente }));
    yield put(
      beneficiarioActions.obterBeneficiarios.request({
        pesquisa: "",
        page: 1,
        filtro: {
          ativos: true,
          desligados: true,
          pendente: true,
          saude: true,
          dental: true,
          vida: true,
        },
      })
    );
  });
}

const listarDependentesApi = (values) => {
  return authenticatedRequest({
    url: `/beneficiario/listarDependentes`,
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const listarDependentes = basicFlow({
  actionGenerator: beneficiarioActions.obterDependentes,
  api: listarDependentesApi,
  transform: function* () {
    const { idBeneficiario, idDependente } = yield select(getPayload);
    if (idDependente) {
      return { idBeneficiario: idDependente };
    } else {
      return { idBeneficiario: idBeneficiario };
    }
  },
});

function* novoBeneficiarioRouteWatcher() {
  yield routeWatcher(routes.NEW_BENEFICIARIO, function* () {
    yield put(clienteActions.listarApolices.request({ situacao: "A" }));
    const { idCliente } = yield select(getPayload);
    yield put(dominiosActions.listarManutencaoCliente.request({ idCliente }));
  });
}

function* novoDependenteRouteWatcher() {
  const { idCliente, idBeneficiario } = yield select(getPayload);
  yield routeWatcher(routes.NEW_DEPENDENTE, function* () {
    yield put(
      clienteActions.listarApolices.request({
        situacao: "A",
        idCliente,
        idBeneficiario,
        isDependente: true,
      })
    );
    yield put(dominiosActions.listarManutencaoCliente.request({ idCliente }));
  });
}
function* editDependenteRouteWatcher() {
  yield routeWatcher(routes.EDIT_DEPENDENTE, function* () {
    const { idCliente, idBeneficiario, idDependente } = yield select(
      getPayload
    );
    yield put(beneficiarioActions.mostrarBeneficiario.request(idDependente));
    yield put(
      clienteActions.listarApolices.request({
        situacao: "A",
        idBeneficiario,
        idCliente,
        isDependente: true,
      })
    );
    yield put(beneficiarioActions.obterDependentes.request());
    yield put(dominiosActions.listarManutencaoCliente.request({ idCliente }));

    yield put(dominiosActions.listarManutencaoCliente.request({ idCliente }));
  });
}

const editarBeneficiariosApi = (values) => {
  return authenticatedRequest({
    url: `/beneficiario/${values.get("id")}`,
    isResourceService: true,
    headers: { "Content-Type": "multipart/form-data" },
    method: "put",
    body: values,
  });
};

const editarBeneficiarios = basicFlow({
  actionGenerator: beneficiarioActions.editarBeneficiario,
  transform: function* (payload) {
    const form = new FormData();

    const { idBeneficiario } = yield select(getPayload);

    const {
      nomeBeneficiario,
      centroCusto,
      descricaoCentroCusto,
      dataAdmissao,
      cargo,
      pis,
      cns,
      dataNascimento,
      genero,
      relacaoDependencia,
      matricula,
      nomeMae,
      cliente,
      emailContato,
      cep,
      uf,
      complemento,
      logradouro,
      bairro,
      nomeMunicipio,
      cpf,
      numeroLogradouro,
      telefoneContato,
      situacao,
      manutencao,
      planos,
      fileDPS,
      fileCTPS,
      fileFR,
      fileDP,
      fileOD,
      editFileDPS,
      editFileCTPS,
      editFileFR,
      editFileDP,
      arrayFileOD,
      beneficiarioPrincipal,
    } = payload;

    form.append("centroCusto", centroCusto);
    form.append("descricaoCentroCusto", descricaoCentroCusto);
    form.append("dataAdmissao", dataAdmissao);
    form.append("cargo", cargo);
    form.append("pis", pis);
    form.append("cns", cns);
    if (!!beneficiarioPrincipal)
      form.append("beneficiarioPrincipal", beneficiarioPrincipal);
    form.append("genero", genero);
    form.append("dataNascimento", dataNascimento);
    form.append("relacaoDependencia", relacaoDependencia);
    form.append("matricula", matricula);
    form.append("nomeMae", nomeMae);
    form.append("cliente", cliente);
    form.append("BeneficiarioApolice", JSON.stringify(planos));
    form.append("nomeBeneficiario", nomeBeneficiario);
    form.append("emailContato", emailContato);
    form.append("cep", cep);
    form.append("uf", uf);
    form.append("complemento", complemento);
    form.append("logradouro", logradouro);
    form.append("bairro", bairro);
    form.append("nomeMunicipio", nomeMunicipio);
    form.append("cpf", cpf);
    form.append("numeroLogradouro", numeroLogradouro);
    form.append("telefoneContato", telefoneContato);
    form.append("situacao", situacao);
    form.append("id", idBeneficiario);
    if (!!manutencao) form.append("manutencao", manutencao);
    form.append("editFileDPS", JSON.stringify(editFileDPS));
    form.append("editFileCTPS", JSON.stringify(editFileCTPS));
    form.append("editFileFR", JSON.stringify(editFileFR));
    form.append("editFileDP", JSON.stringify(editFileDP));
    form.append("arrayFileOD", JSON.stringify(arrayFileOD));

    if (fileDPS[0]) {
      form.append("arquivos", fileDPS[0], "PS" + fileDPS[0].name);
    }
    if (fileCTPS[0]) {
      form.append("arquivos", fileCTPS[0], "CT" + fileCTPS[0].name);
    }
    if (fileFR[0]) {
      form.append("arquivos", fileFR[0], "FR" + fileFR[0].name);
    }
    if (fileDP[0]) {
      form.append("arquivos", fileDP[0], "DP" + fileDP[0].name);
    }
    for (let newFileOD of fileOD) {
      if (newFileOD[0]) {
        form.append("arquivos", newFileOD[0], "OD" + newFileOD[0].name);
      }
    }

    return form;
  },
  api: editarBeneficiariosApi,
  postSuccess: function* (values) {
    const { idCliente } = yield select(getPayload);
    yield toast.success("Beneficiario editado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    if (!values.original.beneficiarioPrincipal)
      yield put(routeActions.redirectTo(routes.BENEFICIARIOS, { idCliente }));
    else
      yield put(
        routeActions.redirectTo(routes.EDIT_BENEFICIARIO, {
          idCliente: idCliente,
          idBeneficiario: values.original.beneficiarioPrincipal,
        })
      );
  },
});

const criarBeneficiariosApi = (values) => {
  return authenticatedRequest({
    url: `/beneficiario`,
    isResourceService: true,
    headers: { "Content-Type": "multipart/form-data" },
    method: "post",
    body: values,
  });
};

const criarBeneficiarios = basicFlow({
  actionGenerator: beneficiarioActions.criarBeneficiario,
  transform: function (payload) {
    const form = new FormData();

    const {
      nomeBeneficiario,
      centroCusto,
      descricaoCentroCusto,
      dataAdmissao,
      cargo,
      pis,
      cns,
      dataNascimento,
      genero,
      relacaoDependencia,
      matricula,
      nomeMae,
      cliente,
      emailContato,
      cep,
      uf,
      complemento,
      logradouro,
      bairro,
      nomeMunicipio,
      cpf,
      numeroLogradouro,
      telefoneContato,
      situacao,
      planos,
      fileDPS,
      fileCTPS,
      fileFR,
      fileDP,
      beneficiarioPrincipal,
      fileOD,
      manutencao,
    } = payload;

    form.append("centroCusto", centroCusto);
    form.append("descricaoCentroCusto", descricaoCentroCusto);
    form.append("dataAdmissao", dataAdmissao);
    form.append("cargo", cargo);
    form.append("pis", pis);
    form.append("cns", cns);
    if (!!beneficiarioPrincipal)
      form.append("beneficiarioPrincipal", beneficiarioPrincipal.id);
    form.append("genero", genero);
    form.append("dataNascimento", dataNascimento);
    form.append("relacaoDependencia", relacaoDependencia);
    form.append("matricula", matricula);
    form.append("nomeMae", nomeMae);
    form.append("cliente", cliente);
    form.append("BeneficiarioApolice", JSON.stringify(planos));
    form.append("nomeBeneficiario", nomeBeneficiario);
    form.append("emailContato", emailContato);
    form.append("cep", cep);
    form.append("uf", uf);
    form.append("complemento", complemento);
    form.append("logradouro", logradouro);
    form.append("bairro", bairro);
    form.append("nomeMunicipio", nomeMunicipio);
    form.append("cpf", cpf);
    form.append("numeroLogradouro", numeroLogradouro);
    form.append("telefoneContato", telefoneContato);
    form.append("situacao", situacao);
    if (!!manutencao) form.append("manutencao", manutencao);

    if (fileDPS[0]) {
      form.append("arquivos", fileDPS[0], "PS" + fileDPS[0].name);
    }
    if (fileCTPS[0]) {
      form.append("arquivos", fileCTPS[0], "CT" + fileCTPS[0].name);
    }
    if (fileFR[0]) {
      form.append("arquivos", fileFR[0], "FR" + fileFR[0].name);
    }
    if (fileDP[0]) {
      form.append("arquivos", fileDP[0], "DP" + fileDP[0].name);
    }
    for (let newFileOD of fileOD) {
      if (newFileOD[0]) {
        form.append("arquivos", newFileOD[0], "OD" + newFileOD[0].name);
      }
    }

    return form;
  },

  api: criarBeneficiariosApi,

  postSuccess: function* ({ response, original, values }) {
    const { idCliente } = yield select(getPayload);
    const { idRateio } = original;
    const location = yield select(getCurrentRoute);
    yield toast.success("Beneficiario criado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    if (!!idRateio) {
      yield put(routeActions.redirectTo(routes.EDIT_RATEIO, { idRateio }));
    } else {
      if (location === "@@route/NEW_ATUALIZACAO") {
        yield put(
          routeActions.redirectTo(routes.LISTAR_ATUALIZACOES, { idCliente })
        );
      } else {
        yield put(
          routeActions.redirectTo(routes.BENEFICIARIOS, {
            idCliente,
            isBeneficiario:
              response?.data?.beneficiarioPrincipal !== null &&
              response?.data?.beneficiarioPrincipal !== "" &&
              response?.data?.beneficiarioPrincipal !== undefined
                ? false
                : true,
            idBeneficiario: response?.data?.id,
          })
        );
      }
    }
  },
});

const deletarBeneficiariosApi = (values) => {
  return authenticatedRequest({
    url: `/beneficiario/delete`,
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const deletarBeneficiarios = basicFlow({
  actionGenerator: beneficiarioActions.deletarBeneficiario,
  api: deletarBeneficiariosApi,
  postSuccess: function* () {
    yield toast.success("Beneficiario inativado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });

    yield put(
      beneficiarioActions.obterBeneficiarios.request({
        filtro: {
          ativos: true,
          desligados: true,
          pendente: true,
          saude: true,
          dental: true,
          vida: true,
        },
      })
    );
  },
});

const ativarBeneficiariosApi = (values) => {
  return authenticatedRequest({
    url: `/beneficiario/ativar`,
    isResourceService: true,
    method: "post",
    body: {
      id: values,
    },
  });
};

const ativarBeneficiarios = basicFlow({
  actionGenerator: beneficiarioActions.ativarBeneficiario,
  api: ativarBeneficiariosApi,
  postSuccess: function* () {
    yield toast.success("Beneficiario ativado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });

    yield put(
      beneficiarioActions.obterBeneficiarios.request({
        filtro: {
          ativos: true,
          desligados: true,
          pendente: true,
          saude: true,
          dental: true,
          vida: true,
        },
      })
    );
  },
});

const mostrarBeneficiarioApi = (values) => {
  return authenticatedRequest({
    url: `/beneficiario/` + values,
    isResourceService: true,
    method: "get",
  });
};

const mostrarBeneficiario = basicFlow({
  actionGenerator: beneficiarioActions.mostrarBeneficiario,
  api: mostrarBeneficiarioApi,
});

function* mostrarBeneficiarioRouteWatcher() {
  yield routeWatcher(routes.EDIT_BENEFICIARIO, function* () {
    const payload = yield select(getPayload);
    yield put(
      beneficiarioActions.mostrarBeneficiario.request(payload.idBeneficiario)
    );
    const { idCliente, idBeneficiario } = yield select(getPayload);
    yield put(
      clienteActions.listarApolices.request({
        situacao: "A",
        idBeneficiario,
        idCliente,
      })
    );
    yield put(beneficiarioActions.obterDependentes.request());
    yield put(dominiosActions.listarManutencaoCliente.request({ idCliente }));
  });
}

const baixarPlanilhaBeneficiariosApi = (values) => {
  return authenticatedRequest({
    url: `/carga/beneficiarios?idCliente=${values}`,
    isResourceService: true,
    method: "get",
    body: values,
    responseType: "arraybuffer",
  });
};

const baixarPlanilhaBeneficiarios = basicFlow({
  actionGenerator: beneficiarioActions.baixarPlanilhaBeneficiarios,
  api: baixarPlanilhaBeneficiariosApi,
  postSuccess: ({ response }) => {
    downloadPlanilhaBeneficiario(response);
  },
});

const downloadPlanilhaBeneficiario = (response) => {
  let bytes = new Uint8Array(response.data);
  let blob = new Blob([bytes], {
    type:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
  });
  saveAs(
    blob,
    `PlanilhaBeneficiarios_${formatters.dates.withHoursNoSpace(
      new Date()
    )}.xlsx`
  );
};

const uploadPlanilhaBeneficiariosApi = (values) => {
  const formData = new FormData();
  formData.append("file", values.file);
  formData.append("idCliente", values.idCliente);
  return authenticatedRequest({
    url: `/carga/beneficiarios`,
    method: "post",
    body: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const uploadPlanilhaBeneficiarios = basicFlow({
  actionGenerator: beneficiarioActions.uploadPlanilhaBeneficiarios,
  api: uploadPlanilhaBeneficiariosApi,
  postFailure: function* ({ error, response }) {
    yield toast.error(
      `Erro ao importar planilha:
      ${error.response.data.error.message}`,
      {
        theme: "colored",
        icon: false,
        style: { backgroundColor: "#990a26" },
      }
    );
  },
  postSuccess: function* () {
    yield toast.success("Planilha importada com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
  },
});

const baixarPlanilhaMigrarPlanoApi = (values) => {
  return authenticatedRequest({
    url: `/carga/migrar-plano?idCliente=${values}`,
    isResourceService: true,
    method: "get",
    body: values,
    responseType: "arraybuffer",
  });
};

const baixarPlanilhaMigrarPlano = basicFlow({
  actionGenerator: beneficiarioActions.baixarPlanilhaMigrarPlano,
  api: baixarPlanilhaMigrarPlanoApi,
  postSuccess: ({ response }) => {
    downloadPlanilhaMigrarPlano(response);
  },
});

const downloadPlanilhaMigrarPlano = (response) => {
  let bytes = new Uint8Array(response.data);
  var blob = new Blob([bytes], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  saveAs(
    blob,
    `PlanilhaMigrarPlano_${formatters.dates.withHoursNoSpace(new Date())}.xlsx`
  );
};

const uploadPlanilhaMigrarPlanoApi = (values) => {
  const formData = new FormData();
  formData.append("file", values.file);
  formData.append("idCliente", values.idCliente);
  return authenticatedRequest({
    url: `/carga/migrar-plano`,
    method: "post",
    body: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const uploadPlanilhaMigrarPlano = basicFlow({
  actionGenerator: beneficiarioActions.uploadPlanilhaMigrarPlano,
  api: uploadPlanilhaMigrarPlanoApi,
  postFailure: function* ({ error, response }) {
    yield toast.error(
      `Erro ao importar planilha:
      ${error.response.data.error.message}`,
      {
        theme: "colored",
        icon: false,
        style: { backgroundColor: "#990a26" },
      }
    );
  },
  postSuccess: function* () {
    yield toast.success("Planilha importada com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
  },
});

export const sagas = [
  mostrarBeneficiarioRouteWatcher(),
  listarBeneficiariosRouteWatcher(),
  editDependenteRouteWatcher(),
  listarBeneficiarios.watcher(),
  mostrarBeneficiario.watcher(),
  editarBeneficiarios.watcher(),
  criarBeneficiarios.watcher(),
  deletarBeneficiarios.watcher(),
  novoBeneficiarioRouteWatcher(),
  ativarBeneficiarios.watcher(),
  listarDependentes.watcher(),
  novoDependenteRouteWatcher(),
  baixarPlanilhaBeneficiarios.watcher(),
  uploadPlanilhaBeneficiarios.watcher(),
  baixarPlanilhaMigrarPlano.watcher(),
  uploadPlanilhaMigrarPlano.watcher(),
];
