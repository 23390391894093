import React, { useCallback } from "react";
import { Dialog, DialogContent, Grid, styled } from "@mui/material";
import { HighlightOffSharp } from "@mui/icons-material";
import Button from "../../components/buttons/PrimaryButton";
import Card from "../../components/Card";
import OutlinedButton from "../../components/buttons/OutlinedButton";
import { CurrencyTextField, TextField } from "../../components/inputs";
import { useDispatch } from "react-redux";
import { actions } from "../../reducers/rateio.actions";
import { toast } from "react-toastify";

const StyledGrid = styled(Grid)`
  text-align: left;
`;

const StyledGridContent = styled(Grid)`
  text-align: left;
  color: #ffffff;
  font-family: nunito;
  font-size: 22px;
  font-weight: 300;
`;

const IconsGrid = styled(Grid)`
  text-align: right;
  align-items: center;
`;

const CardTitle = styled("div")`
  font-family: patua one;
  font-size: 28px;
  font-weight: 400;
  font-spacing: 1.8px;
  color: #ffffff;
`;

const StyledCardActive = styled(Card)`
  height: auto;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 25px;
  background-color: #415781ff;

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const StyledCloseSharp = styled(HighlightOffSharp)`
  width: 35px;
  height: 32px;
  fill: ${({ theme }) => theme.colors.black};
  margin-left: 10px;
  cursor: pointer;
`;

const StyledDialog = styled(Dialog)`
  min-width: 100vw;
`;

const EditarRateioBeneficiariosDialog = ({
  handleClose,
  open,
  id,
  valor,
  observacao,
  setValor,
  setObservacao,
}) => {
  const dispatch = useDispatch();

  const handleEdit = useCallback(() => {
    if (!valor) {
      toast.error("Informar um novo valor de prêmio", {
        theme: "colored",
        icon: false,
        style: { backgroundColor: "#990a26" },
      });
    } else {
      dispatch(
        actions.editarRateioBeneficiarios.request({
          id,
          valor: Number.parseFloat(valor),
          observacao,
        })
      );
      handleClose();
    }
    //eslint-disable-next-line
  }, [handleClose, dispatch]);

  return (
    <StyledDialog onClose={handleClose} open={open}>
      {!!open && (
        <DialogContent>
          <Grid container spacing={4}>
            <IconsGrid item xs={12} md={12}>
              <StyledCloseSharp onClick={handleClose} />
            </IconsGrid>
            <Grid item xs={12}>
              <StyledCardActive key={id}>
                <Grid container spacing={4}>
                  <StyledGrid item xs={12} md={12}>
                    <CardTitle>Alteração Valor Prêmio</CardTitle>
                  </StyledGrid>
                </Grid>
                <Grid container spacing={2}>
                  <StyledGridContent item xs={12} md={4}>
                    <TextField
                      label="Valor Prêmio"
                      value={valor}
                      onChange={(e) => setValor(e.target.value)}
                      InputProps={{
                        inputComponent: CurrencyTextField,
                      }}
                    />
                  </StyledGridContent>
                  <StyledGridContent item xs={12} md={8}>
                    <TextField
                      label="Observação"
                      value={observacao}
                      onChange={(e) => setObservacao(e.target.value)}
                      InputProps={{
                        maxLength: 200,
                      }}
                    />
                  </StyledGridContent>
                </Grid>
              </StyledCardActive>
            </Grid>
            <StyledGrid item xs={6}>
              <OutlinedButton onClick={handleClose}>Voltar</OutlinedButton>
            </StyledGrid>
            <StyledGrid item xs={6}>
              <Button onClick={handleEdit}>Confirmar</Button>
            </StyledGrid>
          </Grid>
        </DialogContent>
      )}
    </StyledDialog>
  );
};

export default EditarRateioBeneficiariosDialog;
