import { createAsyncAction } from "../utils/actionCreators";

export const types = {
  OBTER_RATEIOS: "@@rateio/OBTER_RATEIOS",
  MOSTRAR_RATEIO: "@@rateio/MOSTRAR_RATEIO",
  EDITAR_RATEIO: "@@rateio/EDITAR_RATEIO",
  CRIAR_RATEIO: "@@rateio/CRIAR_RATEIO",
  DELETAR_RATEIO: "@@rateio/DELETAR_RATEIO",
  ATIVAR_RATEIO: "@@rateio/ATIVAR_RATEIO",
  OBTER_VALORES: "@@rateio/OBTER_VALORES",
  BAIXAR_ARTEFATO: "@@rateio/BAIXAR_ARTEFATO",
  OBTER_RATEIO_OUTROS: "@@rateio/OBTER_RATEIO_OUTROS",
  CRIAR_RATEIO_OUTROS: "@@rateio/CRIAR_RATEIO_OUTROS",
  EDITAR_RATEIO_OUTROS: "@@rateio/EDITAR_RATEIO_OUTROS",
  DELETAR_RATEIO_OUTROS: "@@rateio/DELETAR_RATEIO_OUTROS",
  UPLOAD_RATEIO_DOCUMENTOS: "@@rateio/UPLOAD_RATEIO_DOCUMENTOS",
  OBTER_RATEIO_BENEFICIARIOS: "@@rateio/OBTER_RATEIO_BENEFICIARIOS",
  EDITAR_RATEIO_BENEFICIARIOS: "@@rateio/EDITAR_RATEIO_BENEFICIARIOS",
  DOWNLOAD_RATEIO: "@@rateio/DOWNLOAD_RATEIO",
  ATUALIZAR_SITUACAO: "@@rateio/ATUALIZAR_SITUACAO",
  APROVAR_SITUACAO: "@@rateio/APROVAR_SITUACAO",
};

export const actions = {
  obterRateios: createAsyncAction(types.OBTER_RATEIOS),
  mostrarRateio: createAsyncAction(types.MOSTRAR_RATEIO),
  editarRateio: createAsyncAction(types.EDITAR_RATEIO),
  criarRateio: createAsyncAction(types.CRIAR_RATEIO),
  deletarRateio: createAsyncAction(types.DELETAR_RATEIO),
  ativarRateio: createAsyncAction(types.ATIVAR_RATEIO),
  obterValores: createAsyncAction(types.OBTER_VALORES),
  baixarArtefato: createAsyncAction(types.BAIXAR_ARTEFATO),
  obterRateioOutros: createAsyncAction(types.OBTER_RATEIO_OUTROS),
  criarRateioOutros: createAsyncAction(types.CRIAR_RATEIO_OUTROS),
  deletarRateioOutros: createAsyncAction(types.DELETAR_RATEIO_OUTROS),
  editarRateioOutros: createAsyncAction(types.EDITAR_RATEIO_OUTROS),
  uploadRateioDocumentos: createAsyncAction(types.UPLOAD_RATEIO_DOCUMENTOS),
  obterRateioBeneficiarios: createAsyncAction(types.OBTER_RATEIO_BENEFICIARIOS),
  editarRateioBeneficiarios: createAsyncAction(
    types.EDITAR_RATEIO_BENEFICIARIOS
  ),
  downloadRateio: createAsyncAction(types.DOWNLOAD_RATEIO),
  atualizarSituacao: createAsyncAction(types.ATUALIZAR_SITUACAO),
  aprovarSituacao: createAsyncAction(types.APROVAR_SITUACAO),
};
