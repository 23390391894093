import React from "react";
import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
} from "@mui/material";
import formatters from "../../../../utils/formatters";

const StyledTable = styled(Table)`
  margin-top: 10px;
  margin-bottom: 20px;
  overflow-y: auto !important;
`;

const StyledTableRow = styled(TableRow)`
  padding: 0px;
  height: 35px;
  &:nth-of-type(odd) {
    background-color: #415781ff;
    max-height: 10px;
    color: #ffffff !important;
  }
  &:nth-of-type(even) {
    background-color: #a3abc0;
    max-height: 10px;
  }
`;
const StyledTableCell = styled(TableCell)`
  color: inherit;
  padding-top: 5px;
  padding-bottom: 5px;
  border: none !important;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  cursor: pointer;
`;
const StyledFirstTableCell = styled(TableCell)`
  color: inherit;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  font-family: Open Sans;
  border: none !important;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  cursor: pointer;
`;

const StyledTitleTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
  font-family: nunito;
  border: none !important;
  color: #ffdbce !important;
`;

const StyledTableBody = styled(TableBody)`
  overflow-y: auto !important;
`;

const StyledPaper = styled(Paper)`
  margin-top: 10px;
  overflow-y: auto;
  max-height: 400px;
  background-color: #415781ff;
  box-shadow: none;
`;

const TabelaApolices = ({ data, handleGoToApolices }) => {
  if (data?.length === 0) {
    return <></>;
  }

  return (
    <StyledPaper>
      <StyledTable>
        <StyledTableBody>
          <StyledTableRow>
            <StyledTitleTableCell>Apólice</StyledTitleTableCell>
            <StyledTitleTableCell>Cliente</StyledTitleTableCell>
            <StyledTitleTableCell>Seguradora</StyledTitleTableCell>
            <StyledTitleTableCell>Plano</StyledTitleTableCell>
            <StyledTitleTableCell>Data Vencimento</StyledTitleTableCell>
          </StyledTableRow>
          {data?.length > 0 &&
            data.map((item, index) => {
              return (
                <StyledTableRow
                  onClick={() => handleGoToApolices(item)}
                  key={index}
                >
                  <StyledFirstTableCell>{item.numero}</StyledFirstTableCell>
                  <StyledTableCell>{item.Cliente.nomeCliente}</StyledTableCell>
                  <StyledTableCell>
                    {item.Seguradora.NM_SEGURADORA}
                  </StyledTableCell>
                  <StyledTableCell>
                    {item.ClienteApoliceValors[0]?.SeguradoraPlano?.NM_PLANO}
                  </StyledTableCell>
                  <StyledTableCell>
                    {formatters.dates.simpleDateDisplay(
                      new Date(item.dataAniversario)
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
        </StyledTableBody>
      </StyledTable>
    </StyledPaper>
  );
};

export default TabelaApolices;
