import BasePage from "../../BasePage";
import React from "react";
import { Skeleton, styled } from "@mui/material";
import { Grid } from "@mui/material";
import CardBeneficiarioIdentificacao from "../beneficiario/CardBeneficiarioIdentificacao";
import CardEndereco from "../beneficiario/CardEndereco";
import CardContato from "../beneficiario/CardContato";
import CardDadosSeguro from "../beneficiario/CardDadosSeguro";
import CardDadosTrabalho from "../beneficiario/CardDadosTrabalho";

const StyledContainer = styled(Grid)`
  margin-top: 10px;
  padding-left: 25px;
  @media (max-width: 900px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;
const StyledBasePage = styled(BasePage)`
  padding: 3px;
`;

const StyledGrid = styled(Grid)`
  padding: 5px !important;

  .MuiGrid-item {
    padding-top: 5px;
  }
`;

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 500px;
  margin-bottom: -65px;
`;

const FormBeneficiarioPage = ({
  loading,
  formProps,
  handleAddPrecificacao,
  buttonTitle,
  handleDeletePlano,
  handleEditPlano,
  planos,
  isDependente,
  editavel,
  preencherValorPremio,
  preencherValorPremioPorPlano,
  setButtonTitle,
}) => {
  if (loading) {
    return (
      <StyledBasePage isLoggedIn={true} isLightBlue={true}>
        <StyledSkeleton />
      </StyledBasePage>
    );
  }

  return (
    <StyledContainer container spacing={2}>
      <StyledGrid item xs={12}>
        <CardBeneficiarioIdentificacao
          formProps={formProps}
          isDependente={isDependente}
          editavel={editavel}
          preencherValorPremio={preencherValorPremio}
        />
      </StyledGrid>
      <StyledGrid item xs={12}>
        <CardContato
          formProps={formProps}
          editavel={editavel}
        />
      </StyledGrid>
      <StyledGrid item xs={12}>
        <CardEndereco
          formProps={formProps}
          editavel={editavel}
        />
      </StyledGrid>
      <StyledGrid item xs={12}>
        <CardDadosSeguro
          formProps={formProps}
          handleAddPrecificacao={handleAddPrecificacao}
          buttonTitle={buttonTitle}
          handleDeletePlano={handleDeletePlano}
          handleEditPlano={handleEditPlano}
          planos={planos}
          isDependente={isDependente}
          editavel={editavel}
          preencherValorPremioPorPlano={preencherValorPremioPorPlano}
          setButtonTitle={setButtonTitle}
        />
      </StyledGrid>
      {!isDependente &&
        <StyledGrid item xs={12}>
          <CardDadosTrabalho
            formProps={formProps}
            editavel={editavel}
          />
        </StyledGrid>
      }
    </StyledContainer>
  );
};

export default FormBeneficiarioPage;
