import React from "react";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { connectRoutes, NOT_FOUND } from "redux-first-router";
import restoreScroll from "redux-first-router-restore-scroll";
import * as pages from "./business/pages";
import { getCurrentRoute } from "./selectors/routes.selectors";
import { getPerfil, isUsuarioLogado } from "./selectors/usuario.selectors";

import {
  types as routes,
  actions as routeActions,
} from "./reducers/rotas.actions";
import { toast } from "react-toastify";

const routePages = {
  [routes.LOGIN]: {
    component: pages.LoginPage,
  },
  [routes.ESQUECI_SENHA]: {
    component: pages.EsqueciSenhaPage,
  },
  [routes.TROCAR_SENHA]: {
    component: pages.TrocarSenhaPage,
  },
  [routes.USUARIO]: {
    component: pages.UsuarioPage,
    restricted: ["A"],
  },
  [routes.EDIT_USUARIOS]: {
    component: pages.EditUsuarioPage,
    restricted: ["A"],
  },
  [routes.CRIAR_USUARIOS]: {
    component: pages.CriarUsuarioPage,
    restricted: ["A"],
  },
  [routes.CLIENTES]: {
    component: pages.ClientesPage,
    restricted: ["A", "C"],
  },
  [routes.NEW_CLIENTE]: {
    component: pages.FormNewCliente,
    restricted: ["A", "C"],
  },
  [routes.APOLICES]: {
    component: pages.Apolices,
    restricted: ["A", "C"],
  },
  [routes.NEW_APOLICE]: {
    component: pages.NewApolice,
    restricted: ["A", "C"],
  },
  [routes.EDIT_APOLICE]: {
    component: pages.EditApolice,
    restricted: ["A", "C"],
  },
  [routes.EDIT_CLIENTE]: {
    component: pages.FormEditCliente,
    restricted: ["A", "C"],
  },
  [routes.NEW_SUB]: {
    component: pages.FormNewCliente,
    restricted: ["A", "C"],
  },
  [routes.RATEIOS]: {
    component: pages.Rateios,
    restricted: ["A", "C"],
  },
  [routes.EDIT_RATEIO]: {
    component: pages.EditRateio,
  },
  [routes.EDIT_DEPENDENTE]: {
    component: pages.FormEditDependente,
    restricted: ["A", "C"],
  },
  [routes.BENEFICIARIOS]: {
    component: pages.BeneficiariosPage,
    restricted: ["A", "C"],
  },
  [routes.NEW_BENEFICIARIO]: {
    component: pages.FormNewBeneficiario,
    restricted: ["A", "C"],
  },
  [routes.EDIT_BENEFICIARIO]: {
    component: pages.FormEditBeneficiario,
    restricted: ["A", "C"],
  },
  [routes.NEW_DEPENDENTE]: {
    component: pages.FormNewDependente,
    restricted: ["A", "C"],
  },
  [routes.CARGA_BENEFICIARIOS]: {
    component: pages.CargaBeneficiarios,
    restricted: ["A", "C"],
  },
  [routes.MIGRAR_PLANO]: {
    component: pages.MigrarPlanoPage,
    restricted: ["A", "C"],
  },
  [routes.FATURAMENTOS]: {
    component: pages.Faturamentos,
    restricted: ["A", "C"],
  },
  [routes.EDIT_FATURAMENTO]: {
    component: pages.EditFaturamento,
  },
  /*
  [routes.LISTAR_ATUALIZACOES]: {
    component: pages.ManutencaoClientePage
  },
  [routes.NEW_ATUALIZACAO]: {
    component: pages.FormNewBeneficiario,
  },
  [routes.MOSTRAR_ATUALIZACAO]: {
    component: pages.FormEditBeneficiario,
  },
  [routes.NEW_ATUALIZACAO_DEPENDENTE]: {
    component: pages.FormNewBeneficiario,
  },
  */
  [routes.LISTAR_ATUALIZACOES]: {
    component: pages.ManutencaoCliente,
  },
  [routes.NEW_ATUALIZACAO]: {
    component: pages.NovaManutencaoCliente,
  },
  [routes.HOME]: { component: pages.HomePage },
  [NOT_FOUND]: { component: pages.HomePage },
  [routes.PAINEL]: {
    component: pages.PainelPage,
    restricted: ["A", "C"],
  },
  [routes.SINISTROS]: {
    component: pages.SinistrosPage,
    restricted: ["A", "C"],
  },
  [routes.EDIT_SINISTRO]: {
    component: pages.EditSinistroPage,
    restricted: ["A", "C"],
  },
  [routes.RELATORIOS]: {
    component: pages.RelatoriosPage,
    restricted: ["A", "C"],
  },
  [routes.FILAS]: {
    component: pages.FilasPage,
    restricted: ["A", "C"],
  },
  [routes.EDIT_FILA]: {
    component: pages.EditFilaPage,
    restricted: ["A", "C"],
  },
};
export const routePaths = {
  [routes.HOME]: "/home",
  [routes.LOGIN]: "/",
  [routes.ESQUECI_SENHA]: "/esqueci-senha",
  [routes.TROCAR_SENHA]: "/trocar-senha/:token",
  [routes.USUARIO]: "/usuarios",
  [routes.EDIT_USUARIOS]: "/usuarios/:id",
  [routes.CRIAR_USUARIOS]: "/criar-usuarios",
  [routes.CLIENTES]: "/clientes",
  [routes.NEW_CLIENTE]: "/cliente",
  [routes.EDIT_CLIENTE]: "/cliente/:id",
  [routes.APOLICES]: "/clientes/:idCliente/apolices",
  [routes.NEW_APOLICE]: "/clientes/:idCliente/apolice",
  [routes.EDIT_APOLICE]: "/clientes/:idCliente/apolice/:idApolice",
  [routes.NEW_SUB]: "/clientes/:idCliente/subestipulante",
  [routes.RATEIOS]: "/rateios",
  [routes.EDIT_RATEIO]: "/rateio/:idRateio",
  [routes.BENEFICIARIOS]: "/beneficiarios",
  [routes.NEW_CLIENTE]: "/cliente",
  [routes.EDIT_CLIENTE]: "/cliente/:id",
  [routes.BENEFICIARIOS]: "/cliente/:idCliente/beneficiarios",
  [routes.NEW_BENEFICIARIO]: "/cliente/:idCliente/beneficiario",
  [routes.EDIT_DEPENDENTE]:
    "/cliente/:idCliente/beneficiario/:idBeneficiario/dependente/:idDependente",
  [routes.EDIT_BENEFICIARIO]:
    "/cliente/:idCliente/beneficiario/:idBeneficiario",
  [routes.NEW_DEPENDENTE]:
    "/cliente/:idCliente/beneficiario/:idBeneficiario/dependente",
  [routes.CARGA_BENEFICIARIOS]: "/cliente/:idCliente/carga-beneficiarios",
  [routes.FATURAMENTOS]: "/clientes/:idCliente/faturamentos",
  [routes.EDIT_FATURAMENTO]: "/clientes/:idCliente/faturamento/:idFaturamento",
  [routes.LISTAR_ATUALIZACOES]: "/atualizacoes-vida",
  [routes.NEW_ATUALIZACAO]: "/cliente/:idCliente/atualizacao-vida",
  [routes.MOSTRAR_ATUALIZACAO]:
    "/cliente/:idCliente/atualizacao-vida/:idBeneficiario",
  [routes.NEW_ATUALIZACAO_DEPENDENTE]:
    "/cliente/:idCliente/atualizacao-vida/:idBeneficiario/dependente",
  [routes.MIGRAR_PLANO]: "/cliente/:idCliente/migrar-plano",
  [routes.PAINEL]: "/painel",
  [routes.SINISTROS]: "/sinistros",
  [routes.EDIT_SINISTRO]: "/cliente/:idCliente/sinistro/:idSinistro",
  [routes.RELATORIOS]: "/relatorios",
  [routes.FILAS]: "/reajustes",
  [routes.EDIT_FILA]: "/reajuste/:idFila",
};

const { reducer, middleware, enhancer } = connectRoutes(routePaths, {
  querySerializer: queryString,
  restoreScroll: restoreScroll(),
  notFoundPath: "/erro",
  onBeforeChange: (dispatch, getState, { action }) => {
    const route = routePages[action.type] ?? routePages[NOT_FOUND];
    if (route.restricted !== undefined) {
      const state = getState();
      if (!isUsuarioLogado(state)) {
        dispatch(routeActions.rejectTo(routes.LOGIN));
      } else {
        const perfil = getPerfil(state);
        if (!canAccessRestrictedRoute(route, perfil)) {
          toast.error("Acesso Negado");
          dispatch(routeActions.rejectTo(routes.HOME));
        }
      }
    }
  },
});

const canAccessRestrictedRoute = (route, perfil) => {
  if (!perfil) {
    return false;
  }
  if (route.restricted === true) {
    return true;
  }
  if (Array.isArray(route.restricted)) {
    if (
      route.restricted.length === 0 ||
      route.restricted.indexOf(perfil) > -1
    ) {
      return true;
    }
  }
  return false;
};

export { reducer, middleware, enhancer };

const Container = () => {
  const routeCode = useSelector(getCurrentRoute);
  const route = routePages[routeCode] ?? routePages[NOT_FOUND];
  const Component = route.component;

  return <Component />;
};

export default Container;
