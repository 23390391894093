import { createAsyncAction } from "../utils/actionCreators";

export const types = {
  USUARIOS: "@@dominios/USUARIOS",
  PLANOS: "@@dominios/PLANOS",
  SEGURADORAS: "@@dominios/SEGURADORAS",
  CLIENTES: "@@dominios/CLIENTES",
  CLIENTE_MANUTENCAO: "@@dominios/CLIENTE_MANUTENCAO",
  APOLICES: "@@dominios/APOLICES",
};

export const actions = {
  listarUsuarios: createAsyncAction(types.USUARIOS),
  listarPlanos: createAsyncAction(types.PLANOS),
  listarSeguradoras: createAsyncAction(types.SEGURADORAS),
  listarClientes: createAsyncAction(types.CLIENTES),
  listarManutencaoCliente: createAsyncAction(types.CLIENTE_MANUTENCAO),
  listarApolices: createAsyncAction(types.APOLICES),
};
