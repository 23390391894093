import BasePage from "../../BasePage";
import { Grid, styled, Box, IconButton } from "@mui/material";
import {
  Description,
  Download,
  Delete,
  CloudUpload,
} from "@mui/icons-material";
import Spinner from "../../../components/spinner/Spinner";
import React from "react";
import FileInput from "../../../components/inputs/FileInput";
import { toast } from "react-toastify";
import OutlinedButton from "../../../components/buttons/OutlinedButton";

const StyledBasePage = styled(BasePage)`
  padding: 3px;
  margin-bottom: 10px;
`;
const StyledContainer = styled(Grid)`
  margin-top: 1px;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 900px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;
const StyledH1 = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.blueTitle};
  font-weight: 400;
  letter-spacing: 4.8px;
  font-size: 45px;
  margin-bottom: 15px;
  @media (max-width: 900px) {
    font-size: 22px;
  }
`;
const StyledGrid = styled(Grid)`
  padding: 5px;
`;
const StyledBoxFile = styled(Box)`
  width: 100% !important;
  height: 200px !important;
  text-align: center;
  align-items: center;
  background-color: #ebf2f8ff;

  border: 2px solid #3f484a85;
  border-radius: 15px;
`;
const StyledH4 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  letter-spacing: 1.2px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 12px;
  }
`;
const StyledItem = styled(Grid)`
  max-height: 200px;
`;
const StyledBox = styled(Box)`
  width: 100% !important;
  height: 200px !important;
  text-align: center;
  background: repeating-linear-gradient(
    135deg,
    #ffffffff,
    #ffffffff 30px,
    #ebf2f8ff 30px,
    #ebf2f8ff 60px
  );

  border: 2px dashed #3f484a85;
  border-radius: 15px;
`;

const StyledH5 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: ${({ theme }) => theme.colors.primary};
  text-align: left;
  letter-spacing: 1.4px;
  font-size: 12px;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 12px;
  }
`;
const MigrarPlanoPage = ({
  loadingDownload = true,
  loadingUpload = true,
  isFile = false,
  setFile,
  formProps,
  setIsFile,
  file,
  handleVoltar,
  handleDownload,
  handleUpload,
}) => (
  <StyledBasePage isLoggedIn={true} isLightBlue={true}>
    <StyledContainer container spacing={2}>
      <Grid item xs={12}>
        <StyledH1>Migrar Plano</StyledH1>
      </Grid>
      <Grid item xs={6} container spacing={2}>
        <StyledGrid item sm={12}>
          <StyledBoxFile sx={{ width: "100%", height: "100%" }}>
            {!loadingDownload && (
              <>
                <StyledH4>
                  <Description
                    style={{ fontSize: "60px", marginTop: "15px" }}
                  />
                </StyledH4>
                <StyledH4>Baixar planilha de Migração</StyledH4>
                <StyledH4>
                  <IconButton onClick={handleDownload}>
                    <Download style={{ fontSize: "30px", cursor: "pointer" }} />
                  </IconButton>
                </StyledH4>
              </>
            )}
            {loadingDownload && (
              <StyledItem sx={{ width: "100%", height: "100%" }}>
                <Spinner />
              </StyledItem>
            )}
          </StyledBoxFile>
        </StyledGrid>
      </Grid>
      <Grid item xs={6} spacing={2}>
        <StyledGrid item sm={12}>
          <FileInput
            name={"file"}
            onDrop={(acceptedFiles) => {
              if (acceptedFiles.length === 0) {
                toast.error("Formato de arquivo inválido", {
                  theme: "colored",
                  icon: false,
                  style: { backgroundColor: "#990a26" },
                });
              }
              if (acceptedFiles.length > 0) {
                setFile(acceptedFiles);
                setIsFile(true);
              }
            }}
            formProps={formProps}
            maxFiles={1}
            multiple={false}
            acceptMime={{
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
                ".xlsx",
              ],
            }}
          >
            {isFile && (
              <StyledBoxFile sx={{ width: "100%", height: "100%" }}>
                {!loadingUpload && (
                  <>
                    <StyledH4>
                      <Description
                        style={{ fontSize: "60px", marginTop: "15px" }}
                      />
                    </StyledH4>
                    <StyledH4
                      style={{
                        marginLeft: "15px",
                        marginRight: "15px",
                        fontSize: `${
                          file[0]?.name?.length > 25 ? "10px" : "12px"
                        }`,
                      }}
                    >
                      {file[0]?.name}
                    </StyledH4>
                    <StyledH4>
                      <IconButton
                        onClick={(e) => {
                          setFile([]);
                          setIsFile(false);
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <Delete
                          style={{ cursor: "pointer", fontSize: "30px" }}
                        />
                      </IconButton>
                    </StyledH4>
                  </>
                )}

                {loadingUpload && (
                  <StyledItem sx={{ width: "100%", height: "100%" }}>
                    <Spinner />
                  </StyledItem>
                )}
              </StyledBoxFile>
            )}
            {!isFile && (
              <StyledBox
                sx={{
                  cursor: "pointer",
                  width: "100%",
                  height: "100%",
                  "&:hover": {
                    opacity: [0.9, 0.8, 0.7],
                  },
                }}
              >
                <StyledH4 style={{ marginTop: "50px" }}>
                  Arraste o arquivo para cá
                </StyledH4>
                <StyledH4>ou clique para selecioná-lo</StyledH4>
                <StyledH4>(somente arquivos com extensão .xlsx)</StyledH4>
                <StyledH4>
                  <CloudUpload
                    style={{ marginTop: "-5px", fontSize: "30px" }}
                  />
                </StyledH4>
              </StyledBox>
            )}
          </FileInput>
        </StyledGrid>
      </Grid>
      <Grid justifyContent="center" container>
        <StyledGrid item sm={6}>
          <StyledH5>
            *IMPORTANTE: Para migrar planos, é necessário primeiramente baixar
            uma nova planilha. O arquivo não deve ter seu nome, estrutura
            interna ou as três primeiras colunas modificadas.
          </StyledH5>
        </StyledGrid>
      </Grid>
      <Grid justifyContent="center" container>
        <StyledGrid item sm={3}>
          <OutlinedButton onClick={handleVoltar} style={{ width: "100%" }}>
            VOLTAR
          </OutlinedButton>
        </StyledGrid>
        <StyledGrid item sm={3}>
          <OutlinedButton onClick={handleUpload} style={{ width: "100%" }}>
            PROCESSAR
          </OutlinedButton>
        </StyledGrid>
      </Grid>
    </StyledContainer>
  </StyledBasePage>
);

export default MigrarPlanoPage;
