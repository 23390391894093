import { createSyncAction } from "../utils/actionCreators";
import { NOT_FOUND } from "redux-first-router";

export const types = {
  NOT_FOUND: NOT_FOUND,
  HOME: "@@route/HOME",
  OPEN_WINDOW: "@@route/OPEN_WINDOW",
  LOGIN: "@@route/LOGIN",
  ESQUECI_SENHA: "@@route/ESQUECI_SENHA",
  TROCAR_SENHA: "@@route/TROCAR_SENHA",
  USUARIO: "@@route/USUARIO",
  EDIT_USUARIOS: "@@route/EDIT_USUARIOS",
  CRIAR_USUARIOS: "@@route/CRIAR_USUARIOS",
  CLIENTES: "@@route/CLIENTES",
  NEW_CLIENTE: "@@route/NEW_CLIENTE",
  EDIT_CLIENTE: "@@route/EDIT_CLIENTE",
  NEW_SUB: "@@route/NEW_SUB",
  APOLICES: "@@route/APOLICES",
  NEW_APOLICE: "@@route/NEW_APOLICE",
  EDIT_APOLICE: "@@route/EDIT_APOLICE",
  RATEIOS: "@@route/RATEIOS",
  NEW_RATEIO: "@@route/NEW_RATEIO",
  EDIT_RATEIO: "@@route/EDIT_RATEIO",
  BENEFICIARIOS: "@@route/BENEFICIARIOS",
  NEW_BENEFICIARIO: "@@route/NEW_BENEFICIARIO",
  EDIT_BENEFICIARIO: "@@route/EDIT_BENEFICIARIO",
  NEW_DEPENDENTE: "@@route/NEW_DEPENDENTE",
  EDIT_DEPENDENTE: "@@route/EDIT_DEPENDENTE",
  CARGA_BENEFICIARIOS: "@@route/CARGA_BENEFICIARIOS",
  FATURAMENTOS: "@@route/FATURAMENTOS",
  NEW_FATURAMENTO: "@@route/NEW_FATURAMENTO",
  EDIT_FATURAMENTO: "@@route/EDIT_FATURAMENTO",
  LISTAR_ATUALIZACOES: "@@route/LISTAR_ATUALIZACOES",
  MOSTRAR_ATUALIZACAO: "@@route/MOSTRAR_ATUALIZACAO",
  NEW_ATUALIZACAO: "@@route/NEW_ATUALIZACAO",
  NEW_ATUALIZACAO_DEPENDENTE: "@@route/NEW_ATUALIZACAO_DEPENDENTE",
  MIGRAR_PLANO: "@@route/MIGRAR_PLANO",
  PAINEL: "@@route/PAINEL",
  SINISTROS: "@@route/SINISTROS",
  EDIT_SINISTRO: "@@route/EDIT_SINISTRO",
  RELATORIOS: "@@route/RELATORIOS",
  FILAS: "@@route/FILAS",
  EDIT_FILA: "@@route/EDIT_FILA",
};

export const actions = {
  redirectTo: (route, params = {}) => createSyncAction(route, params),
  openNewWindowWith: (route, params = {}) =>
    createSyncAction(types.OPEN_WINDOW, {
      route,
      params,
    }),
  rejectTo: (route, params = {}) =>
    createSyncAction(route, params, {
      meta: {
        location: {
          kind: "redirect",
        },
      },
    }),
};
