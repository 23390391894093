import { useForm } from "react-hook-form";
import validators from "../../../utils/validators";
import FormEditarFilaPage from "./FormEditarFilaPage";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import { getPayload } from "../../../selectors/routes.selectors";
import { actions } from "../../../reducers/fila.actions";
import { getFila, getFilas } from "../../../selectors/fila.selectors";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";
import formatters from "../../../utils/formatters";

const FormEditarFilaPageConnected = () => {
  const cliente = useSelector(getPayload);
  const dispatch = useDispatch();
  const { data: fila, loading: loadingFila } = useSelector(getFila);
  const listaFilas = useSelector(getFilas);
  const { idFila } = useSelector(getPayload);

  const rules = {
    clienteApolice: validators.string({ required: true }),
    apolice: validators.string({ required: true }),
    dataInclusao: validators.string({ required: true }),
    porcentagem: validators.string({ required: true }),
    situacao: validators.string({ required: true }),
  };

  const initialValues = {
    clienteApolice: "",
    apolice: "",
    dataInclusao: "",
    porcentagem: 0,
    situacao: "",
  };

  const formProps = {
    ...useForm({ defaultValues: initialValues }),
    rules,
  };

  const handleSubmit = (values) => {
    let porcentagem = formProps.getValues("porcentagem");
    let request = {
      id: idFila,
      porcentagem,
    };
    dispatch(actions.editarFila.request(request));
  };

  const handleClickVoltar = useCallback(
    () => {
      dispatch(routeActions.redirectTo(routes.FILAS));
    },
    //eslint-disable-next-line
    [dispatch, listaFilas]
  );

  const handleAprovar = (values) => {
    dispatch(
      actions.aprovarSituacao.request({
        situacao: "A",
        id: cliente.idFila,
      })
    );
  };

  useEffect(() => {
    if (!!fila) {
      let dataEnvio = new Date(fila?.dataEnvio);
      dataEnvio = dataEnvio.setDate(dataEnvio.getDate() - 1);

      let dataInclusao = new Date(fila?.dataInclusao);
      dataInclusao = dataInclusao.setDate(dataInclusao.getDate() - 1);

      formProps.reset({
        clienteApolice: fila?.ClienteApolice?.Cliente?.nomeClienteErp,
        apolice: fila?.ClienteApolice?.numero,
        dataInclusao: formatters.dates.simpleDateDisplay(dataInclusao),
        dataEnvio: fila?.dataEnvio
          ? formatters.dates.simpleDateDisplay(dataEnvio)
          : "",
        porcentagem: Number(fila?.porcentagem),
        situacao:
          fila?.situacao === "N"
            ? "Novo"
            : fila?.situacao === "E"
            ? "Enviado"
            : "Aprovado",
      });
    }
    // eslint-disable-next-line
  }, [fila]);

  return (
    <FormEditarFilaPage
      formProps={formProps}
      handleSubmit={handleSubmit}
      fila={fila}
      loading={loadingFila}
      handleClickVoltar={handleClickVoltar}
      handleAprovar={handleAprovar}
      indice={!!fila.indice}
      indiceValor={fila.indice}
    />
  );
};

export default FormEditarFilaPageConnected;
