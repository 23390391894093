import basicFlow from "./asyncHandler";
import { authenticatedRequest } from "../utils/api";
import { routeWatcher } from "./rotas.saga";
import {
  actions as routeActions,
  types as routes,
} from "../reducers/rotas.actions";
import { actions as apoliceActions } from "../reducers/apolice.actions";
import { actions as domainsActions } from "../reducers/dominios.actions";
import { saveAs } from "file-saver";

import { put, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import { getPayload } from "../selectors/routes.selectors";

const ListarApolicessApi = (values) => {
  return authenticatedRequest({
    url: `/apolices`,
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const ListarApolices = basicFlow({
  actionGenerator: apoliceActions.obterApolices,
  api: ListarApolicessApi,
});

function* ListarApolicesRouteWatcher() {
  yield routeWatcher(routes.APOLICES, function* () {
    const cliente = yield select(getPayload);
    yield put(
      apoliceActions.obterApolices.request({
        cliente: cliente.idCliente,
        filtro: {
          ativos: true,
          inativos: true,
          saude: true,
          odonto: true,
          vida: true,
        },
      })
    );
  });
}

function* ListarSeguradorasRouteWatcher() {
  yield routeWatcher(routes.NEW_APOLICE, function* () {
    yield put(domainsActions.listarSeguradoras.request());
  });
}

const EditarApolicesApi = (values) => {
  return authenticatedRequest({
    url: `/apolice/${values.get("id")}`,
    isResourceService: true,
    headers: { "Content-Type": "multipart/form-data" },
    method: "put",
    body: values,
  });
};

const EditarApolices = basicFlow({
  actionGenerator: apoliceActions.editarApolice,
  transform: function (payload) {
    const form = new FormData();
    const {
      cliente,
      numero,
      seguradora,
      tipo,
      dataAniversario,
      dataVencimento,
      diasCorte,
      dataContratacao,
      dataNegociacao,
      situacao,
      file,
      fileImplantacao,
      precificacoes,
      id,
      editFile,
      editFileImplantacao,
      fileOD,
      arrayFileOD,
      flagCalculaSinistralidade,
    } = payload;

    form.append("valorCapitalSegurado", payload?.valorCapitalSegurado ?? "");
    form.append("valorPremio", payload?.valorPremio ?? "");
    form.append("editFile", JSON.stringify(editFile));
    form.append("editFileImplantacao", JSON.stringify(editFileImplantacao));
    form.append("precificacoes", JSON.stringify(precificacoes));
    form.append("id", id);
    form.append("cliente", cliente);
    form.append("numero", numero);
    form.append("seguradora", JSON.stringify(seguradora));
    form.append("tipo", tipo);
    form.append("dataAniversario", dataAniversario);
    form.append("dataVencimento", dataVencimento);
    form.append("diasCorte", diasCorte);
    form.append("dataContratacao", dataContratacao);
    form.append("dataNegociacao", dataNegociacao);
    form.append("situacao", situacao);
    form.append("flagCalculaSinistralidade", flagCalculaSinistralidade);
    if (file[0]) {
      form.append("arquivos", file[0], "CG" + file[0].name);
    }
    if (fileImplantacao[0]) {
      form.append(
        "arquivos",
        fileImplantacao[0],
        "CI" + fileImplantacao[0].name
      );
    }
    form.append("arrayFileOD", JSON.stringify(arrayFileOD));

    for (let newFileOD of fileOD) {
      if (newFileOD[0]) {
        form.append("arquivos", newFileOD[0], "OD" + newFileOD[0].name);
      }
    }

    return form;
  },
  api: EditarApolicesApi,
  postSuccess: function* ({ original }) {
    yield toast.success("Apólice editada com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    yield put(
      routeActions.redirectTo(routes.APOLICES, {
        idCliente: original.cliente,
      })
    );
  },
});

const BaixarArtefatoApi = (values) => {
  return authenticatedRequest({
    url: `/documento/artefato/${values.artefato}`,
    isResourceService: true,
    method: "get",
  });
};

const BaixarArtefato = basicFlow({
  actionGenerator: apoliceActions.baixarArtefato,
  api: BaixarArtefatoApi,
  postSuccess: ({ response, original, values }) => {
    downloadFile(response.data.file, response.data.type, 512, values.filename);
  },
});

const downloadFile = (b64Data, contentType = "", sliceSize = 512, filename) => {
  const byteCharacters = window.atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  saveAs(blob, filename);
};

const criarApolicesApi = (values) => {
  return authenticatedRequest({
    url: `/apolice`,
    isResourceService: true,
    headers: { "Content-Type": "multipart/form-data" },
    method: "post",
    body: values,
  });
};

const CriarApolices = basicFlow({
  actionGenerator: apoliceActions.criarApolice,
  transform: function (payload) {
    const form = new FormData();

    const {
      cliente,
      numero,
      seguradora,
      tipo,
      dataAniversario,
      dataVencimento,
      diasCorte,
      dataContratacao,
      dataNegociacao,
      situacao,
      file,
      fileImplantacao,
      precificacoes,
      fileOD,
      flagCalculaSinistralidade,
    } = payload;

    form.append("valorCapitalSegurado", payload?.valorCapitalSegurado ?? "");
    form.append("valorPremio", payload?.valorPremio ?? "");
    form.append("cliente", cliente);
    form.append("precificacoes", JSON.stringify(precificacoes));
    form.append("numero", numero);
    form.append("seguradora", JSON.stringify(seguradora));
    form.append("tipo", tipo);
    form.append("dataAniversario", dataAniversario);
    form.append("dataVencimento", dataVencimento);
    form.append("diasCorte", diasCorte);
    form.append("dataContratacao", dataContratacao);
    form.append("dataNegociacao", dataNegociacao);
    form.append("situacao", situacao);
    form.append("flagCalculaSinistralidade", flagCalculaSinistralidade);
    if (file[0]) {
      form.append("arquivos", file[0], "CG" + file[0].name);
    }
    if (fileImplantacao[0]) {
      form.append(
        "arquivos",
        fileImplantacao[0],
        "CI" + fileImplantacao[0].name
      );
    }

    for (let newFileOD of fileOD) {
      if (newFileOD[0]) {
        form.append("arquivos", newFileOD[0], "OD" + newFileOD[0].name);
      }
    }

    return form;
  },
  api: criarApolicesApi,
  postSuccess: function* ({ original }) {
    yield toast.success("Apólice criada com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    yield put(
      routeActions.redirectTo(routes.APOLICES, {
        idCliente: original.cliente,
      })
    );
  },
});

const deletarApolicesApi = (values) => {
  return authenticatedRequest({
    url: `/apolice/delete`,
    isResourceService: true,
    method: "post",
    body: {
      id: values,
    },
  });
};

const DeletarApolices = basicFlow({
  actionGenerator: apoliceActions.deletarApolice,
  api: deletarApolicesApi,
  postSuccess: function* () {
    yield toast.success("Apólice inativada com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    const cliente = yield select(getPayload);
    yield put(
      apoliceActions.obterApolices.request({
        cliente: cliente.idCliente,
        filtro: {
          ativos: true,
          inativos: true,
          saude: true,
          odonto: true,
          vida: true,
        },
      })
    );
  },
});

const ativarApolicesApi = (values) => {
  return authenticatedRequest({
    url: `/apolice/ativar`,
    isResourceService: true,
    method: "post",
    body: {
      id: values,
    },
  });
};

const ativarApolices = basicFlow({
  actionGenerator: apoliceActions.ativarApolice,
  api: ativarApolicesApi,
  postSuccess: function* () {
    yield toast.success("Apólice reativada com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    const cliente = yield select(getPayload);
    yield put(
      apoliceActions.obterApolices.request({
        cliente: cliente.idCliente,
        filtro: {
          ativos: true,
          inativos: true,
          saude: true,
          odonto: true,
          vida: true,
        },
      })
    );
  },
});

const MostrarApoliceApi = (values) => {
  return authenticatedRequest({
    url: `/apolice/${values.id}`,
    isResourceService: true,
    method: "get",
  });
};

const MostrarApolice = basicFlow({
  actionGenerator: apoliceActions.mostrarApolice,
  api: MostrarApoliceApi,
});

const ListarValoresApi = (values) => {
  return authenticatedRequest({
    url: `/apolice/plano-valor/listar`,
    isResourceService: true,
    method: "get",
    queryParams: {
      ...values,
    },
  });
};

const ListarValores = basicFlow({
  actionGenerator: apoliceActions.obterValores,
  api: ListarValoresApi,
});

function* MostrarApoliceRouteWatcher() {
  yield routeWatcher(routes.EDIT_APOLICE, function* () {
    let apolice = yield select(getPayload);
    yield put(apoliceActions.mostrarApolice.request({ id: apolice.idApolice }));
    yield put(domainsActions.listarSeguradoras.request());
  });
}

export const sagas = [
  MostrarApoliceRouteWatcher(),
  ListarApolicesRouteWatcher(),
  ListarSeguradorasRouteWatcher(),
  ListarApolices.watcher(),
  MostrarApolice.watcher(),
  EditarApolices.watcher(),
  CriarApolices.watcher(),
  DeletarApolices.watcher(),
  ListarValores.watcher(),
  BaixarArtefato.watcher(),
  ativarApolices.watcher(),
];
