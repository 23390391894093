import { createAsyncAction, createSyncAction } from "../utils/actionCreators";

export const types = {
  OBTER_FATURAMENTOS: "@@faturamento/OBTER_FATURAMENTOS",
  MUDAR_FILTRO: "@@faturamento/MUDAR_FILTRO",
  BAIXAR_ARTEFATO: "@@faturamento/BAIXAR_ARTEFATO",
};

export const actions = {
  obterFaturamentos: createAsyncAction(types.OBTER_FATURAMENTOS),
  baixarArtefato: createAsyncAction(types.BAIXAR_ARTEFATO),
  mudarFiltro: (filtro) => createSyncAction(types.MUDAR_FILTRO, filtro),
};
