import { CircularProgress } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";

export const StyledContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  height: 100%;
`;
export const StyledCircularProgress = styled(CircularProgress)`
  color: ${({ theme }) => theme.colors.secondary};
  opacity: 0.7;
  margin-top: auto;
  margin-bottom: auto;
`;

export const Spinner = (props) => {
  return (
    <StyledContainer>
      <StyledCircularProgress
        thickness={6}
        disableShrink
        variant={"indeterminate"}
        size={70}
      />
    </StyledContainer>
  );
};

export default Spinner;
