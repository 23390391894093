import React, { useCallback } from "react";
import { Dialog, DialogContent, Grid, styled } from "@mui/material";
import { HighlightOffSharp } from "@mui/icons-material";
import Button from "../../components/buttons/PrimaryButton";
import Card from "../../components/Card";
import OutlinedButton from "../../components/buttons/OutlinedButton";
import { motivoInativacaoBeneficiario } from "../../utils/parametros";
import { MenuItem } from "../../components";
import { ControlledSelect } from "../../components/inputs";

const StyledGrid = styled(Grid)`
  text-align: left;
`;

const StyledGridContent = styled(Grid)`
  text-align: left;
  color: #ffffff;
  font-family: nunito;
  font-size: 22px;
  font-weight: 300;
`;

const IconsGrid = styled(Grid)`
  text-align: right;
  align-items: center;
`;

const CardTitle = styled("div")`
  font-family: patua one;
  font-size: 28px;
  font-weight: 400;
  font-spacing: 1.8px;
  color: #ffffff;
`;

const StyledCardActive = styled(Card)`
  height: auto;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 25px;
  background-color: #415781ff;

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const StyledCloseSharp = styled(HighlightOffSharp)`
  width: 35px;
  height: 32px;
  fill: ${({ theme }) => theme.colors.black};
  margin-left: 10px;
  cursor: pointer;
`;

const ConfirmarExclusaoDialog = ({
  handleClose,
  open,
  excluir,
  beneficiario,
  deleteFormProps,
}) => {
  const handleDelete = useCallback(
    (values) => {
      excluir(beneficiario, values);
      handleClose();
    },
    //eslint-disable-next-line
    [beneficiario, handleClose]
  );

  return (
    <Dialog
      onClose={handleClose}
      classes={{ paper: "w100" }}
      open={open}
      beneficiario={beneficiario}
    >
      {!!open && (
        <DialogContent>
          <form
            onSubmit={deleteFormProps.handleSubmit((values) =>
              handleDelete(values)
            )}
            style={{ width: "100%" }}
          >
            <Grid container spacing={4}>
              <IconsGrid item xs={12} md={12}>
                <StyledCloseSharp onClick={handleClose} />
              </IconsGrid>
              <Grid item xs={12}>
                <StyledCardActive key={beneficiario.id}>
                  <Grid container spacing={4}>
                    <StyledGrid item xs={12} md={12}>
                      <CardTitle>Confirmar Exclusão</CardTitle>
                    </StyledGrid>
                  </Grid>
                  <Grid container spacing={4}>
                    <StyledGridContent item xs={12} md={12}>
                      Deseja excluir o beneficiário{" "}
                      {beneficiario.nomeBeneficiario}?
                    </StyledGridContent>
                  </Grid>

                  <Grid container spacing={4}>
                    <StyledGridContent item xs={12} md={12}>
                      <ControlledSelect
                        name={"situacao"}
                        formProps={deleteFormProps}
                        label={"Motivo"}
                        margin={"none"}
                      >
                        {motivoInativacaoBeneficiario.map((item) => (
                          <MenuItem
                            className={"select-submenu-item"}
                            key={item?.id}
                            value={item?.id}
                          >
                            {item.nome}
                          </MenuItem>
                        ))}
                      </ControlledSelect>
                    </StyledGridContent>
                  </Grid>
                </StyledCardActive>
              </Grid>
              <StyledGrid item xs={6}>
                <OutlinedButton onClick={handleClose}>Voltar</OutlinedButton>
              </StyledGrid>
              <StyledGrid item xs={6}>
                <Button type={"submit"}>Confirmar</Button>
              </StyledGrid>
            </Grid>
          </form>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default ConfirmarExclusaoDialog;
