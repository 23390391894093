import React from "react";
import { Grid, styled } from "@mui/material";
import { PieChart, Tooltip, ResponsiveContainer, Pie, Cell } from "recharts";

const StyledTitle = styled("p")`
  color: ${({ theme }) => theme.colors.white};
  font-family: "Nunito";
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 33px;
  margin-bottom: -35px;
  margin-top: -40px;
  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
  text-align: left;
`;

const StyledTotal = styled("p")`
  color: ${({ theme }) => theme.colors.white};
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
  text-align: left;
  margin-bottom: 0px;
`;

const StyledTip = styled("h6")`
  color: black;
  font-family: "Nunito";
  font-size: 12px;
  font-weight: 200px;
  padding: 2px;
  margin: 2px;
`;
const StyledTipDiv = styled("div")`
  background-color: white;
  cursor: pointer;
`;

const StyledContainer = styled(Grid)`
  height: 400px;
  .recharts-cartesian-axis-tick-value {
    font-size: 12px;
    font-family: ${({ theme }) => theme.fonts.family.subTitle};
  }
`;

const StyledGrid = styled(Grid)`
  height: 400px;
  .recharts-cartesian-axis-tick-value {
    font-size: 12px;
    font-family: ${({ theme }) => theme.fonts.family.subTitle};
  }
`;

const StyledGridLabel = styled(Grid)`
  height: 100%;
`;

const StyledLabelIconContainer = styled("div")`
  display: flex;
  align-items: center;
  height: 30px;
`;

const StyledLabelIcon = styled("div")`
  width: 19.25px;
  height: 11px;
  background-color: ${({ color }) => color};
`;

const StyledLabelText = styled("p")`
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  margin-left: 15px;
`;

const RADIAN = Math.PI / 180;

const COLORS = ["#43B5F4", "#89CFF5", "#FFDBCE", "#FFE0A4"];

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  let label = (percent * 100).toFixed(0);

  return (
    <text
      x={x}
      y={y}
      fill={`${index === 2 ? "#AC3300" : "black"}`}
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${label === "0" ? "" : label + "%"}`}
    </text>
  );
};

function setLabel(label) {
  const str2 = label.charAt(0).toUpperCase() + label.slice(1);

  return str2;
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <StyledTipDiv>
        <StyledTip>{`${setLabel(payload[0].name)} ${
          payload[0].payload?.total
        }`}</StyledTip>
      </StyledTipDiv>
    );
  }
  return null;
};

const LabelIcon = ({ color, label }) => {
  return (
    <StyledLabelIconContainer>
      <StyledLabelIcon color={color} />
      <StyledLabelText>{label}</StyledLabelText>
    </StyledLabelIconContainer>
  );
};

const GraficoSituacaoRateio = ({
  data,
  totalSituacao,
  setOpenRateio,
  handleOpenRateio,
}) => {
  return (
    <StyledContainer container>
      <StyledGrid item xs={7}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={800} height={800}>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={160}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  onClickCapture={() => {
                    handleOpenRateio(entry);
                  }}
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                  style={{ cursor: "pointer" }}
                />
              ))}
            </Pie>
            <Tooltip
              cursor={false}
              wrapperStyle={{ outline: "none" }}
              content={<CustomTooltip />}
            />
          </PieChart>
        </ResponsiveContainer>
      </StyledGrid>
      <StyledGridLabel item xs={5}>
        <StyledTitle>Total Rateios no mês</StyledTitle>
        <StyledTotal>{totalSituacao}</StyledTotal>
        <LabelIcon label={"Pagos"} color={"#43B5F4"} />
        <LabelIcon label={"Aprovados"} color={"#89CFF5"} />
        <LabelIcon label={"Atrasados"} color={"#FFDBCE"} />
        <LabelIcon label={"Pendentes"} color={"#FFE0A4"} />
      </StyledGridLabel>
    </StyledContainer>
  );
};
export default GraficoSituacaoRateio;
