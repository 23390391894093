import BasePage from "../BasePage";
import React, { useCallback, useState } from "react";
import { styled } from "@mui/material";
import { Grid } from "@mui/material";
import CardApolice from "./CardApolice";
import Spinner from "../../components/spinner/Spinner";
import OutlinedButton from "../../components/buttons/OutlinedButton";
import { FilterChip } from "../../components";
import ConfirmarExclusaoDialog from "./ConfirmarExclusaoDialog";
import ConfirmarReativacaoDialog from "./ConfirmarReativacaoDialog";

const StyledH1 = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.blueTitle};
  letter-spacing: 4.8px;
  font-size: 45px;
  margin-bottom: 0px;
  @media (max-width: 900px) {
    font-size: 22px;
  }
`;

const StyledH4 = styled("h4")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  color: ${({ theme }) => theme.colors.subtitle};
  letter-spacing: 4.8px;
  margin-bottom: 15px;
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 20px;
  margin-top: 0px;

  display: flex;
  align-items: flex-end;
  letter-spacing: 0.25px; 
  
  @media (max-width: 900px) {
    font-size: 22px;
  }
`;

const StyledContainer = styled(Grid)`
  margin-top: 1px;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 900px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;
const StyledBasePage = styled(BasePage)`
  padding: 3px;
`;

const ClientesPage = ({ data, nomeCliente, filtrarApolices, loading, handleNewApolice, handleClickEdit, handleDelete,
                        handleAtivar, handleClickVoltar}) => {

  const [filtro, setFiltro] = useState({
    ativos: true,
    inativos: true,
    saude: true,
    odonto: true,
    vida: true,
  });

  const handleFiltro = (tipo) => {
    switch (tipo) {
      case "saude":
        setFiltro({
          ...filtro,
          saude: !filtro.saude,
        });
        filtrarApolices({...filtro, saude: !filtro.saude,});
        break;
      case "ativos":
        setFiltro({
          ...filtro,
          ativos: !filtro.ativos,
        });
        filtrarApolices({...filtro, ativos: !filtro.ativos,});
        break;
      case "inativos":
        setFiltro({
          ...filtro,
          inativos: !filtro.inativos,
        });
        filtrarApolices({...filtro, inativos: !filtro.inativos,});
        break;
      case "odonto":
        setFiltro({
          ...filtro,
          odonto: !filtro.odonto,
        });
        filtrarApolices({...filtro, odonto: !filtro.odonto,});
        break;
      case "vida":
        setFiltro({
          ...filtro,
          vida: !filtro.vida,
        });
        filtrarApolices({...filtro, vida: !filtro.vida,});
        break;
      default:
        break;
    }
  };

  const [dadosExclusao, setDadosExclusao] = useState({
    open: false,
  });

  const [dadosReativacao, setDadosReativacao] = useState({
    open: false,
  });

  const handleCloseExclusao = useCallback(() => {
    setDadosExclusao({
      open: false,
    });
  }, []);

  const handleCloseReativacao = useCallback(() => {
    setDadosReativacao({
      open: false,
    });
  }, []);

  const handleOpenExclusao = useCallback((values) => {
    setDadosExclusao({
      open: true,
      apolice: values,
    });
  }, []);

  const handleOpenReativacao = useCallback((values) => {
    setDadosReativacao({
      open: true,
      apolice: values,
    });
  }, []);

  return (
    <StyledBasePage isLoggedIn={true} isLightBlue={true}>
      <StyledContainer container spacing={2}>
        <Grid item xs={12}>
          <StyledH1>Lista de Apólices</StyledH1>
        </Grid>
        <Grid item xs={12} style={{paddingTop: "0px"}}>
          <StyledH4>{nomeCliente}</StyledH4>
        </Grid>
        {!loading &&
        <Grid item xs={12} style={{ alignItems: "center", display: "flex" }}>
          <FilterChip active={filtro.saude} label={"SAUDE"} onClick={() => handleFiltro("saude")}/>
          <FilterChip active={filtro.odonto} label={"ODONTO"} onClick={() => handleFiltro("odonto")}/>
          <FilterChip active={filtro.vida} label={"VIDA"} onClick={() => handleFiltro("vida")}/>
          <FilterChip active={filtro.ativos} label={"ATIVO"} onClick={() => {
            handleFiltro("ativos")
          }}/>
          <FilterChip active={filtro.inativos} label={"INATIVO"} onClick={() => handleFiltro("inativos")}/>
        </Grid>
        }
        {!!loading &&
        <Grid item xs={12} >
          <Spinner />
        </Grid>
        }
        {!loading &&
          data?.length > 0 &&
          data.map((item, index) => {
            return (
              <Grid key={index} item xs={12} md={4} style={{marginTop: "10px"}}>
                <CardApolice
                  handleClickEdit={handleClickEdit}
                  item={item}
                  handleOpenExclusao={handleOpenExclusao}
                  handleOpenReativacao={handleOpenReativacao}
                />
              </Grid>
            );
          })}
        {!loading && data?.length === 0 && (
          <Grid item xs={12}>
            Não existem apólices cadastradas na base
          </Grid>
        )}
        <ConfirmarExclusaoDialog
          open={dadosExclusao.open}
          handleClose={handleCloseExclusao}
          excluir={handleDelete}
          apolice={dadosExclusao.apolice}
        />
        <ConfirmarReativacaoDialog
          open={dadosReativacao.open}
          handleClose={handleCloseReativacao}
          reativar={handleAtivar}
          apolice={dadosReativacao.apolice}
        />
      </StyledContainer>
      <StyledContainer container spacing={2} direction="row" justifyContent="flex-end">
        <Grid item xs={4} style={{marginTop: "20px"}}>
          <OutlinedButton onClick={handleNewApolice}>
            + Incluir Apólice
          </OutlinedButton>
        </Grid>
      </StyledContainer>
      <StyledContainer container spacing={2} direction="row" justifyContent="center">
        <Grid item xs={4} style={{marginTop: "20px"}}>
          <OutlinedButton onClick={handleClickVoltar}>
            Voltar
          </OutlinedButton>
        </Grid>
      </StyledContainer>

    </StyledBasePage>
  );
};

export default ClientesPage;
