import { createAsyncAction, createSyncAction } from "../utils/actionCreators";

export const types = {
  OBTER_APOLICES: "@@apolice/OBTER_APOLICES",
  MOSTRAR_APOLICE: "@@apolice/MOSTRAR_APOLICE",
  EDITAR_APOLICE: "@@apolice/EDITAR_APOLICE",
  CRIAR_APOLICE: "@@apolice/CRIAR_APOLICE",
  DELETAR_APOLICE: "@@apolice/DELETAR_APOLICE",
  ATIVAR_APOLICE: "@@apolice/ATIVAR_APOLICE",
  OBTER_VALORES: "@@apolice/OBTER_VALORES",
  BAIXAR_ARTEFATO: "@@apolice/BAIXAR_ARTEFATO",
  LIMPAR_VALORES: "@@apolice/LIMPAR_VALORES",
};

export const actions = {
  obterApolices: createAsyncAction(types.OBTER_APOLICES),
  mostrarApolice: createAsyncAction(types.MOSTRAR_APOLICE),
  editarApolice: createAsyncAction(types.EDITAR_APOLICE),
  criarApolice: createAsyncAction(types.CRIAR_APOLICE),
  deletarApolice: createAsyncAction(types.DELETAR_APOLICE),
  ativarApolice: createAsyncAction(types.ATIVAR_APOLICE),
  obterValores: createAsyncAction(types.OBTER_VALORES),
  baixarArtefato: createAsyncAction(types.BAIXAR_ARTEFATO),
  limparValores: () => createSyncAction(types.LIMPAR_VALORES),
};
