import { useForm } from "react-hook-form";
import validators from "../../../utils/validators";
import FormClientePage from "./FormCriarClientePage";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { getCliente } from "../../../selectors/cliente.selectors";
import { getUsuarios } from "../../../selectors/dominios.selectors";
import { actions } from "../../../reducers/cliente.actions";
import { toast } from "react-toastify";
import { removeNonDigitsFromString } from "../../../utils/basic";
import { getPayload } from "../../../selectors/routes.selectors";

const FormClienteConnected = () => {
  const { loading } = useSelector(getCliente);
  const usuariosDominio = useSelector(getUsuarios);
  const [isFile, setIsFile] = useState(false);
  const [isFileImplantacao, setIsFileImplantacao] = useState(false);
  const [file, setFile] = useState([]);
  const [fileImplantacao, setFileImplantacao] = useState([]);
  const [isFileOD, setIsFileOD] = useState(false);
  const [fileOD, setFileOD] = useState([]);
  const { clienteMatriz } = useSelector(getPayload);
  const [arrayFileOD, setArrayFileOD] = useState([]);

  const dispatch = useDispatch();

  const rules = {
    nomeCliente: validators.string({ required: true }),
    emailContato: validators.email({ required: true }),
    usuario: validators.string({ required: true }),
    emailsOpcionais: validators.email({ required: false }),
    nomeClienteErp: validators.string({ required: false }),
    cnpj: validators.cnpj({ required: true }),
    telefoneContato: validators.phone({ required: false }),
    telefoneOpcional: validators.phone({ required: false }),
  };

  const initialValues = {
    cnpj: "",
    nomeCliente: "",
    nomeClienteErp: "",
    emailContato: "",
    emailsOpcionais: "",
    telefoneContato: "",
    telefoneOpcional: "",
    logradouro: "",
    numeroLogradouro: "",
    complemento: "",
    bairro: "",
    nomeMunicipio: "",
    uf: "",
    flagCalculaSinistralidade: true,
    usuario: "",
    usuarioResponsavel: "",
  };

  const formProps = {
    ...useForm({ defaultValues: initialValues }),
    rules,
  };

  const handleSubmit = (values) => {
    let { nomeCliente, cnpj, usuario, emailContato } = values;

    if (!cnpj || !nomeCliente || !emailContato || !usuario) {
      toast.error("Favor preencher todos os campos obrigatórios.", {
        theme: "colored",
        icon: false,
        style: { backgroundColor: "#990a26" },
      });
    } else {
      const request = {
        nomeCliente: values.nomeCliente,
        emailContato: values.emailContato,
        cep: removeNonDigitsFromString(values.cep),
        emailsOpcionais: values.emailsOpcionais,
        uf: values.uf,
        complemento: values.complemento,
        logradouro: values.logradouro,
        clienteMatriz: values?.clienteMatriz ?? clienteMatriz,
        usuario: values.usuario,
        usuarioResponsavel: values.usuarioResponsavel,
        bairro: values.bairro,
        nomeClienteErp: values.nomeClienteErp,
        nomeMunicipio: values.nomeMunicipio,
        cnpj: removeNonDigitsFromString(values.cnpj),
        numeroLogradouro: values.numeroLogradouro,
        telefoneContato: removeNonDigitsFromString(values.telefoneContato),
        telefoneOpcional: removeNonDigitsFromString(values.telefoneOpcional),
        situacao: "A",
        file,
        fileImplantacao,
        fileOD,
      };

      dispatch(actions.criarCliente.request(request));
    }
  };

  return (
    <FormClientePage
      loading={loading || usuariosDominio.loading}
      formProps={formProps}
      isFile={isFile}
      setIsFile={setIsFile}
      isFileImplantacao={isFileImplantacao}
      setIsFileImplantacao={setIsFileImplantacao}
      file={file}
      setFile={setFile}
      fileImplantacao={fileImplantacao}
      setFileImplantacao={setFileImplantacao}
      handleSubmit={handleSubmit}
      isFileOD={isFileOD}
      setIsFileOD={setIsFileOD}
      fileOD={fileOD}
      setFileOD={setFileOD}
      setArrayFileOD={setArrayFileOD}
      arrayFileOD={arrayFileOD}
    />
  );
};

export default FormClienteConnected;
