import { ControlledTextField } from "../../components/inputs";
import { PrimaryButton as Button } from "../../components";
import { Lock, NotInterested, Check } from "@mui/icons-material";

import React, { useState } from "react";
import { Grid, InputAdornment } from "@mui/material";
import { styled } from "@mui/system";

const StyledFormGrid = styled(Grid)`
  place-self: flex-start;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const StyledControlledTextField = styled(ControlledTextField)`

margin-bottom:1px;
margin-top:1px;
font-weight: normal;
letter-spacing: 2.4px;


flex: 1!important;

@media (max-width: 900px) {
  margin-bottom:5px;
  margin-top:5px;
}


  & .MuiInputLabel-root {
    background-color: #415781ff;
    color: #ffffff !important;

    padding: 8px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  
  & .MuiFormHelperText-root.Mui-error {
    color: #f0e500ff!important;

  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #ffffff;
    },
    &:hover fieldset {
      border-color: #ffffff;
    },
    &.Mui-focused fieldset {
      border-color: #ffffff;
    },
    &.Mui-error fieldset {
    border: 2px solid #f0e500ff;
    },`;

const StyledH2 = styled("h2")`
  text-align: center;
  margin-top: -5px;
  font-family: ${({ theme }) => theme.fonts.family.title};
  letter-spacing: 1.8px;
  text-align: left;
  font-weight: normal;
  margin-bottom: 10px;

  color: #ffffff;
  font-size: 14px;
  @media (max-width: 900px) {
    font-size: 12px;
    margin-bottom: 10px;
  }
`;
const StyledCheck = styled(Check)`
  color: white;
  font-size: 17px;
  vertical-align: middle;
  margin-top: -2px;
`;
const StyledNotInterested = styled(NotInterested)`
  color: red;
  font-size: 17px;
  vertical-align: middle;
  margin-top: -2px;
`;

const StyledButton = styled(Button)`
  margin-top: 5px;
`;

const ValidationItem = ({ state, item, children }) => (
  <div className="instrucoes">
    <StyledH2>
      {!!state.dirty && state.list.indexOf(item) === -1 ? (
        <StyledCheck />
      ) : (
        <StyledNotInterested />
      )}{" "}
      {children}
    </StyledH2>
  </div>
);

const TrocarSenhaForm = ({
  loading,
  formProps,
  handleSubmit,
  handlePasswordChange,
}) => {
  const [state, setState] = useState({ dirty: false, list: [] });

  return (
    <form onSubmit={formProps.handleSubmit(handleSubmit)}>
      <StyledFormGrid item xs={12} md={12}>
        <StyledControlledTextField
          label="Nova Senha"
          name={"password"}
          formProps={formProps}
          type={"password"}
          margin={"none"}
          onChange={(v) => handlePasswordChange(v, setState)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className={"ml-2 mr-3"}>
                <Lock style={{ color: "#ffffff" }} className="color-brand-2" />
              </InputAdornment>
            ),
          }}
        />
      </StyledFormGrid>
      <StyledFormGrid item xs={12} md={12}>
        <StyledControlledTextField
          label="Repetir Senha"
          name={"password2"}
          formProps={formProps}
          type={"password"}
          margin={"none"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className={"ml-2 mr-3"}>
                <Lock style={{ color: "#ffffff" }} className="color-brand-2" />
              </InputAdornment>
            ),
          }}
        />
      </StyledFormGrid>
      <div className="d-flex flex-column align-items-center mt-3">
        <StyledH2 style={{ marginBotton: "15px" }}>
          Sua nova senha deve respeitar as seguintes regras:
        </StyledH2>
        <div className="mt-3">
          <ValidationItem state={state} item={"min"}>
            Ter no mínimo 8 caracteres
          </ValidationItem>
          <ValidationItem state={state} item={"lowercase"}>
            Ter ao menos uma letra minúscula
          </ValidationItem>
          <ValidationItem state={state} item={"uppercase"}>
            Ter ao menos uma letra maiúscula
          </ValidationItem>
          <ValidationItem state={state} item={"digits"}>
            Ter ao menos um número
          </ValidationItem>
          <ValidationItem state={state} item={"symbols"}>
            Ter ao menos um caracter especial
          </ValidationItem>
        </div>
      </div>
      <div className="mini-separator mb-3" />
      <div className={"row justify-content-center"}>
        <div className="col-xs-12 col-md-6">
          <StyledButton loading={loading} type={"submit"}>
            CONFIRMAR
          </StyledButton>
        </div>
      </div>
    </form>
  );
};

export default TrocarSenhaForm;
