import React from "react";
import { styled } from "@mui/material";
import { Grid } from "@mui/material";
import { Card } from "../../components";
import ApolicesCard from "./acoes/ApolicesCard";
import NovasSolicitacoesCard from "./acoes/NovasSolicitacoesCard";
import AtualizacoesVidaCard from "./acoes/AtualizacoesVidaCard";

const StyledCard = styled(Card)`
  height: auto;
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 25px;
  background-color: #415781ff;

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const StyledH2 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.white};
  text-align: left;
  letter-spacing: 1.8px;
  font-size: 28px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
`;

const AcoesCard = ({ data }) => {
  return (
    <Grid item xs={12}>
      <StyledCard>
        <StyledH2>Ações Necessárias</StyledH2>
        <Grid container>
          <ApolicesCard apolice={data.apolices} />
          <NovasSolicitacoesCard solicitacao={data.solicitacoes} />
          <AtualizacoesVidaCard atualizacao={data.atualizacoes} />
        </Grid>
      </StyledCard>
    </Grid>
  );
};

export default AcoesCard;
