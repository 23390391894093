import basicFlow from "./asyncHandler";
import { unauthenticatedRequest } from "../utils/api";
import { actions } from "../reducers/endereco.actions";
import { removeNonDigitsFromString } from "../utils/basic";

const consultaCepCorreiosApi = ({ value }) => {
  return unauthenticatedRequest({
    url: `/ws/${removeNonDigitsFromString(value)}/json/`,
    method: "get",
    base: "https://viacep.com.br",
  });
};

const consultarCep = basicFlow({
  actionGenerator: actions.consultarCepCorreios,
  api: consultaCepCorreiosApi,
  postSuccess: function* ({ response, original }) {
    if (!!original.successCallback) {
      yield original.successCallback(response, original.value);
    }
  },
});

export const sagas = [
  consultarCep.watcher(),
];
