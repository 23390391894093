import basicFlow from "./asyncHandler";
import { authenticatedRequest } from "../utils/api";
import { routeWatcher } from "./rotas.saga";
import {
  actions as routeActions,
  types as routes,
} from "../reducers/rotas.actions";
import { actions as domainsActions } from "../reducers/dominios.actions";
import { actions as clienteActions } from "../reducers/cliente.actions";
import { put, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import { getPayload } from "../selectors/routes.selectors";
import { getClientes } from "../selectors/cliente.selectors";

const listarClientesApi = (values) => {
  return authenticatedRequest({
    url: `/clientes`,
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const listarClientes = basicFlow({
  actionGenerator: clienteActions.obterClientes,
  api: listarClientesApi,
  transform: function* (values) {
    const clientes = yield select(getClientes);
    return {
      pagina: values.pagina,
      filtros: clientes.filtros,
      pesquisa: values.pesquisa,
    };
  },
});

function* listarClientesRouteWatcher() {
  yield routeWatcher(routes.CLIENTES, function* () {
    const clientes = yield select(getClientes);

    yield put(clienteActions.obterClientes.request(clientes));
  });
}

function* novoClienteRouteWatcher() {
  yield routeWatcher(routes.NEW_CLIENTE, function* () {
    yield put(domainsActions.listarUsuarios.request());
  });
}

const editarClientesApi = (values) => {
  return authenticatedRequest({
    url: `/cliente/${values.get("id")}`,
    isResourceService: true,
    headers: { "Content-Type": "multipart/form-data" },
    method: "put",
    body: values,
  });
};

const editarClientes = basicFlow({
  actionGenerator: clienteActions.editarCliente,
  transform: function (payload) {
    const form = new FormData();

    const {
      nomeCliente,
      emailContato,
      cep,
      emailsOpcionais,
      uf,
      complemento,
      logradouro,
      flagCalculaSinistralidade,
      clienteMatriz,
      usuario,
      usuarioResponsavel,
      bairro,
      nomeClienteErp,
      nomeMunicipio,
      cnpj,
      numeroLogradouro,
      telefoneContato,
      telefoneOpcional,
      situacao,
      file,
      fileImplantacao,
      editFile,
      editFileImplantacao,
      id,
      fileOD,
      arrayFileOD,
    } = payload;

    form.append("editFile", JSON.stringify(editFile));
    form.append("editFileImplantacao", JSON.stringify(editFileImplantacao));
    form.append("id", id);
    form.append("nomeCliente", nomeCliente);
    form.append("emailContato", emailContato);
    form.append("cep", cep);
    form.append("emailsOpcionais", emailsOpcionais);
    form.append("uf", uf);
    form.append("complemento", complemento);
    form.append("logradouro", logradouro);
    form.append("flagCalculaSinistralidade", flagCalculaSinistralidade);
    form.append("clienteMatriz", clienteMatriz);
    form.append("usuario", usuario);
    form.append("usuarioResponsavel", usuarioResponsavel);
    form.append("bairro", bairro);
    form.append("nomeClienteErp", nomeClienteErp);
    form.append("nomeMunicipio", nomeMunicipio);
    form.append("cnpj", cnpj);
    form.append("numeroLogradouro", numeroLogradouro);
    form.append("telefoneContato", telefoneContato);
    form.append("telefoneOpcional", telefoneOpcional);
    form.append("situacao", situacao);
    if (file[0]) {
      form.append("arquivos", file[0], "CC" + file[0].name);
    }
    if (fileImplantacao[0]) {
      form.append(
        "arquivos",
        fileImplantacao[0],
        "CI" + fileImplantacao[0].name
      );
    }

    form.append("arrayFileOD", JSON.stringify(arrayFileOD));

    for (let newFileOD of fileOD) {
      if (newFileOD[0]) {
        form.append("arquivos", newFileOD[0], "OD" + newFileOD[0].name);
      }
    }

    return form;
  },

  api: editarClientesApi,
  postSuccess: function* () {
    yield toast.success("Cliente editado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    yield put(routeActions.redirectTo(routes.CLIENTES));
  },
});

const criarClientesApi = (values) => {
  return authenticatedRequest({
    url: `/cliente`,
    isResourceService: true,
    headers: { "Content-Type": "multipart/form-data" },
    method: "post",
    body: values,
  });
};

const criarClientes = basicFlow({
  actionGenerator: clienteActions.criarCliente,
  transform: function (payload) {
    const form = new FormData();

    const {
      nomeCliente,
      emailContato,
      cep,
      emailsOpcionais,
      uf,
      complemento,
      logradouro,
      flagCalculaSinistralidade,
      clienteMatriz,
      usuario,
      usuarioResponsavel,
      bairro,
      nomeClienteErp,
      nomeMunicipio,
      cnpj,
      numeroLogradouro,
      telefoneContato,
      telefoneOpcional,
      situacao,
      file,
      fileImplantacao,
      fileOD,
    } = payload;

    form.append("nomeCliente", nomeCliente);
    form.append("emailContato", emailContato);
    form.append("cep", cep);
    form.append("emailsOpcionais", emailsOpcionais);
    form.append("uf", uf);
    form.append("complemento", complemento);
    form.append("logradouro", logradouro);
    form.append("flagCalculaSinistralidade", flagCalculaSinistralidade);
    form.append("clienteMatriz", clienteMatriz);
    form.append("usuario", usuario);
    form.append("usuarioResponsavel", usuarioResponsavel);
    form.append("bairro", bairro);
    form.append("nomeClienteErp", nomeClienteErp);
    form.append("nomeMunicipio", nomeMunicipio);
    form.append("cnpj", cnpj);
    form.append("numeroLogradouro", numeroLogradouro);
    form.append("telefoneContato", telefoneContato);
    form.append("telefoneOpcional", telefoneOpcional);
    form.append("situacao", situacao);
    if (file[0]) {
      form.append("arquivos", file[0], "CC" + file[0].name);
    }
    if (fileImplantacao[0]) {
      form.append(
        "arquivos",
        fileImplantacao[0],
        "CI" + fileImplantacao[0].name
      );
    }

    for (let newFileOD of fileOD) {
      if (newFileOD[0]) {
        form.append("arquivos", newFileOD[0], "OD" + newFileOD[0].name);
      }
    }

    return form;
  },

  api: criarClientesApi,

  postSuccess: function* () {
    yield toast.success("Cliente criado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    yield put(routeActions.redirectTo(routes.CLIENTES));
  },
});

const deletarClientesApi = (values) => {
  return authenticatedRequest({
    url: `/cliente/delete`,
    isResourceService: true,
    method: "post",
    body: {
      id: values,
    },
  });
};

const deletarClientes = basicFlow({
  actionGenerator: clienteActions.deletarCliente,
  api: deletarClientesApi,
  postSuccess: function* () {
    yield toast.success("Cliente inativado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });

    yield put(
      clienteActions.obterClientes.request({
        pagina: 1,
        filtros: {
          ativos: true,
          inativos: true,
          cliente: true,
          subestipulante: true,
        },
        pesquisa: "",
      })
    );
  },
});

const ativarClientesApi = (values) => {
  return authenticatedRequest({
    url: `/cliente/ativar`,
    isResourceService: true,
    method: "post",
    body: {
      id: values,
    },
  });
};

const ativarClientes = basicFlow({
  actionGenerator: clienteActions.ativarCliente,
  api: ativarClientesApi,
  postSuccess: function* () {
    yield toast.success("Cliente ativado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });

    yield put(
      clienteActions.obterClientes.request({
        pagina: 1,
        filtros: {
          ativos: true,
          inativos: true,
          cliente: true,
          subestipulante: true,
        },
        pesquisa: "",
      })
    );
  },
});

const mostrarClienteApi = (values) => {
  return authenticatedRequest({
    url: `/cliente/` + values.id,
    isResourceService: true,
    method: "get",
  });
};

const mostrarCliente = basicFlow({
  actionGenerator: clienteActions.mostrarCliente,
  api: mostrarClienteApi,
});

function* mostrarClienteRouteWatcher() {
  yield routeWatcher(routes.EDIT_CLIENTE, function* () {
    yield put(domainsActions.listarUsuarios.request());
    const cliente = yield select(getPayload);
    yield put(clienteActions.mostrarCliente.request(cliente));
    yield put(
      clienteActions.obterSubestipulantes.request({
        idCliente: Number.parseInt(cliente.id),
      })
    );
  });
}

const obterSubestipulantesApi = (values) => {
  return authenticatedRequest({
    url: `/cliente/subestipulantes`,
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const obterSubestipulantes = basicFlow({
  actionGenerator: clienteActions.obterSubestipulantes,
  api: obterSubestipulantesApi,
});

function* novoSubstabelecidoRouteWatcher() {
  yield routeWatcher(routes.NEW_SUB, function* () {
    yield put(domainsActions.listarUsuarios.request());
  });
}

const listarApolicesApi = (values) => {
  return authenticatedRequest({
    url: `/cliente/apolices`,
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const listarApolices = basicFlow({
  actionGenerator: clienteActions.listarApolices,
  api: listarApolicesApi,
  transform: function* (values) {
    const cliente = yield select(getPayload);
    return {
      idCliente: Number.parseInt(cliente.idCliente),
      situacao: values?.situacao,
      idBeneficiario: !values?.idBeneficiario
        ? false
        : Number.parseInt(values?.idBeneficiario),
    };
  },
});

export const sagas = [
  mostrarClienteRouteWatcher(),
  listarClientesRouteWatcher(),
  listarClientes.watcher(),
  mostrarCliente.watcher(),
  editarClientes.watcher(),
  criarClientes.watcher(),
  deletarClientes.watcher(),
  novoClienteRouteWatcher(),
  ativarClientes.watcher(),
  obterSubestipulantes.watcher(),
  novoSubstabelecidoRouteWatcher(),
  listarApolices.watcher(),
];
