import { actions } from "./manutencaoCliente.actions";
import { types as routeActions } from "./rotas.actions";

const initialState = {
  manutencoes: {
    loading: false,
    page: 1,
  },
  manutencao: {
    loading: false,
    cliente: "",
    data: [],
  },
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.obterManutencoesCliente.REQUEST:
    case actions.obterManutencoesCliente.SUCCESS:
    case actions.obterManutencoesCliente.FAILURE:
      return {
        ...state,
        manutencoes: {
          ...state.manutencoes,
          loading: action.type === actions.obterManutencoesCliente.REQUEST,
          data:
            action.type === actions.obterManutencoesCliente.SUCCESS
              ? action.payload.response.data.resultado
              : [],
          page:
            action.type === actions.obterManutencoesCliente.SUCCESS
              ? action.payload.response.data.paginaAtual
              : state.manutencoes.page,
          count:
            action.type === actions.obterManutencoesCliente.SUCCESS
              ? action.payload.response.data.total
              : [],
        },
      };
    case routeActions.NEW_ATUALIZACAO:
      return {
        ...state,
        manutencao: {
          ...state.manutencao,
          cliente: action.payload.idCliente
        },
      };
    case actions.mostrarManutencaoCliente.REQUEST:
    case actions.mostrarManutencaoCliente.SUCCESS:
    case actions.mostrarManutencaoCliente.FAILURE:
      return {
        ...state,
        manutencao: {
          ...state.manutencao,
          loading: action.type === actions.mostrarManutencaoCliente.REQUEST,
          data:
            action.type === actions.mostrarManutencaoCliente.SUCCESS
              ? {
                  ...action.payload.response.data,
                }
              : state.manutencao.data,
        },
      };
    case actions.criarManutencaoCliente.REQUEST:
    case actions.criarManutencaoCliente.SUCCESS:
    case actions.criarManutencaoCliente.FAILURE:
      return {
        ...state,
        manutencao: {
          ...state.manutencao,
          loading: action.type === actions.criarManutencaoCliente.REQUEST,
          data:
            action.type === actions.criarManutencaoCliente.SUCCESS
              ? action.payload.response.data
              : action.type === actions.criarManutencaoCliente.REQUEST
              ? {
                  ...action.payload,
                }
              : {
                  ...action.payload.original.manutencao,
                },
        },
      };
    case actions.deletarManutencaoCliente.REQUEST:
    case actions.deletarManutencaoCliente.SUCCESS:
    case actions.deletarManutencaoCliente.FAILURE:
      return {
        ...state,
        manutencoes: {
          ...state.manutencoes,
          loading: action.type === actions.deletarManutencaoCliente.REQUEST,
        },
      };
    case actions.alterarSituacao.REQUEST:
    case actions.alterarSituacao.SUCCESS:
    case actions.alterarSituacao.FAILURE:
      return {
        ...state,
        manutencoes: {
          ...state.manutencoes,
          loading: action.type === actions.alterarSituacao.REQUEST,
        },
      };
    default:
      return state;
  }
};

export default reducer;
