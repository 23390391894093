import BasePage from "../BasePage";
import React, { useState } from "react";
import { Card, MenuItem, Skeleton, styled } from "@mui/material";
import { Grid } from "@mui/material";
import { ControlledTextField } from "../../components/inputs";
import Button from "../../components/buttons/PrimaryButton";
import { useDispatch } from "react-redux";
import {
  actions as routeActions,
  types as routes,
} from "../../reducers/rotas.actions";
import { actions } from "../../reducers/usuarioAdm.actions";

const StyledCard = styled(Card)`
  background-color: #ffffff;
  height: auto;
  width: 100%;
  margin: auto;
  margin-top: 75px;
  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  box-shadow: 0px 0px !important;
  border: 1.5px solid #b4cfe7ff;
  padding: 25px;
  @media (max-width: 900px) {
    width: 100wh;
    margin: 0px;
    margin-top: 40px;
  }
`;
const StyledControlledTextField = styled(ControlledTextField)`
    background-color: #ffffff;
    margin-bottom:4px;
    margin-top:4px;
    flex: 1!important;
    font-family: ${({ theme }) => theme.fonts.family.subTitle};
    color: ${({ theme }) => theme.colors.primary};
    font-size: 17px;

    & .MuiInputBase-input{
      font-family: ${({ theme }) => theme.fonts.family.subTitle};
      color: ${({ theme }) => theme.colors.primary};
      font-size: 17px;
      }
  

    @media (max-width: 900px) {
      margin-bottom:5px;
      margin-top:5px;
    }
    


    & .MuiInputBase-input{
      color: ${({ theme }) => theme.colors.primary};
    
    }


  & .MuiInputLabel-root {
    background-color: #ffffff;
    color: #849dce !important;

    padding: 8px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  & .MuiFormHelperText-root.Mui-error {
    color: #D11F44!important;

  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #849dce;
    },
    &:hover fieldset {
      border-color: #849dce;
    },
    &.Mui-focused fieldset {
      border: 3px solid #849dce;
    },
    &.Mui-error fieldset {
    border: 2.5px solid #D11F44;
    },`;

const StyledGrid = styled(Grid)`
  padding: 5px;
`;

const StyledGridContainer = styled(Grid)`
  margin-bottom: 20px;
`;

const ButtonContainer = styled("div")`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 900px) {
    display: flex;
    justify-content: center !important;
    margin-right: 80px;
    margin-left: 100px;
  }
`;

const StyledButton = styled(Button)`
  width: 200px !important;
  margin-right: 5px;
  @media (max-width: 900px) {
    width: 120px !important;
    margin-right: 0px;
  }
`;
const StyledLeftButton = styled(Button)`
  width: 200px !important;
  margin-right: 20px;
  @media (max-width: 900px) {
    width: 120px !important;
    margin-right: 0px;
  }
`;

const StyledH2 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: ${({ theme }) => theme.colors.primary};
  text-align: left;
  letter-spacing: 2px;
  font-size: 20px;
  margin: 25px;
  margin-left: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
`;
const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 500px;
  margin-bottom: -65px;
  margin-top: -35px;
`;

const FormCriarUsuarioPage = ({ loading, formProps, clientes }) => {
  const dispatch = useDispatch();

  const handleSubmit = () => {
    const nome = formProps.getValues("nome");
    const email = formProps.getValues("email");
    const codigo = formProps.getValues("codigo");
    const perfil = formProps.getValues("perfil");
    const cliente = formProps.getValues("cliente");

    dispatch(actions.criarUsuario.request({ nome, email, codigo, perfil, cliente }));
  };

  const handleClickVoltar = () => {
    dispatch(routeActions.redirectTo(routes.USUARIO));
  };

  const [isCliente, setIsCliente] = useState(false);

  const handleCliente = (e) => {
    setIsCliente(e.target.value === 'F')
  };

  if (loading) {
    return (
      <BasePage isLoggedIn={true} isLightBlue={true}>
        <StyledSkeleton />
      </BasePage>
    );
  }

  return (
    <form onSubmit={formProps.handleSubmit(handleSubmit)}>
      <BasePage isLoggedIn={true} isLightBlue={true}>
        <StyledCard>
          <StyledH2>Usuário (NOVO)</StyledH2>
          <StyledGridContainer container>
            <StyledGrid item xs={12} sm={6} md={6}>
              <StyledControlledTextField
                formProps={formProps}
                fullWidth
                name="codigo"
                label="Usuário"
                inputProps={{ maxLength: 60 }}
              />
            </StyledGrid>

            <StyledGrid item xs={12} sm={6} md={6}>
              <StyledControlledTextField
                formProps={formProps}
                fullWidth
                name="email"
                label="Email"
                inputProps={{ maxLength: 100 }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={6} md={6}>
              <StyledControlledTextField
                formProps={formProps}
                fullWidth
                name="nome"
                label="Nome"
                inputProps={{ maxLength: 100 }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={6} md={6}>
              <StyledControlledTextField
                formProps={formProps}
                fullWidth
                name="perfil"
                label="Perfil"
                select
                onChange={(e) => handleCliente(e)}
              >
                <MenuItem key={"A"} value={"A"}>
                  Administrador
                </MenuItem>
                <MenuItem key={"C"} value={"C"}>
                  Corretor
                </MenuItem>
                <MenuItem key={"F"} value={"F"}>
                  Cliente
                </MenuItem>
              </StyledControlledTextField>
            </StyledGrid>
            {isCliente &&
              <StyledGrid item xs={12} sm={12} md={12}>
                <StyledControlledTextField
                  formProps={formProps}
                  fullWidth
                  name="cliente"
                  label="Cliente"
                  select
                >
                  {clientes.map((i) => (
                      <MenuItem key={i.id} value={i.id}>
                        {i.nomeCliente}
                      </MenuItem>
                    )
                  )}
                </StyledControlledTextField>
              </StyledGrid>
            }
          </StyledGridContainer>

          <ButtonContainer>
            <StyledLeftButton onClick={handleClickVoltar}>
              Voltar
            </StyledLeftButton>
            <StyledButton type={"submit"} loading={loading}>
              Criar
            </StyledButton>
          </ButtonContainer>
        </StyledCard>
      </BasePage>
    </form>
  );
};

export default FormCriarUsuarioPage;
