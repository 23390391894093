import RateiosPage from "./RateiosPage";
import { useSelector, useDispatch } from "react-redux";
import {
  actions as routeActions,
  types as routes,
} from "../../reducers/rotas.actions";
import { useCallback } from "react";
import { getRateios } from "../../selectors/rateio.selectors";
import { getPayload } from "../../selectors/routes.selectors";
import { actions } from "../../reducers/rateio.actions";
import { useForm } from "react-hook-form";
import validators from "../../utils/validators";
import {
  getClientes,
  getSeguradoras,
} from "../../selectors/dominios.selectors";

const RateiosPageConnected = () => {
  const dispatch = useDispatch();
  const { loading, data, nomeCliente, page, count, filtros } = useSelector(getRateios);
  const { idCliente } = useSelector(getPayload);
  const { data: dataSeguradoras } = useSelector(getSeguradoras);
  const { data: dataClientes } = useSelector(getClientes);
  const { subestipulante } = useSelector(getPayload);

  const handleNewApolice = useCallback(() => {
    dispatch(routeActions.redirectTo(routes.NEW_RATEIO, { idCliente }));
    //eslint-disable-next-line
  }, [dispatch]);

  const handleClickEdit = useCallback(
    (id, idCliente) => {
      dispatch(
        routeActions.redirectTo(routes.EDIT_RATEIO, {
          idCliente,
          idRateio: id,
        })
      );
    },
    //eslint-disable-next-line
    [dispatch]
  );

  const handleClickEditDocumetos = useCallback(
    (id, idCliente) => {
      dispatch(
        routeActions.redirectTo(routes.EDIT_RATEIO, {
          idCliente,
          idRateio: id,
          isDocumento: true,
        })
      );
    },
    //eslint-disable-next-line
    [dispatch]
  );

  const handleClickDownload = useCallback(
    (id, numeroApolice, nomeCliente) => {
      dispatch(
        actions.downloadRateio.request({
          id,
          numeroApolice,
          nomeCliente,
        })
      );
    },
    [dispatch]
  );

  const handleClickVoltar = useCallback(
    () => {
      if (subestipulante) {
        dispatch(
          routeActions.redirectTo(routes.EDIT_CLIENTE, {
            id: subestipulante,
            isSubestipulante: true,
            tab: 1,
          })
        );
      } else {
        dispatch(routeActions.redirectTo(routes.CLIENTES));
      }
    },
    //eslint-disable-next-line
    [dispatch]
  );

  const handleDelete = useCallback(
    (id) => {
      dispatch(actions.deletarRateio.request(id));
    },
    [dispatch]
  );

  const handleAtivar = useCallback(
    (id) => {
      dispatch(actions.ativarRateio.request(id));
    },
    [dispatch]
  );

  const filtrarRateios = useCallback(
    (values) => {
      dispatch(
        actions.obterRateios.request({
          filtro: values,
          cliente: idCliente,
          pagina: page,
        })
      );
    },
    // eslint-disable-next-line
    [dispatch, idCliente, page]
  );

  const initialValues = {
    cliente: idCliente,
    seguradora: "",
    competencia: "",
  };

  const rules = {
    cliente: validators.string({ required: false }),
    seguradora: validators.string({ required: false }),
    competencia: validators.string({ required: false }),
  };

  const formProps = {
    ...useForm({ defaultValues: initialValues }),
    rules,
  };

  const handlePagination = useCallback(
    (e, pagina) => {
      dispatch(
        actions.obterRateios.request({ filtro: filtros, pagina })
      );
    },
    //eslint-disable-next-line
    [dispatch, filtros]
  );

  return (
    <RateiosPage
      loading={loading}
      data={data}
      handleNewApolice={handleNewApolice}
      handleClickEdit={handleClickEdit}
      nomeCliente={nomeCliente}
      filtrarRateios={filtrarRateios}
      handleDelete={handleDelete}
      handleAtivar={handleAtivar}
      handleClickVoltar={handleClickVoltar}
      formProps={formProps}
      clientesDomain={dataClientes}
      seguradorasDomain={dataSeguradoras}
      page={page}
      count={count}
      handleClickEditDocumetos={handleClickEditDocumetos}
      handleClickDownload={handleClickDownload}
      handlePagination={handlePagination}
    />
  );
};

export default RateiosPageConnected;
