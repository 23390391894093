import BasePage from "../../BasePage";
import React, { useState } from "react";
import Button from "../../../components/buttons/PrimaryButton";
import OutlinedButton from "../../../components/buttons/OutlinedButton";
import { Grid, Skeleton, Tabs, Tab, styled } from "@mui/material";
import FormDadosApolice from "../tabs/FormDadosApolice";
import FormDocumentosApolice from "../tabs/FormDocumentosApolice";

const StyledH1 = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.blueTitle};
  letter-spacing: 4.8px;
  font-size: 45px;
  margin-bottom: -30px;
  @media (max-width: 900px) {
    font-size: 22px;
    margin-bottom: 10px;
  }
`;

const StyledTab = styled(Tabs)`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  & .Mui-selected {
    color: ${({ theme }) => theme.buttons.primary.background};
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;

const StyledTabLabel = styled(Tab)`
  align-items: flex-start;
  min-width: 50%;
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: gray;
  font-weight: bold;
  text-transform: none;
  margin-bottom: -60px;
  font-size: 16px;
`;

const StyledContainer = styled(Grid)`
  margin-top: 10px;
  padding-left: 25px;
  padding-right: 20px;
  @media (max-width: 900px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;
const StyledBasePage = styled(BasePage)`
  padding: 3px;
`;

const StyledGrid = styled(Grid)`
  padding: 5px;
`;

const StyledGridContainer = styled(Grid)`
  margin-bottom: 0px;
`;

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 500px;
  margin-bottom: -65px;
`;

const FormApolicePage = ({
  data,
  loading,
  formProps,
  formPropsPrecificao,
  handleAddPrecificacao,
  seguradorasDomain,
  handleLoadPlanos,
  planosDomain,
  handleLoadValores,
  clearValores,
  setPrecificacoes,
  precificacoes,
  buttonTitle,
  handleEdit,
  checked,
  handleSubmit,
  isFile,
  setIsFile,
  isFileImplantacao,
  setIsFileImplantacao,
  file,
  setFile,
  fileImplantacao,
  setFileImplantacao,
  handleDeletePrecificacao,
  handleClickVoltar,
  lastSeguradora,
  setLastSeguradora,
  setIsVida,
  isVida,
  isFileOD,
  fileOD,
  setFileOD,
  setIsFileOD,
  arrayFileOD,
  setArrayFileOD,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (loading) {
    return (
      <StyledBasePage isLoggedIn={true} isLightBlue={true}>
        <StyledSkeleton />
      </StyledBasePage>
    );
  }

  return (
    <form onSubmit={formProps.handleSubmit(handleSubmit)}>
      <BasePage isLoggedIn={true} isLightBlue={true}>
        <StyledContainer container spacing={4}>
          <Grid item xs={12}>
            <StyledH1>Cadastro da Apólice</StyledH1>
          </Grid>
          <Grid item xs={12}>
            <StyledTab
              sx={{ marginBottom: "10px" }}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              color={({ theme }) => theme.colors.primary}
              TabIndicatorProps={{
                style: {
                  background: "#F24F13",
                  color: "#F24F13",
                  minHeight: "6px",
                  marginRight: "20px",
                },
              }}
              textColor={"inherit"}
            >
              <StyledTabLabel label="Dados da Apólice" />
              <StyledTabLabel label="Documentos" />
            </StyledTab>
          </Grid>
        </StyledContainer>
        <StyledContainer>
          {value === 0 && (
            <FormDadosApolice
              formProps={formProps}
              formPropsPrecificao={formPropsPrecificao}
              handleAddPrecificacao={handleAddPrecificacao}
              seguradorasDomain={seguradorasDomain}
              handleLoadPlanos={handleLoadPlanos}
              planosDomain={planosDomain}
              handleLoadValores={handleLoadValores}
              clearValores={clearValores}
              precificacoes={precificacoes}
              buttonTitle={buttonTitle}
              handleEdit={handleEdit}
              checked={checked}
              handleDeletePrecificacao={handleDeletePrecificacao}
              setPrecificacoes={setPrecificacoes}
              lastSeguradora={lastSeguradora}
              setLastSeguradora={setLastSeguradora}
              isVida={isVida}
              setIsVida={setIsVida}
            />
          )}
          {value === 1 && (
            <FormDocumentosApolice
              isFile={isFile}
              setIsFile={setIsFile}
              isFileImplantacao={isFileImplantacao}
              setIsFileImplantacao={setIsFileImplantacao}
              file={file}
              setFile={setFile}
              fileImplantacao={fileImplantacao}
              setFileImplantacao={setFileImplantacao}
              formProps={formProps}
              isFileOD={isFileOD}
              setIsFileOD={setIsFileOD}
              fileOD={fileOD}
              setFileOD={setFileOD}
              setArrayFileOD={setArrayFileOD}
              arrayFileOD={arrayFileOD}
            />
          )}
        </StyledContainer>
        <StyledGridContainer container>
          <StyledGrid
            sx={{ display: "flex", justifyContent: "flex-end" }}
            item
            xs={6}
          >
            <OutlinedButton
              sx={{ maxWidth: "270px" }}
              onClick={handleClickVoltar}
            >
              Voltar
            </OutlinedButton>
          </StyledGrid>
          <StyledGrid item xs={6}>
            <Button
              sx={{ maxWidth: "270px" }}
              type={"submit"}
              loading={loading}
            >
              Confirmar
            </Button>
          </StyledGrid>
        </StyledGridContainer>
      </BasePage>
    </form>
  );
};

export default FormApolicePage;
