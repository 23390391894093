import { useForm } from "react-hook-form";
import validators from "../../../utils/validators";
import FormClientePage from "./FormEditarClientePage";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getCliente } from "../../../selectors/cliente.selectors";
import { getUsuarios } from "../../../selectors/dominios.selectors";
import { actions as actionsApolice } from "../../../reducers/apolice.actions";
import { actions } from "../../../reducers/cliente.actions";
import { removeNonDigitsFromString } from "../../../utils/basic";
import { toast } from "react-toastify";
import { getPayload } from "../../../selectors/routes.selectors";

const FormClienteConnected = () => {
  const { loading, data } = useSelector(getCliente);
  const usuariosDominio = useSelector(getUsuarios);
  const [isFile, setIsFile] = useState(false);
  const [isFileImplantacao, setIsFileImplantacao] = useState(false);
  const [file, setFile] = useState([]);
  const [editFile, setEditFile] = useState(false);
  const [editFileImplantacao, setEditFileImplantacao] = useState(false);
  const [fileImplantacao, setFileImplantacao] = useState([]);
  const [isFileOD, setIsFileOD] = useState(false);
  const [fileOD, setFileOD] = useState([]);
  const [arrayFileOD, setArrayFileOD] = useState([]);

  const {
    id,
    isDocumento,
    subestipulante,
    isDocumentoSubestipulante,
  } = useSelector(getPayload);

  const dispatch = useDispatch();

  const rules = {
    nomeCliente: validators.string({ required: true }),
    emailContato: validators.email({ required: true }),
    usuario: validators.string({ required: true }),
    emailsOpcionais: validators.email({ required: false }),
    nomeClienteErp: validators.string({ required: false }),
    cnpj: validators.cnpj({ required: true }),
    telefoneContato: validators.phone({ required: false }),
    telefoneOpcional: validators.phone({ required: false }),
  };

  const initialValues = {
    cnpj: "",
    nomeCliente: "",
    nomeClienteErp: "",
    emailContato: "",
    emailsOpcionais: "",
    telefoneContato: "",
    telefoneOpcional: "",
    logradouro: "",
    numeroLogradouro: "",
    complemento: "",
    bairro: "",
    nomeMunicipio: "",
    uf: "",
    flagCalculaSinistralidade: true,
    usuario: "",
    usuarioResponsavel: "",
  };

  const formProps = {
    ...useForm({ defaultValues: initialValues }),
    initialValues: {
      cnpj: "",
      nomeCliente: "",
      nomeClienteErp: "",
      emailContato: "",
      emailsOpcionais: "",
      telefoneContato: "",
      telefoneOpcional: "",
      logradouro: "",
      numeroLogradouro: "",
      complemento: "",
      bairro: "",
      nomeMunicipio: "",
      uf: "",
      flagCalculaSinistralidade: true,
      usuario: "",
      usuarioResponsavel: "",
    },
    rules,
  };

  useEffect(() => {
    if (!!data && !loading) {
      if (!data.uf) {
        data.uf = "";
      }

      if (!data.usuarioResponsavel) {
        data.usuarioResponsavel = "";
      }

      formProps.reset(data);

      if (data?.fileCC) {
        setEditFile(data.fileCC);
      } else {
        setEditFile();
      }
      if (data?.fileCI) {
        setEditFileImplantacao(data.fileCI);
      } else {
        setEditFileImplantacao();
      }
      if (data?.filesOD) {
        let files = [];
        for (let fileODInside of data.filesOD) {
          files.push(fileODInside);
        }
        setArrayFileOD(files);
      } else {
        setArrayFileOD([]);
      }
    }

    // eslint-disable-next-line
  }, [data]);

  const handleDownloadArtefato = (values) => {
    dispatch(
      actionsApolice.baixarArtefato.request({
        artefato: values.artefato,
        filename: values.filename,
      })
    );
  };

  const handleSubmit = (values) => {
    let { nomeCliente, cnpj, usuario, emailContato } = values;

    if ((!cnpj || !nomeCliente, !!emailContato, !usuario)) {
      toast.error("Favor preencher todos os campos obrigatórios.", {
        theme: "colored",
        icon: false,
        style: { backgroundColor: "#990a26" },
      });
    } else {
      const request = {
        id: id,
        nomeCliente: values.nomeCliente,
        emailContato: values.emailContato,
        cep: removeNonDigitsFromString(values.cep),
        emailsOpcionais: values.emailsOpcionais,
        uf: values.uf,
        complemento: values.complemento,
        logradouro: values.logradouro,
        clienteMatriz: values.clienteMatriz,
        usuario: values.usuario,
        usuarioResponsavel: values.usuarioResponsavel,
        bairro: values.bairro,
        nomeClienteErp: values.nomeClienteErp,
        nomeMunicipio: values.nomeMunicipio,
        cnpj: removeNonDigitsFromString(values.cnpj),
        numeroLogradouro: values.numeroLogradouro,
        telefoneContato: removeNonDigitsFromString(values.telefoneContato),
        telefoneOpcional: removeNonDigitsFromString(values.telefoneOpcional),
        situacao: "A",
        file,
        fileImplantacao,
        editFile,
        editFileImplantacao,
        arrayFileOD,
        fileOD,
      };

      dispatch(actions.editarCliente.request(request));
    }
  };

  return (
    <FormClientePage
      loading={loading || usuariosDominio.loading}
      formProps={formProps}
      isFile={isFile}
      setIsFile={setIsFile}
      isFileImplantacao={isFileImplantacao}
      setIsFileImplantacao={setIsFileImplantacao}
      file={file}
      setFile={setFile}
      fileImplantacao={fileImplantacao}
      setFileImplantacao={setFileImplantacao}
      editFile={editFile}
      editFileImplantacao={editFileImplantacao}
      setEditFile={setEditFile}
      setEditFileImplantacao={setEditFileImplantacao}
      handleDownloadArtefato={handleDownloadArtefato}
      handleSubmit={handleSubmit}
      isDocumento={isDocumento}
      subestipulante={subestipulante}
      isDocumentoSubestipulante={isDocumentoSubestipulante}
      isFileOD={isFileOD}
      setIsFileOD={setIsFileOD}
      fileOD={fileOD}
      setFileOD={setFileOD}
      arrayFileOD={arrayFileOD}
      setArrayFileOD={setArrayFileOD}
      disabled={data?.situacao !== "A"}
      data={data}
    />
  );
};

export default FormClienteConnected;
