import React from 'react';
import Switch from './Switch';
import { Controller } from 'react-hook-form';

const ControlledSwitch = ({ formProps, name, ...otherProps }) => {
  const {
    control,
    formState: { errors },
    rules,
  } = formProps;
  const isError = errors[name] !== undefined;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules[name]}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <Switch
            {...otherProps}
            value={value}
            error={isError}
            helperText={errors[name]?.message}
            onChange={(v) => {
              onChange(v.target.checked);
              !!otherProps.onChange && otherProps.onChange(v);
            }}
            onBlur={onBlur}
          />
        );
      }}
    />
  );
};

export default ControlledSwitch;
