import { createSelector } from "reselect";

export const getAtualizacaoVida = (state) => state.atualizacaoVida;

export const getBeneficiarios = createSelector(
  getAtualizacaoVida,
  (state) => state.manutencoes
);

export const getBeneficiario = createSelector(
  getAtualizacaoVida,
  (state) => state.manutencao
);

export const getDependentes = createSelector(
  getAtualizacaoVida,
  (state) => state.manutencao.dependentes
);

