import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { actions } from "../../reducers/usuarioAdm.actions";
import { getUsuarios } from "../../selectors/usuarioAdm.selectors";
import UsuarioPage from "./UsuarioPage";

const UsuarioPageConnected = () => {
  const { loading, data } = useSelector(getUsuarios);
  const [filtroValue, setFiltroValue] = useState("");
  const [filtro, setFiltro] = useState("");

  const dispatch = useDispatch();

  const handlePagination = (e, page) => {
    dispatch(
      actions.obterUsuarios.request({
        pagina: page,
        filtro,
      })
    );
  };

  const handleSearch = () => {
    setFiltro(filtroValue);
    dispatch(
      actions.obterUsuarios.request({
        pagina: 1,
        filtro: filtroValue,
      })
    );
  };

  return (
    <UsuarioPage
      data={data}
      loading={loading}
      handlePagination={handlePagination}
      handleSearch={handleSearch}
      filtro={filtroValue}
      setFiltro={setFiltroValue}
    />
  );
};

export default UsuarioPageConnected;
