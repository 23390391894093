import React from "react";
import { Pagination as MPagination, styled } from "@mui/material";

const StyledPagination = styled(MPagination)`
  
  & .MuiPaginationItem-root {
    background-color: #FFFFFF;
    border-color: #FFFFFF;
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-size: 16px;
    font-weight: 700;
    border-radius: 12px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin-left: 0px;
    margin-right: 10px;
  }
  
  & .Mui-selected {
    background-color: #FFDBCE !important;
    border-color: #FEC3AF;
    font-weight: 700;
    color: #BA1B1B;
    box-shadow: 0px 0px 0px 0px;
  }
  
`;

const Pagination = ({...otherProps}) =>
{
  return (
    <StyledPagination
      variant="outlined"
      shape="rounded"
      size="large"
      {...otherProps}
    />
  );
};

export default Pagination;



