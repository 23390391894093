import React from "react";
import { Card, styled } from "@mui/material";
import { Grid } from "@mui/material";
import {
  ControlledTextField,
  PhoneTextField,
} from "../../../components/inputs";

const StyledCard = styled(Card)`
  height: auto;
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 15px;
  background-color: #415781ff;

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const StyledGrid = styled(Grid)`
  padding: 5px;
  place-self: flex-start;
`;

const StyledGridContainer = styled(Grid)`
  margin-bottom: 0px;
`;

const StyledH2 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.white};
  text-align: left;
  letter-spacing: 1.8px;
  font-size: 28px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
`;

const CardContato = ({ formProps, disabled }) => {
  return (
    <StyledCard>
      <StyledH2>Contato</StyledH2>
      <StyledGridContainer container>
        <StyledGrid item xs={12} md={6}>
          <ControlledTextField
            formProps={formProps}
            fullWidth
            name="emailContato"
            label="E-Mail"
            disabled={disabled}
          />
        </StyledGrid>
        <StyledGrid item xs={12} md={6}>
          <ControlledTextField
            formProps={formProps}
            fullWidth
            name="emailsOpcionais"
            label="E-Mails Opcionais"
            inputProps={{ maxLength: 100 }}
            disabled={disabled}
          />
        </StyledGrid>
        <StyledGrid item xs={6}>
          <ControlledTextField
            formProps={formProps}
            fullWidth
            name="telefoneContato"
            label="Telefone Contato"
            InputProps={{
              inputComponent: PhoneTextField,
            }}
            disabled={disabled}
          />
        </StyledGrid>
        <StyledGrid item xs={6}>
          <ControlledTextField
            formProps={formProps}
            fullWidth
            name="telefoneOpcional"
            label="Telefone Opcional"
            InputProps={{
              inputComponent: PhoneTextField,
            }}
            disabled={disabled}
          />
        </StyledGrid>
      </StyledGridContainer>
    </StyledCard>
  );
};

export default CardContato;
