import React from "react";
import {
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useState } from "react";
import EditarRateioBeneficiariosDialog from "../EditarRateioBeneficiariosDialog";
import formatters from "../../../utils/formatters";

const StyledTable = styled(Table)`
  margin-top: 10px;
  margin-bottom: 20px;
  overflow-y: auto !important;
`;

const StyledTableRow = styled(TableRow)`
  padding: 0px;
  height: 35px;
  &:nth-of-type(odd) {
    background-color: #415781ff;
    max-height: 10px;
    color: #ffffff !important;
  }
  &:nth-of-type(even) {
    background-color: #a3abc0;
    max-height: 10px;
  }
`;
const StyledTableCell = styled(TableCell)`
  color: inherit;
  padding-top: 5px;
  padding-bottom: 5px;
  border: none !important;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
`;
const StyledFirstTableCell = styled(TableCell)`
  color: inherit;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  font-family: Open Sans;
  border: none !important;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
`;
const StyledButtonTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-family: Open Sans;
  border: none !important;
  justify-contend: flex-end;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
`;

const StyledTitleTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
  font-family: nunito;
  border: none !important;
  color: #ffdbce !important;
`;

const StyledTableBody = styled(TableBody)`
  overflow-y: auto !important;
`;

const StyledPaper = styled(Paper)`
  margin-top: 10px;
  overflow-y: auto;
  max-height: 400px;
  background-color: #415781ff;
  box-shadow: none;
`;

const TabelaRateio = ({ data, handleSetEdit, situacao }) => {
  const [open, setOpen] = useState(false);
  const [valor, setValor] = useState("");
  const [observacao, setObservacao] = useState("");
  const [id, setId] = useState("");

  if (data?.length === 0) {
    return <></>;
  }

  return (
    <StyledPaper>
      <StyledTable>
        <StyledTableBody>
          <StyledTableRow>
            <StyledTitleTableCell>Beneficiário</StyledTitleTableCell>
            <StyledTitleTableCell>Relação Dependência</StyledTitleTableCell>
            <StyledTitleTableCell>Dependente</StyledTitleTableCell>
            <StyledTitleTableCell>Observação</StyledTitleTableCell>
            <StyledTitleTableCell>Plano</StyledTitleTableCell>
            <StyledTitleTableCell>R$ Prêmio</StyledTitleTableCell>
            <StyledTitleTableCell></StyledTitleTableCell>
          </StyledTableRow>
          {data?.length > 0 &&
            data.map((item, index) => {
              const handleSetEditItem = () => {
                setValor(item?.valorPremio.replace(".", ","));
                setObservacao(item?.observacao);
                setId(item?.id);
                setOpen(true);
              };
              return (
                <StyledTableRow key={index}>
                  <StyledFirstTableCell>
                    {item.beneficiario}
                  </StyledFirstTableCell>
                  <StyledTableCell>{item.relacaoDependencia}</StyledTableCell>
                  <StyledTableCell>{item.dependente}</StyledTableCell>
                  <StyledTableCell>{item?.observacao}</StyledTableCell>
                  <StyledTableCell>{item.plano}</StyledTableCell>
                  <StyledTableCell>R$ {formatters.numbers.currency(item?.valorPremio)}</StyledTableCell>
                  <StyledButtonTableCell align="right">
                    <IconButton
                      disabled={situacao !== "N"}
                      onClick={() => handleSetEditItem(item)}
                    >
                      <Edit />
                    </IconButton>
                  </StyledButtonTableCell>
                </StyledTableRow>
              );
            })}
        </StyledTableBody>
      </StyledTable>
      <EditarRateioBeneficiariosDialog
        open={open}
        handleClose={() => setOpen(false)}
        observacao={observacao}
        setObservacao={setObservacao}
        valor={valor}
        id={id}
        setValor={setValor}
      />
    </StyledPaper>
  );
};

export default TabelaRateio;
