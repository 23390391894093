import React from 'react';
import { Grid, styled } from "@mui/material";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

const StyledContainer = styled(Grid)`
  height: 400px;
  .recharts-cartesian-axis-tick-value {
    font-size: 12px;
    font-family: ${({ theme }) => theme.fonts.family.subTitle};
  }
`;

const StyledGrid = styled(Grid)`
  height: 400px;
  .recharts-cartesian-axis-tick-value {
    font-size: 12px;
    font-family: ${({ theme }) => theme.fonts.family.subTitle};
  }
`;

const GraficoSinistralidade = ({dadosSinistralidade}) => {
  return (
    <StyledContainer>
      <StyledGrid item xs={12}>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            width={500}
            height={400}
            data={dadosSinistralidade}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid stroke="#ffffff" />
            <XAxis stroke="#ffffff" dataKey="dataMesReferencia" scale="band" />
            <YAxis stroke="#ffffff" yAxisId="left" />
            <YAxis stroke="#ffffff" yAxisId="right" orientation="right" />
            <Tooltip />
            <Bar dataKey="valorReceitaLiquida" yAxisId="left" barSize={20} fill="#28B463" />
            <Bar dataKey="valorSinistro" yAxisId="left" barSize={20} fill="#FF0033" />
            <Line type="monotone" yAxisId="right" dataKey="sinistralidade" stroke="#43B5F4" />
          </ComposedChart>
        </ResponsiveContainer>
      </StyledGrid>
    </StyledContainer>
  );
};
export default GraficoSinistralidade;
