import { createAsyncAction } from "../utils/actionCreators";

export const types = {
  OBTER_BENEFICIARIOS: "@@beneficiario/OBTER_BENEFICIARIOS",
  MOSTRAR_BENEFICIARIO: "@@beneficiario/MOSTRAR_BENEFICIARIO",
  EDITAR_BENEFICIARIO: "@@beneficiario/EDITAR_BENEFICIARIO",
  CRIAR_BENEFICIARIO: "@@beneficiario/CRIAR_BENEFICIARIO",
  DELETAR_BENEFICIARIO: "@@beneficiario/DELETAR_BENEFICIARIO",
  ATIVAR_BENEFICIARIO: "@@beneficiario/ATIVAR_BENEFICIARIO",
  BAIXAR_ARTEFATO: "@@beneficiario/BAIXAR_ARTEFATO",
  OBTER_DEPENDENTES: "@@beneficiario/OBTER_DEPENDENTES",
  BAIXAR_PLANILHA_BENEFICIARIOS: "@@beneficiario/BAIXAR_PLANILHA_BENEFICIARIOS",
  UPLOAD_PLANILHA_BENEFICIARIOS: "@@beneficiario/UPLOAD_PLANILHA_BENEFICIARIOS",
  BAIXAR_PLANILHA_MIGRAR_PLANO: "@@beneficiario/BAIXAR_PLANILHA_MIGRAR_PLANO",
  UPLOAD_PLANILHA_MIGRAR_PLANO: "@@beneficiario/UPLOAD_PLANILHA_MIGRAR_PLANO",
  ATUALIZAR_SITUACAO_MANUTENCAO: "@@beneficiario/ATUALIZAR_SITUACAO_MANUTENCAO",
};

export const actions = {
  obterBeneficiarios: createAsyncAction(types.OBTER_BENEFICIARIOS),
  mostrarBeneficiario: createAsyncAction(types.MOSTRAR_BENEFICIARIO),
  editarBeneficiario: createAsyncAction(types.EDITAR_BENEFICIARIO),
  criarBeneficiario: createAsyncAction(types.CRIAR_BENEFICIARIO),
  deletarBeneficiario: createAsyncAction(types.DELETAR_BENEFICIARIO),
  ativarBeneficiario: createAsyncAction(types.ATIVAR_BENEFICIARIO),
  baixarArtefato: createAsyncAction(types.BAIXAR_ARTEFATO),
  obterDependentes: createAsyncAction(types.OBTER_DEPENDENTES),
  baixarPlanilhaBeneficiarios: createAsyncAction(
    types.BAIXAR_PLANILHA_BENEFICIARIOS
  ),
  uploadPlanilhaBeneficiarios: createAsyncAction(
    types.UPLOAD_PLANILHA_BENEFICIARIOS
  ),
  baixarPlanilhaMigrarPlano: createAsyncAction(
    types.BAIXAR_PLANILHA_MIGRAR_PLANO
  ),
  uploadPlanilhaMigrarPlano: createAsyncAction(
    types.UPLOAD_PLANILHA_MIGRAR_PLANO
  ),
  atualizarSituacaoManutencao: createAsyncAction(
    types.ATUALIZAR_SITUACAO_MANUTENCAO
  ),
};
