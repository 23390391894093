import BasePage from "../../BasePage";
import React, { useEffect, useState } from "react";
import Button from "../../../components/buttons/PrimaryButton";
import OutlinedButton from "../../../components/buttons/OutlinedButton";
import { Grid, Tabs, Tab, styled } from "@mui/material";
import Spinner from "../../../components/spinner/Spinner";
import FormDocumentosRateio from "../tabs/FormDocumentosRateio";
import FormDadosRateio from "../tabs/FormDadosRateio";
import SituacaoPopover from "./SituacaoPopover";

const StyledH1 = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.blueTitle};
  letter-spacing: 4.8px;
  font-size: 45px;
  margin-bottom: -30px;
  @media (max-width: 900px) {
    font-size: 22px;
  }
`;
const StyledH2 = styled("h1")`
  font-family: Nunito;
  font-size: 28px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  margin-top: 30px;
  margin-bottom: -30px;
  color: #ac3300;
  @media (max-width: 900px) {
    font-size: 12px;
  }
`;

const StyledTab = styled(Tabs)`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  & .Mui-selected {
    color: ${({ theme }) => theme.buttons.primary.background};
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;

const StyledTabLabel = styled(Tab)`
  align-items: flex-start;
  min-width: 50%;
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: gray;
  font-weight: bold;
  text-transform: none;
  margin-bottom: -60px;
  font-size: 16px;
`;

const StyledContainer = styled(Grid)`
  margin-top: 10px;
  padding-left: 25px;
  padding-right: 20px;
  @media (max-width: 900px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;
const StyledBasePage = styled(BasePage)`
  padding: 3px;
`;

const StyledGrid = styled(Grid)`
  padding: 5px;
`;

const StyledGridContainer = styled(Grid)`
  margin-bottom: 0px;
`;

const FormEditarApolicePage = ({
  loading,
  formProps,
  buttonTitle,
  handleSubmit,
  handleClickVoltar,
  handleDownloadArtefato,
  dataRateioOutros,
  handleDeleteRateioOutros,
  handleSetEdit,
  rateio,
  loadingRateio,
  isFileBO,
  setIsFileBO,
  fileBO,
  setFileBO,
  editFileBO,
  setEditFileBO,
  isFileNF,
  setIsFileNF,
  setFileNF,
  fileNF,
  editFileNF,
  setEditFileNF,
  isFileFA,
  setIsFileFA,
  setFileFA,
  fileFA,
  editFileFA,
  setEditFileFA,
  isFileCP,
  setIsFileCP,
  setFileCP,
  fileCP,
  editFileCP,
  setEditFileCP,
  isFileOD,
  setIsFileOD,
  setFileOD,
  fileOD,
  arrayFileOD,
  setArrayFileOD,
  handleClickUploadDocumentos,
  isDocumento,
  dataRateioBeneficiarios,
  planosBeneficiarios,
  handleIncluirBeneficiario,
  situacao,
  handleAprovar,
}) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (isDocumento) {
      setValue(1);
    }
  }, [isDocumento]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (loading) {
    return (
      <StyledBasePage isLoggedIn={true} isLightBlue={true}>
        <Spinner />
      </StyledBasePage>
    );
  }

  return (
    <form onSubmit={formProps.handleSubmit(handleSubmit)}>
      <BasePage isLoggedIn={true} isLightBlue={true}>
        <StyledContainer container spacing={4}>
          <Grid item xs={10}>
            <StyledH1>Rateio</StyledH1>
            <StyledH2>{rateio.cliente}</StyledH2>
          </Grid>
          <Grid item xs={2}>
            <SituacaoPopover />
          </Grid>
          <Grid item xs={12}>
            <StyledTab
              sx={{ marginBottom: "10px" }}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              color={({ theme }) => theme.colors.primary}
              TabIndicatorProps={{
                style: {
                  background: "#F24F13",
                  color: "#F24F13",
                  minHeight: "6px",
                  marginRight: "20px",
                },
              }}
              textColor={"inherit"}
            >
              <StyledTabLabel label="Rateio" />
              <StyledTabLabel label="Faturamento/Documentos" />
            </StyledTab>
          </Grid>
        </StyledContainer>
        <StyledContainer>
          {value === 0 && (
            <>
              <FormDadosRateio
                formProps={formProps}
                dataRateioOutros={dataRateioOutros}
                handleDeleteRateioOutros={handleDeleteRateioOutros}
                handleSetEdit={handleSetEdit}
                rateio={rateio}
                loadingRateio={loadingRateio}
                buttonTitle={buttonTitle}
                dataRateioBeneficiarios={dataRateioBeneficiarios}
                planosBeneficiarios={planosBeneficiarios}
                handleIncluirBeneficiario={handleIncluirBeneficiario}
                situacao={situacao}
              />
              <StyledGridContainer container>
                <StyledGrid
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                  item
                  xs={6}
                >
                  <OutlinedButton
                    sx={{ maxWidth: "270px" }}
                    onClick={handleClickVoltar}
                  >
                    Voltar
                  </OutlinedButton>
                </StyledGrid>
                <StyledGrid item xs={6}>
                  <Button
                    disabled={situacao !== "N"}
                    sx={{ maxWidth: "270px" }}
                    loading={loading}
                    onClick={handleAprovar}
                  >
                    Aprovar
                  </Button>
                </StyledGrid>
              </StyledGridContainer>
            </>
          )}
          {value === 1 &&
            (!loadingRateio ? (
              <>
                <FormDocumentosRateio
                  formProps={formProps}
                  handleDownloadArtefato={handleDownloadArtefato}
                  handleDeleteRateioOutros={handleDeleteRateioOutros}
                  isFileBO={isFileBO}
                  setIsFileBO={setIsFileBO}
                  fileBO={fileBO}
                  setFileBO={setFileBO}
                  editFileBO={editFileBO}
                  setEditFileBO={setEditFileBO}
                  isFileNF={isFileNF}
                  setIsFileNF={setIsFileNF}
                  setFileNF={setFileNF}
                  fileNF={fileNF}
                  editFileNF={editFileNF}
                  setEditFileNF={setEditFileNF}
                  isFileFA={isFileFA}
                  setIsFileFA={setIsFileFA}
                  setFileFA={setFileFA}
                  fileFA={fileFA}
                  editFileFA={editFileFA}
                  setEditFileFA={setEditFileFA}
                  isFileCP={isFileCP}
                  setIsFileCP={setIsFileCP}
                  setFileCP={setFileCP}
                  fileCP={fileCP}
                  editFileCP={editFileCP}
                  setEditFileCP={setEditFileCP}
                  isFileOD={isFileOD}
                  setIsFileOD={setIsFileOD}
                  setFileOD={setFileOD}
                  fileOD={fileOD}
                  arrayFileOD={arrayFileOD}
                  setArrayFileOD={setArrayFileOD}
                />
                <StyledGridContainer container>
                  <StyledGrid
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                    item
                    xs={6}
                  >
                    <OutlinedButton
                      sx={{ maxWidth: "270px" }}
                      onClick={handleClickVoltar}
                    >
                      Voltar
                    </OutlinedButton>
                  </StyledGrid>
                  <StyledGrid item xs={6}>
                    <Button
                      onClick={() => {
                        handleClickUploadDocumentos();
                        setValue(0);
                      }}
                      sx={{ maxWidth: "270px" }}
                      loading={loading}
                    >
                      Confirmar
                    </Button>
                  </StyledGrid>
                </StyledGridContainer>
              </>
            ) : (
              <Spinner />
            ))}
        </StyledContainer>
      </BasePage>
    </form>
  );
};

export default FormEditarApolicePage;
