import BasePage from "../BasePage";
import React from "react";
import { styled } from "@mui/material";
import { Grid } from "@mui/material";
import AcoesCard from "./AcoesCard";
import FaturamentoCard from "./faturamento/FaturamentoCard";
import SituacaoRateioCard from "./situacaoRateio/SituacaoRateioCard";
import Spinner from "../../components/spinner/Spinner";
import SinistralidadeCard from "./sinistralidade/SinistralidadeCard";

const StyledH1 = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.blueTitle};
  font-weight: 400;
  letter-spacing: 4.8px;
  font-size: 45px;
  margin-bottom: 15px;
  @media (max-width: 900px) {
    font-size: 22px;
  }
`;

const StyledContainer = styled(Grid)`
  margin-top: 1px;
  padding-left: 10px;
  padding-right: 10px;
  @media (max-width: 900px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;
const StyledBasePage = styled(BasePage)`
  padding: 3px;
  margin-bottom: 10px;
`;

const StyledSpinnerContainer = styled("div")`
  width: 100%;
`;

const PainelPage = ({ data, loading, clientes, handleChangeFilter, sinistralidadeLoading, dadosSinistralidade }) => {
  return (
    <StyledBasePage isLoggedIn={true} isLightBlue={true}>
      <StyledContainer container spacing={2}>
        <Grid item xs={12}>
          <StyledH1>Painel</StyledH1>
        </Grid>
        {loading && (
          <StyledSpinnerContainer>
            <Spinner />
          </StyledSpinnerContainer>
        )}
        {!loading && (
          <>
            <AcoesCard data={data} />
            <SituacaoRateioCard data={data} />
            <FaturamentoCard data={data} />
            <SinistralidadeCard
              data={data}
              clientes={clientes}
              handleChangeFilter={handleChangeFilter}
              sinistralidadeLoading={sinistralidadeLoading}
              dadosSinistralidade={dadosSinistralidade}
            />
          </>
        )}
      </StyledContainer>
    </StyledBasePage>
  );
};

export default PainelPage;
