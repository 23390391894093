import basicFlow from "./asyncHandler";
import { authenticatedRequest } from "../utils/api";
import { routeWatcher } from "./rotas.saga";
import {
  actions as routeActions,
  types as routes,
} from "../reducers/rotas.actions";
import { actions as usuarioAdm } from "../reducers/usuarioAdm.actions";
import { actions as dominiosActions } from "../reducers/dominios.actions";
import { put, select } from "redux-saga/effects";
import { toast } from "react-toastify";

const ListarUsuariosApi = (values) => {
  return authenticatedRequest({
    url: `/usuario/listar`,
    isResourceService: true,
    method: "post",
    body: {
      pagina: values?.pagina ?? 1,
      limite: 10,
      filtro: values?.filtro ?? "",
    },
  });
};

const ListarUsuarios = basicFlow({
  actionGenerator: usuarioAdm.obterUsuarios,
  api: ListarUsuariosApi,
});

function* ListarUsuariosRouteWatcher() {
  yield routeWatcher(routes.USUARIO, function* () {
    yield put(usuarioAdm.obterUsuarios.request());
  });
}

const EditarUsuariosApi = (values) => {
  return authenticatedRequest({
    url: `/usuario/update`,
    isResourceService: true,
    method: "post",
    body: {
      ...values
    },
  });
};

const EditarUsuarios = basicFlow({
  actionGenerator: usuarioAdm.editarUsuario,
  api: EditarUsuariosApi,
  postSuccess: function* () {
    yield toast.success("Usuário atualizado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    yield put(routeActions.redirectTo(routes.USUARIO));
  },
});

const criarUsuariosApi = (values) => {
  return authenticatedRequest({
    url: `/usuario`,
    isResourceService: true,
    method: "post",
    body: {...values},
  });
};

const CriarUsuarios = basicFlow({
  actionGenerator: usuarioAdm.criarUsuario,
  api: criarUsuariosApi,
  postSuccess: function* () {
    yield toast.success("Usuário criado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    yield put(routeActions.redirectTo(routes.USUARIO));
  },
});

const deletarUsuariosApi = (values) => {
  return authenticatedRequest({
    url: `/usuario/update`,
    isResourceService: true,
    method: "post",
    body: {
      id: values.id,
      situacao: "I",
    },
  });
};

const DeletarUsuarios = basicFlow({
  actionGenerator: usuarioAdm.deletarUsuario,
  api: deletarUsuariosApi,
  postSuccess: function* () {
    yield toast.success("Usuário inativado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });

    yield put(usuarioAdm.obterUsuarios.request());
  },
});

const MostrarUsuarioApi = (values) => {
  return authenticatedRequest({
    url: `/usuario/show`,
    isResourceService: true,
    method: "post",
    body: {
      id: values.id,
    },
  });
};

const MostrarUsuario = basicFlow({
  actionGenerator: usuarioAdm.mostrarUsuario,
  transform: function* (payload) {
    const id = yield select((state) => state.usuarioAdm.id);
    return { id, ...payload };
  },

  api: MostrarUsuarioApi,
});

function* MostrarUsuarioRouteWatcher() {
  yield routeWatcher(routes.EDIT_USUARIOS, function* () {
    yield put(usuarioAdm.mostrarUsuario.request());
    yield put(dominiosActions.listarClientes.request());
  });
}

function* CriarUsuarioRouteWatcher() {
  yield routeWatcher(routes.CRIAR_USUARIOS, function* () {
    yield put(dominiosActions.listarClientes.request());
  });
}

export const sagas = [
  MostrarUsuarioRouteWatcher(),
  ListarUsuariosRouteWatcher(),
  CriarUsuarioRouteWatcher(),
  ListarUsuarios.watcher(),
  MostrarUsuario.watcher(),
  EditarUsuarios.watcher(),
  CriarUsuarios.watcher(),
  DeletarUsuarios.watcher(),
];
