import React, { useEffect, useState } from "react";
import { styled } from "@mui/material";
import { Grid } from "@mui/material";
import { Card } from "../../../components";
import GraficoSituacaoRateio from "./GraficoSituacaoRateio";
import ModalRateio from "./ModalRateios";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../reducers/painel.actions";
import { getRateios } from "../../../selectors/painel.selectors";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";
import { getPayload } from "../../../selectors/routes.selectors";

const StyledCard = styled(Card)`
  height: auto;
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 25px;
  background-color: #415781ff;

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const StyledH2 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.white};
  text-align: left;
  letter-spacing: 1.8px;
  font-size: 28px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
`;

const SituacaoRateioCard = ({ data }) => {
  const [openRateio, setOpenRateio] = useState(false);
  const { data: dataRateios, loading, page, count, situacao } = useSelector(
    getRateios
  );
  const payload = useSelector(getPayload);

  const dispatch = useDispatch();

  const handleOpenRateio = (value) => {
    let pagina = value.name !== situacao ? 1 : page;
    setOpenRateio(true);
    dispatch(actions.obterRateios.request({ situacao: value.name, pagina }));
  };

  const handlePagination = (e, page) => {
    dispatch(
      actions.obterRateios.request({
        situacao: situacao,
        pagina: page,
      })
    );
  };

  useEffect(() => {
    if (payload.isRateio) {
      setOpenRateio(true);
    }
    payload.isRateio = false;
    //eslint-disable-next-line
  }, [payload]);

  const handleGoToRateios = (item) => {
    dispatch(
      routeActions.redirectTo(routes.EDIT_RATEIO, {
        idRateio: item.id,
        isPainel: true,
      })
    );
  };

  return (
    <Grid item xs={12}>
      <StyledCard>
        <StyledH2>Situação de Rateio/Faturamento no mês</StyledH2>

        {data && data?.graficoSituacao?.length > 0 && (
          <GraficoSituacaoRateio
            totalSituacao={data.totalSituacao}
            data={data.graficoSituacao}
            openRateio={openRateio}
            setOpenRateio={setOpenRateio}
            handleOpenRateio={handleOpenRateio}
          />
        )}
      </StyledCard>
      <ModalRateio
        loadingRateios={loading}
        page={page}
        count={count}
        open={openRateio}
        handleClose={() => setOpenRateio(false)}
        data={dataRateios}
        loading={loading}
        handleGoToRateios={handleGoToRateios}
        handlePagination={handlePagination}
      />
    </Grid>
  );
};

export default SituacaoRateioCard;
