import React, { useCallback, useState } from "react";
import { styled } from "@mui/material";
import { Grid } from "@mui/material";
import CardDependente from "./CardDependente";
import OutlinedButton from "../../../../components/buttons/OutlinedButton";
import ConfirmarExclusaoDialog from "./ConfirmarExclusaoDialog";
import ConfirmarReativacaoDialog from "./ConfirmarReativacaoDialog";

const StyledContainer = styled(Grid)`
  margin-top: 0px;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 900px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const DependentesPage = ({
  data,
  handleNewDependente,
  handleEditDependente,
  handleDeleteDependente,
  handleAtivarDependente,
  editavel,
}) => {
  const [dadosExclusaoBeneficiario, setDadosExclusaoBeneficiario] = useState({
    open: false,
  });

  const [dadosReativacaoBeneficiario, setDadosReativacaoBeneficiario] = useState({
    open: false,
  });

  const handleCloseExclusaoBeneficiario = useCallback(() => {
    setDadosExclusaoBeneficiario({
      open: false,
    });
  }, []);

  const handleCloseReativacaoBeneficiario = useCallback(() => {
    setDadosReativacaoBeneficiario({
      open: false,
    });
  }, []);

  const handleOpenExclusaoBeneficiario = useCallback((values) => {
    setDadosExclusaoBeneficiario({
      open: true,
      cliente: values,
    });
  }, []);

  const handleOpenReativacaoBeneficiario = useCallback((values) => {
    setDadosReativacaoBeneficiario({
      open: true,
      cliente: values,
    });
  }, []);

  return (
    <StyledContainer container spacing={2}>
      {data?.length > 0 && (
        <>
          {data.map((i) => (
            <Grid item key={i.id} xs={12} md={4} style={{ marginTop: "10px" }}>
              <CardDependente
                editavel={editavel}
                beneficiario={i}
                handleEditBeneficiario={handleEditDependente}
                handleDeleteBeneficiario={handleDeleteDependente}
                handleAtivarBeneficiario={handleAtivarDependente}
                handleOpenExclusaoBeneficiario={handleOpenExclusaoBeneficiario}
                handleOpenReativacaoBeneficiario={handleOpenReativacaoBeneficiario}
              />
            </Grid>
          ))}
        </>
      )}
      {data?.length === 0 && (
        <Grid item xs={12}>
          Não existem dependentes cadastrados na base
        </Grid>
      )}
      <Grid item xs={12} style={{ marginBottom: "20px" }}>
        <Grid item xs={12} md={4}>
          <OutlinedButton onClick={handleNewDependente} disabled={!editavel}>
            + Incluir Dependente
          </OutlinedButton>
        </Grid>
      </Grid>
      <ConfirmarExclusaoDialog
        open={dadosExclusaoBeneficiario.open}
        handleClose={handleCloseExclusaoBeneficiario}
        excluirCliente={handleDeleteDependente}
        cliente={dadosExclusaoBeneficiario.cliente}
      />
      <ConfirmarReativacaoDialog
        open={dadosReativacaoBeneficiario.open}
        handleClose={handleCloseReativacaoBeneficiario}
        reativarCliente={handleAtivarDependente}
        cliente={dadosReativacaoBeneficiario.cliente}
      />
    </StyledContainer>
  );
};

export default DependentesPage;
