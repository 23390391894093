import SinistrosPage from "./SinistrosPage";
import { useSelector, useDispatch } from "react-redux";
import {
  actions as routeActions,
  types as routes,
} from "../../reducers/rotas.actions";
import { useCallback } from "react";
import { getSinistros } from "../../selectors/sinistro.selectors";
import { getPayload } from "../../selectors/routes.selectors";
import { actions } from "../../reducers/sinistro.actions";
import { useForm } from "react-hook-form";
import validators from "../../utils/validators";
import {
  getClientes,
  getSeguradoras,
} from "../../selectors/dominios.selectors";

const SinistrosPageConnected = () => {
  const dispatch = useDispatch();
  const { loading, data, nomeCliente, page, count, filtros } = useSelector(
    getSinistros
  );
  const { idCliente } = useSelector(getPayload);
  const { data: dataSeguradoras } = useSelector(getSeguradoras);
  const { data: dataClientes } = useSelector(getClientes);
  const { subestipulante } = useSelector(getPayload);

  const handleClickEdit = useCallback(
    (id, idCliente) => {
      dispatch(
        routeActions.redirectTo(routes.EDIT_SINISTRO, {
          idCliente,
          idSinistro: id,
        })
      );
    },
    //eslint-disable-next-line
    [dispatch]
  );

  const handleClickEditDocumetos = useCallback(
    (id, idCliente) => {
      dispatch(
        routeActions.redirectTo(routes.EDIT_SINISTRO, {
          idCliente,
          idSinistro: id,
          isDocumento: true,
        })
      );
    },
    //eslint-disable-next-line
    [dispatch]
  );

  const handleClickDownload = useCallback(
    (id, numeroApolice, nomeCliente) => {
      dispatch(
        actions.downloadSinistro.request({
          id,
          numeroApolice,
          nomeCliente,
        })
      );
    },
    [dispatch]
  );

  const handleClickVoltar = useCallback(
    () => {
      if (subestipulante) {
        dispatch(
          routeActions.redirectTo(routes.EDIT_CLIENTE, {
            id: subestipulante,
            isSubestipulante: true,
            tab: 1,
          })
        );
      } else {
        dispatch(routeActions.redirectTo(routes.CLIENTES));
      }
    },
    //eslint-disable-next-line
    [dispatch]
  );

  const filtrarSinistros = useCallback(
    (values) => {
      dispatch(
        actions.obterSinistros.request({
          filtro: values,
          cliente: idCliente,
          pagina: page,
        })
      );
    },
    // eslint-disable-next-line
    [dispatch, idCliente, page]
  );

  const initialValues = {
    cliente: idCliente,
    seguradora: "",
    competencia: "",
  };

  const rules = {
    cliente: validators.string({ required: false }),
    seguradora: validators.string({ required: false }),
    competencia: validators.string({ required: false }),
  };

  const formProps = {
    ...useForm({ defaultValues: initialValues }),
    rules,
  };

  const handlePagination = useCallback(
    (e, pagina) => {
      dispatch(actions.obterSinistros.request({ filtro: filtros, pagina }));
    },
    //eslint-disable-next-line
    [dispatch, filtros]
  );

  return (
    <SinistrosPage
      loading={loading}
      data={data}
      handleClickEdit={handleClickEdit}
      nomeCliente={nomeCliente}
      filtrarSinistros={filtrarSinistros}
      handleClickVoltar={handleClickVoltar}
      formProps={formProps}
      clientesDomain={dataClientes}
      seguradorasDomain={dataSeguradoras}
      page={page}
      count={count}
      handleClickEditDocumetos={handleClickEditDocumetos}
      handleClickDownload={handleClickDownload}
      handlePagination={handlePagination}
    />
  );
};

export default SinistrosPageConnected;
