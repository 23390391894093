import { select, spawn, takeEvery } from "redux-saga/effects";
import { actionToPath } from "redux-first-router";
import { getCurrentRoute } from "../selectors/routes.selectors";
import { actions, types } from "../reducers/rotas.actions";
import { routePaths } from "../routes";
import queryString from "query-string";

export function* routeWatcher(route, worker) {
  const initialRoute = yield select(getCurrentRoute);
  if (initialRoute === route) {
    yield spawn(worker);
  }
  yield takeEvery(route, worker);
}

function* openWindowWatcher() {
  yield takeEvery(types.OPEN_WINDOW, (action) => {
    const { route, params } = action.payload;
    const path = actionToPath(
      actions.redirectTo(route, params),
      routePaths,
      queryString
    );
    window.open(path);
  });
}

export const sagas = [openWindowWatcher()];
