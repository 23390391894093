import React from "react";
import {
  styled,
  FormControl,
  FormHelperText,
  InputLabel,
  Select as MSelect,
} from "@mui/material";
import InputLoader from "../loaders/InputLoader";

const StyledFormControl = styled(FormControl)`
  margin-top: 20px;

  & .MuiOutlinedInput-notchedOutline {
    border-color: #ffffff;
    
    &.Mui-focused fieldset {
      border-color: #ffffff;
    }
    
    &.Mui-error fieldset {
      border: 2px solid #f0e500ff;
    }
  }
  
  & .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline , & .MuiInputBase-root:active .MuiOutlinedInput-notchedOutline{
    border-color: #ffffff;
  }
  
  & .MuiSelect-icon {
    color: #ffffff;
  }
  & .MuiFormLabel-root {
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-size: ${({ theme }) => theme.fonts.size.inputs.title};
    color: ${({ theme }) => theme.colors.white};
    font-weight: 700;
    letter-spacing: 1.4px;
    background-color: ${({ theme }) => theme.cards.primary};
    padding: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  & .MuiFormLabel-root.Mui-focused {
    color: ${({ theme }) => theme.colors.white};
  }
  & .MuiFormLabel-root.Mui-error {
    color: ${({ theme }) => theme.colors.error};
  }
  & .MuiInputBase-root {
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-size: ${({ theme }) => theme.fonts.size.inputs.content};
    font-weight: 400;
    color: ${({ theme }) => theme.colors.white};
    border-radius: 15px;
    border-color: #ffffff;
  }
  & .MuiSelect-select:focus {
    background-color: transparent;
    border-color: #ffffff;
  }
  & .MuiFilledInput-underline:after {
    border-bottom: 2px solid ${({ theme }) => theme.colors.white};
  }
  & .Mui-error:after {
    border-bottom: 2px solid ${({ theme }) => theme.colors.error};
  }
  & .MuiFormLabel-root.Mui-error {
    color: ${({ theme }) => theme.colors.error};
  }
  & .MuiFormHelperText-root.Mui-error {
    color: ${({ theme }) => theme.colors.error};
  }
`;

const Select = ({
  id,
  name,
  variant,
  value,
  fullWidth = true,
  margin = "none",
  multiple,
  disabled,
  label,
  helperText,
  MenuProps,
  children,
  loading,
  ...otherValues
}) => (
  <StyledFormControl margin={margin} fullWidth={fullWidth} disabled={disabled}>
    {!!label && (
      <InputLabel htmlFor={name} focused={!!value} shrink={true}>
        {label}
      </InputLabel>
    )}
    <MSelect
      {...otherValues}
      margin={margin}
      variant={variant}
      multiple={multiple}
      value={value}
      MenuProps={MenuProps}
      inputProps={{
        name: name,
        id: id,
        value: value,
        disabled: disabled,
      }}
    >
      {children}
    </MSelect>
    {!!loading && <InputLoader></InputLoader>}
    {Boolean(helperText) && (
      <FormHelperText error={Boolean(otherValues.error)}>
        {helperText}
      </FormHelperText>
    )}
  </StyledFormControl>
);

export default Select;
