import React from "react";
import { Card, styled } from "@mui/material";
import { Grid } from "@mui/material";
import {
  ControlledTextField,
  CurrencyTextField,
  PercentTextField,
} from "../../../components/inputs";

const StyledCard = styled(Card)`
  height: auto;
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 25px;
  background-color: #415781ff;

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const StyledGrid = styled(Grid)`
  padding: 5px;
  place-self: flex-start;
`;

const StyledGridContainer = styled(Grid)`
  margin-bottom: 0px;
`;

const StyledH2 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.white};
  text-align: left;
  letter-spacing: 1.8px;
  font-size: 28px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
`;

const StyledDisableControlledTextField = styled(ControlledTextField)`
  .Mui-disabled {
    -webkit-text-fill-color: #ffffff !important;
    border-color: #ffffff !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: #ffffff !important;
  }
`;

const FormDadosRateio = ({
  formProps,
  dataRateioBeneficiarios,
  indice,
  indiceValor,
  breakeven,
}) => {
  const handleSetReajuste = () => {
    const sinistro = formProps.getValues("valorSinistro");
    const receitaLiquida = formProps.getValues("valorReceitaLiquida");
    const indice = formProps.getValues("indice");

    let reajuste;

    reajuste =
      (
        ((1 + Number(indice) / 100) *
          (Number(sinistro) / Number(receitaLiquida) / (breakeven / 100)) -
          1) *
        100
      ).toFixed(2) + " %";

    if (Number(sinistro) === 0 && Number(receitaLiquida) === 0) {
      reajuste = "0 %";
    }

    if (Number(receitaLiquida) === 0) {
      reajuste = "0 %";
    }

    if (reajuste === `NaN %`) {
      reajuste = "0 %";
    }

    formProps.setValue("reajuste", reajuste);
  };

  return (
    <StyledCard>
      <StyledGridContainer container>
        <StyledGrid item xs={12} md={8}>
          <StyledH2>Informações de Sinistralidade</StyledH2>
        </StyledGrid>
        <StyledGrid item xs={12} md={6}>
          <StyledDisableControlledTextField
            disabled={true}
            formProps={formProps}
            fullWidth
            name="apolice"
            label="Apólice"
          />
        </StyledGrid>
        <StyledGrid item xs={12} md={6}>
          <StyledDisableControlledTextField
            disabled={true}
            formProps={formProps}
            fullWidth
            name="dataMesReferencia"
            label="Competência"
          />
        </StyledGrid>
        <StyledGrid item xs={12} md={6}>
          <ControlledTextField
            formProps={formProps}
            fullWidth
            onChange={() => handleSetReajuste()}
            name="valorSinistro"
            label="Sinistro"
            InputProps={{
              inputComponent: CurrencyTextField,
            }}
          />
        </StyledGrid>
        <StyledGrid item xs={12} md={6}>
          <ControlledTextField
            formProps={formProps}
            onChange={() => handleSetReajuste()}
            fullWidth
            name="valorReceitaLiquida"
            label="Receita Líquida"
            InputProps={{
              inputComponent: CurrencyTextField,
            }}
          />
        </StyledGrid>
        <StyledGrid item xs={12} md={6}>
          <ControlledTextField
            formProps={formProps}
            fullWidth
            onChange={() => handleSetReajuste()}
            name="indice"
            label="% VCHM"
            InputProps={{
              inputComponent: PercentTextField,
            }}
          />
        </StyledGrid>
        {indice && (
          <StyledGrid item xs={12} md={6}>
            <StyledDisableControlledTextField
              disabled={true}
              formProps={formProps}
              fullWidth
              name="reajuste"
              label="Reajuste"
            />
          </StyledGrid>
        )}
      </StyledGridContainer>
    </StyledCard>
  );
};

export default FormDadosRateio;
