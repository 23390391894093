import { createSelector } from "reselect";

export const getManutencaoCliente = (state) => state.manutencaoCliente;

export const getManutencoes = createSelector(
  getManutencaoCliente,
  (state) => state.manutencoes
);

export const getManutencao = createSelector(
  getManutencaoCliente,
  (state) => state.manutencao
);
