import React from "react";
import NumberFormat from "react-number-format";

export default function PercentTextField({
  inputRef,
  decimalScale = 2,
  suffix = " %",
  onChange,
  ...other
}) {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.floatValue,
          },
        });
      }}
      thousandSeparator={"."}
      decimalSeparator={","}
      decimalScale={decimalScale}
      fixedDecimalScale={true}
      suffix={suffix}
    />
  );
}
