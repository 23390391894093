import { Grid, Tooltip } from "@mui/material";
import { styled } from "@mui/system";
import Card from "../../components/Card";
import { MarkEmailRead, ModeEditOutlined } from "@mui/icons-material";
import HealingIcon from "@mui/icons-material/Healing";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";

const StyledGrid = styled(Grid)`
  text-align: left;
`;

const IconsGrid = styled(Grid)`
  text-align: right;
  margin-top: 20px;
  align-items: center;
`;

const StyledCard = styled(Card)`
  height: auto;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 0px;
  background-color: #415781ff;

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const TipoChips = styled("div")`
  font-family: Nunito;
  font-size: 14px;
  font-weight: 500;
  font-spacing: 0.5px;
  background-color: #fff;
  width: 87px;
  border-radius: 25px;
  text-align: center;
  margin: auto;
  margin-top: -30px;
`;

const NomeCliente = styled("div")`
  font-family: Nunito;
  font-size: 18px;
  font-weight: 300;
  font-spacing: 0.25px;
  margin: auto;
  margin-left: 5px;

  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const NomeApolice = styled("div")`
  font-family: Nunito;
  font-size: 14px;
  font-weight: 300;
  font-spacing: 0.25px;
  margin: auto;
  margin-left: 5px;

  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const NomeCompetencia = styled("div")`
  font-family: Nunito;
  font-size: 12px;
  font-weight: 300;
  font-spacing: 0.25px;
  margin: auto;
  margin-left: 5px;

  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledAttachFileOutlinedIcon = styled(AttachFileOutlinedIcon)`
  width: 35px;
  height: 35px;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
`;

const StyledModeEdit = styled(ModeEditOutlined)`
  width: 35px;
  height: 35px;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
`;

const StyledMail = styled(MarkEmailRead)`
  width: 35px;
  height: 35px;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
`;

const StyledHealingIcon = styled(HealingIcon)`
  width: 24px;
  height: 24px;
  margin-right: 6px;
  margin-bottom: -6px;
  color: #ffffff;
`;

const CardApolice = ({
  item,
  handleClickEdit,
  handleClickDownload,
  handleOpenReativacao,
  handleClickEditDocumetos,
}) => {
  return (
    <StyledCard>
      <Grid container>
        <StyledGrid item xs={1} md={1}>
          <StyledHealingIcon />
        </StyledGrid>
        <StyledGrid item xs={11} md={11}>
          <NomeCliente>{item.cliente}</NomeCliente>
        </StyledGrid>
        <StyledGrid item xs={1} md={1} />
        <StyledGrid item xs={11} md={11}>
          <NomeApolice>Apólice #{item.apolice}</NomeApolice>
        </StyledGrid>
        <StyledGrid item xs={1} md={1} />
        <StyledGrid item xs={7} md={7}>
          <NomeCompetencia>{item.competencia}</NomeCompetencia>
        </StyledGrid>
        <StyledGrid item xs={4} md={4}>
          <TipoChips>
            {item.tipoProduto === "D"
              ? "Dental"
              : item.tipoProduto === "V"
              ? "Vida"
              : "Saúde"}
          </TipoChips>
        </StyledGrid>
      </Grid>
      <Grid container>
        <IconsGrid item xs={12} md={12}>
          {item.situacao === "E" && (
            <Tooltip title="E-mail enviado">
              <StyledMail />
            </Tooltip>
          )}

          <Tooltip title="Documentos">
            <StyledAttachFileOutlinedIcon
              sx={{ cursor: "pointer" }}
              onClick={() => handleClickEditDocumetos(item.id, item.idCliente)}
            />
          </Tooltip>
          <Tooltip title="Editar">
            <StyledModeEdit
              sx={{ cursor: "pointer" }}
              onClick={() => handleClickEdit(item.id, item.idCliente)}
            />
          </Tooltip>
        </IconsGrid>
      </Grid>
    </StyledCard>
  );
};

export default CardApolice;
