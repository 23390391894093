import React from "react";
import {
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { DeleteSharp, Edit } from "@mui/icons-material";
import formatters from "../../../../utils/formatters";

const StyledTable = styled(Table)`
  max-height: 10px !important;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const StyledTableRow = styled(TableRow)`
  padding: 0px;
  height: 35px;
  &:nth-of-type(odd) {
    background-color: #415781ff;
    max-height: 10px;
    color: #ffffff !important;
  }
  &:nth-of-type(even) {
    background-color: #a3abc0;
    max-height: 10px;
    color: #000000 !important;
  }
`;
const StyledTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  border: none !important;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  color: inherit;
`;
const StyledFirstTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  font-family: Open Sans;
  border: none !important;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  color: inherit;
`;
const StyledButtonTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-family: nunito;
  border: none !important;
  justify-contend: flex-end;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  color: inherit;
`;

const StyledTitleTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
  font-family: nunito;
  border: none !important;
  color: #ffdbce !important;
`;

const TabelaDadosSeguro = ({
  data,
  handleEdit,
  handleDeletePrecificacao,
  apolices,
  disabled,
}) => {

  if (data.length === 0 && apolices.length === 0) {
    return <></>;
  }

  return (
    <>
      <StyledTable>
        <TableBody>
          <StyledTableRow>
            <StyledTitleTableCell>Apólice</StyledTitleTableCell>
            <StyledTitleTableCell>Plano</StyledTitleTableCell>
            <StyledTitleTableCell>Carteirinha</StyledTitleTableCell>
            <StyledTitleTableCell>Prêmio</StyledTitleTableCell>
            <StyledTitleTableCell>Início Vigência</StyledTitleTableCell>
            <StyledTitleTableCell>R$ Vida</StyledTitleTableCell>
            <StyledTitleTableCell></StyledTitleTableCell>
          </StyledTableRow>
          {data.length > 0 &&
            data.map((item, index) => {
              const handleDelete = () => {
                handleDeletePrecificacao(item);
              };
              const apolice = apolices.data.filter(
                (i) => i.id === item.apolice
              )[0];

              return (
                <StyledTableRow key={index}>
                  <StyledFirstTableCell>
                    {apolice?.tipo === "S"
                      ? "SAÚDE - " + apolice?.numero
                      : apolice?.tipo === "V"
                      ? "VIDA - " + apolice?.numero
                      : "DENTAL - " + apolice?.numero}
                  </StyledFirstTableCell>
                  <StyledTableCell>
                    {
                      apolice?.ClienteApoliceValors.filter(
                        (i) => i.id === item.clienteApoliceValor
                      )[0].SeguradoraPlano.NM_PLANO
                    }
                  </StyledTableCell>
                  <StyledTableCell>{item.numero}</StyledTableCell>
                  <StyledTableCell>
                    R$ {formatters.numbers.currency(item.valorPremioSeguro)}
                  </StyledTableCell>
                  <StyledTableCell>
                    {!!item.dataVigencia ? item.dataVigencia.split("-")[2] +
                      "/" +
                      item.dataVigencia.split("-")[1] +
                      "/" +
                      item.dataVigencia.split("-")[0] : ""}
                  </StyledTableCell>
                  <StyledTableCell>
                    R$ {formatters.numbers.currency(item.valorCapitalSegurado)}
                  </StyledTableCell>
                  <StyledButtonTableCell align="right">
                    <IconButton onClick={() => handleEdit(item)} disabled={disabled}>
                      <Edit />
                    </IconButton>
                    <IconButton onClick={handleDelete} disabled={disabled}>
                      <DeleteSharp />
                    </IconButton>
                  </StyledButtonTableCell>
                </StyledTableRow>
              );
            })}
        </TableBody>
      </StyledTable>
    </>
  );
};

export default TabelaDadosSeguro;
