import React from "react";
import { Fab } from "@mui/material";
import { styled } from "@mui/material";
import { Loader } from "../loaders";
import { SearchOutlined } from "@mui/icons-material";

const StyledButton = styled(Fab)`
  background-color: ${({ theme }) => theme.buttons.fab.background};
  border-radius: 16px;
  font-family: ${({ theme }) => theme.fonts.family.buttons};
  font-size: ${({ theme }) => theme.fonts.size.buttons}
  color: ${({ theme }) => theme.buttons.primary.color};
  
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.3));

  &:focus {
    outline: 0;
  }
  &:hover {
    background-color: ${({ theme }) => theme.buttons.primary.backgroundHover};
  }
 
`;

const SearchButton = ({
                  children,
                  loading,
                  loaderType = "dots",
                  loaderColor = "white",
                  loaderSize = 24,
                  disableElevation = true,
                  ...otherProps
                }) => (
  <StyledButton color="primary" aria-label="search" {...otherProps}>
    <SearchOutlined />
    {!!loading ? (
      <Loader type={loaderType} stroke={loaderColor} size={loaderSize} />
    ) : (
      children
    )}
  </StyledButton>
);

export default SearchButton;




