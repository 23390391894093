import { styled }  from "@mui/material";

const StyledSpan = styled("span")`
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-size: ${({ theme }) => theme.fonts.size.cards.title};
  font-weight: 800;
  color: ${({ theme }) => theme.colors.primary};
`;

const CardTitle = ({ children, ...otherProps }) => {
  return <StyledSpan {...otherProps}>{children}</StyledSpan>;
};

export default CardTitle;
