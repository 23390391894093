import BasePage from "../BasePage";
import React, { useCallback, useState } from "react";
import { styled } from "@mui/material";
import { Grid } from "@mui/material";
import CardCliente from "./CardCliente";
import Spinner from "../../components/spinner/Spinner";
import OutlinedButton from "../../components/buttons/OutlinedButton";
import ConfirmarExclusaoDialog from "./ConfirmarExclusaoDialog";
import ConfirmarReativacaoDialog from "./ConfirmarReativacaoDialog";
import { Pagination, FilterChip } from "../../components";
import { ControlledPesquisa } from "../../components/inputs";
import SearchButton from "../../components/buttons/SearchButton";
import InputAdornment from "@mui/material/InputAdornment";

const StyledH1 = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.blueTitle};
  font-weight: 400;
  letter-spacing: 4.8px;
  font-size: 45px;
  margin-bottom: 15px;
  @media (max-width: 900px) {
    font-size: 22px;
  }
`;

const StyledContainer = styled(Grid)`
  margin-top: 1px;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 900px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;
const StyledBasePage = styled(BasePage)`
  padding: 3px;
  margin-bottom: 10px;
`;

const StyledItem = styled(Grid)`
  max-height: 200px;
`;

const ClientesPage = ({
  data,
  page,
  count,
  loading,
  handleNewCliente,
  handleEditCliente,
  handleDeleteCliente,
  handleAtivarCliente,
  filtrarClientes,
  handleClickApolice,
  handlePesquisa,
  formProps,
  handleClickBeneficiarios,
  handleClickRateio,
  handlePagination,
  filtro,
  setFiltro,
  handleClickFaturamentos,
  handleClickEditDocumetos,
  handleClickBeneficiario,
}) => {
  const [tipoPesquisa, setTipoPesquisa] = useState(null);

  const [dadosExclusaoCliente, setDadosExclusaoCliente] = useState({
    open: false,
  });

  const [dadosReativacaoCliente, setDadosReativacaoCliente] = useState({
    open: false,
  });

  const handleFiltro = (tipo) => {
    switch (tipo) {
      case "cliente":
        setFiltro({
          ...filtro,
          cliente: !filtro.cliente,
        });
        break;
      case "ativos":
        setFiltro({
          ...filtro,
          ativos: !filtro.ativos,
        });
        break;
      case "inativos":
        setFiltro({
          ...filtro,
          inativos: !filtro.inativos,
        });
        break;
      case "subestipulante":
        setFiltro({
          ...filtro,
          subestipulante: !filtro.subestipulante,
        });
        break;
      default:
        break;
    }
  };

  const handleCloseExclusaoCliente = useCallback(() => {
    setDadosExclusaoCliente({
      open: false,
    });
  }, []);

  const handleCloseReativacaoCliente = useCallback(() => {
    setDadosReativacaoCliente({
      open: false,
    });
  }, []);

  const handleOpenExclusaoCliente = useCallback((values) => {
    setDadosExclusaoCliente({
      open: true,
      cliente: values,
    });
  }, []);

  const handleOpenReativacaoCliente = useCallback((values) => {
    setDadosReativacaoCliente({
      open: true,
      cliente: values,
    });
  }, []);

  return (
    <StyledBasePage isLoggedIn={true} isLightBlue={true}>
      <StyledContainer container spacing={2}>
        <Grid item xs={12}>
          <StyledH1>Lista de Clientes</StyledH1>
        </Grid>
        {!loading && (
          <form
            onSubmit={formProps.handleSubmit((values) =>
              handlePesquisa(values, filtro)
            )}
            style={{ width: "100%" }}
          >
            <Grid
              container
              spacing={2}
              style={{ paddingRight: "0", paddingLeft: "16px" }}
            >
              <Grid
                item
                xs={7}
                style={{ alignItems: "center", display: "flex" }}
              >
                <FilterChip
                  active={filtro.cliente}
                  label={"CLIENTE"}
                  onClick={() => handleFiltro("cliente")}
                />
                <FilterChip
                  active={filtro.subestipulante}
                  label={"SUB-ESTIPULANTE"}
                  onClick={() => handleFiltro("subestipulante")}
                />
                <FilterChip
                  active={filtro.ativos}
                  label={"ATIVO"}
                  onClick={() => {
                    handleFiltro("ativos");
                  }}
                />
                <FilterChip
                  active={filtro.inativos}
                  label={"INATIVO"}
                  onClick={() => handleFiltro("inativos")}
                />
              </Grid>
              <Grid
                item
                xs={5}
                style={{ justifyContent: "right", display: "flex" }}
              >
                <ControlledPesquisa
                  formProps={formProps}
                  fullWidth={false}
                  name="pesquisa"
                  label="Pesquisa"
                  style={{ width: "87%", paddingRight: "20px" }}
                  placeholder={"Nome do Cliente ou CPF do Beneficiário"}
                  onChange={(e) =>
                    setTipoPesquisa(
                      !e.target.value
                        ? ""
                        : Number.isNaN(Number.parseInt(e.target.value))
                        ? "Cliente: "
                        : "CPF: "
                    )
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {tipoPesquisa}
                      </InputAdornment>
                    ),
                  }}
                />
                <SearchButton type={"submit"} />
              </Grid>
            </Grid>
          </form>
        )}
        {!!loading && (
          <StyledItem item xs={12}>
            <Spinner />
          </StyledItem>
        )}
        {!loading && (
          <>
            {data?.length > 0 && (
              <>
                {data.map((i) => (
                  <Grid
                    item
                    key={i.id}
                    xs={12}
                    md={4}
                    style={{ marginTop: "10px" }}
                  >
                    <CardCliente
                      handleClickRateio={handleClickRateio}
                      handleClickApolice={handleClickApolice}
                      cliente={i}
                      handleEditCliente={handleEditCliente}
                      handleDeleteCliente={handleDeleteCliente}
                      handleAtivarCliente={handleAtivarCliente}
                      handleOpenExclusaoCliente={handleOpenExclusaoCliente}
                      handleOpenReativacaoCliente={handleOpenReativacaoCliente}
                      handleClickBeneficiarios={handleClickBeneficiarios}
                      handleClickFaturamentos={handleClickFaturamentos}
                      handleClickEditDocumetos={handleClickEditDocumetos}
                      handleClickBeneficiario={handleClickBeneficiario}
                    />
                  </Grid>
                ))}
              </>
            )}
            {data?.length === 0 && (
              <Grid item xs={12}>
                Não existem clientes cadastrados na base
              </Grid>
            )}
            <Grid item xs={9}>
              <Pagination
                count={Math.ceil(count / 9)}
                page={page}
                onChange={handlePagination}
              />
            </Grid>
            <Grid item xs={3}>
              <OutlinedButton onClick={handleNewCliente}>
                + Incluir Cliente
              </OutlinedButton>
            </Grid>
          </>
        )}
        <ConfirmarExclusaoDialog
          open={dadosExclusaoCliente.open}
          handleClose={handleCloseExclusaoCliente}
          excluirCliente={handleDeleteCliente}
          cliente={dadosExclusaoCliente.cliente}
        />
        <ConfirmarReativacaoDialog
          open={dadosReativacaoCliente.open}
          handleClose={handleCloseReativacaoCliente}
          reativarCliente={handleAtivarCliente}
          cliente={dadosReativacaoCliente.cliente}
        />
      </StyledContainer>
    </StyledBasePage>
  );
};

export default ClientesPage;
