import { createAsyncAction } from "../utils/actionCreators";

export const types = {
  OBTER_MANUTENCOES_VIDA: "@@manutencaoCliente/OBTER_MANUTENCOES_VIDA",
  MOSTRAR_MANUTENCAO_VIDA: "@@manutencaoCliente/MOSTRAR_MANUTENCAO_VIDA",
  CRIAR_MANUTENCAO_VIDA: "@@manutencaoCliente/CRIAR_MANUTENCAO_VIDA",
  DELETAR_MANUTENCAO_VIDA: "@@manutencaoCliente/DELETAR_MANUTENCAO_VIDA",
  ALTERAR_SITUACAO: "@@manutencaoCliente/ALTERAR_SITUACAO",
};

export const actions = {
  obterManutencoesCliente: createAsyncAction(types.OBTER_MANUTENCOES_VIDA),
  mostrarManutencaoCliente: createAsyncAction(types.MOSTRAR_MANUTENCAO_VIDA),
  criarManutencaoCliente: createAsyncAction(types.CRIAR_MANUTENCAO_VIDA),
  deletarManutencaoCliente: createAsyncAction(types.DELETAR_MANUTENCAO_VIDA),
  alterarSituacao: createAsyncAction(types.ALTERAR_SITUACAO),
};
