import { useForm } from "react-hook-form";
import validators from "../../../utils/validators";
import FormApolicePage from "./FormCriarApolicePage";
import { useDispatch, useSelector } from "react-redux";
import { getCliente } from "../../../selectors/cliente.selectors";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  getPlanos,
  getSeguradoras,
} from "../../../selectors/dominios.selectors";
import { actions as asctionsDomain } from "../../../reducers/dominios.actions";
import { actions } from "../../../reducers/apolice.actions";
import { getValores } from "../../../selectors/apolice.selectors";
import { useEffect } from "react";
import { getPayload } from "../../../selectors/routes.selectors";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";

const FormCriarApolicePageConnected = () => {
  const { loading } = useSelector(getCliente);
  const { data } = useSelector(getSeguradoras);
  const { data: dataPlano } = useSelector(getPlanos);
  const { data: dataValores } = useSelector(getValores);
  const [precificacoes, setPrecificacoes] = useState([]);
  const [buttonTitle, setButtonTitle] = useState("Incluir");
  const [checked, setChecked] = useState(false);
  const cliente = useSelector(getPayload);
  const dispatch = useDispatch();
  const [isFile, setIsFile] = useState(false);
  const [isFileImplantacao, setIsFileImplantacao] = useState(false);
  const [file, setFile] = useState([]);
  const [fileImplantacao, setFileImplantacao] = useState([]);
  const [lastSeguradora, setLastSeguradora] = useState("");
  const [isVida, setIsVida] = useState(false);
  const [isFileOD, setIsFileOD] = useState(false);
  const [fileOD, setFileOD] = useState([]);
  const [arrayFileOD, setArrayFileOD] = useState([]);

  useEffect(() => {}, [lastSeguradora]);

  const rules = {
    numero: validators.string({ required: true }),
    seguradora: validators.string({ required: true }),
    tipo: validators.string({ required: true }),
    dataAniversario: validators.string({
      required: true,
      custom: {
        dataInvalida: (value) => {
          const [ano, mes, dia] = value.split("-");
          if (ano > 9999) return "Ano inválido";
          if (mes > 12) return "Mês inválido";
          if (dia > 31) return "Dia inválido";
        },
      },
    }),
    dataVencimento: validators.string({
      required: true,
      custom: {
        dataInvalida: (value) => {
          const [ano, mes, dia] = value.split("-");
          if (ano > 9999) return "Ano inválido";
          if (mes > 12) return "Mês inválido";
          if (dia > 31) return "Dia inválido";
        },
      },
    }),
    diasCorte: validators.string({
      required: true,
      custom: {
        diaInvalido: (value) => {
          if (Number(value) > 31) return "Dia inválido";
        },
      },
    }),
    valorCapitalSegurado: validators.string({
      custom: {
        obrigatorio: (value) => {
          let tipo = formProps.getValues("tipo");

          if (tipo === "V") {
            if (value === "") return "Campo obrigatório";
          }
        },
      },
    }),
    valorPremio: validators.string({
      custom: {
        obrigatorio: (value) => {
          let tipo = formProps.getValues("tipo");

          if (tipo === "V") {
            if (value === "") return "Campo obrigatório";
          }
        },
      },
    }),

    dataContratacao: validators.string({
      required: true,
      custom: {
        dataInvalida: (value) => {
          const [ano, mes, dia] = value.split("-");
          if (ano > 9999) return "Ano inválido";
          if (mes > 12) return "Mês inválido";
          if (dia > 31) return "Dia inválido";
        },
      },
    }),
    dataNegociacao: validators.string({
      required: true,
      custom: {
        dataInvalida: (value) => {
          const [ano, mes, dia] = value.split("-");
          if (ano > 9999) return "Ano inválido";
          if (mes > 12) return "Mês inválido";
          if (dia > 31) return "Dia inválido";
        },
      },
    }),
  };

  const initialValues = {
    numero: "",
    seguradora: "",
    tipo: "D",
    dataAniversario: "",
    dataVencimento: "",
    diasCorte: "",
    dataContratacao: "",
    dataNegociacao: "",
    valorCapitalSegurado: "",
    valorPremio: "",
    flagCalculaSinistralidade: false,
    breakeven: 70,
  };

  const rulesPrecificacao = {
    plano: validators.string({ required: true }),
    tipoAcomodacao: validators.string({ required: true }),
    valor00a18: validators.string({ required: true }),
    valor19a23: validators.string({ required: true }),
    valor24a28: validators.string({ required: true }),
    valor29a33: validators.string({ required: true }),
    valor34a38: validators.string({ required: true }),
    valor39a43: validators.string({ required: true }),
    valor44a48: validators.string({ required: true }),
    valor49a53: validators.string({ required: true }),
    valor54a58: validators.string({ required: true }),
    valor59a99: validators.string({ required: true }),
  };

  const initialValuesPrecificao = {
    plano: "",
    tipoCoparticipacao: false,
    tipoAcomodacao: "A",
    porcentagemCoparticipacao: "",
    tipoAdesao: "C",
    valor00a18: "",
    valor19a23: "",
    valor24a28: "",
    valor29a33: "",
    valor34a38: "",
    valor39a43: "",
    valor44a48: "",
    valor49a53: "",
    valor54a58: "",
    valor59a99: "",
  };

  const formProps = {
    ...useForm({ defaultValues: initialValues }),
    rules,
    initialValues,
  };

  const formPropsPrecificao = {
    ...useForm({ defaultValues: initialValuesPrecificao }),
    rules: rulesPrecificacao,
    initialValues: initialValuesPrecificao,
  };

  const clearValores = () => {
    formPropsPrecificao.setValue("valor00a18", "");
    formPropsPrecificao.setValue("valor19a23", "");
    formPropsPrecificao.setValue("valor24a28", "");
    formPropsPrecificao.setValue("valor29a33", "");
    formPropsPrecificao.setValue("valor34a38", "");
    formPropsPrecificao.setValue("valor39a43", "");
    formPropsPrecificao.setValue("valor44a48", "");
    formPropsPrecificao.setValue("valor49a53", "");
    formPropsPrecificao.setValue("valor54a58", "");
    formPropsPrecificao.setValue("valor59a99", "");
    setButtonTitle("Incluir");
  };

  const handleAddPrecificacao = (values) => {
    const valores = {
      valor00a18: formPropsPrecificao.getValues("valor00a18"),
      valor19a23: formPropsPrecificao.getValues("valor19a23"),
      valor24a28: formPropsPrecificao.getValues("valor24a28"),
      valor29a33: formPropsPrecificao.getValues("valor29a33"),
      valor34a38: formPropsPrecificao.getValues("valor34a38"),
      valor39a43: formPropsPrecificao.getValues("valor39a43"),
      valor44a48: formPropsPrecificao.getValues("valor44a48"),
      valor49a53: formPropsPrecificao.getValues("valor49a53"),
      valor54a58: formPropsPrecificao.getValues("valor54a58"),
      valor59a99: formPropsPrecificao.getValues("valor59a99"),
    };

    const result = {
      ...valores,
      planoId: formPropsPrecificao.getValues("plano"),
      plano: formPropsPrecificao.getValues("plano"),
      porcentagemCoparticipacao: formPropsPrecificao.getValues(
        "porcentagemCoparticipacao"
      ),
      tipoAcomodacao: formPropsPrecificao.getValues("tipoAcomodacao"),
      tipoAdesao: formPropsPrecificao.getValues("tipoAdesao"),
      tipoCoparticipacao: formPropsPrecificao.getValues("tipoCoparticipacao"),
    };

    const plano = formPropsPrecificao.getValues("plano");
    const tipoAcomodacao = formPropsPrecificao.getValues("tipoAcomodacao");

    let rule = "ok";

    Object.keys(valores).forEach(function (key) {
      if (!result[key] && rule === "ok") {
        rule = undefined;
      }
    });

    if (!plano) {
      if (dataPlano.length === 0) {
        toast.error(
          "O campo com o plano é obrigatório, favor escolher uma seguradora para carregar os planos disponíveis.",
          {
            theme: "colored",
            icon: false,
            style: { backgroundColor: "#990a26" },
          }
        );
      } else {
        toast.error("O campo com o plano é obrigatório.", {
          theme: "colored",
          icon: false,
          style: { backgroundColor: "#990a26" },
        });
      }
    } else if (!tipoAcomodacao) {
      toast.error("O campo com a acomodação é obrigatório.", {
        theme: "colored",
        icon: false,
        style: { backgroundColor: "#990a26" },
      });
    } else if (rule) {
      result.plano =
        dataPlano.find((el) => el.ID_SEGURADORA_PLANO === plano)?.NM_PLANO ??
        "";

      let existe = precificacoes.find(
        (el) =>
          el.planoId === result.planoId &&
          el.tipoAcomodacao === result.tipoAcomodacao &&
          el.tipoCoparticipacao === result.tipoCoparticipacao &&
          el.porcentagemCoparticipacao === result.porcentagemCoparticipacao &&
          el.porcentagemAdesao === result.porcentagemAdesao
      );
      if (existe && buttonTitle !== "Salvar") {
        toast.error("O plano já se encontra na lista.", {
          theme: "colored",
          icon: false,
          style: { backgroundColor: "#990a26" },
        });
      } else {
        if (buttonTitle === "Salvar") {
          let novaPrecificacao = precificacoes.filter((el) => {
            return (
              el.planoId !== result.planoId ||
              el.tipoAcomodacao !== result.tipoAcomodacao ||
              el.tipoCoparticipacao !== result.tipoCoparticipacao ||
              el.tipoAdesao !== result.tipoAdesao ||
              el.porcentagemCoparticipacao !==
                result.porcentagemCoparticipacao ||
              el.plano !== result.plano
            );
          });

          setButtonTitle("Incluir");
          setPrecificacoes([...novaPrecificacao, result]);
          clearValores();
        } else {
          setPrecificacoes([...precificacoes, result]);
          clearValores();
        }
      }
    } else {
      toast.error(
        "Todos os campos de valores por faixa etária são obrigatórios.",
        {
          theme: "colored",
          icon: false,
          style: { backgroundColor: "#990a26" },
        }
      );
    }
  };

  const handleLoadPlanos = (id) => {
    clearValores();
    dispatch(asctionsDomain.listarPlanos.request({ id: id }));
  };

  const handleLoadValores = () => {
    let plano = formPropsPrecificao.getValues("plano");
    let acomodacao = formPropsPrecificao.getValues("tipoAcomodacao");

    if (!plano) {
      if (dataPlano.length === 0) {
        toast.error(
          "O campo com o plano é obrigatório, favor escolher uma seguradora para carregar os planos disponíveis.",
          {
            theme: "colored",
            icon: false,
            style: { backgroundColor: "#990a26" },
          }
        );
      } else {
        toast.error("O campo com o plano é obrigatório.", {
          theme: "colored",
          icon: false,
          style: { backgroundColor: "#990a26" },
        });
      }
    } else if (!acomodacao) {
      toast.error("O campo com a acomodação é obrigatório.", {
        theme: "colored",
        icon: false,
        style: { backgroundColor: "#990a26" },
      });
    } else {
      let porcentagem = formPropsPrecificao.getValues(
        "porcentagemCoparticipacao"
      );
      let coparticipacao = formPropsPrecificao.getValues("tipoCoparticipacao");
      let adesao = formPropsPrecificao.getValues("tipoAdesao");

      dispatch(
        actions.obterValores.request({
          plano,
          acomodacao,
          porcentagem,
          adesao,
          coparticipacao: coparticipacao ? "C" : "S",
        })
      );
    }
  };

  useEffect(() => {
    formPropsPrecificao.setValue(
      "valor00a18",
      dataValores.valor00a18?.replace(",", "").replace(".", ",")
    );
    formPropsPrecificao.setValue(
      "valor19a23",
      dataValores.valor19a23?.replace(",", "").replace(".", ",")
    );
    formPropsPrecificao.setValue(
      "valor24a28",
      dataValores.valor24a28?.replace(",", "").replace(".", ",")
    );
    formPropsPrecificao.setValue(
      "valor29a33",
      dataValores.valor29a33?.replace(",", "").replace(".", ",")
    );
    formPropsPrecificao.setValue(
      "valor34a38",
      dataValores.valor34a38?.replace(",", "").replace(".", ",")
    );
    formPropsPrecificao.setValue(
      "valor39a43",
      dataValores.valor39a43?.replace(",", "").replace(".", ",")
    );
    formPropsPrecificao.setValue(
      "valor44a48",
      dataValores.valor44a48?.replace(",", "").replace(".", ",")
    );
    formPropsPrecificao.setValue(
      "valor49a53",
      dataValores.valor49a53?.replace(",", "").replace(".", ",")
    );
    formPropsPrecificao.setValue(
      "valor54a58",
      dataValores.valor54a58?.replace(",", "").replace(".", ",")
    );
    formPropsPrecificao.setValue(
      "valor59a99",
      dataValores.valor59a99?.replace(",", "").replace(".", ",")
    );
    //eslint-disable-next-line
  }, [dataValores]);

  const handleEdit = (values) => {
    values.tipoCoparticipacao === true ? setChecked(true) : setChecked(false);
    setButtonTitle("Salvar");
    formPropsPrecificao.setValue("plano", values.planoId);
    formPropsPrecificao.setValue("tipoAcomodacao", values.tipoAcomodacao);
    formPropsPrecificao.setValue("tipoAdesao", values.tipoAdesao);
    formPropsPrecificao.setValue(
      "tipoCoparticipacao",
      values.tipoCoparticipacao ? true : false
    );
    formPropsPrecificao.setValue(
      "porcentagemCoparticipacao",
      values.porcentagemCoparticipacao ? values.porcentagemCoparticipacao : ""
    );
    formPropsPrecificao.setValue("valor00a18", values.valor00a18);
    formPropsPrecificao.setValue("valor19a23", values.valor19a23);
    formPropsPrecificao.setValue("valor24a28", values.valor24a28);
    formPropsPrecificao.setValue("valor29a33", values.valor29a33);
    formPropsPrecificao.setValue("valor34a38", values.valor34a38);
    formPropsPrecificao.setValue("valor39a43", values.valor39a43);
    formPropsPrecificao.setValue("valor44a48", values.valor44a48);
    formPropsPrecificao.setValue("valor49a53", values.valor49a53);
    formPropsPrecificao.setValue("valor54a58", values.valor54a58);
    formPropsPrecificao.setValue("valor59a99", values.valor59a99);
  };

  const handleDeletePrecificacao = (values) => {
    setPrecificacoes(precificacoes.filter((item) => item !== values));
  };

  const handleSubmit = (values) => {
    values.cliente = cliente.idCliente;

    let {
      numero,
      seguradora,
      tipo,
      dataAniversario,
      dataVencimento,
      diasCorte,
      dataContratacao,
      dataNegociacao,
      valorPremio,
      valorCapitalSegurado,
    } = values;

    !values?.flagCalculaSinistralidade ||
    values?.flagCalculaSinistralidade === false
      ? (values.flagCalculaSinistralidade = "N")
      : (values.flagCalculaSinistralidade = "S");

    if (
      !numero ||
      !seguradora ||
      !tipo ||
      !dataAniversario ||
      !dataVencimento ||
      !diasCorte ||
      !dataContratacao ||
      !dataNegociacao ||
      (tipo === "V" && !valorPremio) ||
      (tipo === "V" && !valorCapitalSegurado)
    ) {
      toast.error("Favor preencher todos os campos obrigatórios.", {
        theme: "colored",
        icon: false,
        style: { backgroundColor: "#990a26" },
      });
    } else {
      dispatch(
        actions.criarApolice.request({
          ...values,
          precificacoes,
          file,
          fileImplantacao,
          fileOD,
        })
      );
    }
  };

  const handleClickVoltar = () => {
    dispatch(
      routeActions.redirectTo(routes.APOLICES, { idCliente: cliente.idCliente })
    );
  };

  return (
    <FormApolicePage
      loading={loading}
      formProps={formProps}
      handleAddPrecificacao={handleAddPrecificacao}
      formPropsPrecificao={formPropsPrecificao}
      seguradorasDomain={data}
      planosDomain={dataPlano}
      handleLoadPlanos={handleLoadPlanos}
      handleLoadValores={handleLoadValores}
      clearValores={clearValores}
      setPrecificacoes={setPrecificacoes}
      precificacoes={precificacoes}
      buttonTitle={buttonTitle}
      handleEdit={handleEdit}
      checked={checked}
      handleSubmit={handleSubmit}
      isFile={isFile}
      setIsFile={setIsFile}
      isFileImplantacao={isFileImplantacao}
      setIsFileImplantacao={setIsFileImplantacao}
      file={file}
      setFile={setFile}
      fileImplantacao={fileImplantacao}
      setFileImplantacao={setFileImplantacao}
      handleDeletePrecificacao={handleDeletePrecificacao}
      handleClickVoltar={handleClickVoltar}
      lastSeguradora={lastSeguradora}
      setLastSeguradora={setLastSeguradora}
      isVida={isVida}
      setIsVida={setIsVida}
      isFileOD={isFileOD}
      setIsFileOD={setIsFileOD}
      fileOD={fileOD}
      setFileOD={setFileOD}
      setArrayFileOD={setArrayFileOD}
      arrayFileOD={arrayFileOD}
    />
  );
};

export default FormCriarApolicePageConnected;
