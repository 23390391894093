import { createSelector } from "reselect";

export const getFilaState = (state) => state.fila;

export const getFilas = createSelector(getFilaState, (state) => state.filas);

export const getFila = createSelector(getFilaState, (state) => state.fila);

export const getValores = createSelector(
  getFilaState,
  (state) => state.valores
);
export const getFilaOutros = createSelector(
  getFilaState,
  (state) => state.filaOutros
);

export const getFilaBeneficiarios = createSelector(
  getFilaState,
  (state) => state.filaBeneficiarios
);

export const getFilaSituacao = createSelector(
  getFilaState,
  (state) => state.situacao
);

export const getBreakeven = createSelector(
  getFilaState,
  (state) => state.fila.breakeven
);
