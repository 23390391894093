import basicFlow from "./asyncHandler";
import { authenticatedRequest } from "../utils/api";
import { routeWatcher } from "./rotas.saga";
import { types as routes } from "../reducers/rotas.actions";
import { actions as painel } from "../reducers/painel.actions";
import { actions as dominio } from "../reducers/dominios.actions";
import { put } from "redux-saga/effects";

const listarDadosPainelApi = () => {
  return authenticatedRequest({
    url: "/painel/dados",
    method: "get",
  });
};

const listarDadosPainel = basicFlow({
  actionGenerator: painel.obterDados,
  api: listarDadosPainelApi,
});

const listarApolicesApi = (values) => {
  return authenticatedRequest({
    url: "/painel/apolices",
    method: "post",
    body: {
      pagina: values?.pagina ?? 1,
      limite: values?.limite ?? 5,
    },
  });
};

const listarApolices = basicFlow({
  actionGenerator: painel.obterApolices,
  api: listarApolicesApi,
});

const listarRateiosApi = (values) => {
  return authenticatedRequest({
    url: "/painel/rateios",
    method: "post",
    body: {
      pagina: values?.pagina ?? 1,
      limite: values?.limite ?? 5,
      situacao: values?.situacao ?? "",
    },
  });
};

const listarRateios = basicFlow({
  actionGenerator: painel.obterRateios,
  api: listarRateiosApi,
});

const graficoSinistralidadeApi = (values) => {
  return authenticatedRequest({
    url: "/painel/sinistralidade",
    method: "post",
    body: {
      apolice: values.id
    },
  })
};

const graficoSinistralidade = basicFlow({
  actionGenerator: painel.graficoSinistralidade,
  api: graficoSinistralidadeApi,
});

function* PainelRouteWatcher() {
  yield routeWatcher(routes.PAINEL, function* () {
    yield put(painel.obterDados.request());
    yield put(dominio.listarApolices.request());
  });
}

export const sagas = [
  PainelRouteWatcher(),
  listarDadosPainel.watcher(),
  listarApolices.watcher(),
  listarRateios.watcher(),
  graficoSinistralidade.watcher(),
];
