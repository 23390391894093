import { createAsyncAction } from "../utils/actionCreators";

export const types = {
  OBTER_ATUALIZACOES_VIDA: "@@atualizacaoVida/OBTER_ATUALIZACOES_VIDA",
  MOSTRAR_ATUALIZACAO_VIDA: "@@atualizacaoVida/MOSTRAR_ATUALIZACAO_VIDA",
  EDITAR_ATUALIZACAO_VIDA: "@@atualizacaoVida/EDITAR_ATUALIZACAO_VIDA",
  CRIAR_ATUALIZACAO_VIDA: "@@atualizacaoVida/CRIAR_ATUALIZACAO_VIDA",
  DELETAR_BENEFICIARIO: "@@atualizacaoVida/DELETAR_BENEFICIARIO",
  DELETAR_ATUALIZACAO_VIDA: "@@atualizacaoVida/DELETAR_ATUALIZACAO_VIDA",
  ATIVAR_ATUALIZACAO_VIDA: "@@atualizacaoVida/ATIVAR_ATUALIZACAO_VIDA",
  BAIXAR_ARTEFATO: "@@atualizacaoVida/BAIXAR_ARTEFATO",
  OBTER_DEPENDENTES: "@@atualizacaoVida/OBTER_DEPENDENTES",
};

export const actions = {
  obterAtualizacoesVida: createAsyncAction(types.OBTER_ATUALIZACOES_VIDA),
  mostrarAtualizacaoVida: createAsyncAction(types.MOSTRAR_ATUALIZACAO_VIDA),
  editarAtualizacaoVida: createAsyncAction(types.EDITAR_ATUALIZACAO_VIDA),
  criarAtualizacaoVida: createAsyncAction(types.CRIAR_ATUALIZACAO_VIDA),
  deletarAtualizacaoVida: createAsyncAction(types.DELETAR_ATUALIZACAO_VIDA),
  deletarBeneficiario: createAsyncAction(types.DELETAR_BENEFICIARIO),
  ativarAtualizacaoVida: createAsyncAction(types.ATIVAR_ATUALIZACAO_VIDA),
  baixarArtefato: createAsyncAction(types.BAIXAR_ARTEFATO),
  obterDependentes: createAsyncAction(types.OBTER_DEPENDENTES),
};
