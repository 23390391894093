import { Header } from "./pages";
import React from "react";
import { styled } from "@mui/material";
import { Container } from "@mui/material";

const StyledContainer = styled(Container)`
  width: 100%;
  min-height: 100vh;
  margin-right: auto;
  margin-left: auto;
  header + & {
    padding-top: 60px;
  }
`;
const StyledContainerLightBlue = styled(Container)`
  position: absolute;
  background-color: #F2F2F2;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
  header + & {
    padding-top: 60px;
  }
  padding-bottom: 40px;
`;

const BasePage = ({ children, isCotacao, isLoggedIn, isLightBlue }) => {
  return (
    <>
      <Header isCotacao={isCotacao} isLoggedIn={isLoggedIn} />

      {isLightBlue ? (
        <StyledContainerLightBlue>
          <StyledContainer>{children}</StyledContainer>
        </StyledContainerLightBlue>
      ) : (
        <StyledContainer>{children}</StyledContainer>
      )}
    </>
  );
};
export default BasePage;
