import React from "react";
import { Controller } from "react-hook-form";
import TextField from "./TextField";
import { styled } from "@mui/material";

const StyledTextField = styled(TextField)`

  margin-top: 0px;

  .MuiInputLabel-root {
    background-color: rgba(242, 242, 242, 1);
    color: #231B00 !important;

    padding: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
  }
  
  & .MuiFormHelperText-root.Mui-error {
    color: #f0e500ff!important;

  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #231B00;
    },
    &:hover fieldset {
      border-color: #231B00;
    },
    &.Mui-focused fieldset {
      border-color: #231B00;
    },
    &.Mui-error fieldset {
    border: 2px solid #f0e500ff;
    }
  }

  & .MuiFormLabel-root {
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-size: ${({ theme }) => theme.fonts.size.inputs.title};
    font-weight: 700;
    color: #D14A13 !important;
    letter-spacing: 1.4px;
  }
  & .MuiFormLabel-root.Mui-focused {
    color: #D14A13;
  }
  & .MuiInputBase-root {
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-size: ${({ theme }) => theme.fonts.size.inputs.content};
    font-weight: 400;
    text-align: left;
    border-radius: 15px;
    color: #231B00;
  }
  & .MuiFilledInput-underline:after {
    border-bottom: 2px solid ${({ theme }) => theme.colors.gray["800"]};
  }
  & .Mui-error:after {
    border-bottom: 2px solid ${({ theme }) => theme.colors.error};
  }
  & .MuiFormLabel-root.Mui-error {
    color: ${({ theme }) => theme.colors.error};
  }
  & .MuiFormHelperText-root.Mui-error {
    color: ${({ theme }) => theme.colors.error};
  }
  & .MuiOutlinedInput-notchedOutline {
    border-bottom-color: #231B00;
    border-left-color: #231B00;
    border-right-color: #231B00;
    border-top-color: #231B00;
  }
  & .MuiSelect-icon {
    color: ${({ theme }) => theme.colors.black};
  }
`;

const ControlledPesquisa = ({
  formProps,
  name,
  ignoreError = false,
  ...otherProps
}) => {
  const {
    control,
    formState: { errors },
    rules,
  } = formProps;
  const isError = errors[name] !== undefined && !ignoreError;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules[name]}
      render={({ field: { onChange, onBlur, value } }) => (
        <StyledTextField
          {...otherProps}
          value={value}
          error={isError}
          helperText={!ignoreError ? errors[name]?.message : undefined}
          onChange={(v) => {
            onChange(v);
            if (!!otherProps.onChange) {
              otherProps.onChange(v);
            }
          }}
          onBlur={() => {
            onBlur();
            if (!!otherProps.onBlur) {
              otherProps.onBlur();
            }
          }}
        />
      )}
    />
  );
};

export default ControlledPesquisa;
