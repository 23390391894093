import { createSelector } from "reselect";

export const getRateioState = (state) => state.rateio;

export const getRateios = createSelector(
  getRateioState,
  (state) => state.rateios
);

export const getRateio = createSelector(
  getRateioState,
  (state) => state.rateio
);

export const getValores = createSelector(
  getRateioState,
  (state) => state.valores
);
export const getRateioOutros = createSelector(
  getRateioState,
  (state) => state.rateioOutros
);

export const getRateioBeneficiarios = createSelector(
  getRateioState,
  (state) => state.rateioBeneficiarios
);

export const getRateioSituacao = createSelector(
  getRateioState,
  (state) => state.situacao
);
