import { useForm } from "react-hook-form";
import validators from "../../utils/validators";
import FormCriarUsuarioPage from "./FormCriarUsuarioPage";
import { useSelector } from "react-redux";
import { getUsuario } from "../../selectors/usuarioAdm.selectors";
import { getClientes } from "../../selectors/dominios.selectors";

const FormDeletarUsuarioPageConnected = () => {
  const { loading } = useSelector(getUsuario);
  const { data } = useSelector(getClientes);

  const rules = {
    nome: validators.string({ required: true }),
    email: validators.email({ required: true }),
    codigo: validators.string({ required: true }),
    perfil: validators.string({ required: true }),
    cliente: validators.string({ required: true }),
  };

  const initialValues = {
    nome: "",
    email: "",
    codigo: "",
    perfil: "",
    cliente: "",
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  return <FormCriarUsuarioPage loading={loading} formProps={formProps} clientes={data} />;
};

export default FormDeletarUsuarioPageConnected;
