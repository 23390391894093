import { applyMiddleware, createStore, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import loggerMiddleware from 'redux-logger'
import rootReducer from './reducers'
import rootSagas from './sagas'
import {
	enhancer as routesEnhancer,
	middleware as routesMiddleware
} from './routes'
import { loadState } from "./utils/localStorage";

const composeEnhancers =
	(typeof window !== "undefined" &&
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
	compose;

const sagaMiddleware = createSagaMiddleware();

const localState = loadState();

const store = createStore(
	rootReducer,
	localState,
	composeEnhancers(
		routesEnhancer,
		applyMiddleware(sagaMiddleware, routesMiddleware, loggerMiddleware)
	)
);

sagaMiddleware.run(rootSagas);

export default store;
