import React, { useCallback, useState } from "react";
import { styled } from "@mui/material";
import { Grid } from "@mui/material";
import CardCliente from "./CardCliente";
import OutlinedButton from "../../../../components/buttons/OutlinedButton";
import ConfirmarExclusaoDialog from "./ConfirmarExclusaoDialog";
import ConfirmarReativacaoDialog from "./ConfirmarReativacaoDialog";

const StyledContainer = styled(Grid)`
  margin-top: 0px;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 900px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const ClientesPage = ({
  data,
  handleNewCliente,
  handleEditCliente,
  handleDeleteCliente,
  handleAtivarCliente,
  handleClickApolice,
  handleClickRateio,
  handleClickFaturamentos,
  handleClickBeneficiarios,
  handleClickEditDocumetos,
}) => {
  const [dadosExclusaoCliente, setDadosExclusaoCliente] = useState({
    open: false,
  });

  const [dadosReativacaoCliente, setDadosReativacaoCliente] = useState({
    open: false,
  });

  const handleCloseExclusaoCliente = useCallback(() => {
    setDadosExclusaoCliente({
      open: false,
    });
  }, []);

  const handleCloseReativacaoCliente = useCallback(() => {
    setDadosReativacaoCliente({
      open: false,
    });
  }, []);

  const handleOpenExclusaoCliente = useCallback((values) => {
    setDadosExclusaoCliente({
      open: true,
      cliente: values,
    });
  }, []);

  const handleOpenReativacaoCliente = useCallback((values) => {
    setDadosReativacaoCliente({
      open: true,
      cliente: values,
    });
  }, []);

  return (
    <StyledContainer container spacing={2}>
      {data?.length > 0 && (
        <>
          {data.map((i) => (
            <Grid item key={i.id} xs={12} md={4} style={{ marginTop: "10px" }}>
              <CardCliente
                cliente={i}
                handleOpenExclusaoCliente={handleOpenExclusaoCliente}
                handleOpenReativacaoCliente={handleOpenReativacaoCliente}
                handleNewCliente={handleNewCliente}
                handleEditCliente={handleEditCliente}
                handleDeleteCliente={handleDeleteCliente}
                handleAtivarCliente={handleAtivarCliente}
                handleClickApolice={handleClickApolice}
                handleClickRateio={handleClickRateio}
                handleClickFaturamentos={handleClickFaturamentos}
                handleClickBeneficiarios={handleClickBeneficiarios}
                handleClickEditDocumetos={handleClickEditDocumetos}
              />
            </Grid>
          ))}
        </>
      )}
      {data?.length === 0 && (
        <Grid item xs={12}>
          Não existem Sub-Estipulantes cadastrados na base
        </Grid>
      )}
      <Grid item xs={12} style={{ marginBottom: "20px" }}>
        <Grid item xs={12} md={4}>
          <OutlinedButton onClick={handleNewCliente}>
            + Incluir Sub-Estipulante
          </OutlinedButton>
        </Grid>
      </Grid>
      <ConfirmarExclusaoDialog
        open={dadosExclusaoCliente.open}
        handleClose={handleCloseExclusaoCliente}
        excluirCliente={handleDeleteCliente}
        cliente={dadosExclusaoCliente.cliente}
      />
      <ConfirmarReativacaoDialog
        open={dadosReativacaoCliente.open}
        handleClose={handleCloseReativacaoCliente}
        reativarCliente={handleAtivarCliente}
        cliente={dadosReativacaoCliente.cliente}
      />
    </StyledContainer>
  );
};

export default ClientesPage;
