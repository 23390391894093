import React from "react";
import {
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { DeleteSharp, Edit } from "@mui/icons-material";
import { getMesAno } from "../../../utils/basic";
import { useState } from "react";
import ConfirmarExclusaoRateioOutrosDialog from "../ConfirmarExclusaoRateioOutrosDialog";

const StyledTable = styled(Table)`
  max-height: 10px !important;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const StyledTableRow = styled(TableRow)`
  padding: 0px;
  height: 35px;
  &:nth-of-type(odd) {
    background-color: #415781ff;
    max-height: 10px;
    color: #ffffff !important;
  }
  &:nth-of-type(even) {
    background-color: #a3abc0;
    max-height: 10px;
  }
`;
const StyledTableCell = styled(TableCell)`
  color: inherit;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  border: none !important;
  font-family: Droid Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
`;
const StyledFirstTableCell = styled(TableCell)`
  color: inherit;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-family: Droid Sans;
  border: none !important;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
`;
const StyledButtonTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-family: Droid Sans;
  border: none !important;
  justify-contend: flex-end;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
`;

const StyledTitleTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
  font-family: Droid Sans;
  border: none !important;
  color: #ffdbce !important;
`;

const TabelaEstorno = ({ data, handleDelete, handleSetEdit }) => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const [tipoValor, setTipoValor] = useState("");

  if (data?.length === 0) {
    return <></>;
  }

  return (
    <>
      <StyledTable>
        <TableBody>
          <StyledTableRow>
            <StyledTitleTableCell>Competência</StyledTitleTableCell>
            <StyledTitleTableCell>Valor</StyledTitleTableCell>
            <StyledTitleTableCell>Tipo</StyledTitleTableCell>
            <StyledTitleTableCell>Observação</StyledTitleTableCell>
            <StyledTitleTableCell></StyledTitleTableCell>
          </StyledTableRow>
          {data?.length > 0 &&
            data.map((item, index) => {
              const handleDeleteItem = () => {
                setId(item.id);
                setTipoValor(item.tipoValor);
                setOpen(true);
              };
              const handleSetEditItem = () => {
                handleSetEdit(item);
              };
              return (
                <StyledTableRow key={index}>
                  <StyledFirstTableCell>
                    {getMesAno(item.dataCompetencia)}
                  </StyledFirstTableCell>
                  <StyledTableCell>R$ {item.valorOutros}</StyledTableCell>
                  <StyledTableCell>
                    {item.tipoValor === "O"
                      ? "Outros"
                      : item.tipoValor === "E"
                      ? "Estorno"
                      : item.tipoValor === "M"
                      ? "Multa"
                      : "Juros"}
                  </StyledTableCell>
                  <StyledTableCell>{item.observacao}</StyledTableCell>
                  <StyledButtonTableCell align="right">
                    <IconButton onClick={() => handleSetEditItem(item)}>
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteItem(item)}>
                      <DeleteSharp />
                    </IconButton>
                  </StyledButtonTableCell>
                </StyledTableRow>
              );
            })}
        </TableBody>
      </StyledTable>
      <ConfirmarExclusaoRateioOutrosDialog
        open={open}
        handleClose={() => setOpen(false)}
        deleteFunction={handleDelete}
        title={`${
          tipoValor === "O"
            ? "o item Outros"
            : tipoValor === "E"
            ? "o item Estorno"
            : tipoValor === "M"
            ? "o item Multa"
            : "o item Juros"
        }`}
        id={id}
      />
    </>
  );
};

export default TabelaEstorno;
