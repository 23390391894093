import Header from "./Header";
import { getUsuario } from "../../selectors/usuario.selectors";
import { useSelector, useDispatch } from "react-redux";
import { actions as authActions } from "../../reducers/autenticacao.actions";
import {
  types as routes,
  actions as routeActions,
} from "../../reducers/rotas.actions";
import { useCallback } from "react";
import { actions } from "../../reducers/cliente.actions";

const HeaderConnected = ({ isCotacao, isLoggedIn }) => {
  const { nome, perfil } = useSelector(getUsuario);
  const dispatch = useDispatch();

  const handleClickMenu = useCallback(
    (route) => {
      dispatch(routeActions.redirectTo(route));
    },
    [dispatch]
  );
  const handleClickCliente = useCallback(
    (route) => {
      dispatch(
        actions.setFiltros({
          pagina: 1,
          ativos: true,
          inativos: true,
          cliente: true,
          subestipulante: true,
          pesquisa: "",
        })
      );

      dispatch(routeActions.redirectTo(route, { isNew: true }));
    },
    [dispatch]
  );

  const menus = [
    {
      id: "painel",
      label: "Painel",
      action: handleClickMenu,
      perfil: "A",
      route: routes.PAINEL,
    },
    {
      id: "atualizacaoVida",
      label: "Atualização de Vida",
      action: handleClickMenu,
      route: routes.LISTAR_ATUALIZACOES,
    },
    {
      id: "sinistro",
      label: "Sinistro",
      action: handleClickMenu,
      perfil: "A",
      route: routes.SINISTROS,
    },
    {
      id: "rateio",
      label: "Rateio",
      action: handleClickMenu,
      perfil: ["A", "C"],
      route: routes.RATEIOS,
    },
    {
      id: "relatorios",
      label: "Relatórios",
      action: handleClickMenu,
      perfil: ["A", "C"],
      route: routes.RELATORIOS,
    },
    {
      id: "fila",
      label: "Reajuste",
      action: handleClickMenu,
      perfil: ["A", "C"],
      route: routes.FILAS,
    },
    {
      id: "cadastros",
      label: "Cadastros",
      perfil: ["A", "C"],

      menus: [
        {
          id: "cliente",
          label: "Clientes",
          route: routes.CLIENTES,
          action: handleClickCliente,
        },
        {
          id: "usuario",
          label: "Usuários",
          route: routes.USUARIO,
          action: handleClickMenu,
          perfil: ["A"],
        },
      ],
    },
  ];

  const handleClickLogout = useCallback(() => {
    dispatch(authActions.logout());
  }, [dispatch]);

  const restrictedMenus = restrictMenus(menus, [], perfil);

  return (
    <Header
      isCotacao={isCotacao}
      isLoggedIn={isLoggedIn}
      menus={restrictedMenus}
      username={nome}
      handleClickLogout={handleClickLogout}
    />
  );
};

export default HeaderConnected;

const restrictMenus = (menus, current, perfil) => {
  menus.forEach((menu) => {
    if (!menu.perfil) {
      if (!menu.menus) {
        current.push(menu);
      } else {
        const restricted = {
          ...menu,
          menus: restrictMenus(menu.menus, [], perfil),
        };
        current.push(restricted);
      }
    } else {
      const canAccess = menu.perfil.indexOf(perfil) > -1;
      if (canAccess) {
        if (!menu.menus) {
          current.push(menu);
        } else {
          const restricted = {
            ...menu,
            menus: restrictMenus(menu.menus, [], perfil),
          };
          current.push(restricted);
        }
      }
    }
  });
  return current;
};
