import ApolicesPage from "./ApolicesPage";
import { useSelector, useDispatch } from "react-redux";
import {
  actions as routeActions,
  types as routes,
} from "../../reducers/rotas.actions";
import { useCallback } from "react";
import { getApolices } from "../../selectors/apolice.selectors";
import { getPayload } from "../../selectors/routes.selectors";
import { actions } from "../../reducers/apolice.actions";

const ApolicesPageConnected = () => {
  const dispatch = useDispatch();
  const { loading, data, nomeCliente } = useSelector(getApolices);
  const { idCliente, subestipulante } = useSelector(getPayload);

  const handleNewApolice = useCallback(() => {
    dispatch(routeActions.redirectTo(routes.NEW_APOLICE, { idCliente }));
    //eslint-disable-next-line
  }, [dispatch]);

  const handleClickEdit = useCallback(
    (id) => {
      dispatch(
        routeActions.redirectTo(routes.EDIT_APOLICE, {
          idCliente,
          idApolice: id,
        })
      );
    },
    //eslint-disable-next-line
    [dispatch]
  );

  const handleClickVoltar = useCallback(
    () => {
      if (subestipulante) {
        dispatch(
          routeActions.redirectTo(routes.EDIT_CLIENTE, {
            id: subestipulante,
            isSubestipulante: true,
            tab: 1,
          })
        );
      } else {
        dispatch(routeActions.redirectTo(routes.CLIENTES));
      }
    },
    //eslint-disable-next-line
    [dispatch]
  );

  const handleDelete = useCallback(
    (id) => {
      dispatch(actions.deletarApolice.request(id));
    },
    [dispatch]
  );

  const handleAtivar = useCallback(
    (id) => {
      dispatch(actions.ativarApolice.request(id));
    },
    [dispatch]
  );

  const filtrarApolices = useCallback(
    (values) => {
      dispatch(
        actions.obterApolices.request({ filtro: values, cliente: idCliente })
      );
    },
    [dispatch, idCliente]
  );

  return (
    <ApolicesPage
      loading={loading}
      data={data}
      handleNewApolice={handleNewApolice}
      handleClickEdit={handleClickEdit}
      nomeCliente={nomeCliente}
      filtrarApolices={filtrarApolices}
      handleDelete={handleDelete}
      handleAtivar={handleAtivar}
      handleClickVoltar={handleClickVoltar}
    />
  );
};

export default ApolicesPageConnected;
