import { combineReducers } from "redux";
import { reducer as routesReducer } from "../routes";
import { default as autenticacao } from "./autenticacao.reducer";
import { default as usuario } from "./usuario.reducer";
import { default as dashboard } from "./dashboard.reducer";
import { default as usuarioAdm } from "./usuarioAdm.reducer";
import { default as cliente } from "./cliente.reducer";
import { default as apolice } from "./apolice.reducer";
import { default as endereco } from "./endereco.reducer";
import { default as dominios } from "./dominios.reducer";
import { default as rateio } from "./rateio.reducer";
import { default as beneficiario } from "./beneficiario.reducer";
import { default as documentos } from "./beneficiario.reducer";
import { default as faturamento } from "./faturamento.reducer";
import { default as manutencaoCliente } from "./manutencaoCliente.reducer";
import { default as painel } from "./painel.reducer";
import { default as sinistro } from "./sinistro.reducer";
import { default as fila } from "./fila.reducer";

export default combineReducers({
  location: routesReducer,
  autenticacao,
  usuario,
  dashboard,
  usuarioAdm,
  cliente,
  apolice,
  endereco,
  dominios,
  rateio,
  beneficiario,
  documentos,
  faturamento,
  manutencaoCliente,
  painel,
  sinistro,
  fila,
});
