import BasePage from "../BasePage";
import React, { useCallback, useState } from "react";
import { styled } from "@mui/material";
import { Grid } from "@mui/material";
import CardBeneficiario from "./CardBeneficiario";
import Spinner from "../../components/spinner/Spinner";
import OutlinedButton from "../../components/buttons/OutlinedButton";
import ConfirmarExclusaoDialog from "./ConfirmarExclusaoDialog";
import ConfirmarReativacaoDialog from "./ConfirmarReativacaoDialog";
import { Pagination, FilterChip } from "../../components";
import { ControlledPesquisa } from "../../components/inputs";
import SearchButton from "../../components/buttons/SearchButton";
import IncluirDependentesDialog from "./IncluirDependentesDialog";

const StyledH1 = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.blueTitle};
  font-weight: 400;
  letter-spacing: 4.8px;
  font-size: 45px;
  margin-bottom: 15px;
  @media (max-width: 900px) {
    font-size: 22px;
  }
`;

const StyledContainer = styled(Grid)`
  margin-top: 1px;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 900px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;
const StyledBasePage = styled(BasePage)`
  padding: 3px;
  margin-bottom: 10px;
`;

const StyledItem = styled(Grid)`
  max-height: 200px;
`;

const BeneficiariosPage = ({
  data,
  page,
  count,
  loading,
  handleNewBeneficiario,
  handleEditBeneficiario,
  handleDeleteBeneficiario,
  handleAtivarBeneficiario,
  filtrarBeneficiarios,
  handlePesquisa,
  formProps,
  handleClickVoltar,
  handleCargaBeneficiarios,
  deleteFormProps,
  handlePaginacao,
  handleMigrarPlano,
  open,
  handleClose,
  handleIncluir,
}) => {
  const [dadosExclusaoBeneficiario, setDadosExclusaoBeneficiario] = useState({
    open: false,
  });

  const [
    dadosReativacaoBeneficiario,
    setDadosReativacaoBeneficiario,
  ] = useState({
    open: false,
  });

  const [filtro, setFiltro] = useState({
    ativos: true,
    desligados: true,
    pendente: true,
    saude: true,
    dental: true,
    vida: true,
  });

  const handleFiltro = (tipo) => {
    switch (tipo) {
      case "pendente":
        setFiltro({
          ...filtro,
          pendente: !filtro.pendente,
        });
        filtrarBeneficiarios({ ...filtro, pendente: !filtro.pendente });
        break;
      case "ativos":
        setFiltro({
          ...filtro,
          ativos: !filtro.ativos,
        });
        filtrarBeneficiarios({ ...filtro, ativos: !filtro.ativos });
        break;
      case "desligados":
        setFiltro({
          ...filtro,
          desligados: !filtro.desligados,
        });
        filtrarBeneficiarios({ ...filtro, desligados: !filtro.desligados });
        break;
      case "saude":
        setFiltro({
          ...filtro,
          saude: !filtro.saude,
        });
        filtrarBeneficiarios({ ...filtro, saude: !filtro.saude });
        break;
      case "dental":
        setFiltro({
          ...filtro,
          dental: !filtro.dental,
        });
        filtrarBeneficiarios({ ...filtro, dental: !filtro.dental });
        break;
      case "vida":
        setFiltro({
          ...filtro,
          vida: !filtro.vida,
        });
        filtrarBeneficiarios({ ...filtro, vida: !filtro.vida });
        break;
      default:
        break;
    }
  };

  const handleCloseExclusaoBeneficiario = useCallback(() => {
    setDadosExclusaoBeneficiario({
      open: false,
    });
  }, []);

  const handleCloseReativacaoBeneficiario = useCallback(() => {
    setDadosReativacaoBeneficiario({
      open: false,
    });
  }, []);

  const handleOpenExclusaoBeneficiario = useCallback((values) => {
    setDadosExclusaoBeneficiario({
      open: true,
      beneficiario: values,
    });
  }, []);

  const handleOpenReativacaoBeneficiario = useCallback((values) => {
    setDadosReativacaoBeneficiario({
      open: true,
      beneficiario: values,
    });
  }, []);

  return (
    <StyledBasePage isLoggedIn={true} isLightBlue={true}>
      <StyledContainer container spacing={2}>
        <Grid item xs={12}>
          <StyledH1>Lista de Beneficiários</StyledH1>
        </Grid>
        {!loading && (
          <form
            onSubmit={formProps.handleSubmit((values) =>
              handlePesquisa(values, filtro)
            )}
            style={{ width: "100%" }}
          >
            <Grid
              container
              spacing={2}
              style={{ paddingRight: "0", paddingLeft: "16px" }}
            >
              <Grid
                item
                xs={7}
                style={{ alignItems: "center", display: "flex" }}
              >
                <FilterChip
                  active={filtro.ativos}
                  label={"ATIVO"}
                  onClick={() => {
                    handleFiltro("ativos");
                  }}
                />
                <FilterChip
                  active={filtro.desligados}
                  label={"INATIVO"}
                  onClick={() => handleFiltro("desligados")}
                />
                <FilterChip
                  active={filtro.saude}
                  label={"SAÚDE"}
                  onClick={() => handleFiltro("saude")}
                />
                <FilterChip
                  active={filtro.dental}
                  label={"DENTAL"}
                  onClick={() => handleFiltro("dental")}
                />
                <FilterChip
                  active={filtro.vida}
                  label={"VIDA"}
                  onClick={() => handleFiltro("vida")}
                />
              </Grid>
              <Grid
                item
                xs={5}
                style={{ justifyContent: "right", display: "flex" }}
              >
                <ControlledPesquisa
                  formProps={formProps}
                  fullWidth={false}
                  name="pesquisa"
                  label="Pesquisa"
                  style={{ width: "87%", paddingRight: "20px" }}
                  placeholder={"Nome ou CPF do Beneficiário"}
                />
                <SearchButton type={"submit"} />
              </Grid>
            </Grid>
          </form>
        )}
        {!!loading && (
          <StyledItem item xs={12}>
            <Spinner />
          </StyledItem>
        )}
        {!loading && (
          <>
            {data?.length > 0 && (
              <>
                {data.map((i) => (
                  <Grid
                    item
                    key={i.id}
                    xs={12}
                    md={4}
                    style={{ marginTop: "10px" }}
                  >
                    <CardBeneficiario
                      beneficiario={i}
                      handleEditBeneficiario={handleEditBeneficiario}
                      handleDeleteBeneficiario={handleDeleteBeneficiario}
                      handleAtivarBeneficiario={handleAtivarBeneficiario}
                      handleOpenExclusaoBeneficiario={
                        handleOpenExclusaoBeneficiario
                      }
                      handleOpenReativacaoBeneficiario={
                        handleOpenReativacaoBeneficiario
                      }
                    />
                  </Grid>
                ))}
              </>
            )}
            {data?.length === 0 && (
              <Grid item xs={12}>
                Não existem beneficiários cadastrados para esse cliente
              </Grid>
            )}
            <StyledContainer container spacing={2}>
              <Grid item xs={12}>
                <Pagination
                  count={Math.ceil(count / 10)}
                  page={page}
                  onChange={(event, page) => handlePaginacao(filtro, page)}
                />
              </Grid>
              <Grid item xs={3}>
                <OutlinedButton onClick={handleMigrarPlano}>
                  + Migrar Plano
                </OutlinedButton>
              </Grid>
              <Grid item xs={3}>
                <OutlinedButton onClick={handleCargaBeneficiarios}>
                  + Carga de Beneficiário
                </OutlinedButton>
              </Grid>
              <Grid item xs={3}>
                <OutlinedButton onClick={handleNewBeneficiario}>
                  + Incluir Beneficiário
                </OutlinedButton>
              </Grid>
            </StyledContainer>
          </>
        )}
        <ConfirmarExclusaoDialog
          open={dadosExclusaoBeneficiario.open}
          handleClose={handleCloseExclusaoBeneficiario}
          excluir={handleDeleteBeneficiario}
          beneficiario={dadosExclusaoBeneficiario.beneficiario}
          deleteFormProps={deleteFormProps}
        />
        <ConfirmarReativacaoDialog
          open={dadosReativacaoBeneficiario.open}
          handleClose={handleCloseReativacaoBeneficiario}
          reativar={handleAtivarBeneficiario}
          beneficiario={dadosReativacaoBeneficiario.beneficiario}
        />
        <IncluirDependentesDialog
          handleClose={handleClose}
          open={open}
          handleIncluir={handleIncluir}
        />
      </StyledContainer>
      <StyledContainer
        container
        spacing={2}
        direction="row"
        justifyContent="center"
      >
        <Grid item xs={4} style={{ marginTop: "20px" }}>
          <OutlinedButton onClick={handleClickVoltar}>Voltar</OutlinedButton>
        </Grid>
      </StyledContainer>
    </StyledBasePage>
  );
};

export default BeneficiariosPage;
