import ClientesPage from "./ClientesPage";
import { getClientes, getFiltros } from "../../selectors/cliente.selectors";
import { useSelector, useDispatch } from "react-redux";
import {
  actions as routeActions,
  types as routes,
} from "../../reducers/rotas.actions";
import { actions } from "../../reducers/cliente.actions";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import validators from "../../utils/validators";

const ClientesPageConnected = () => {
  const initialValues = {
    pesquisa: "",
  };

  const rules = {
    pesquisa: validators.string({ required: false }),
  };

  const formProps = {
    ...useForm({ defaultValues: initialValues }),
    rules,
  };

  const dispatch = useDispatch();
  const { loading, data, page, count, pesquisa } = useSelector(getClientes);
  const filtros = useSelector(getFiltros);

  const handleNewCliente = useCallback(() => {
    dispatch(routeActions.redirectTo(routes.NEW_CLIENTE));
  }, [dispatch]);

  const handleEditCliente = useCallback(
    (id) => {
      dispatch(routeActions.redirectTo(routes.EDIT_CLIENTE, { id }));
    },
    [dispatch]
  );

  const handleClickApolice = (id) => {
    dispatch(routeActions.redirectTo(routes.APOLICES, { idCliente: id }));
  };

  const handleClickRateio = (id) => {
    dispatch(routeActions.redirectTo(routes.RATEIOS, { idCliente: id }));
  };

  const handleClickFaturamentos = (id) => {
    dispatch(routeActions.redirectTo(routes.FATURAMENTOS, { idCliente: id }));
  };

  const handleDeleteCliente = useCallback(
    (id) => {
      dispatch(actions.deletarCliente.request(id));
    },
    [dispatch]
  );

  const handleAtivarCliente = useCallback(
    (id) => {
      dispatch(actions.ativarCliente.request(id));
    },
    [dispatch]
  );

  const filtrarClientes = useCallback(
    (values) => {
      const pesquisa = formProps.getValues("pesquisa");
      dispatch(actions.obterClientes.request({ pesquisa, filtros, pagina: 1 }));
    },
    //eslint-disable-next-line
    [dispatch]
  );

  const handlePagination = useCallback(
    (e, page) => {
      const pesquisa = formProps.getValues("pesquisa");

      dispatch(
        actions.obterClientes.request({ pesquisa, filtros, pagina: page })
      );
    },
    //eslint-disable-next-line
    [dispatch]
  );

  useEffect(() => {
    formProps.setValue("pesquisa", pesquisa);
    //eslint-disable-next-line
  }, [pesquisa]);

  const handlePesquisa = useCallback(
    (values, filtro) => {
      dispatch(
        actions.obterClientes.request({
          pesquisa: values.pesquisa,
          filtros: filtro,
          pagina: 1,
        })
      );
    },
    [dispatch]
  );

  const handleClickBeneficiarios = (id) => {
    dispatch(routeActions.redirectTo(routes.BENEFICIARIOS, { idCliente: id }));
  };

  const handleClickBeneficiario = (idCliente, idBeneficiario) => {
    dispatch(
      routeActions.redirectTo(routes.EDIT_BENEFICIARIO, {
        idCliente: idCliente,
        idBeneficiario: idBeneficiario,
      })
    );
  };

  const handleClickEditDocumetos = useCallback(
    (id) => {
      dispatch(
        routeActions.redirectTo(routes.EDIT_CLIENTE, {
          id: id,
          tab: 2,
        })
      );
    },
    //eslint-disable-next-line
    [dispatch]
  );

  const setFiltro = (values) => {
    const pesquisa = formProps.getValues("pesquisa");
    dispatch(actions.setFiltros(values));
    dispatch(
      actions.obterClientes.request({
        pesquisa,
        filtros: values,
        pagina: 1,
      })
    );
  };

  return (
    <ClientesPage
      filter={filtros}
      loading={loading}
      data={data}
      page={page}
      count={count}
      handleNewCliente={handleNewCliente}
      handleEditCliente={handleEditCliente}
      handleDeleteCliente={handleDeleteCliente}
      handleAtivarCliente={handleAtivarCliente}
      filtrarClientes={filtrarClientes}
      handleClickApolice={handleClickApolice}
      handlePesquisa={handlePesquisa}
      formProps={formProps}
      handleClickBeneficiarios={handleClickBeneficiarios}
      handleClickRateio={handleClickRateio}
      handlePagination={handlePagination}
      filtro={filtros}
      setFiltro={setFiltro}
      handleClickFaturamentos={handleClickFaturamentos}
      handleClickEditDocumetos={handleClickEditDocumetos}
      handleClickBeneficiario={handleClickBeneficiario}
    />
  );
};

export default ClientesPageConnected;
