import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDados, getGraficoSinistralidade } from "../../selectors/painel.selectors";
import { getApolices } from "../../selectors/dominios.selectors";

import PainelPage from "./PainelPage";
import { actions } from "../../reducers/painel.actions";

const PainelPageConnected = () => {
  const { data, loading } = useSelector(getDados);
  const { data: dadosSinistralidade, loading: sinistralidadeLoading } = useSelector(getGraficoSinistralidade);
  const { data: dataClientes } = useSelector(getApolices);
  const dispatch = useDispatch();

  const handleChangeFilter = (id) => {
    dispatch(actions.graficoSinistralidade.request({ id }));
  };

  return <PainelPage
            data={data}
            loading={loading}
            clientes={dataClientes}
            handleChangeFilter={handleChangeFilter}
            dadosSinistralidade={dadosSinistralidade}
            sinistralidadeLoading={sinistralidadeLoading}
         />;

};

export default PainelPageConnected;
