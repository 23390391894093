import BasePage from "../../BasePage";
import React, { useEffect, useState } from "react";
import Button from "../../../components/buttons/PrimaryButton";
import OutlinedButton from "../../../components/buttons/OutlinedButton";
import { Grid, Tabs, Tab, styled } from "@mui/material";
import Spinner from "../../../components/spinner/Spinner";
import FormDocumentosSinistro from "../tabs/FormDocumentosSinistro";
import FormDadosSinistro from "../tabs/FormDadosSinistro";

const StyledH1 = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.blueTitle};
  letter-spacing: 4.8px;
  font-size: 45px;
  margin-bottom: -30px;
  @media (max-width: 900px) {
    font-size: 22px;
  }
`;
const StyledH2 = styled("h1")`
  font-family: Nunito;
  font-size: 28px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  margin-top: 30px;
  margin-bottom: -30px;
  color: #ac3300;
  @media (max-width: 900px) {
    font-size: 12px;
  }
`;

const StyledTab = styled(Tabs)`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  & .Mui-selected {
    color: ${({ theme }) => theme.buttons.primary.background};
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;

const StyledTabLabel = styled(Tab)`
  align-items: flex-start;
  min-width: 50%;
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: gray;
  font-weight: bold;
  text-transform: none;
  margin-bottom: -60px;
  font-size: 16px;
`;

const StyledContainer = styled(Grid)`
  margin-top: 10px;
  padding-left: 25px;
  padding-right: 20px;
  @media (max-width: 900px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;
const StyledBasePage = styled(BasePage)`
  padding: 3px;
`;

const StyledSpinner = styled(Spinner)`
  margin-top: 200px !important;
`;

const StyledGrid = styled(Grid)`
  padding: 5px;
`;

const StyledGridContainer = styled(Grid)`
  margin-bottom: 0px;
`;

const FormEditarApolicePage = ({
  loading,
  formProps,
  buttonTitle,
  handleSubmit,
  handleClickVoltar,
  handleDownloadArtefato,
  dataSinistroOutros,
  handleDeleteSinistroOutros,
  handleSetEdit,
  sinistro,
  loadingSinistro,
  isFileAS,
  setIsFileAS,
  setFileAS,
  fileAS,
  editFileAS,
  setEditFileAS,
  handleClickUploadDocumentos,
  isDocumento,
  dataSinistroBeneficiarios,
  planosBeneficiarios,
  handleIncluirBeneficiario,
  situacao,
  handleAprovar,
  indice,
  indiceValor,
  breakeven,
}) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (isDocumento) {
      setValue(1);
    }
  }, [isDocumento]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (loading) {
    return (
      <StyledBasePage isLoggedIn={true} isLightBlue={true}>
        <div style={{ height: "100vh" }}>
          <StyledSpinner />
        </div>
      </StyledBasePage>
    );
  }

  return (
    <form onSubmit={formProps.handleSubmit(handleSubmit)}>
      <BasePage isLoggedIn={true} isLightBlue={true}>
        <StyledContainer container spacing={4}>
          <Grid item xs={12}>
            <StyledH1>Sinistralidade</StyledH1>
            <StyledH2>{sinistro?.cliente}</StyledH2>
          </Grid>
          <Grid item xs={12}>
            <StyledTab
              sx={{ marginBottom: "10px" }}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              color={({ theme }) => theme.colors.primary}
              TabIndicatorProps={{
                style: {
                  background: "#F24F13",
                  color: "#F24F13",
                  minHeight: "6px",
                  marginRight: "20px",
                },
              }}
              textColor={"inherit"}
            >
              <StyledTabLabel label="Sinistralidade" />
              <StyledTabLabel label="Documentos" />
            </StyledTab>
          </Grid>
        </StyledContainer>
        <StyledContainer>
          {value === 0 && (
            <FormDadosSinistro
              formProps={formProps}
              dataSinistroOutros={dataSinistroOutros}
              handleDeleteSinistroOutros={handleDeleteSinistroOutros}
              handleSetEdit={handleSetEdit}
              sinistro={sinistro}
              loadingSinistro={loadingSinistro}
              buttonTitle={buttonTitle}
              dataSinistroBeneficiarios={dataSinistroBeneficiarios}
              planosBeneficiarios={planosBeneficiarios}
              handleIncluirBeneficiario={handleIncluirBeneficiario}
              situacao={situacao}
              indice={indice}
              indiceValor={sinistro.indice}
              breakeven={breakeven}
            />
          )}
          {value === 1 &&
            (!loadingSinistro ? (
              <FormDocumentosSinistro
                formProps={formProps}
                handleDownloadArtefato={handleDownloadArtefato}
                handleDeleteSinistroOutros={handleDeleteSinistroOutros}
                isFileAS={isFileAS}
                setIsFileAS={setIsFileAS}
                setFileAS={setFileAS}
                fileAS={fileAS}
                editFileAS={editFileAS}
                setEditFileAS={setEditFileAS}
              />
            ) : (
              <Spinner />
            ))}
          <StyledGridContainer container>
            <StyledGrid
              sx={{ display: "flex", justifyContent: "flex-end" }}
              item
              xs={6}
            >
              <OutlinedButton
                sx={{ maxWidth: "270px" }}
                onClick={handleClickVoltar}
              >
                Voltar
              </OutlinedButton>
            </StyledGrid>
            <StyledGrid item xs={6}>
              <Button
                sx={{ maxWidth: "270px" }}
                loading={loading}
                type="submit"
              >
                Confirmar
              </Button>
            </StyledGrid>
          </StyledGridContainer>
        </StyledContainer>
      </BasePage>
    </form>
  );
};

export default FormEditarApolicePage;
