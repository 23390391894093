import { actions, types } from "./apolice.actions";
import { types as routes } from "./rotas.actions";

const initialState = {
  apolices: {
    loading: false,
    data: [],
  },
  apolice: {
    loading: false,
    data: [],
  },
  valores: {
    loading: false,
    data: [],
  },
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.obterApolices.REQUEST:
    case actions.obterApolices.SUCCESS:
    case actions.obterApolices.FAILURE:
      return {
        ...state,
        apolices: {
          ...state.apolices,
          loading: action.type === actions.obterApolices.REQUEST,
          nomeCliente:
            action.type === actions.obterApolices.SUCCESS
              ? action.payload.response.data.nomeCliente
              : [],
          data:
            action.type === actions.obterApolices.SUCCESS
              ? action.payload.response.data.resultado
              : [],
        },
      };
    case actions.editarApolice.REQUEST:
    case actions.editarApolice.SUCCESS:
    case actions.editarApolice.FAILURE:
      return {
        ...state,
        apolice: {
          ...state.apolice,
          loading: action.type === actions.editarApolice.REQUEST,
          data:
            action.type === actions.editarApolice.SUCCESS
              ? {
                ...action.payload.response.data,
                flagCalculaSinistralidade:
                  action.payload.response.data?.flagCalculaSinistralidade !== "N",
              }
              : [],
        },
      };

    case routes.EDIT_USUARIOS:
      return {
        ...state,
        id: action.payload.id,
      };

    case actions.mostrarApolice.REQUEST:
    case actions.mostrarApolice.SUCCESS:
    case actions.mostrarApolice.FAILURE:
      return {
        ...state,
        apolice: {
          ...state.apolice,
          loading: action.type === actions.mostrarApolice.REQUEST,
          data:
            action.type === actions.mostrarApolice.SUCCESS
              ? {
                ...action.payload.response.data,
                flagCalculaSinistralidade:
                  action.payload.response.data?.flagCalculaSinistralidade !== "N",
              }
              : [],
        },
      };
    case actions.criarApolice.REQUEST:
    case actions.criarApolice.SUCCESS:
    case actions.criarApolice.FAILURE:
      return {
        ...state,
        apolice: {
          ...state.apolice,
          loading: action.type === actions.criarApolice.REQUEST,
          data:
            action.type === actions.criarApolice.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.deletarApolice.REQUEST:
    case actions.deletarApolice.SUCCESS:
    case actions.deletarApolice.FAILURE:
      return {
        ...state,
        apolice: {
          ...state.apolice,
          loading: action.type === actions.deletarApolice.REQUEST,
        },
      };
    case actions.obterValores.REQUEST:
    case actions.obterValores.SUCCESS:
    case actions.obterValores.FAILURE:
      return {
        ...state,
        valores: {
          ...state.valores,
          loading: action.type === actions.obterValores.REQUEST,
          data:
            action.type === actions.obterValores.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case types.LIMPAR_VALORES:
      return {
        ...state,
        valores: {
          ...state.valores,
          data: [],
        },
      };

    default:
      return state;
  }
};

export default reducer;
