import React from "react";

import OutrosDocumentos from "./OutrosDocumentos";
import OutrosDocumentosVazio from "./OutrosDocumentosVazio";

const OutrosDocumentosConnect = ({
  formProps,
  isFile,
  setIsFile,
  file,
  setFile,
  setIsFileImplantacao,
  isFileImplantacao,
  fileImplantacao,
  setFileImplantacao,
  editFileImplantacao,
  setEditFileImplantacao,
  editFile,
  setEditFile,
  handleDownloadArtefato,
  setIsFileOD,
  isFileOD,
  fileOD,
  setFileOD,
  arrayFileOD,
  setArrayFileOD,
  index,
}) => {
  return (
    <>
      {arrayFileOD?.length > 0 &&
        arrayFileOD?.map((el, index) => {
          return (
            <OutrosDocumentos
              key={index}
              handleDownloadArtefato={handleDownloadArtefato}
              setIsFileOD={setIsFileOD}
              isFileOD={isFileOD}
              fileOD={fileOD}
              setFileOD={setFileOD}
              arrayFileOD={arrayFileOD}
              setArrayFileOD={setArrayFileOD}
              index={index}
              el={el}
            />
          );
        })}
      <OutrosDocumentosVazio
        handleDownloadArtefato={handleDownloadArtefato}
        setIsFileOD={setIsFileOD}
        isFileOD={isFileOD}
        fileOD={fileOD}
        setFileOD={setFileOD}
        arrayFileOD={arrayFileOD}
        setArrayFileOD={setArrayFileOD}
      />
    </>
  );
};

export default OutrosDocumentosConnect;
