import React from "react";
import { styled } from "@mui/material";
import { Grid } from "@mui/material";
import { Card } from "../../../components";
import GraficoFaturamento from "./GraficoFaturamento";
import ShowChartIcon from "@mui/icons-material/ShowChart";

const StyledCard = styled(Card)`
  height: auto;
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 25px;
  background-color: #415781ff;

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const StyledH2 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.white};
  text-align: left;
  letter-spacing: 1.8px;
  font-size: 28px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
`;

const StyledLabelIconContainer = styled("div")`
  display: flex;
  align-items: center;
  height: 30px;
  margin-left: 30px;
`;

const StyledLabelIcon = styled("div")`
  width: 19.25px;
  height: 11px;
  background-color: ${({ color }) => color};
`;

const StyledLabelText = styled("p")`
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  margin-left: 15px;
`;

const StyledLabelContainer = styled("div")`
  display: flex;
  justify-content: center;
`;

const LabelIcon = ({ color, label }) => {
  return (
    <StyledLabelIconContainer>
      <StyledLabelIcon color={color} />
      <StyledLabelText>{label}</StyledLabelText>
    </StyledLabelIconContainer>
  );
};
const LabelIconChart = ({ color, label }) => {
  return (
    <StyledLabelIconContainer>
      <ShowChartIcon style={{ fill: color }} />
      <StyledLabelText>{label}</StyledLabelText>
    </StyledLabelIconContainer>
  );
};

const FaturamentoCard = ({ data }) => {
  return (
    <Grid item xs={12}>
      <StyledCard>
        <StyledH2>Faturamento nos últimos 6 meses</StyledH2>
        <GraficoFaturamento data={data.graficoFaturamento} />
        <StyledLabelContainer>
          <LabelIcon label={"Prêmio"} color={"#F3622C"} />
          <LabelIcon label={"Estorno"} color={"#933BEA"} />
          <LabelIconChart label={"Total"} color={"#43B5F4"} />
        </StyledLabelContainer>
      </StyledCard>
    </Grid>
  );
};

export default FaturamentoCard;
