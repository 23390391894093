import { createAsyncAction, createSyncAction } from "../utils/actionCreators";

export const types = {
  OBTER_CLIENTES: "@@cliente/OBTER_CLIENTES",
  MOSTRAR_CLIENTE: "@@cliente/MOSTRAR_CLIENTE",
  EDITAR_CLIENTE: "@@cliente/EDITAR_CLIENTE",
  CRIAR_CLIENTE: "@@cliente/CRIAR_CLIENTE",
  DELETAR_CLIENTE: "@@cliente/DELETAR_CLIENTE",
  ATIVAR_CLIENTE: "@@cliente/ATIVAR_CLIENTE",
  OBTER_SUBESTIPULANTES: "@@clientes/OBTER_SUBESTIPULANTES",
  LISTAR_APOLICES: "@@clientes/LISTAR_APOLICES",
  SET_FILTROS: "@@clientes/SET_FILTROS",
};

export const actions = {
  obterClientes: createAsyncAction(types.OBTER_CLIENTES),
  mostrarCliente: createAsyncAction(types.MOSTRAR_CLIENTE),
  editarCliente: createAsyncAction(types.EDITAR_CLIENTE),
  criarCliente: createAsyncAction(types.CRIAR_CLIENTE),
  deletarCliente: createAsyncAction(types.DELETAR_CLIENTE),
  ativarCliente: createAsyncAction(types.ATIVAR_CLIENTE),
  obterSubestipulantes: createAsyncAction(types.OBTER_SUBESTIPULANTES),
  listarApolices: createAsyncAction(types.LISTAR_APOLICES),
  setFiltros: (filtros) => createSyncAction(types.SET_FILTROS, filtros),
};
