import React from "react";
import { Card, styled } from "@mui/material";
import { Grid } from "@mui/material";
import { ControlledTextField } from "../../../components/inputs";

const StyledCard = styled(Card)`
  height: auto;
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 15px;
  background-color: #415781ff;
  margin-top: -5px;
  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const StyledGrid = styled(Grid)`
  padding: 5px;
  place-self: flex-start;
`;

const StyledGridContainer = styled(Grid)`
  margin-bottom: 0px;
`;

const StyledH2 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.white};
  text-align: left;
  letter-spacing: 1.8px;
  font-size: 28px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
`;

const StyledControlledCustomTextField = styled(ControlledTextField)`
  .MuiOutlinedInput-input {
    color-scheme: dark;
  }
`;

const CardDadosTrabalho = ({ formProps, editavel }) => {
  return (
    <StyledCard>
      <StyledH2>Dados do Trabalho</StyledH2>
      <StyledGridContainer container>
        <StyledGrid item xs={12} md={6}>
          <StyledControlledCustomTextField
            formProps={formProps}
            fullWidth
            name="dataAdmissao"
            label="Data de Admissão"
            type="date"
            disabled={!editavel}
          />
        </StyledGrid>
        <StyledGrid item xs={12} md={6}>
          <ControlledTextField
            formProps={formProps}
            fullWidth
            name="cargo"
            label="Cargo"
            inputProps={{ maxLength: 30 }}
            disabled={!editavel}
          />
        </StyledGrid>
        <StyledGrid item xs={12} md={6}>
          <ControlledTextField
            formProps={formProps}
            fullWidth
            name="pis"
            label="PIS"
            inputProps={{ maxLength: 20 }}
            disabled={!editavel}
          />
        </StyledGrid>
        <StyledGrid item xs={12} md={6}>
          <ControlledTextField
            formProps={formProps}
            fullWidth
            name="cns"
            label="CNS"
            inputProps={{ maxLength: 20 }}
            disabled={!editavel}
          />
        </StyledGrid>
        <StyledGrid item xs={12} md={6}>
          <ControlledTextField
            formProps={formProps}
            fullWidth
            name="centroCusto"
            label="Centro de Custo"
            inputProps={{
              maxLength: 10,
            }}
            disabled={!editavel}
          />
        </StyledGrid>
        <StyledGrid item xs={12} md={6}>
          <ControlledTextField
            formProps={formProps}
            fullWidth
            name="descricaoCentroCusto"
            label="Descrição de Centro de Custo"
            inputProps={{
              maxLength: 30,
            }}
            disabled={!editavel}
          />
        </StyledGrid>
      </StyledGridContainer>
    </StyledCard>
  );
};

export default CardDadosTrabalho;
