import React from "react";
import { Grid, styled } from "@mui/material";
import {
  Tooltip,
  ResponsiveContainer,
  Bar,
  XAxis,
  YAxis,
  Line,
  CartesianGrid,
  ComposedChart,
} from "recharts";

const StyledContainer = styled(Grid)`
  height: 400px;
  .recharts-cartesian-axis-tick-value {
    font-size: 12px;
    font-family: ${({ theme }) => theme.fonts.family.subTitle};
  }
`;

const StyledGrid = styled(Grid)`
  height: 400px;
  .recharts-cartesian-axis-tick-value {
    font-size: 12px;
    font-family: ${({ theme }) => theme.fonts.family.subTitle};
  }
`;

const GraficoFaturamento = ({ data }) => {
  return (
    <StyledContainer container>
      <StyledGrid item xs={12}>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            width={500}
            height={400}
            data={data}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid stroke="#ffffff" />
            <XAxis stroke="#ffffff" dataKey="label" />
            <YAxis stroke="#ffffff" />
            <Tooltip
              wrapperStyle={{ outline: "none" }}
              labelStyle={{
                justifyContent: "left",
                textAlign: "center",
                fontFamily: "nunito",
              }}
              contentStyle={{ textAlign: "left" }}
            />
            <Bar
              dataKey="Premio"
              name={`Prêmio ‎`}
              barSize={20}
              fill="#F3622C"
            />
            <Bar dataKey="Estorno" barSize={20} fill="#933BEA" />
            <Line
              name={"Total  ‎  ‎  ‎  ‎  ‎"}
              type="monotone"
              dataKey="Total"
              stroke="#43B5F4"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </StyledGrid>
    </StyledContainer>
  );
};
export default GraficoFaturamento;
