import RelatorioAuditoria from "./RelatoriosPage";
import { useForm } from "react-hook-form";
import { getClientes } from "../../selectors/dominios.selectors";
import { useSelector } from "react-redux";

const RelatoriosPageConnected = () => {
  const { data: dataClientes } = useSelector(getClientes);

  const initialValues = {
    situacao: "",
    relatorio: "",
    cliente: "",
  };

  const formProps = {
    ...useForm(),
    rules: {},
    initialValues,
  };

  return (
    <RelatorioAuditoria
      loadingFile={false}
      loading={false}
      formProps={formProps}
      clientes={dataClientes}
    />
  );
};

export default RelatoriosPageConnected;
