import { actions } from "./endereco.actions";

const initialState = {
  cep: {
    loading: false,
  },
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.consultarCepCorreios.REQUEST:
    case actions.consultarCepCorreios.SUCCESS:
    case actions.consultarCepCorreios.FAILURE:
      return {
        ...state,
        cep: {
          ...state.cep,
          loading: action.type === actions.consultarCepCorreios.REQUEST,
          error: action.type === actions.consultarCepCorreios.FAILURE,
        },
      };
    default:
      return state;
  }
};

export default reducer;
