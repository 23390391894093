import React, { useCallback } from "react";
import { Dialog, DialogContent, Grid, styled } from "@mui/material";
import { HighlightOffSharp } from "@mui/icons-material";
import Button from "../../components/buttons/PrimaryButton";
import Card from "../../components/Card";
import OutlinedButton from "../../components/buttons/OutlinedButton";
import { ControlledSelect } from "../../components/inputs";
import { situacaoManutencaoCliente } from "../../utils/parametros";
import { MenuItem } from "../../components";
import validators from "../../utils/validators";
import { useForm } from "react-hook-form";

const StyledGrid = styled(Grid)`
  text-align: left;
`;

const StyledGridContent = styled(Grid)`
  text-align: left;
  color: #ffffff;
  font-family: nunito;
  font-size: 22px;
  font-weight: 300;
`;

const IconsGrid = styled(Grid)`
  text-align: right;
  align-items: center;
`;

const CardTitle = styled("div")`
  font-family: patua one;
  font-size: 28px;
  font-weight: 400;
  font-spacing: 1.8px;
  color: #ffffff;
`;

const StyledCardActive = styled(Card)`
  height: auto;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 25px;
  background-color: #415781ff;

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const StyledCloseSharp = styled(HighlightOffSharp)`
  width: 35px;
  height: 32px;
  fill: ${({ theme }) => theme.colors.black};
  margin-left: 10px;
  cursor: pointer;
`;

const AlterarSituacaoDialog = ({
  handleClose,
  open,
  alterar,
  manutencao,
}) => {

  const initialValues = {
    situacao: manutencao?.situacao,
  };

  const rules = {
    situacao: validators.string({ required: true }),
  };

  const formProps = {
    ...useForm({ defaultValues: initialValues }),
    rules,
  };

  const alterarSituacao = useCallback((values) => {
    alterar(manutencao?.id, values);
    handleClose();
    //eslint-disable-next-line
  }, [manutencao, handleClose]);

  return (
    <Dialog
      onClose={handleClose}
      classes={{ paper: "w100" }}
      open={open}
      manutencao={manutencao}
    >
      {!!open && (
        <DialogContent>
          <form
            onSubmit={formProps.handleSubmit((values) =>
              alterarSituacao(values)
            )}
            style={{ width: "100%" }}
          >
            <Grid container spacing={4}>
              <IconsGrid item xs={12} md={12}>
                <StyledCloseSharp onClick={handleClose} />
              </IconsGrid>
              <Grid item xs={12}>
                <StyledCardActive key={manutencao.id}>
                  <Grid container spacing={4}>
                    <StyledGrid item xs={12} md={12}>
                      <CardTitle>Alterar Situação</CardTitle>
                    </StyledGrid>
                  </Grid>
                  <Grid container spacing={4}>
                    <StyledGridContent item xs={12} md={12}>
                      <ControlledSelect
                        name={"situacao"}
                        formProps={formProps}
                        label={"Situação"}
                        margin={"none"}
                      >
                        {situacaoManutencaoCliente.map((item) => (
                          <MenuItem
                            className={"select-submenu-item"}
                            key={item?.id}
                            value={item?.id}
                          >
                            {item.nome}
                          </MenuItem>
                        ))}
                      </ControlledSelect>
                    </StyledGridContent>
                  </Grid>
                </StyledCardActive>
              </Grid>
              <StyledGrid item xs={6}>
                <OutlinedButton onClick={handleClose}>Voltar</OutlinedButton>
              </StyledGrid>
              <StyledGrid item xs={6}>
                <Button type={"submit"}>Confirmar</Button>
              </StyledGrid>
            </Grid>
          </form>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default AlterarSituacaoDialog;
