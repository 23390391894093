import { Grid, Tooltip } from "@mui/material";
import { styled } from "@mui/system";
import Card from "../../components/Card";
import {
  Done,
  EditOffOutlined,
  MarkEmailRead,
  ModeEditOutlined,
} from "@mui/icons-material";

const StyledGrid = styled(Grid)`
  text-align: left;
`;

const IconsGrid = styled(Grid)`
  text-align: right;
  align-items: center;
`;

const StyledCard = styled(Card)`
  height: auto;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 0px;
  background-color: #415781ff;

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const TipoChips = styled("div")`
  font-family: Nunito;
  font-size: 14px;
  font-weight: 500;
  font-spacing: 0.5px;
  background-color: #fff;
  width: 87px;
  border-radius: 25px;
  text-align: center;
  margin-top: -20px;
`;

const NomeCliente = styled("div")`
  font-family: Nunito;
  font-size: 18px;
  font-weight: 300;
  font-spacing: 0.25px;
  margin: auto;
  margin-left: 5px;

  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const NomeApolice = styled("div")`
  font-family: Nunito;
  font-size: 14px;
  font-weight: 300;
  font-spacing: 0.25px;
  margin: auto;
  margin-left: 5px;

  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const NomeCompetencia = styled("div")`
  font-family: Nunito;
  font-size: 12px;
  font-weight: 300;
  font-spacing: 0.25px;
  margin: auto;
  margin-left: 5px;

  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledModeEdit = styled(ModeEditOutlined)`
  width: 35px;
  height: 35px;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
`;

const StyledMail = styled(MarkEmailRead)`
  width: 35px;
  height: 35px;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
`;

const CardTitle = styled("div")`
  font-family: nunito;
  font-size: 16px;
  font-weight: 700;
  font-spacing: 0.5px;
  color: #ffb59b;
  margin-left: 5px;
`;
const StyledEditOffOutlined = styled(EditOffOutlined)`
  width: 35px;
  height: 32px;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
  cursor: pointer;
`;
const StyledDone = styled(Done)`
  width: 35px;
  height: 35px;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
`;

const CardFila = ({ item, handleClickEdit }) => {
  return (
    <StyledCard>
      <Grid container>
        <StyledGrid item xs={12} md={12}>
          <CardTitle>Cliente</CardTitle>
        </StyledGrid>
        <StyledGrid item xs={12} md={12}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div>
              <TipoChips>
                {item.ClienteApolice.tipo === "D"
                  ? "Dental"
                  : item.ClienteApolice.tipo === "V"
                  ? "Vida"
                  : "Saúde"}
              </TipoChips>
            </div>
          </div>
        </StyledGrid>
        <StyledGrid item xs={11} md={11}>
          <NomeCliente>
            {item.ClienteApolice.Cliente.nomeClienteErp}
          </NomeCliente>
        </StyledGrid>
        <StyledGrid item xs={1} md={1} />
        <StyledGrid item xs={11} md={11}>
          <NomeApolice>Apólice #{item.ClienteApolice.numero}</NomeApolice>
        </StyledGrid>
        <StyledGrid item xs={1} md={1} />
        <StyledGrid item xs={7} md={7}>
          <NomeCompetencia>{item.competencia}</NomeCompetencia>
        </StyledGrid>
      </Grid>
      <Grid container>
        <IconsGrid item xs={12} md={12}>
          {item.situacao === "E" && (
            <>
              <Tooltip title="E-mail enviado">
                <StyledMail />
              </Tooltip>
              <Tooltip title="Consultar">
                <StyledEditOffOutlined
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleClickEdit(item.id)}
                />
              </Tooltip>
            </>
          )}

          {item.situacao === "N" && (
            <Tooltip title="Editar">
              <StyledModeEdit
                sx={{ cursor: "pointer" }}
                onClick={() => handleClickEdit(item.id, item.idCliente)}
              />
            </Tooltip>
          )}
          {item.situacao === "A" && (
            <>
              <Tooltip title="Aprovado">
                <StyledDone />
              </Tooltip>
              <Tooltip title="Editar">
                <StyledModeEdit
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleClickEdit(item.id, item.idCliente)}
                />
              </Tooltip>
            </>
          )}
        </IconsGrid>
      </Grid>
    </StyledCard>
  );
};

export default CardFila;
