import React from "react";
import { Card, styled } from "@mui/material";
import { Grid } from "@mui/material";
import {
  ControlledTextField,
  PercentTextField,
} from "../../../components/inputs";

const StyledCard = styled(Card)`
  height: auto;
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 25px;
  background-color: #415781ff;

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const StyledGrid = styled(Grid)`
  padding: 5px;
  place-self: flex-start;
`;

const StyledGridContainer = styled(Grid)`
  margin-bottom: 0px;
`;

const StyledH2 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.white};
  text-align: left;
  letter-spacing: 1.8px;
  font-size: 28px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
`;

const StyledDisableControlledTextField = styled(ControlledTextField)`
  .Mui-disabled {
    -webkit-text-fill-color: #ffffff !important;
    border-color: #ffffff !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: #ffffff !important;
  }
`;

const FormDadosFila = ({ formProps, fila }) => {
  return (
    <StyledCard>
      <StyledGridContainer container>
        <StyledGrid item xs={12} md={8}>
          <StyledH2>Informações de Reajuste</StyledH2>
        </StyledGrid>
        <StyledGrid item xs={12} md={6}>
          <StyledDisableControlledTextField
            disabled={true}
            formProps={formProps}
            fullWidth
            name="clienteApolice"
            label="Cliente"
          />
        </StyledGrid>
        <StyledGrid item xs={12} md={6}>
          <StyledDisableControlledTextField
            disabled={true}
            formProps={formProps}
            fullWidth
            name="apolice"
            label="Apólice"
          />
        </StyledGrid>
        <StyledGrid item xs={12} md={6}>
          <StyledDisableControlledTextField
            disabled={true}
            formProps={formProps}
            fullWidth
            name="dataInclusao"
            label="Data de Inclusão"
          />
        </StyledGrid>
        <StyledGrid item xs={12} md={6}>
          <StyledDisableControlledTextField
            disabled={true}
            formProps={formProps}
            fullWidth
            name="situacao"
            label="Situação"
          />
        </StyledGrid>
        <StyledGrid item xs={12} md={6}>
          {fila.situacao === "E" ? (
            <StyledDisableControlledTextField
              disabled={true}
              formProps={formProps}
              fullWidth
              name="porcentagem"
              label="Reajuste"
              InputProps={{
                inputComponent: PercentTextField,
              }}
            />
          ) : (
            <ControlledTextField
              formProps={formProps}
              fullWidth
              name="porcentagem"
              label="Reajuste"
              InputProps={{
                inputComponent: PercentTextField,
              }}
            />
          )}
        </StyledGrid>
        {fila.situacao === "E" && (
          <StyledGrid item xs={12} md={6}>
            <StyledDisableControlledTextField
              disabled={true}
              formProps={formProps}
              fullWidth
              name="dataEnvio"
              label="Data do Envio"
            />
          </StyledGrid>
        )}
      </StyledGridContainer>
    </StyledCard>
  );
};

export default FormDadosFila;
