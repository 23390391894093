import { createAsyncAction } from "../utils/actionCreators";

export const types = {
  OBTER_DADOS: "@@dashboard/OBTER_DADOS",
  OBTER_APOLICES: "@@dashboard/OBTER_APOLICES",
  OBTER_RATEIOS: "@@dashboard/OBTER_RATEIOS",
  GRAFICO_SINISTRALIDADE: "@@dashboard/GRAFICO_SINISTRALIDADE",
};

export const actions = {
  obterDados: createAsyncAction(types.OBTER_DADOS),
  obterApolices: createAsyncAction(types.OBTER_APOLICES),
  obterRateios: createAsyncAction(types.OBTER_RATEIOS),
  graficoSinistralidade: createAsyncAction(types.GRAFICO_SINISTRALIDADE),
};
