import React, { useState } from "react";
import { Card, MenuItem, styled } from "@mui/material";
import { Grid } from "@mui/material";
import {
  ControlledSelect,
  ControlledTextField,
  Select,
} from "../../../components/inputs";
import OutlinedButton from "../../../components/buttons/OutlinedButton";
import TabelaEstorno from "../tables/TabelaEstorno";
import CardDetalhesRateio from "../faturamento/CardDetalhesRateio";
import TabelaRateio from "../tables/TabelaRateio";
import SearchButton from "../../../components/buttons/SearchButton";
import { useEffect } from "react";

const StyledCard = styled(Card)`
  height: auto;
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 25px;
  background-color: #415781ff;

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const StyledGrid = styled(Grid)`
  padding: 5px;
  place-self: flex-start;
`;

const StyledGridRateio = styled(Grid)`
  max-height: 400px;
`;

const StyledGridContainer = styled(Grid)`
  margin-bottom: 0px;
`;
const StyledGridCardContainer = styled(Grid)`
  margin-bottom: 20px;
`;
const StyledControlledTextField = styled(ControlledTextField)`
  .MuiOutlinedInput-input {
    color-scheme: dark;
  }
`;

const StyledH2 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.white};
  text-align: left;
  letter-spacing: 1.8px;
  font-size: 28px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
`;

const StyledOutlinedButton = styled(OutlinedButton)`
  border-color: #ffffff;
  color: #ffffff;
  margin-top: 25px;

  &:focus {
    outline: 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.buttons.secondary.backgroundHover};
    border-color: #ffffff;
  }

  width: 322px;
`;

const FormDadosRateio = ({
  formProps,
  dataRateioOutros,
  rateio,
  handleDeleteRateioOutros,
  handleSetEdit,
  buttonTitle,
  loadingRateio,
  dataRateioBeneficiarios,
  planosBeneficiarios,
  handleIncluirBeneficiario,
}) => {
  const [rateiosBeneficiarios, setRateiosBeneficiarios] = useState([]);
  const [value, setValue] = useState("Todos");

  useEffect(() => {
    setRateiosBeneficiarios(dataRateioBeneficiarios);
    handleFilterBeneficiarios();
    //eslint-disable-next-line
  }, [dataRateioBeneficiarios]);

  const handleFilterBeneficiarios = () => {
    if (value !== "Todos") {
      let novosBeneficiarios = dataRateioBeneficiarios.filter((el) => {
        return el.planoId === value;
      });
      setRateiosBeneficiarios(novosBeneficiarios);
    } else {
      setRateiosBeneficiarios(dataRateioBeneficiarios);
    }
  };

  return (
    <>
      <StyledGridCardContainer container spacing={2}>
        <CardDetalhesRateio
          title="Beneficiários"
          icon={true}
          value={rateio.beneficiario}
          loading={loadingRateio}
        />

        <CardDetalhesRateio
          title="Valores Beneficiários"
          value={rateio.valoresBeneficiarios}
          loading={loadingRateio}
        />

        <CardDetalhesRateio
          title="Outros Valores"
          value={rateio.valoresOutros}
          loading={loadingRateio}
        />

        <CardDetalhesRateio
          title="Total"
          value={rateio?.total}
          loading={loadingRateio}
        />
      </StyledGridCardContainer>
      <StyledCard>
        <StyledGridContainer container>
          <StyledGrid item xs={12} md={8}>
            <StyledH2>Tabela de Rateio</StyledH2>
          </StyledGrid>
          <StyledGrid item xs={8} md={3}>
            <Select
              value={value}
              onChange={(e) => setValue(e.target.value)}
              align="right"
              sx={{ marginTop: "0px" }}
              label="Plano"
            >
              {planosBeneficiarios?.map((plano) => {
                return (
                  <MenuItem key={plano.id} value={plano.id}>
                    {plano.nome}
                  </MenuItem>
                );
              })}
              <MenuItem key={"Todos"} value={"Todos"}>
                Todos
              </MenuItem>
            </Select>
          </StyledGrid>
          <StyledGrid align="right" item xs={4} md={1}>
            <SearchButton onClick={() => handleFilterBeneficiarios()} />
          </StyledGrid>
        </StyledGridContainer>
        <StyledGridContainer container>
          <StyledGridRateio item xs={12} md={12}>
            <TabelaRateio data={rateiosBeneficiarios} />
          </StyledGridRateio>
          <StyledGrid item xs={12} md={12} align="end">
            <StyledOutlinedButton onClick={() => handleIncluirBeneficiario()}>
              + Incluir Beneficiário
            </StyledOutlinedButton>
          </StyledGrid>
        </StyledGridContainer>
      </StyledCard>
      <StyledCard>
        <StyledH2>Tabela de Estornos, multas, juros e outros</StyledH2>
        <StyledGridContainer container>
          <StyledGrid item xs={12} md={3}>
            <StyledControlledTextField
              disabled={rateio?.situacao === "I"}
              formProps={formProps}
              fullWidth
              name="dataCompetencia"
              label="Competência"
              type="month"
              InputProps={{
                max: `${new Date().getFullYear()}-${
                  new Date().getMonth() > 8
                    ? new Date().getMonth() + 1
                    : "0" + (new Date().getMonth() + 1)
                }`,
                min: "2021-01",
              }}
            />
          </StyledGrid>
          <StyledGrid item xs={12} md={2}>
            <ControlledTextField
              disabled={rateio?.situacao === "I"}
              formProps={formProps}
              fullWidth
              name="valorOutros"
              label="Valor"
            />
          </StyledGrid>
          <StyledGrid item xs={12} md={2}>
            <ControlledSelect
              disabled={rateio?.situacao === "I"}
              formProps={formProps}
              fullWidth
              name="tipoValor"
              label="Tipo"
            >
              <MenuItem key={"E"} value={"E"}>
                Estorno
              </MenuItem>
              <MenuItem key={"M"} value={"M"}>
                Multa
              </MenuItem>
              <MenuItem key={"J"} value={"J"}>
                Juros
              </MenuItem>
              <MenuItem key={"O"} value={"O"}>
                Outros
              </MenuItem>
              <MenuItem key={""} value={""}></MenuItem>
            </ControlledSelect>
          </StyledGrid>
          <StyledGrid item xs={12} md={5}>
            <ControlledTextField
              disabled={rateio?.situacao === "I"}
              formProps={formProps}
              fullWidth
              name="observacao"
              label="Observação"
              inputProps={{ maxLength: 200 }}
            />
          </StyledGrid>
          <StyledGrid item xs={12} md={12} align="end">
            <StyledOutlinedButton type="submit">
              {buttonTitle}
            </StyledOutlinedButton>
          </StyledGrid>
        </StyledGridContainer>
        <TabelaEstorno
          data={dataRateioOutros}
          handleDelete={handleDeleteRateioOutros}
          handleSetEdit={handleSetEdit}
        />
      </StyledCard>
    </>
  );
};

export default FormDadosRateio;
