import { createAsyncAction } from "../utils/actionCreators";

export const types = {
  OBTER_SINISTROS: "@@rateio/OBTER_SINISTROS",
  MOSTRAR_SINISTRO: "@@rateio/MOSTRAR_SINISTRO",
  EDITAR_SINISTRO: "@@rateio/EDITAR_SINISTRO",
  CRIAR_SINISTRO: "@@rateio/CRIAR_SINISTRO",
  DELETAR_SINISTRO: "@@rateio/DELETAR_SINISTRO",
  ATIVAR_SINISTRO: "@@rateio/ATIVAR_SINISTRO",
  OBTER_VALORES: "@@rateio/OBTER_VALORES",
  BAIXAR_ARTEFATO: "@@rateio/BAIXAR_ARTEFATO",
  OBTER_SINISTRO_OUTROS: "@@rateio/OBTER_SINISTRO_OUTROS",
  CRIAR_SINISTRO_OUTROS: "@@rateio/CRIAR_SINISTRO_OUTROS",
  EDITAR_SINISTRO_OUTROS: "@@rateio/EDITAR_SINISTRO_OUTROS",
  DELETAR_SINISTRO_OUTROS: "@@rateio/DELETAR_SINISTRO_OUTROS",
  UPLOAD_SINISTRO_DOCUMENTOS: "@@rateio/UPLOAD_SINISTRO_DOCUMENTOS",
  OBTER_SINISTRO_BENEFICIARIOS: "@@rateio/OBTER_SINISTRO_BENEFICIARIOS",
  EDITAR_SINISTRO_BENEFICIARIOS: "@@rateio/EDITAR_SINISTRO_BENEFICIARIOS",
  DOWNLOAD_SINISTRO: "@@rateio/DOWNLOAD_SINISTRO",
  ATUALIZAR_SITUACAO: "@@rateio/ATUALIZAR_SITUACAO",
  APROVAR_SITUACAO: "@@rateio/APROVAR_SITUACAO",
};

export const actions = {
  obterSinistros: createAsyncAction(types.OBTER_SINISTROS),
  mostrarSinistro: createAsyncAction(types.MOSTRAR_SINISTRO),
  editarSinistro: createAsyncAction(types.EDITAR_SINISTRO),
  criarSinistro: createAsyncAction(types.CRIAR_SINISTRO),
  deletarSinistro: createAsyncAction(types.DELETAR_SINISTRO),
  ativarSinistro: createAsyncAction(types.ATIVAR_SINISTRO),
  obterValores: createAsyncAction(types.OBTER_VALORES),
  baixarArtefato: createAsyncAction(types.BAIXAR_ARTEFATO),
  obterSinistroOutros: createAsyncAction(types.OBTER_SINISTRO_OUTROS),
  criarSinistroOutros: createAsyncAction(types.CRIAR_SINISTRO_OUTROS),
  deletarSinistroOutros: createAsyncAction(types.DELETAR_SINISTRO_OUTROS),
  editarSinistroOutros: createAsyncAction(types.EDITAR_SINISTRO_OUTROS),
  uploadSinistroDocumentos: createAsyncAction(types.UPLOAD_SINISTRO_DOCUMENTOS),
  obterSinistroBeneficiarios: createAsyncAction(
    types.OBTER_SINISTRO_BENEFICIARIOS
  ),
  editarSinistroBeneficiarios: createAsyncAction(
    types.EDITAR_SINISTRO_BENEFICIARIOS
  ),
  downloadSinistro: createAsyncAction(types.DOWNLOAD_SINISTRO),
  atualizarSituacao: createAsyncAction(types.ATUALIZAR_SITUACAO),
  aprovarSituacao: createAsyncAction(types.APROVAR_SITUACAO),
};
