import { actions } from "./beneficiario.actions";
import { types as routeActions } from "./rotas.actions";

const initialState = {
  beneficiarios: {
    loading: false,
    page: 1,
    pesquisa: "",
  },
  beneficiario: {
    loading: false,
    data: {
      cep: "",
      uf: "",
      complemento: "",
      logradouro: "",
      bairro: "",
      nomeBeneficiario: "",
      nomeMunicipio: "",
      cpf: "",
      numeroLogradouro: "",
      situacao: "A",
      centroCusto: "",
      descricaoCentroCusto: "",
      dataAdmissao: "",
      cargo: "",
      pis: "",
      cns: "",
      beneficiarioPrincipal: "",
      dataNascimento: "",
      genero: "",
      relacaoDependencia: "",
      matricula: "",
      nomeMae: "",
      emailContato: "",
      telefoneContato: "",
      apolice: "",
      plano: "",
      numero: "",
      valorPremioSeguro: "",
      dataVigencia: "",
      valorCapitalSegurado: "",
      manutencaoCliente: "",
    },
    dependentes: [],
  },
  planilhaBeneficiario: {
    loadingDownload: false,
    loadingUpload: false,
  },
  planilhaMigrarPlano: {
    loadingDownload: false,
    loadingUpload: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.obterBeneficiarios.REQUEST:
    case actions.obterBeneficiarios.SUCCESS:
    case actions.obterBeneficiarios.FAILURE:
      return {
        ...state,
        beneficiarios: {
          ...state.beneficiarios,
          pesquisa:
            action.type === actions.obterBeneficiarios.REQUEST
              ? action.payload.pesquisa
              : state.beneficiarios.pesquisa,
          loading: action.type === actions.obterBeneficiarios.REQUEST,
          data:
            action.type === actions.obterBeneficiarios.SUCCESS
              ? action.payload.response.data.resultado
              : [],
          page:
            action.type === actions.obterBeneficiarios.SUCCESS
              ? action.payload.response.data.paginaAtual
              : state.beneficiarios.page,
          count:
            action.type === actions.obterBeneficiarios.SUCCESS
              ? action.payload.response.data.total
              : [],
        },
      };
    case actions.editarBeneficiario.REQUEST:
    case actions.editarBeneficiario.SUCCESS:
    case actions.editarBeneficiario.FAILURE:
      return {
        ...state,
        beneficiario: {
          ...state.beneficiario,
          loading: action.type === actions.editarBeneficiario.REQUEST,
          data:
            action.type === actions.editarBeneficiario.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };

    case actions.mostrarBeneficiario.REQUEST:
    case actions.mostrarBeneficiario.SUCCESS:
    case actions.mostrarBeneficiario.FAILURE:
      return {
        ...state,
        beneficiario: {
          ...state.beneficiario,
          loading: action.type === actions.mostrarBeneficiario.REQUEST,
          data:
            action.type === actions.mostrarBeneficiario.SUCCESS
              ? {
                  ...action.payload.response.data,
                }
              : state.beneficiario.data,
        },
      };
    case actions.criarBeneficiario.REQUEST:
    case actions.criarBeneficiario.SUCCESS:
    case actions.criarBeneficiario.FAILURE:
      return {
        ...state,
        beneficiario: {
          ...state.beneficiario,
          loading: action.type === actions.criarBeneficiario.REQUEST,
          data:
            action.type === actions.criarBeneficiario.SUCCESS
              ? action.payload.response.data
              : action.type === actions.criarBeneficiario.REQUEST
              ? {
                  ...action.payload,
                }
              : {
                  ...action.payload.original.beneficiario,
                },
        },
      };
    case actions.deletarBeneficiario.REQUEST:
    case actions.deletarBeneficiario.SUCCESS:
    case actions.deletarBeneficiario.FAILURE:
      return {
        ...state,
        beneficiarios: {
          loading: action.type === actions.deletarBeneficiario.REQUEST,
        },
        beneficiario: {
          ...state.beneficiario,
          loading: action.type === actions.deletarBeneficiario.REQUEST,
        },
      };

    case actions.ativarBeneficiario.REQUEST:
    case actions.ativarBeneficiario.SUCCESS:
    case actions.ativarBeneficiario.FAILURE:
      return {
        ...state,
        beneficiarios: {
          loading: action.type === actions.ativarBeneficiario.REQUEST,
        },
        beneficiario: {
          ...state.beneficiario,
          loading: action.type === actions.ativarBeneficiario.REQUEST,
        },
      };

    case routeActions.NEW_BENEFICIARIO:
      return {
        ...state,
        beneficiario: {
          ...state.beneficiario,
          data: {
            cep: "",
            uf: "",
            complemento: "",
            logradouro: "",
            bairro: "",
            nomeBeneficiario: "",
            nomeMunicipio: "",
            cpf: "",
            numeroLogradouro: "",
            situacao: "A",
            centroCusto: "",
            descricaoCentroCusto: "",
            dataAdmissao: "",
            cargo: "",
            pis: "",
            cns: "",
            beneficiarioPrincipal: "",
            dataNascimento: "",
            genero: "",
            relacaoDependencia: "",
            matricula: "",
            nomeMae: "",
            cliente: action.payload.idCliente,
            emailContato: "",
            telefoneContato: "",
            apolice: "",
            plano: "",
            numero: "",
            valorPremioSeguro: "",
            dataVigencia: "",
            valorCapitalSegurado: "",
            manutencaoCliente: "",
          },
          dependentes: [],
        },
      };
    case routeActions.EDIT_BENEFICIARIO:
      return {
        ...state,
        beneficiario: {
          ...state.beneficiario,
          data: {
            cep: "",
            uf: "",
            complemento: "",
            logradouro: "",
            bairro: "",
            nomeBeneficiario: "",
            nomeMunicipio: "",
            cpf: "",
            numeroLogradouro: "",
            situacao: "",
            centroCusto: "",
            descricaoCentroCusto: "",
            dataAdmissao: "",
            cargo: "",
            pis: "",
            cns: "",
            beneficiarioPrincipal: "",
            dataNascimento: "",
            genero: "",
            relacaoDependencia: "",
            matricula: "",
            nomeMae: "",
            cliente: action.payload.idCliente,
            emailContato: "",
            telefoneContato: "",
            manutencaoCliente: "",
            planos: [],
          },
          loading: true,
        },
      };
    case actions.obterDependentes.REQUEST:
    case actions.obterDependentes.SUCCESS:
    case actions.obterDependentes.FAILURE:
      return {
        ...state,
        beneficiario: {
          ...state.beneficiario,
          dependentes:
            action.type === actions.obterDependentes.SUCCESS
              ? action.payload.response.data
              : state.beneficiario.dependentes,
        },
      };
    case routeActions.NEW_DEPENDENTE:
      return {
        ...state,
        beneficiario: {
          ...state.beneficiario,
          data: {
            ...state.beneficiario.data,
            nomeBeneficiario: "",
            cpf: "",
            situacao: "A",
            centroCusto: "",
            descricaoCentroCusto: "",
            dataAdmissao: "",
            cargo: "",
            pis: "",
            cns: "",
            beneficiarioPrincipal: state.beneficiario.data,
            dataNascimento: "",
            genero: "",
            relacaoDependencia: "",
            matricula: "",
            nomeMae: "",
            cliente: action.payload.idCliente,
            manutencaoCliente: "",
          },
          dependentes: [],
        },
      };
    case routeActions.NEW_ATUALIZACAO_DEPENDENTE:
      return {
        ...state,
        beneficiario: {
          ...state.beneficiario,
          data: {
            cep: "",
            uf: "",
            complemento: "",
            logradouro: "",
            bairro: "",
            nomeBeneficiario: "",
            nomeMunicipio: "",
            cpf: "",
            numeroLogradouro: "",
            situacao: "P",
            centroCusto: "",
            descricaoCentroCusto: "",
            dataAdmissao: "",
            cargo: "",
            pis: "",
            cns: "",
            beneficiarioPrincipal: state.beneficiario.data,
            dataNascimento: "",
            genero: "",
            relacaoDependencia: "",
            matricula: "",
            nomeMae: "",
            cliente: action.payload.idCliente,
            emailContato: "",
            telefoneContato: "",
            planos: [],
            manutencaoCliente: "",
          },
        },
      };
    case actions.baixarPlanilhaBeneficiarios.REQUEST:
    case actions.baixarPlanilhaBeneficiarios.SUCCESS:
    case actions.baixarPlanilhaBeneficiarios.FAILURE:
      return {
        ...state,
        planilhaBeneficiario: {
          ...state.planilhaBeneficiario,
          loadingDownload:
            action.type === actions.baixarPlanilhaBeneficiarios.REQUEST,
        },
      };
    case actions.uploadPlanilhaBeneficiarios.REQUEST:
    case actions.uploadPlanilhaBeneficiarios.SUCCESS:
    case actions.uploadPlanilhaBeneficiarios.FAILURE:
      return {
        ...state,
        planilhaBeneficiario: {
          ...state.planilhaBeneficiario,
          loadingUpload:
            action.type === actions.uploadPlanilhaBeneficiarios.REQUEST,
        },
      };
    case routeActions.NEW_ATUALIZACAO:
      return {
        ...state,
        beneficiario: {
          ...state.beneficiario,
          data: {
            cep: "",
            uf: "",
            complemento: "",
            logradouro: "",
            bairro: "",
            nomeBeneficiario: "",
            nomeMunicipio: "",
            cpf: "",
            numeroLogradouro: "",
            situacao: "P",
            centroCusto: "",
            descricaoCentroCusto: "",
            dataAdmissao: "",
            cargo: "",
            pis: "",
            cns: "",
            beneficiarioPrincipal: "",
            dataNascimento: "",
            genero: "",
            relacaoDependencia: "",
            matricula: "",
            nomeMae: "",
            cliente: action.payload.idCliente,
            emailContato: "",
            telefoneContato: "",
            apolice: "",
            plano: "",
            numero: "",
            valorPremioSeguro: "",
            dataVigencia: "",
            valorCapitalSegurado: "",
            manutencaoCliente: "",
          },
        },
      };
    case routeActions.MOSTRAR_ATUALIZACAO:
      return {
        ...state,
        beneficiario: {
          ...state.beneficiario,
          data: {
            cep: "",
            uf: "",
            complemento: "",
            logradouro: "",
            bairro: "",
            nomeBeneficiario: "",
            nomeMunicipio: "",
            cpf: "",
            numeroLogradouro: "",
            situacao: "P",
            centroCusto: "",
            descricaoCentroCusto: "",
            dataAdmissao: "",
            cargo: "",
            pis: "",
            cns: "",
            beneficiarioPrincipal: "",
            dataNascimento: "",
            genero: "",
            relacaoDependencia: "",
            matricula: "",
            nomeMae: "",
            cliente: action.payload.idCliente,
            emailContato: "",
            telefoneContato: "",
            apolice: "",
            plano: "",
            numero: "",
            valorPremioSeguro: "",
            dataVigencia: "",
            valorCapitalSegurado: "",
            manutencaoCliente: "",
          },
        },
      };
    default:
      return state;
  }
};

export default reducer;
