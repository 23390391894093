import React from "react";
import BasePage from "../BasePage";
import Filtros from "./Filtros";
import { Grid, styled } from "@mui/material";

const StyledBasePage = styled(BasePage)`
  padding: 3px;
`;

const StyledContainer = styled(Grid)`
  margin-top: 1px;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 900px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const StyledH1 = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.blueTitle};
  letter-spacing: 4.8px;
  font-size: 45px;
  margin-bottom: 0px;
  @media (max-width: 900px) {
    font-size: 22px;
  }
`;

const RelatoriosPage = ({
  formProps,
  handleFilterClick,
  usuarios,
  loading,
  loadingFile,
  clientes,
}) => {
  return (
    <StyledBasePage isLoggedIn={true} isLightBlue={true}>
      <StyledContainer container spacing={2}>
        <Grid item xs={12}>
          <StyledH1>Relatórios</StyledH1>
        </Grid>
      </StyledContainer>
      <StyledContainer container spacing={2}>
        <Grid item xs={12}>
          <Filtros
            usuarios={usuarios}
            formProps={formProps}
            handleFilterClick={handleFilterClick}
            loading={loading}
            loadingFile={loadingFile}
            clientes={clientes}
          />
        </Grid>
      </StyledContainer>
    </StyledBasePage>
  );
};

export default RelatoriosPage;
