import React, { useState } from "react";
import { MenuItem, styled } from "@mui/material";
import { Grid } from "@mui/material";
import { Card } from "../../../components";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import GraficoSinistralidade from "./GraficoSinistralidade";
import SearchButton from "../../../components/buttons/SearchButton";
import Select from "../../../components/inputs/Select";

const StyledCard = styled(Card)`
  height: auto;
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 25px;
  background-color: #415781ff;

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const StyledGrid = styled(Grid)`
  padding: 5px;
  place-self: flex-start;
`;

const StyledH2 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.white};
  text-align: left;
  letter-spacing: 1.8px;
  font-size: 28px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
`;

const StyledGridContainer = styled(Grid)`
  margin-bottom: 0px;
`;

const StyledLabelIconContainer = styled("div")`
  display: flex;
  align-items: center;
  height: 30px;
  margin-left: 30px;
`;

const StyledLabelIcon = styled("div")`
  width: 19.25px;
  height: 11px;
  background-color: ${({ color }) => color};
`;

const StyledLabelText = styled("p")`
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  margin-left: 15px;
`;

const StyledLabelContainer = styled("div")`
  display: flex;
  justify-content: center;
`;

const LabelIcon = ({ color, label }) => {
  return (
    <StyledLabelIconContainer>
      <StyledLabelIcon color={color} />
      <StyledLabelText>{label}</StyledLabelText>
    </StyledLabelIconContainer>
  );
};
const LabelIconChart = ({ color, label }) => {
  return (
    <StyledLabelIconContainer>
      <ShowChartIcon style={{ fill: color }} />
      <StyledLabelText>{label}</StyledLabelText>
    </StyledLabelIconContainer>
  );
};

const SinistralidadeCard = ({ data, clientes, handleChangeFilter, dadosSinistralidade, sinistralidadeLoading }) => {

  const [value, setValue] = useState("Todos");

  const handleFilterApolices = () => {
    handleChangeFilter(value);
  };

  return (
    <Grid item xs={12}>
      <StyledCard>
        <StyledGridContainer container>
          <StyledGrid item xs={12} md={8}>
            <StyledH2>Sinitro x Faturamento x Reajuste</StyledH2>
          </StyledGrid>
          <StyledGrid sx={{ marginTop: "-20px" }} item xs={8} md={3}>
            <Select
              onChange={(e) => setValue(e.target.value)}
              label="Clientes"
            >
              {clientes?.filter((i) => i.flagCalculaSinistralidade === "S").map((plano) => {
                return (
                  <MenuItem key={plano.id} value={plano.id}>
                    {`${plano.Cliente.nomeCliente} - ${plano.tipo === 'S' ? 'Saúde' : plano.tipo === 'V' ? 'Vida' : 'Dental' } - ${plano.numero}`}
                  </MenuItem>
                );
              })}
            </Select>
          </StyledGrid>
          <StyledGrid align="right" item xs={4} md={1}>
            <SearchButton
              onClick={() => handleFilterApolices()}
            />
          </StyledGrid>
        </StyledGridContainer>
        {!sinistralidadeLoading && <GraficoSinistralidade dadosSinistralidade={dadosSinistralidade}/>}
        <StyledLabelContainer>
          <LabelIcon label={"Receita"} color={"#28B463"} />
          <LabelIcon label={"Custo Total"} color={"#FF0033"} />
          <LabelIconChart label={"Sinistralidade"} color={"#43B5F4"} />
        </StyledLabelContainer>
      </StyledCard>
    </Grid>
  );
};

export default SinistralidadeCard;
