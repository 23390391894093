import { all } from "redux-saga/effects";
import { sagas as authSagas } from "./autenticacao.saga";
import { sagas as routeSagas } from "./rotas.saga";
import { sagas as usuarioAdmSagas } from "./usuarioAdm.saga";
import { sagas as clienteSagas } from "./cliente.saga";
import { sagas as apoliceSagas } from "./apolice.saga";
import { sagas as enderecoSagas } from "./endereco.saga";
import { sagas as dominiosSagas } from "./dominios.saga";
import { sagas as rateioSagas } from "./rateio.saga";
import { sagas as beneficiarioSagas } from "./beneficiario.saga";
import { sagas as documentoSagas } from "./documentos.saga";
//import { sagas as atualizacaoVida } from  "./atualizacaoVida.saga";
import { sagas as faturamentoSagas } from "./faturamento.saga";
import { sagas as manutencaoClienteSagas } from "./manutencaoCliente.saga";

import { sagas as painelSagas } from "./painel.saga";
import { sagas as sinistroSagas } from "./sinistro.saga";
import { sagas as relatoriosSagas } from "./relatorios.saga";
import { sagas as filaSagas } from "./fila.saga";

export default function* foo() {
  yield all([
    ...routeSagas,
    ...authSagas,
    ...usuarioAdmSagas,
    ...clienteSagas,
    ...apoliceSagas,
    ...enderecoSagas,
    ...dominiosSagas,
    ...rateioSagas,
    ...beneficiarioSagas,
    ...documentoSagas,
    ...faturamentoSagas,
    ...manutencaoClienteSagas,
    ...painelSagas,
    ...sinistroSagas,
    ...relatoriosSagas,
    ...filaSagas,
    //    ...atualizacaoVida,
  ]);
}
