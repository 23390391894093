import BasePage from "../BasePage";
import React from "react";
import { styled } from "@mui/material";
import { Grid } from "@mui/material";
import CardSinistro from "./CardSinistro";
import Spinner from "../../components/spinner/Spinner";
import OutlinedButton from "../../components/buttons/OutlinedButton";
import { Pagination } from "../../components";
import {
  ControlledAutocompleteCreate,
  ControlledPesquisa,
} from "../../components/inputs";
import SearchButton from "../../components/buttons/SearchButton";

const StyledH1 = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.blueTitle};
  letter-spacing: 4.8px;
  font-size: 45px;
  margin-bottom: 0px;
  @media (max-width: 900px) {
    font-size: 22px;
  }
`;

const StyledH4 = styled("h4")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  color: ${({ theme }) => theme.colors.subtitle};
  letter-spacing: 4.8px;
  margin-bottom: 15px;
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 20px;
  margin-top: 0px;

  display: flex;
  align-items: flex-end;
  letter-spacing: 0.25px;

  @media (max-width: 900px) {
    font-size: 22px;
  }
`;

const StyledContainer = styled(Grid)`
  margin-top: 1px;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 900px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;
const StyledBasePage = styled(BasePage)`
  padding: 3px;
`;

const StyledAutoComplete = styled(ControlledAutocompleteCreate)`
  width: 100% !important;
  margin-top: 0px;

  & .MuiInputLabel-root {
    background-color: rgba(242, 242, 242, 1);
    color: #231b00 !important;

    padding: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
  }

  & .MuiFormHelperText-root.Mui-error {
    color: #f0e500ff !important;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #231b00;
    }
    ,
    &:hover fieldset {
      border-color: #231b00;
    }
    ,
    &.Mui-focused fieldset {
      border-color: #231b00;
    }
    ,
    &.Mui-error fieldset {
      border: 2px solid #f0e500ff;
    }
  }

  & .MuiFormLabel-root {
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-size: ${({ theme }) => theme.fonts.size.inputs.title};
    font-weight: 700;
    color: #d14a13 !important;
    letter-spacing: 1.4px;
  }
  & .MuiFormLabel-root.Mui-focused {
    color: #d14a13;
  }
  & .MuiInputBase-root {
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-size: ${({ theme }) => theme.fonts.size.inputs.content};
    font-weight: 400;
    text-align: left;
    border-radius: 15px;
    color: #231b00;
    width: 100%;
  }
  & .MuiFilledInput-underline:after {
    border-bottom: 2px solid ${({ theme }) => theme.colors.gray["800"]};
  }
  & .Mui-error:after {
    border-bottom: 2px solid ${({ theme }) => theme.colors.error};
  }
  & .MuiFormLabel-root.Mui-error {
    color: ${({ theme }) => theme.colors.error};
  }
  & .MuiFormHelperText-root.Mui-error {
    color: ${({ theme }) => theme.colors.error};
  }
  & .MuiOutlinedInput-notchedOutline {
    border-bottom-color: #231b00;
    border-left-color: #231b00;
    border-right-color: #231b00;
    border-top-color: #231b00;
  }
  & .MuiSelect-icon {
    color: ${({ theme }) => theme.colors.black};
  }
`;

const ClientesPage = ({
  data,
  nomeCliente,
  filtrarSinistros,
  loading,
  handleClickEdit,
  handleClickVoltar,
  clientesDomain,
  seguradorasDomain,
  formProps,
  count,
  page,
  handleClickEditDocumetos,
  handleClickDownload,
  handlePagination,
}) => {
  return (
    <StyledBasePage isLoggedIn={true} isLightBlue={true}>
      <form onSubmit={formProps.handleSubmit(filtrarSinistros)}>
        <StyledContainer container spacing={2}>
          <Grid item xs={12}>
            <StyledH1>Lista de Sinistros</StyledH1>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: "0px" }}>
            <StyledH4>{nomeCliente}</StyledH4>
          </Grid>
          {!loading && (
            <>
              <Grid item xs={4}>
                <StyledAutoComplete
                  formProps={formProps}
                  fullWidth={false}
                  name="cliente"
                  label="Cliente"
                  labelField={"nomeCliente"}
                  idField={"id"}
                  domain={clientesDomain}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={4}>
                <StyledAutoComplete
                  formProps={formProps}
                  fullWidth={true}
                  name="seguradora"
                  label="Seguradora"
                  labelField={"NM_SEGURADORA"}
                  idField={"id"}
                  domain={seguradorasDomain}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid
                item
                xs={3}
                style={{ justifyContent: "right", display: "flex" }}
              >
                <ControlledPesquisa
                  formProps={formProps}
                  fullWidth={false}
                  name="competencia"
                  label="Competência"
                  style={{ width: "100%" }}
                  type="month"
                  InputProps={{
                    max: `${new Date().getFullYear()}-${
                      new Date().getMonth() > 8
                        ? new Date().getMonth() + 1
                        : "0" + (new Date().getMonth() + 1)
                    }`,
                    min: "2022-01",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={1}
                style={{ justifyContent: "right", display: "flex" }}
              >
                <SearchButton type={"submit"} />
              </Grid>
            </>
          )}
          {!!loading && (
            <Grid item xs={12}>
              <Spinner />
            </Grid>
          )}
          {!loading &&
            data?.length > 0 &&
            data?.map((item, index) => {
              return (
                <Grid
                  key={index}
                  item
                  xs={12}
                  md={4}
                  style={{ marginTop: "10px" }}
                >
                  <CardSinistro
                    handleClickEdit={handleClickEdit}
                    item={item}
                    handleClickEditDocumetos={handleClickEditDocumetos}
                    handleClickDownload={handleClickDownload}
                  />
                </Grid>
              );
            })}
          {!loading && data?.length === 0 && (
            <Grid item xs={12}>
              Não existem sinistros cadastrados na base
            </Grid>
          )}
        </StyledContainer>
        <StyledContainer
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
        >
          <Grid item xs={12}>
            <Pagination
              count={Math.ceil(count / 9)}
              page={page}
              onChange={handlePagination}
            />
          </Grid>
        </StyledContainer>
        <StyledContainer
          container
          spacing={2}
          direction="row"
          justifyContent="center"
        >
          <Grid item xs={4} style={{ marginTop: "20px" }}>
            <OutlinedButton onClick={handleClickVoltar}>Voltar</OutlinedButton>
          </Grid>
        </StyledContainer>
      </form>
    </StyledBasePage>
  );
};

export default ClientesPage;
