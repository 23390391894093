import BasePage from "../BasePage";
import React, { useCallback, useState } from "react";
import { styled } from "@mui/material";
import { Grid } from "@mui/material";
import CardBeneficiario from "./CardBeneficiario";
import Spinner from "../../components/spinner/Spinner";
import OutlinedButton from "../../components/buttons/OutlinedButton";
import ConfirmarExclusaoDialog from "./ConfirmarExclusaoDialog";
import ConfirmarReativacaoDialog from "./ConfirmarReativacaoDialog";
import { Pagination, FilterChip } from "../../components";
import { ControlledPesquisa } from "../../components/inputs";
import SearchButton from "../../components/buttons/SearchButton";

const StyledH1 = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.blueTitle};
  font-weight: 400;
  letter-spacing: 4.8px;
  font-size: 45px;
  margin-bottom: 15px;
  @media (max-width: 900px) {
    font-size: 22px;
  }
`;

const StyledH4 = styled("h4")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  color: ${({ theme }) => theme.colors.subtitle};
  letter-spacing: 4.8px;
  margin-bottom: 15px;
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 20px;
  margin-top: 0px;

  display: flex;
  align-items: flex-end;
  letter-spacing: 0.25px; 
  
  @media (max-width: 900px) {
    font-size: 22px;
  }
`;

const StyledContainer = styled(Grid)`
  margin-top: 1px;
  padding-left: 10px;
  padding-right: 10px;
  @media (max-width: 900px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;
const StyledBasePage = styled(BasePage)`
  padding: 3px;
  margin-bottom: 10px;
`;

const StyledItem = styled(Grid)`
  max-height: 200px;
`;

const AtualizacoesVidaPage = ({
  data,
  page,
  count,
  loading,
  handleNewBeneficiario,
  handleEditBeneficiario,
  handleDeleteBeneficiario,
  handleAtivarBeneficiario,
  filtrarBeneficiarios,
  handlePesquisa,
  formProps,
  handleClickVoltar,
  deleteFormProps,
  usuarioCliente,
}) => {
  const [dadosExclusaoBeneficiario, setDadosExclusaoBeneficiario] = useState({
    open: false,
  });

  const [
    dadosReativacaoBeneficiario,
    setDadosReativacaoBeneficiario,
  ] = useState({
    open: false,
  });

  const [filtro, setFiltro] = useState({
    ativos: !!usuarioCliente.id,
    desligados: false,
    novo: true,
    aguardandoConclusao: true,
    finalizado: true,
  });

  const handleFiltro = (tipo) => {
    switch (tipo) {
      case "ativos":
        setFiltro({
          ...filtro,
          ativos: !filtro.ativos,
        });
        filtrarBeneficiarios({ ...filtro, ativos: !filtro.ativos });
        break;
      case "desligados":
        setFiltro({
          ...filtro,
          desligados: !filtro.desligados,
        });
        filtrarBeneficiarios({ ...filtro, desligados: !filtro.desligados });
        break;
      case "novo":
        setFiltro({
          ...filtro,
          novo: !filtro.novo,
        });
        filtrarBeneficiarios({ ...filtro, novo: !filtro.novo });
        break;
      case "aguardandoConclusao":
        setFiltro({
          ...filtro,
          aguardandoConclusao: !filtro.aguardandoConclusao,
        });
        filtrarBeneficiarios({ ...filtro, aguardandoConclusao: !filtro.aguardandoConclusao });
        break;
      case "finalizado":
        setFiltro({
          ...filtro,
          finalizado: !filtro.finalizado,
        });
        filtrarBeneficiarios({ ...filtro, finalizado: !filtro.finalizado });
        break;
      default:
        break;
    }
  };

  const handleCloseExclusaoBeneficiario = useCallback(() => {
    setDadosExclusaoBeneficiario({
      open: false,
    });
  }, []);

  const handleCloseReativacaoBeneficiario = useCallback(() => {
    setDadosReativacaoBeneficiario({
      open: false,
    });
  }, []);

  const handleOpenExclusaoBeneficiario = useCallback((values) => {
    setDadosExclusaoBeneficiario({
      open: true,
      beneficiario: values,
    });
  }, []);

  const handleOpenReativacaoBeneficiario = useCallback((values) => {
    setDadosReativacaoBeneficiario({
      open: true,
      beneficiario: values,
    });
  }, []);

  return (
    <StyledBasePage isLoggedIn={true} isLightBlue={true}>
      <StyledContainer container spacing={2}>
        <Grid item xs={12}>
          <StyledH1>Lista de Beneficiários</StyledH1>
          <StyledH4>{!loading ? usuarioCliente.nome : ""}</StyledH4>
        </Grid>
        {!loading && (
          <Grid item xs={12}>
            <form
              onSubmit={formProps.handleSubmit((values) =>
                handlePesquisa(values, filtro)
              )}
              style={{ width: "100%" }}
            >
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={8}
                  style={{ alignItems: "center", display: "flex" }}
                >
                  <FilterChip
                    active={filtro.ativos}
                    label={"ATIVO"}
                    onClick={() => {
                      handleFiltro("ativos");
                    }}
                  />
                  <FilterChip
                    active={filtro.desligados}
                    label={"INATIVO"}
                    onClick={() => handleFiltro("desligados")}
                  />
                  <FilterChip
                    active={filtro.novo}
                    label={"NOVO"}
                    onClick={() => handleFiltro("novo")}
                  />
                  <FilterChip
                    active={filtro.novo}
                    label={"AGUARDANDO CONCLUSÃO"}
                    onClick={() => handleFiltro("aguardandoConclusao")}
                  />
                  <FilterChip
                    active={filtro.novo}
                    label={"FINALIZADO"}
                    onClick={() => handleFiltro("finalizado")}
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ justifyContent: "right", display: "flex" }}
                >
                  <ControlledPesquisa
                    formProps={formProps}
                    fullWidth={false}
                    name="pesquisa"
                    label="Pesquisa"
                    style={{ width: "87%", paddingRight: "20px" }}
                    placeholder={"Nome ou CPF do Beneficiário"}
                  />
                  <SearchButton type={"submit"} />
                </Grid>
              </Grid>
            </form>
          </Grid>
        )}
        {!!loading && (
          <StyledItem item xs={12}>
            <Spinner />
          </StyledItem>
        )}
        {!loading && (
          <Grid item xs={12}>
            <StyledContainer container spacing={2} style={{paddingLeft:"0px", paddingRight:"0px"}}>
              {data?.length > 0 && (
                <>
                  {data.map((i) => (
                    <Grid
                      item
                      key={i.id}
                      xs={12}
                      md={4}
                    >
                      <CardBeneficiario
                        beneficiario={i}
                        handleEditBeneficiario={handleEditBeneficiario}
                        handleDeleteBeneficiario={handleDeleteBeneficiario}
                        handleAtivarBeneficiario={handleAtivarBeneficiario}
                        handleOpenExclusaoBeneficiario={
                          handleOpenExclusaoBeneficiario
                        }
                        handleOpenReativacaoBeneficiario={
                          handleOpenReativacaoBeneficiario
                        }
                      />
                    </Grid>
                  ))}
                </>
              )}
              {data?.length === 0 && (
                <Grid item xs={12}>
                  Não existem beneficiários cadastrados para esse cliente
                </Grid>
              )}
              <Grid item xs={12}>
                <StyledContainer container spacing={2} style={{paddingLeft:"0px", paddingRight:"0px"}}>
                  <Grid item xs={8}>
                    <Pagination count={Math.ceil(count / 10)} page={page} />
                  </Grid>
                  {!!usuarioCliente.id &&
                    <Grid item xs={4}>
                      <OutlinedButton onClick={handleNewBeneficiario}>
                        + Incluir Beneficiário
                      </OutlinedButton>
                    </Grid>
                  }
                </StyledContainer>
              </Grid>
            </StyledContainer>
          </Grid>
        )}
        <ConfirmarExclusaoDialog
          open={dadosExclusaoBeneficiario.open}
          handleClose={handleCloseExclusaoBeneficiario}
          excluir={handleDeleteBeneficiario}
          beneficiario={dadosExclusaoBeneficiario.beneficiario}
          deleteFormProps={deleteFormProps}
        />
        <ConfirmarReativacaoDialog
          open={dadosReativacaoBeneficiario.open}
          handleClose={handleCloseReativacaoBeneficiario}
          reativar={handleAtivarBeneficiario}
          beneficiario={dadosReativacaoBeneficiario.beneficiario}
        />
      </StyledContainer>

      <StyledContainer
        container
        spacing={2}
        direction="row"
        justifyContent="center"
      >
        <Grid item xs={4} style={{ marginTop: "20px" }}>
          <OutlinedButton onClick={handleClickVoltar}>Voltar</OutlinedButton>
        </Grid>
      </StyledContainer>
    </StyledBasePage>
  );
};

export default AtualizacoesVidaPage;
