import { meses } from "./parametros";

export const removeNonDigitsFromString = (s) => {
  if (!s) return s;
  return String(s)?.replace(/\D/g, "");
};
export const getValueFromObject = (obj, name, defaultValue) => {
  if (!!obj && !!name) {
    const paths = name.split(/[,[\].]+?/);
    let p = 0;
    while (obj && p < paths.length) {
      obj = obj[paths[p++]];
    }
    return obj === undefined ? defaultValue : obj;
  }

  return undefined;
};

export const getMesAno = (date) => {
  let mes = meses.find((el) => el.ref === date.slice(5, 7) * 1);
  let result = mes?.mes + "/" + date.slice(0, 4);
  return result;
};

export const padHour = (num) => {
  num = num.toString();
  if (num.length < 2) num = "0" + num;
  return num;
};
