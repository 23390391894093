import { actions } from "./fila.actions";
import { types as routes } from "./rotas.actions";

const initialState = {
  filas: {
    loading: false,
    page: 1,
    count: 0,
    cliente: "",
    seguradora: "",
    competencia: "",
    data: [],
    filtros: "",
  },
  fila: {
    loading: false,
    data: [],
  },
  valores: {
    loading: false,
    data: [],
  },
  filaOutros: {
    loading: false,
    data: [],
  },
  filaBeneficiarios: {
    loading: false,
    data: [],
  },
  situacao: {
    loading: false,
    data: "N",
  },
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.obterFilas.REQUEST:
    case actions.obterFilas.SUCCESS:
    case actions.obterFilas.FAILURE:
      return {
        ...state,
        filas: {
          ...state.filas,
          loading: action.type === actions.obterFilas.REQUEST,
          data:
            action.type === actions.obterFilas.SUCCESS
              ? action.payload.response.data.resultado
              : [],
          cliente:
            action.type === actions.obterFilas.SUCCESS
              ? action.payload.response.data.filter?.cliente ?? ""
              : [],
          seguradora:
            action.type === actions.obterFilas.SUCCESS
              ? action.payload.response.data.filter?.seguradora ?? ""
              : [],
          competencia:
            action.type === actions.obterFilas.SUCCESS
              ? action.payload.response.data.filter?.competencia ?? ""
              : [],
          page:
            action.type === actions.obterFilas.SUCCESS
              ? action.payload.response.data.paginaAtual
              : [],
          count:
            action.type === actions.obterFilas.SUCCESS
              ? action.payload.response.data.total
              : [],
          filtros:
            action.type === actions.obterFilas.REQUEST
              ? action.payload.filtro
              : state.filas.filtros,
        },
      };
    case actions.editarFila.REQUEST:
    case actions.editarFila.SUCCESS:
    case actions.editarFila.FAILURE:
      return {
        ...state,
        fila: {
          ...state.fila,
          loading: action.type === actions.editarFila.REQUEST,
          data:
            action.type === actions.editarFila.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };

    case routes.EDIT_USUARIOS:
      return {
        ...state,
        id: action.payload.id,
      };

    case actions.mostrarFila.REQUEST:
    case actions.mostrarFila.SUCCESS:
    case actions.mostrarFila.FAILURE:
      return {
        ...state,
        fila: {
          ...state.fila,
          breakeven:
            action.type === actions.mostrarFila.SUCCESS
              ? action.payload.response.data.breakeven
              : 0,
          loading: action.type === actions.mostrarFila.REQUEST,
          data:
            action.type === actions.mostrarFila.SUCCESS
              ? action.payload.response.data
              : [],
        },
        situacao: {
          ...state.situacao,
          loading: action.type === actions.mostrarFila.REQUEST,
          data:
            action.type === actions.mostrarFila.SUCCESS
              ? action.payload.response.data.situacao
              : [],
        },
      };
    case actions.uploadFilaDocumentos.REQUEST:
    case actions.uploadFilaDocumentos.SUCCESS:
    case actions.uploadFilaDocumentos.FAILURE:
      return {
        ...state,
        fila: {
          ...state.fila,
          loading: action.type === actions.uploadFilaDocumentos.REQUEST,
          data:
            action.type === actions.uploadFilaDocumentos.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.criarFila.REQUEST:
    case actions.criarFila.SUCCESS:
    case actions.criarFila.FAILURE:
      return {
        ...state,
        fila: {
          ...state.fila,
          loading: action.type === actions.criarFila.REQUEST,
          data:
            action.type === actions.criarFila.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.deletarFila.REQUEST:
    case actions.deletarFila.SUCCESS:
    case actions.deletarFila.FAILURE:
      return {
        ...state,
        fila: {
          ...state.fila,
          loading: action.type === actions.deletarFila.REQUEST,
        },
      };
    case actions.obterValores.REQUEST:
    case actions.obterValores.SUCCESS:
    case actions.obterValores.FAILURE:
      return {
        ...state,
        valores: {
          ...state.valores,
          loading: action.type === actions.obterValores.REQUEST,
          data:
            action.type === actions.obterValores.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.obterFilaOutros.REQUEST:
    case actions.obterFilaOutros.SUCCESS:
    case actions.obterFilaOutros.FAILURE:
      return {
        ...state,
        filaOutros: {
          ...state.filaOutros,
          loading: action.type === actions.obterFilaOutros.REQUEST,
          data:
            action.type === actions.obterFilaOutros.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.obterFilaBeneficiarios.REQUEST:
    case actions.obterFilaBeneficiarios.SUCCESS:
    case actions.obterFilaBeneficiarios.FAILURE:
      return {
        ...state,
        filaBeneficiarios: {
          ...state.filaBeneficiarios,
          loading: action.type === actions.obterFilaBeneficiarios.REQUEST,
          data:
            action.type === actions.obterFilaBeneficiarios.SUCCESS
              ? action.payload.response.data.data
              : [],
          planos:
            action.type === actions.obterFilaBeneficiarios.SUCCESS
              ? action.payload.response.data.planos
              : [],
        },
      };
    case actions.atualizarSituacao.REQUEST:
    case actions.atualizarSituacao.SUCCESS:
    case actions.atualizarSituacao.FAILURE:
      return {
        ...state,
        fila: {
          ...state.fila,
          loading: action.type === actions.atualizarSituacao.REQUEST,
        },
        situacao: {
          ...state.situacao,
          loading: action.type === actions.atualizarSituacao.REQUEST,
          data:
            action.type === actions.atualizarSituacao.SUCCESS
              ? action.payload.response.data.situacao
              : [],
        },
      };
    case actions.aprovarSituacao.REQUEST:
    case actions.aprovarSituacao.SUCCESS:
    case actions.aprovarSituacao.FAILURE:
      return {
        ...state,
        fila: {
          ...state.fila,
          loading: action.type === actions.aprovarSituacao.REQUEST,
        },
        situacao: {
          ...state.situacao,
          loading: action.type === actions.aprovarSituacao.REQUEST,
          data:
            action.type === actions.aprovarSituacao.SUCCESS
              ? action.payload.response.data.situacao
              : [],
        },
      };

    default:
      return state;
  }
};

export default reducer;
