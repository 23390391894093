import { createAsyncAction } from "../utils/actionCreators";

export const types = {
  OBTER_PORCENTAGEM: "@@dashboard/OBTER_PORCENTAGEM",
  OBTER_COTACAO: "@@dashboard/OBTER_COTACAO",
  OBTER_HOSPITAIS: "@@dashboard/OBTER_HOSPITAIS",
  OBTER_SEGURADORAS: "@@dashboard/OBTER_SEGURADORAS",
  OBTER_SEGURADORA_PLANOS: "@@dashboard/OBTER_SEGURADORA_PLANOS",
  OBTER_SEGURADORA_PERIODO: "@@dashboard/OBTER_SEGURADORA_PERIODO",
};

export const actions = {
  obterPorcentagem: createAsyncAction(types.OBTER_PORCENTAGEM),
  obterCotacoes: createAsyncAction(types.OBTER_COTACAO),
  obterHospitais: createAsyncAction(types.OBTER_HOSPITAIS),
  obterSeguradoras: createAsyncAction(types.OBTER_SEGURADORAS),
  obterSeguradoraPlanos: createAsyncAction(types.OBTER_SEGURADORA_PLANOS),
  obterSeguradoraPeriodo: createAsyncAction(types.OBTER_SEGURADORA_PERIODO),
};
