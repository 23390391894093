import { createSelector } from "reselect";

export const getUsuarioAdm = (state) => state.usuarioAdm;

export const getUsuarios = createSelector(
  getUsuarioAdm,
  (state) => state.usuarios
);

export const getUsuario = createSelector(
  getUsuarioAdm,
  (state) => state.usuario
);
