import React from "react";
import { Box, Card, IconButton, styled } from "@mui/material";
import { Grid } from "@mui/material";
import {
  CloudUpload,
  Delete,
  GetApp,
  InsertDriveFile,
} from "@mui/icons-material";
import formatters from "../../../utils/formatters";
import FileInput from "../../../components/inputs/FileInput";
import OutrosDocumentosConnect from "../../outrosDocumentos/OutrosDocumentos.connect";

const StyledCard = styled(Card)`
  background-color: white;
  width: 100%;
  margin: auto;
  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px !important;
  border: 1.5px solid #b4cfe7ff;
`;

const StyledGrid = styled(Grid)`
  padding: 5px;
`;

const StyledGridContainer = styled(Grid)`
  margin-bottom: 20px;
`;

const StyledH2 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.blueTitle};
  letter-spacing: 1.8px;
  font-size: 20px;
  margin-left: 15px;
  margin-bottom: -10px;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
`;

const StyledH4 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  letter-spacing: 1.2px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 12px;
  }
`;

const StyledBox = styled(Box)`
  width: 100% !important;
  height: 200px !important;
  text-align: center;
  background: repeating-linear-gradient(
    135deg,
    #ffffffff,
    #ffffffff 30px,
    #ebf2f8ff 30px,
    #ebf2f8ff 60px
  );

  border: 2px dashed #b4cfe7ff;
  border-radius: 15px;
`;
const StyledBoxFile = styled(Box)`
  width: 100% !important;
  height: 200px !important;
  text-align: center;
  background-color: #ebf2f8ff;

  border: 2px solid #b4cfe7ff;
  border-radius: 15px;
`;

const StyledFileContainer = styled(Box)`
  padding: 20px;
`;

const filesize = (size) => {
  if (size > 1024 * 1024) {
    // Mb
    return `${formatters.numbers.nFixed(
      Math.round(size / (1024 * 1024), 1),
      1
    )} Mb`;
  }
  if (size > 1024) {
    // Mb
    return `${formatters.numbers.nFixed(size / 1024, 1)} Kb`;
  }
  return `${size} bytes`;
};

const FormDocumentosRateio = ({
  isFileBO,
  setIsFileBO,
  fileBO,
  setFileBO,
  editFileBO,
  setEditFileBO,
  isFileNF,
  setIsFileNF,
  setFileNF,
  fileNF,
  editFileNF,
  setEditFileNF,
  isFileFA,
  setIsFileFA,
  setFileFA,
  fileFA,
  editFileFA,
  setEditFileFA,
  isFileCP,
  setIsFileCP,
  setFileCP,
  fileCP,
  editFileCP,
  setEditFileCP,
  isFileOD,
  setIsFileOD,
  setFileOD,
  fileOD,
  arrayFileOD,
  setArrayFileOD,
  handleDownloadArtefato,
}) => {
  return (
    <StyledGridContainer container spacing={2}>
      <StyledGrid item xs={4}>
        <StyledCard>
          <StyledH2>Nota Fiscal</StyledH2>
          <StyledFileContainer>
            {editFileNF && (
              <StyledBoxFile
                sx={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <>
                  <StyledH4>
                    <InsertDriveFile
                      style={{
                        fontSize: "60px",
                        marginTop: "15px",
                        color: "#415781ff",
                      }}
                    />
                  </StyledH4>
                  <StyledH4
                    style={{
                      marginLeft: "15px",
                      marginRight: "15px",
                      fontSize: `${
                        editFileNF.nome.length > 25 ? "10px" : "12px"
                      }`,
                    }}
                  >
                    {editFileNF.nome}
                  </StyledH4>
                  <IconButton
                    onClick={(e) => {
                      setEditFileNF(false);
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <Delete
                      style={{
                        color: "#f00e0e",
                        cursor: "pointer",
                        fontSize: "30px",
                      }}
                    />
                  </IconButton>
                  <IconButton
                    onClick={(e) => {
                      handleDownloadArtefato({
                        artefato: editFileNF.artefato,
                        filename: editFileNF.nome,
                      });
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <GetApp
                      style={{
                        color: "#33c481",
                        cursor: "pointer",
                        fontSize: "30px",
                      }}
                    />
                  </IconButton>
                </>
              </StyledBoxFile>
            )}
            {!editFileNF && (
              <FileInput
                maxFiles={1}
                multiple={false}
                onDrop={(acceptedFiles) => {
                  setFileNF(acceptedFiles);
                  setIsFileNF(true);
                }}
              >
                {isFileNF && (
                  <StyledBoxFile
                    sx={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <>
                      <StyledH4>
                        <InsertDriveFile
                          style={{
                            fontSize: "60px",
                            marginTop: "15px",
                            color: "#415781ff",
                          }}
                        />
                      </StyledH4>
                      <StyledH4
                        style={{
                          marginLeft: "15px",
                          marginRight: "15px",
                          fontSize: `${
                            fileNF[0].name.length > 25 ? "10px" : "12px"
                          }`,
                        }}
                      >
                        {fileNF[0].name}
                        <br />
                        {filesize(fileNF[0].size)}
                      </StyledH4>
                      <IconButton
                        onClick={(e) => {
                          setFileNF([]);
                          setIsFileNF(false);
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <Delete
                          style={{
                            color: "#f00e0e",
                            cursor: "pointer",
                            fontSize: "30px",
                          }}
                        />
                      </IconButton>
                    </>
                  </StyledBoxFile>
                )}
                {!isFileNF && (
                  <StyledBox
                    sx={{
                      cursor: "pointer",
                      width: "100%",
                      height: "100%",
                      "&:hover": {
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                  >
                    <StyledH4 style={{ marginTop: "50px" }}>
                      Arraste o arquivo para cá
                    </StyledH4>
                    <StyledH4>ou clique para selecioná-lo</StyledH4>
                    <StyledH4>
                      <CloudUpload
                        style={{ fontSize: "30px", marginTop: "-5px" }}
                      />
                    </StyledH4>
                  </StyledBox>
                )}
              </FileInput>
            )}
          </StyledFileContainer>
        </StyledCard>
      </StyledGrid>
      <StyledGrid item xs={4}>
        <StyledCard>
          <StyledH2>Boleto</StyledH2>
          <StyledFileContainer>
            {editFileBO && (
              <StyledBoxFile
                sx={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <>
                  <StyledH4>
                    <InsertDriveFile
                      style={{
                        fontSize: "60px",
                        marginTop: "15px",
                        color: "#415781ff",
                      }}
                    />
                  </StyledH4>
                  <StyledH4
                    style={{
                      marginLeft: "15px",
                      marginRight: "15px",
                      fontSize: `${
                        editFileBO.nome.length > 25 ? "10px" : "12px"
                      }`,
                    }}
                  >
                    {editFileBO.nome}
                  </StyledH4>
                  <IconButton
                    onClick={(e) => {
                      setEditFileBO(false);
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <Delete
                      style={{
                        color: "#f00e0e",
                        cursor: "pointer",
                        fontSize: "30px",
                      }}
                    />
                  </IconButton>
                  <IconButton
                    onClick={(e) => {
                      handleDownloadArtefato({
                        artefato: editFileBO.artefato,
                        filename: editFileBO.nome,
                      });
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <GetApp
                      style={{
                        color: "#33c481",
                        cursor: "pointer",
                        fontSize: "30px",
                      }}
                    />
                  </IconButton>
                </>
              </StyledBoxFile>
            )}
            {!editFileBO && (
              <FileInput
                maxFiles={1}
                multiple={false}
                onDrop={(acceptedFiles) => {
                  setFileBO(acceptedFiles);
                  setIsFileBO(true);
                }}
              >
                {isFileBO && (
                  <StyledBoxFile
                    sx={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <>
                      <StyledH4>
                        <InsertDriveFile
                          style={{
                            fontSize: "60px",
                            marginTop: "15px",
                            color: "#415781ff",
                          }}
                        />
                      </StyledH4>
                      <StyledH4
                        style={{
                          marginLeft: "15px",
                          marginRight: "15px",
                          fontSize: `${
                            fileBO[0].name.length > 25 ? "10px" : "12px"
                          }`,
                        }}
                      >
                        {fileBO[0].name}
                        <br />
                        {filesize(fileBO[0].size)}
                      </StyledH4>
                      <IconButton
                        onClick={(e) => {
                          setFileBO([]);
                          setIsFileBO(false);
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <Delete
                          style={{
                            color: "#f00e0e",
                            cursor: "pointer",
                            fontSize: "30px",
                          }}
                        />
                      </IconButton>
                    </>
                  </StyledBoxFile>
                )}
                {!isFileBO && (
                  <StyledBox
                    sx={{
                      cursor: "pointer",
                      width: "100%",
                      height: "100%",
                      "&:hover": {
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                  >
                    <StyledH4 style={{ marginTop: "50px" }}>
                      Arraste o arquivo para cá
                    </StyledH4>
                    <StyledH4>ou clique para selecioná-lo</StyledH4>
                    <StyledH4>
                      <CloudUpload
                        style={{ fontSize: "30px", marginTop: "-5px" }}
                      />
                    </StyledH4>
                  </StyledBox>
                )}
              </FileInput>
            )}
          </StyledFileContainer>
        </StyledCard>
      </StyledGrid>
      <StyledGrid item xs={4}>
        <StyledCard>
          <StyledH2>Fatura</StyledH2>
          <StyledFileContainer>
            {editFileFA && (
              <StyledBoxFile
                sx={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <>
                  <StyledH4>
                    <InsertDriveFile
                      style={{
                        fontSize: "60px",
                        marginTop: "15px",
                        color: "#415781ff",
                      }}
                    />
                  </StyledH4>
                  <StyledH4
                    style={{
                      marginLeft: "15px",
                      marginRight: "15px",
                      fontSize: `${
                        editFileFA.nome.length > 25 ? "10px" : "12px"
                      }`,
                    }}
                  >
                    {editFileFA.nome}
                  </StyledH4>
                  <IconButton
                    onClick={(e) => {
                      setEditFileFA(false);
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <Delete
                      style={{
                        color: "#f00e0e",
                        cursor: "pointer",
                        fontSize: "30px",
                      }}
                    />
                  </IconButton>
                  <IconButton
                    onClick={(e) => {
                      handleDownloadArtefato({
                        artefato: editFileFA.artefato,
                        filename: editFileFA.nome,
                      });
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <GetApp
                      style={{
                        color: "#33c481",
                        cursor: "pointer",
                        fontSize: "30px",
                      }}
                    />
                  </IconButton>
                </>
              </StyledBoxFile>
            )}
            {!editFileFA && (
              <FileInput
                maxFiles={1}
                multiple={false}
                onDrop={(acceptedFiles) => {
                  setFileFA(acceptedFiles);
                  setIsFileFA(true);
                }}
              >
                {isFileFA && (
                  <StyledBoxFile
                    sx={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <>
                      <StyledH4>
                        <InsertDriveFile
                          style={{
                            fontSize: "60px",
                            marginTop: "15px",
                            color: "#415781ff",
                          }}
                        />
                      </StyledH4>
                      <StyledH4
                        style={{
                          marginLeft: "15px",
                          marginRight: "15px",
                          fontSize: `${
                            fileFA[0].name.length > 25 ? "10px" : "12px"
                          }`,
                        }}
                      >
                        {fileFA[0].name}
                        <br />
                        {filesize(fileFA[0].size)}
                      </StyledH4>
                      <IconButton
                        onClick={(e) => {
                          setFileFA([]);
                          setIsFileFA(false);
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <Delete
                          style={{
                            color: "#f00e0e",
                            cursor: "pointer",
                            fontSize: "30px",
                          }}
                        />
                      </IconButton>
                    </>
                  </StyledBoxFile>
                )}
                {!isFileFA && (
                  <StyledBox
                    sx={{
                      cursor: "pointer",
                      width: "100%",
                      height: "100%",
                      "&:hover": {
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                  >
                    <StyledH4 style={{ marginTop: "50px" }}>
                      Arraste o arquivo para cá
                    </StyledH4>
                    <StyledH4>ou clique para selecioná-lo</StyledH4>
                    <StyledH4>
                      <CloudUpload
                        style={{ fontSize: "30px", marginTop: "-5px" }}
                      />
                    </StyledH4>
                  </StyledBox>
                )}
              </FileInput>
            )}
          </StyledFileContainer>
        </StyledCard>
      </StyledGrid>
      <StyledGrid item xs={6}>
        <StyledCard>
          <StyledH2>Comprovante de Pagamento</StyledH2>
          <StyledFileContainer>
            {editFileCP && (
              <StyledBoxFile
                sx={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <>
                  <StyledH4>
                    <InsertDriveFile
                      style={{
                        fontSize: "60px",
                        marginTop: "15px",
                        color: "#415781ff",
                      }}
                    />
                  </StyledH4>
                  <StyledH4
                    style={{
                      marginLeft: "15px",
                      marginRight: "15px",
                      fontSize: `${
                        editFileCP.nome.length > 25 ? "10px" : "12px"
                      }`,
                    }}
                  >
                    {editFileCP.nome}
                  </StyledH4>
                  <IconButton
                    onClick={(e) => {
                      setEditFileCP(false);
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <Delete
                      style={{
                        color: "#f00e0e",
                        cursor: "pointer",
                        fontSize: "30px",
                      }}
                    />
                  </IconButton>
                  <IconButton
                    onClick={(e) => {
                      handleDownloadArtefato({
                        artefato: editFileCP.artefato,
                        filename: editFileCP.nome,
                      });

                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <GetApp
                      style={{
                        color: "#33c481",
                        cursor: "pointer",
                        fontSize: "30px",
                      }}
                    />
                  </IconButton>
                </>
              </StyledBoxFile>
            )}
            {!editFileCP && (
              <FileInput
                maxFiles={1}
                multiple={false}
                onDrop={(acceptedFiles) => {
                  setFileCP(acceptedFiles);
                  setIsFileCP(true);
                }}
              >
                {isFileCP && (
                  <StyledBoxFile
                    sx={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <>
                      <StyledH4>
                        <InsertDriveFile
                          style={{
                            fontSize: "60px",
                            marginTop: "15px",
                            color: "#415781ff",
                          }}
                        />
                      </StyledH4>
                      <StyledH4
                        style={{
                          marginLeft: "15px",
                          marginRight: "15px",
                          fontSize: `${
                            fileCP[0].name.length > 25 ? "10px" : "12px"
                          }`,
                        }}
                      >
                        {fileCP[0].name}
                        <br />
                        {filesize(fileCP[0].size)}
                      </StyledH4>
                      <IconButton
                        onClick={(e) => {
                          setFileCP([]);
                          setIsFileCP(false);
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <Delete
                          style={{
                            color: "#f00e0e",
                            cursor: "pointer",
                            fontSize: "30px",
                          }}
                        />
                      </IconButton>
                    </>
                  </StyledBoxFile>
                )}
                {!isFileCP && (
                  <StyledBox
                    sx={{
                      cursor: "pointer",
                      width: "100%",
                      height: "100%",
                      "&:hover": {
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                  >
                    <StyledH4 style={{ marginTop: "50px" }}>
                      Arraste o arquivo para cá
                    </StyledH4>
                    <StyledH4>ou clique para selecioná-lo</StyledH4>
                    <StyledH4>
                      <CloudUpload
                        style={{ fontSize: "30px", marginTop: "-5px" }}
                      />
                    </StyledH4>
                  </StyledBox>
                )}
              </FileInput>
            )}
          </StyledFileContainer>
        </StyledCard>
      </StyledGrid>
      <OutrosDocumentosConnect
        handleDownloadArtefato={handleDownloadArtefato}
        setIsFileOD={setIsFileOD}
        isFileOD={isFileOD}
        fileOD={fileOD}
        setFileOD={setFileOD}
        arrayFileOD={arrayFileOD}
        setArrayFileOD={setArrayFileOD}
      />
    </StyledGridContainer>
  );
};

export default FormDocumentosRateio;
