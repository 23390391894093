import BasePage from "../BasePage";
import React, { useState } from "react";
import { styled } from "@mui/material";
import { Grid } from "@mui/material";
import CardFila from "./CardFila";
import Spinner from "../../components/spinner/Spinner";
import OutlinedButton from "../../components/buttons/OutlinedButton";
import { FilterChip, Pagination } from "../../components";

const StyledH1 = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.blueTitle};
  letter-spacing: 4.8px;
  font-size: 45px;
  margin-bottom: 0px;
  @media (max-width: 900px) {
    font-size: 22px;
  }
`;

const StyledH4 = styled("h4")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  color: ${({ theme }) => theme.colors.subtitle};
  letter-spacing: 4.8px;
  margin-bottom: 15px;
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 20px;
  margin-top: 0px;

  display: flex;
  align-items: flex-end;
  letter-spacing: 0.25px;

  @media (max-width: 900px) {
    font-size: 22px;
  }
`;

const StyledContainer = styled(Grid)`
  margin-top: 1px;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 900px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;
const StyledBasePage = styled(BasePage)`
  padding: 3px;
`;

const FilasPage = ({
  data,
  nomeCliente,
  filtrarFilas,
  loading,
  handleClickEdit,
  handleClickVoltar,
  clientesDomain,
  seguradorasDomain,
  formProps,
  count,
  page,
  handleClickEditDocumetos,
  handleClickDownload,
  handlePagination,
  filtrarFila,
}) => {
  const [filtro, setFiltro] = useState({
    novo: true,
    aprovado: true,
    enviado: true,
  });

  const handleFiltro = (tipo) => {
    switch (tipo) {
      case "novo":
        setFiltro({
          ...filtro,
          novo: !filtro.novo,
        });
        filtrarFila({ ...filtro, novo: !filtro.novo });
        break;
      case "aprovado":
        setFiltro({
          ...filtro,
          aprovado: !filtro.aprovado,
        });
        filtrarFila({ ...filtro, aprovado: !filtro.aprovado });
        break;
      case "enviado":
        setFiltro({
          ...filtro,
          enviado: !filtro.enviado,
        });
        filtrarFila({ ...filtro, enviado: !filtro.enviado });
        break;
      default:
        break;
    }
  };

  return (
    <StyledBasePage isLoggedIn={true} isLightBlue={true}>
      <form onSubmit={formProps.handleSubmit(filtrarFilas)}>
        <StyledContainer container spacing={2}>
          <Grid item xs={12}>
            <StyledH1>Fila de Reajustes</StyledH1>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: "0px" }}>
            <StyledH4>{nomeCliente}</StyledH4>
          </Grid>
          {!loading && (
            <>
              <Grid item xs={12}>
                <form
                  onSubmit={formProps.handleSubmit((values) =>
                    handleFiltro(values, filtro)
                  )}
                  style={{ width: "100%" }}
                >
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={8}
                      style={{ alignItems: "center", display: "flex" }}
                    >
                      <FilterChip
                        active={filtro.novo}
                        label={"NOVO"}
                        onClick={() => {
                          handleFiltro("novo");
                        }}
                      />
                      <FilterChip
                        active={filtro.aprovado}
                        label={"APROVADO"}
                        onClick={() => handleFiltro("aprovado")}
                      />
                      <FilterChip
                        active={filtro.enviado}
                        label={"ENVIADO"}
                        onClick={() => handleFiltro("enviado")}
                      />
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </>
          )}
          {!!loading && (
            <Grid item xs={12}>
              <Spinner />
            </Grid>
          )}
          {!loading &&
            data?.length > 0 &&
            data?.map((item, index) => {
              return (
                <Grid
                  key={index}
                  item
                  xs={12}
                  md={4}
                  style={{ marginTop: "10px" }}
                >
                  <CardFila
                    handleClickEdit={handleClickEdit}
                    item={item}
                    handleClickEditDocumetos={handleClickEditDocumetos}
                    handleClickDownload={handleClickDownload}
                  />
                </Grid>
              );
            })}
          {!loading && data?.length === 0 && (
            <Grid item xs={12}>
              Não existem reajustes cadastrados na base
            </Grid>
          )}
        </StyledContainer>
        <StyledContainer
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
        >
          <Grid item xs={12}>
            <Pagination
              count={Math.ceil(count / 9)}
              page={page}
              onChange={handlePagination}
            />
          </Grid>
        </StyledContainer>
        <StyledContainer
          container
          spacing={2}
          direction="row"
          justifyContent="center"
        >
          <Grid item xs={4} style={{ marginTop: "20px" }}>
            <OutlinedButton onClick={handleClickVoltar}>Voltar</OutlinedButton>
          </Grid>
        </StyledContainer>
      </form>
    </StyledBasePage>
  );
};

export default FilasPage;
