import FaturamentoPage from "./FaturamentosPage";
import { useSelector, useDispatch } from "react-redux";
import {
  actions as routeActions,
  types as routes,
} from "../../reducers/rotas.actions";
import { useCallback, useEffect } from "react";
import { getFaturamentos } from "../../selectors/faturamento.selectors";
import { getPayload } from "../../selectors/routes.selectors";
import { actions } from "../../reducers/faturamento.actions";
import { useForm } from "react-hook-form";
import validators from "../../utils/validators";
import {
  getClientes,
  getSeguradoras,
} from "../../selectors/dominios.selectors";
import { useState } from "react";

const FaturamentoPageConnected = () => {
  const dispatch = useDispatch();
  const {
    loading,
    data,
    nomeCliente,
    page,
    count,
    saude,
    odonto,
    vida,
    competenciaInicial,
    competenciaFinal,
  } = useSelector(getFaturamentos);
  const { idCliente, subestipulante } = useSelector(getPayload);
  const { data: dataSeguradoras } = useSelector(getSeguradoras);
  const { data: dataClientes } = useSelector(getClientes);
  const [update, setUpdate] = useState(0);
  const filtros = useSelector(getFaturamentos);

  const handleClickVoltar = useCallback(
    () => {
      if (subestipulante) {
        dispatch(
          routeActions.redirectTo(routes.EDIT_CLIENTE, {
            id: subestipulante,
            tab: 1,
          })
        );
      } else {
        dispatch(routeActions.redirectTo(routes.CLIENTES));
      }
    },
    //eslint-disable-next-line
    [dispatch]
  );

  const handleDownloadArtefato = useCallback(
    ({ tipo, idReferencia, tipoReferencia }) => {
      dispatch(
        actions.baixarArtefato.request({ tipo, idReferencia, tipoReferencia })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (update !== 0) {
      dispatch(
        actions.obterFaturamentos.request({
          filtro: {
            saude,
            odonto,
            vida,
            competenciaInicial,
            competenciaFinal,
            cliente: idCliente,
          },
          cliente: idCliente,
          page,
        })
      );
    }
    // eslint-disable-next-line
  }, [update]);

  const filtrarFaturamento = useCallback(
    (values) => {
      dispatch(
        actions.obterFaturamentos.request({
          filtro: {
            saude,
            odonto,
            vida,
            competenciaInicial: values.competenciaInicial,
            competenciaFinal: values.competenciaFinal,
            cliente: idCliente,
          },
          cliente: idCliente,
          page,
        })
      );
    },
    // eslint-disable-next-line
    [dispatch, idCliente]
  );

  const initialValues = {
    cliente: idCliente,
    seguradora: "",
    competencia: "",
  };

  const rules = {
    competenciaInicial: validators.string({ required: false }),
    competenciaFinal: validators.string({ required: false }),
  };

  const formProps = {
    ...useForm({ defaultValues: initialValues }),
    rules,
  };

  useEffect(() => {
    formProps.reset({ ...filtros });
    // eslint-disable-next-line
  }, [filtros]);

  return (
    <FaturamentoPage
      loading={loading}
      data={data}
      nomeCliente={nomeCliente}
      filtrarFaturamento={filtrarFaturamento}
      handleClickVoltar={handleClickVoltar}
      formProps={formProps}
      clientesDomain={dataClientes}
      seguradorasDomain={dataSeguradoras}
      page={page}
      count={count}
      handleDownloadArtefato={handleDownloadArtefato}
      filtros={filtros}
      saude={saude}
      odonto={odonto}
      vida={vida}
      update={update}
      setUpdate={setUpdate}
    />
  );
};

export default FaturamentoPageConnected;
