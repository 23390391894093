import DependentesPage from "./DependentesPage";
import { getDependentes } from "../../../../selectors/beneficiario.selectors";
import {
  getCurrentRoute,
  getPayload,
} from "../../../../selectors/routes.selectors";
import { useSelector, useDispatch } from "react-redux";
import {
  actions as routeActions,
  types as routes,
} from "../../../../reducers/rotas.actions";
import { actions } from "../../../../reducers/beneficiario.actions";
import { useCallback } from "react";

const DependentesPageConnected = (editavel) => {
  const dispatch = useDispatch();
  const dependentes = useSelector(getDependentes);
  const payload = useSelector(getPayload);
  const location = useSelector(getCurrentRoute);

  const handleNewDependente = useCallback(() => {
    if (location === "@@route/MOSTRAR_ATUALIZACAO") {
      dispatch(
        routeActions.redirectTo(routes.NEW_ATUALIZACAO_DEPENDENTE, {
          idBeneficiario: payload.idBeneficiario,
          idCliente: payload.idCliente,
        })
      );
    } else {
      dispatch(
        routeActions.redirectTo(routes.NEW_DEPENDENTE, {
          idBeneficiario: payload.idBeneficiario,
          idCliente: payload.idCliente,
        })
      );
    }
  }, [dispatch, payload, location]);

  const handleEditDependente = useCallback(
    (id, tab) => {
      dispatch(
        routeActions.redirectTo(routes.EDIT_DEPENDENTE, {
          idBeneficiario: payload.idBeneficiario,
          idDependente: id,
          idCliente: payload.idCliente,
          tab,
        })
      );
      //eslint-disable-next-line
    },
    //eslint-disable-next-line
    [dispatch]
  );

  const handleDeleteDependente = useCallback(
    (id) => {
      dispatch(actions.deletarBeneficiario.request(id));
    },
    [dispatch]
  );

  const handleAtivarDependente = useCallback(
    (id) => {
      dispatch(actions.ativarBeneficiario.request(id));
    },
    [dispatch]
  );

  return (
    <DependentesPage
      data={dependentes}
      editavel={editavel}
      handleNewDependente={handleNewDependente}
      handleEditDependente={handleEditDependente}
      handleDeleteDependente={handleDeleteDependente}
      handleAtivarDependente={handleAtivarDependente}
    />
  );
};

export default DependentesPageConnected;
