import { createAsyncAction } from "../utils/actionCreators";

export const types = {
  OBTER_FILAS: "@@rateio/OBTER_FILAS",
  MOSTRAR_FILA: "@@rateio/MOSTRAR_FILA",
  EDITAR_FILA: "@@rateio/EDITAR_FILA",
  CRIAR_FILA: "@@rateio/CRIAR_FILA",
  DELETAR_FILA: "@@rateio/DELETAR_FILA",
  ATIVAR_FILA: "@@rateio/ATIVAR_FILA",
  OBTER_VALORES: "@@rateio/OBTER_VALORES",
  BAIXAR_ARTEFATO: "@@rateio/BAIXAR_ARTEFATO",
  OBTER_FILA_OUTROS: "@@rateio/OBTER_FILA_OUTROS",
  CRIAR_FILA_OUTROS: "@@rateio/CRIAR_FILA_OUTROS",
  EDITAR_FILA_OUTROS: "@@rateio/EDITAR_FILA_OUTROS",
  DELETAR_FILA_OUTROS: "@@rateio/DELETAR_FILA_OUTROS",
  UPLOAD_FILA_DOCUMENTOS: "@@rateio/UPLOAD_FILA_DOCUMENTOS",
  OBTER_FILA_BENEFICIARIOS: "@@rateio/OBTER_FILA_BENEFICIARIOS",
  EDITAR_FILA_BENEFICIARIOS: "@@rateio/EDITAR_FILA_BENEFICIARIOS",
  DOWNLOAD_FILA: "@@rateio/DOWNLOAD_FILA",
  ATUALIZAR_SITUACAO: "@@rateio/ATUALIZAR_SITUACAO",
  APROVAR_SITUACAO: "@@rateio/APROVAR_SITUACAO",
};

export const actions = {
  obterFilas: createAsyncAction(types.OBTER_FILAS),
  mostrarFila: createAsyncAction(types.MOSTRAR_FILA),
  editarFila: createAsyncAction(types.EDITAR_FILA),
  criarFila: createAsyncAction(types.CRIAR_FILA),
  deletarFila: createAsyncAction(types.DELETAR_FILA),
  ativarFila: createAsyncAction(types.ATIVAR_FILA),
  obterValores: createAsyncAction(types.OBTER_VALORES),
  baixarArtefato: createAsyncAction(types.BAIXAR_ARTEFATO),
  obterFilaOutros: createAsyncAction(types.OBTER_FILA_OUTROS),
  criarFilaOutros: createAsyncAction(types.CRIAR_FILA_OUTROS),
  deletarFilaOutros: createAsyncAction(types.DELETAR_FILA_OUTROS),
  editarFilaOutros: createAsyncAction(types.EDITAR_FILA_OUTROS),
  uploadFilaDocumentos: createAsyncAction(types.UPLOAD_FILA_DOCUMENTOS),
  obterFilaBeneficiarios: createAsyncAction(types.OBTER_FILA_BENEFICIARIOS),
  editarFilaBeneficiarios: createAsyncAction(types.EDITAR_FILA_BENEFICIARIOS),
  downloadFila: createAsyncAction(types.DOWNLOAD_FILA),
  atualizarSituacao: createAsyncAction(types.ATUALIZAR_SITUACAO),
  aprovarSituacao: createAsyncAction(types.APROVAR_SITUACAO),
};
