import React from "react";
import {
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { DeleteSharp, Edit } from "@mui/icons-material";
import formatters from "../../../utils/formatters";

const StyledTable = styled(Table)`
  max-height: 10px !important;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const StyledTableRow = styled(TableRow)`
  padding: 0px;
  height: 35px;
  &:nth-of-type(odd) {
    background-color: #415781ff;
    max-height: 10px;
    color: #ffffff !important;
  }
  &:nth-of-type(even) {
    background-color: #a3abc0;
    max-height: 10px;
  }
`;
const StyledTableCell = styled(TableCell)`
  color: inherit;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  border: none !important;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
`;
const StyledFirstTableCell = styled(TableCell)`
  color: inherit;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  font-family: Open Sans;
  border: none !important;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
`;
const StyledButtonTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-family: nunito;
  border: none !important;
  justify-contend: flex-end;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
`;

const StyledTitleTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
  font-family: nunito;
  border: none !important;
  color: #ffdbce !important;
`;

const TabelaCriarPrecificacao = ({
  data,
  handleEdit,
  handleDelete,
  handleDeletePrecificacao,
  apolice,
}) => {
  if (data.length === 0) {
    return <></>;
  }

  return (
    <>
      <StyledTable>
        <TableBody>
          <StyledTableRow>
            <StyledTitleTableCell>Plano</StyledTitleTableCell>
            <StyledTitleTableCell>Acomodação</StyledTitleTableCell>
            <StyledTitleTableCell>Coparticipação</StyledTitleTableCell>
            <StyledTitleTableCell>
              0-18
              <br />
              39-43
            </StyledTitleTableCell>
            <StyledTitleTableCell>
              19-23
              <br />
              44-48
            </StyledTitleTableCell>
            <StyledTitleTableCell>
              24-28
              <br />
              49-53
            </StyledTitleTableCell>
            <StyledTitleTableCell>
              29-33
              <br />
              54-58
            </StyledTitleTableCell>
            <StyledTitleTableCell>
              34-38
              <br />
              59+
            </StyledTitleTableCell>
            <StyledTitleTableCell></StyledTitleTableCell>
          </StyledTableRow>
          {data.length > 0 &&
            data.map((item, index) => {
              const handleDelete = () => {
                handleDeletePrecificacao(item);
              };
              return (
                <StyledTableRow key={index}>
                  <StyledFirstTableCell>{item.plano}</StyledFirstTableCell>
                  <StyledTableCell>
                    {item.tipoAcomodacao === "A" ? "Apartamento" : "Enfermaria"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {item.tipoCoparticipacao ? "Sim" : "Não"}
                  </StyledTableCell>
                  <StyledTableCell>
                    R$ {formatters.numbers.currency(item.valor00a18)}
                    <br />
                    R$ {formatters.numbers.currency(item.valor39a43)}
                  </StyledTableCell>
                  <StyledTableCell>
                    R$ {formatters.numbers.currency(item.valor19a23)}
                    <br />
                    R$ {formatters.numbers.currency(item.valor44a48)}
                  </StyledTableCell>
                  <StyledTableCell>
                    R$ {formatters.numbers.currency(item.valor24a28)}
                    <br />
                    R$ {formatters.numbers.currency(item.valor49a53)}
                  </StyledTableCell>
                  <StyledTableCell>
                    R$ {formatters.numbers.currency(item.valor29a33)}
                    <br />
                    R$ {formatters.numbers.currency(item.valor54a58)}
                  </StyledTableCell>
                  <StyledTableCell>
                    R$ {formatters.numbers.currency(item.valor34a38)}
                    <br />
                    R$ {formatters.numbers.currency(item.valor59a99)}{" "}
                  </StyledTableCell>
                  <StyledButtonTableCell align="right">
                    <IconButton
                      onClick={() => handleEdit(item)}
                      disabled={apolice?.situacao === "I"}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      onClick={handleDelete}
                      disabled={apolice?.situacao === "I"}
                    >
                      <DeleteSharp />
                    </IconButton>
                  </StyledButtonTableCell>
                </StyledTableRow>
              );
            })}
        </TableBody>
      </StyledTable>
      {/* <PosicaoDialog
        open={open}
        handleClose={handleClose}
        posicoes={dialogList}
      /> */}
    </>
  );
};

export default TabelaCriarPrecificacao;
