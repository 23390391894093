import React, { PureComponent } from "react";
import { MenuItem, styled } from "@mui/material";

const StyledMenuItem = styled(MenuItem)`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.inputs.title};
`;

export default class SelectItem extends PureComponent {
  render() {
    return (
      <StyledMenuItem {...this.props} classes={{ root: "submenu-item" }}>
        {this.props.children}
      </StyledMenuItem>
    );
  }
}