import { format as formatCpf } from "@fnando/cpf";
import { format as formatCnpj } from "@fnando/cnpj";
import { padHour } from "./basic";

// numbers
const simpleNumber = (number, options) => {
  if (number !== undefined && !isNaN(number)) {
    return Number(number).toLocaleString("pt-BR", options);
  }
  return "";
};
const nFixed = (number, fixed) => {
  return simpleNumber(number, {
    minimumFractionDigits: fixed,
    maximumFractionDigits: fixed,
  });
};
const currency = (number) => {
  return nFixed(number, 2);
};
const percent = (number) => {
  return nFixed(number, 4);
};
// dates

const simpleDateDisplay = (date, options) => {
  let newDate = new Date(date).setDate(new Date(date).getDate() + 1);

  return new Date(newDate)
    .toLocaleDateString("pt-BR", options)
    .replace("erça", "erca")
    .replace("ábado", "abado")
    .replace("arço", "arco")
    .replace(/[^ -~/]/g, "")
    .replace("abado", "ábado")
    .replace("erca", "erça")
    .replace("arco", "arço");
};

const simpleDate = (date, options) => {
  return date
    .toLocaleDateString("pt-BR", options)
    .replace("erça", "erca")
    .replace("ábado", "abado")
    .replace("arço", "arco")
    .replace(/[^ -~/]/g, "")
    .replace("abado", "ábado")
    .replace("erca", "erça")
    .replace("arco", "arço");
};
const iso = (date) => {
  return date.toISOString().slice(0, 10);
};
const short = (date) => {
  return simpleDate(date);
};
const long = (date) => {
  return simpleDate(date, {
    weekday: "long",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
  }).replace(/ de /g, "/");
};
const longMonth = (date) => {
  const d = simpleDate(date, { year: "numeric", month: "long" });
  return d.charAt(0).toUpperCase() + d.slice(1);
};
const justMonth = (date, type) => {
  return simpleDate(date, { month: type });
};
const withHours = (date) => {
  if (!date) return "";
  return simpleDate(date, {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });
};

const withHoursNoSpace = (date) => {
  if (!date) return "";
  const newDate = new Date(date);
  return `${padHour(newDate.getDate())}${padHour(
    newDate.getMonth() + 1
  )}${newDate.getFullYear()}${padHour(newDate.getHours())}${padHour(
    newDate.getMinutes()
  )}${padHour(newDate.getSeconds())}`;
};

// strings
const capitalizeFirstLetter = (text) => {
  return text.slice(0, 1).toUpperCase() + text.slice(1);
};
const zipCode = (text) => {
  const full = ("00000000000000" + text).slice(-8);
  return full.slice(0, 5) + "-" + full.slice(5);
};
const cpf = (text) => {
  return formatCpf(("00000000000000" + text).slice(-11));
};
const cnpj = (text) => {
  return formatCnpj(("00000000000000" + text).slice(-14));
};
const cpfCnpj = (text, tipo) => {
  if (tipo === "F") {
    return cpf(text);
  }
  if (tipo === "J") {
    return cnpj(text);
  }
  return "";
};
const formatters = {
  numbers: {
    simple: simpleNumber,
    currency,
    percent,
    nFixed,
  },
  dates: {
    simple: simpleDate,
    short,
    long,
    justMonth,
    iso,
    longMonth,
    withHours,
    withHoursNoSpace,
    simpleDateDisplay,
  },
  strings: {
    capitalizeFirstLetter,
    cpf,
    cnpj,
    cpfCnpj,
    zipCode,
  },
};

export default formatters;
