import { useForm } from "react-hook-form";
import validators from "../../../utils/validators";
import FormBeneficiarioPage from "./FormCriarBeneficiarioPage";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getBeneficiario } from "../../../selectors/beneficiario.selectors";
import { getPayload } from "../../../selectors/routes.selectors";
import { actions } from "../../../reducers/beneficiario.actions";
import { toast } from "react-toastify";
import { removeNonDigitsFromString } from "../../../utils/basic";
import { getPerfil } from "../../../selectors/usuario.selectors";
import moment from "moment";
import { getCliente } from "../../../selectors/cliente.selectors";

const FormBeneficiarioConnected = () => {
  const { loading, data } = useSelector(getBeneficiario);
  const [isFileDPS, setIsFileDPS] = useState(false);
  const [fileDPS, setFileDPS] = useState([]);
  const [isFileCTPS, setIsFileCTPS] = useState(false);
  const [fileCTPS, setFileCTPS] = useState([]);
  const [isFileFR, setIsFileFR] = useState(false);
  const [fileFR, setFileFR] = useState([]);
  const [isFileDP, setIsFileDP] = useState(false);
  const [fileDP, setFileDP] = useState([]);
  const [planos, setPlanos] = useState([]);
  const [buttonTitle, setButtonTitle] = useState("Incluir");
  const payload = useSelector(getPayload);
  const perfil = useSelector(getPerfil);
  const [isFileOD, setIsFileOD] = useState(false);
  const [fileOD, setFileOD] = useState([]);
  const [arrayFileOD, setArrayFileOD] = useState([]);
  const { apolices } = useSelector(getCliente);

  const dispatch = useDispatch();

  const rules = {
    cep: validators.number({ required: false }),
    uf: validators.string({ required: false }),
    complemento: validators.string({ required: false }),
    logradouro: validators.string({ required: false }),
    bairro: validators.string({ required: false }),
    nomeBeneficiario: validators.string({ required: true }),
    nomeMunicipio: validators.string({ required: false }),
    cpf: validators.cpf({
      custom: {
        obrigatorio: (value) => {
          let tipo = formProps.getValues("relacaoDependencia");

          if (!tipo) {
            if (value === "") return "Campo obrigatório";
          }
        },
      },
    }),
    numeroLogradouro: validators.string({ required: false }),
    centroCusto: validators.string({ required: false }),
    descricaoCentroCusto: validators.string({ required: false }),
    dataAdmissao: validators.date({ required: false }),
    cargo: validators.string({ required: false }),
    pis: validators.string({ required: false }),
    cns: validators.string({ required: false }),
    dataNascimento: validators.date({ required: false }),
    genero: validators.string({ required: true }),
    matricula: validators.string({ required: false }),
    nomeMae: validators.string({ required: false }),
    emailContato: validators.email({
      custom: {
        obrigatorio: (value) => {
          let tipo = formProps.getValues("relacaoDependencia");

          if (!tipo) {
            if (value === "") return "Campo obrigatório";
          }
        },
      },
    }),
    telefoneContato: validators.phone({ required: false }),
  };

  const initialValues = {
    ...data,
  };

  const formProps = {
    ...useForm({ defaultValues: initialValues }),
    rules,
  };

  useEffect(() => {
    if (!!data) {
      formProps.reset(data);
    }
    if (data?.BeneficiarioApolice?.length > 0) {
      setPlanos(
        data.BeneficiarioApolice.map((i) => {
          return {
            ...i,
            apolice: i.ClienteApoliceValor.clienteApolice,
            clienteApoliceValor: i.clienteApoliceValor,
            numero: "",
            valorPremioSeguro: "",
            dataVigencia: "",
            valorCapitalSegurado: "",
            ClienteApolice: i.ClienteApoliceValor.ClienteApolice,
          };
        }).filter((i) => i.ClienteApolice.tipo === "S")
      );
      //terminar o map do beneficiario, para retornar os campos necessários na lista
    }
    // eslint-disable-next-line
  }, [data]);

  const handleSubmit = (values) => {
    let { nomeBeneficiario } = values;

    if (!nomeBeneficiario) {
      toast.error(
        "Favor preencher todos os campos obrigatórios, incluíndo a associação a ao menos uma apólice.",
        {
          theme: "colored",
          icon: false,
          style: { backgroundColor: "#990a26" },
        }
      );
    } else if (planos.length === 0) {
      toast.error("Favor incluir ao menos uma apólice ao beneficiário.", {
        theme: "colored",
        icon: false,
        style: { backgroundColor: "#990a26" },
      });
    } else {
      const request = {
        nomeBeneficiario: values.nomeBeneficiario,
        centroCusto: values.centroCusto,
        descricaoCentroCusto: values.descricaoCentroCusto,
        dataAdmissao: values.dataAdmissao,
        cargo: values.cargo,
        pis: values.pis,
        cns: values.cns,
        beneficiarioPrincipal: values.beneficiarioPrincipal,
        dataNascimento: values.dataNascimento,
        genero: values.genero,
        relacaoDependencia: values.relacaoDependencia,
        matricula: values.matricula,
        nomeMae: values.nomeMae,
        cliente: payload.idCliente,
        emailContato: values.emailContato,
        cep: removeNonDigitsFromString(values.cep),
        uf: values.uf,
        complemento: values.complemento,
        logradouro: values.logradouro,
        bairro: values.bairro,
        nomeMunicipio: values.nomeMunicipio,
        cpf: removeNonDigitsFromString(values.cpf),
        numeroLogradouro: values.numeroLogradouro,
        telefoneContato: removeNonDigitsFromString(values.telefoneContato),
        situacao: data.situacao,
        manutencao: data.manutencao,
        planos,
        fileDPS,
        fileCTPS,
        fileFR,
        fileDP,
        fileOD,
        isRateio: payload.isRateio,
        idRateio: payload.idRateio,
      };
      dispatch(actions.criarBeneficiario.request(request));
    }
  };

  const clearValores = () => {
    formProps.setValue("apolice", "");
    formProps.setValue("plano", "");
    formProps.setValue("numero", "");
    formProps.setValue("valorPremioSeguro", "");
    formProps.setValue("dataVigencia", "");
    formProps.setValue("valorCapitalSegurado", "");
    setButtonTitle("Incluir");
  };

  const handleAddPrecificacao = () => {
    const result = {
      apolice: formProps.getValues("apolice"),
      clienteApoliceValor: formProps.getValues("plano"),
      numero: formProps.getValues("numero"),
      valorPremioSeguro: formProps.getValues("valorPremioSeguro"),
      dataVigencia: formProps.getValues("dataVigencia"),
      valorCapitalSegurado: formProps.getValues("valorCapitalSegurado"),
    };

    if (!result.apolice) {
      toast.error("O campo apólice é obrigatório.", {
        theme: "colored",
        icon: false,
        style: { backgroundColor: "#990a26" },
      });
    } else if (!result.clienteApoliceValor) {
      toast.error("O campo plano é obrigatório.", {
        theme: "colored",
        icon: false,
        style: { backgroundColor: "#990a26" },
      });
    } else if (!result.valorPremioSeguro) {
      toast.error("O campo valor do Prêmio de Seguro é obrigatório.", {
        theme: "colored",
        icon: false,
        style: { backgroundColor: "#990a26" },
      });
    } else if (!result.dataVigencia) {
      toast.error("O campo Data Início de Vigência é obrigatório.", {
        theme: "colored",
        icon: false,
        style: { backgroundColor: "#990a26" },
      });
    } else {
      let existe = planos.find(
        (el) => el.clienteApoliceValor === result.clienteApoliceValor
      );
      console.log(result);
      if (existe && buttonTitle !== "Salvar") {
        toast.error("O plano já se encontra na lista.", {
          theme: "colored",
          icon: false,
          style: { backgroundColor: "#990a26" },
        });
      } else if (existe && buttonTitle === "Salvar") {
        setPlanos([
          ...planos.filter(
            (item) => item.clienteApoliceValor !== result.clienteApoliceValor
          ),
          result,
        ]);
        clearValores();
      } else {
        setPlanos([...planos, result]);
        clearValores();
      }
    }
  };

  const preencherValorPremioPorPlano = (value) => {
    formProps.setValue("valorCapitalSegurado", "");
    const a = moment(formProps.getValues("dataNascimento"));
    const b = moment();

    if (a.isValid()) {
      const idade = b.diff(a, "years");

      const apolice = apolices.data.filter(
        (a) => a.id === formProps.getValues("apolice")
      )[0];

      switch (true) {
        case idade < 18:
          formProps.setValue(
            "valorPremioSeguro",
            apolice.ClienteApoliceValors.filter(
              (a) => formProps.getValues("plano") === a.id
            )[0].valor00a18.replace(".", ",")
          );
          break;
        case idade >= 19 && idade <= 23:
          formProps.setValue(
            "valorPremioSeguro",
            apolice.ClienteApoliceValors.filter(
              (a) => formProps.getValues("plano") === a.id
            )[0].valor19a23.replace(".", ",")
          );
          break;
        case idade >= 24 && idade <= 28:
          formProps.setValue(
            "valorPremioSeguro",
            apolice.ClienteApoliceValors.filter(
              (a) => formProps.getValues("plano") === a.id
            )[0].valor24a28.replace(".", ",")
          );
          break;
        case idade >= 29 && idade <= 33:
          formProps.setValue(
            "valorPremioSeguro",
            apolice.ClienteApoliceValors.filter(
              (a) => formProps.getValues("plano") === a.id
            )[0].valor29a33.replace(".", ",")
          );
          break;
        case idade >= 34 && idade <= 38:
          formProps.setValue(
            "valorPremioSeguro",
            apolice.ClienteApoliceValors.filter(
              (a) => formProps.getValues("plano") === a.id
            )[0].valor34a38.replace(".", ",")
          );
          break;
        case idade >= 39 && idade <= 43:
          formProps.setValue(
            "valorPremioSeguro",
            apolice.ClienteApoliceValors.filter(
              (a) => formProps.getValues("plano") === a.id
            )[0].valor39a43.replace(".", ",")
          );
          break;
        case idade >= 44 && idade <= 48:
          formProps.setValue(
            "valorPremioSeguro",
            apolice.ClienteApoliceValors.filter(
              (a) => formProps.getValues("plano") === a.id
            )[0].valor44a48.replace(".", ",")
          );
          break;
        case idade >= 49 && idade <= 53:
          formProps.setValue(
            "valorPremioSeguro",
            apolice.ClienteApoliceValors.filter(
              (a) => formProps.getValues("plano") === a.id
            )[0].valor49a53.replace(".", ",")
          );
          break;
        case idade >= 54 && idade <= 58:
          formProps.setValue(
            "valorPremioSeguro",
            apolice.ClienteApoliceValors.filter(
              (a) => formProps.getValues("plano") === a.id
            )[0].valor54a58.replace(".", ",")
          );
          break;
        default:
          formProps.setValue(
            "valorPremioSeguro",
            apolice.ClienteApoliceValors.filter(
              (a) => formProps.getValues("plano") === a.id
            )[0].valor59a99.replace(".", ",")
          );
          break;
      }
    }
  };

  const handleDeletePlano = (values) => {
    setPlanos(planos.filter((item) => item !== values));
  };

  const preencherValorPremio = (dataNascimento) => {
    const a = moment(dataNascimento);
    const b = moment();

    if (a.isValid()) {
      const idade = b.diff(a, "years");
      setPlanos(
        planos.map((i) => {
          const apolice = apolices.data.filter((a) => a.id === i.apolice)[0];

          switch (true) {
            case idade < 18:
              return {
                ...i,
                valorPremioSeguro: apolice.ClienteApoliceValors.filter(
                  (a) => i.clienteApoliceValor === a.id
                )[0].valor00a18,
              };
            case idade >= 19 && idade <= 23:
              return {
                ...i,
                valorPremioSeguro: apolice.ClienteApoliceValors.filter(
                  (a) => i.clienteApoliceValor === a.id
                )[0].valor19a23,
              };
            case idade >= 24 && idade <= 28:
              return {
                ...i,
                valorPremioSeguro: apolice.ClienteApoliceValors.filter(
                  (a) => i.clienteApoliceValor === a.id
                )[0].valor24a28,
              };
            case idade >= 29 && idade <= 33:
              return {
                ...i,
                valorPremioSeguro: apolice.ClienteApoliceValors.filter(
                  (a) => i.clienteApoliceValor === a.id
                )[0].valor29a33,
              };
            case idade >= 34 && idade <= 38:
              return {
                ...i,
                valorPremioSeguro: apolice.ClienteApoliceValors.filter(
                  (a) => i.clienteApoliceValor === a.id
                )[0].valor34a38,
              };
            case idade >= 39 && idade <= 43:
              return {
                ...i,
                valorPremioSeguro: apolice.ClienteApoliceValors.filter(
                  (a) => i.clienteApoliceValor === a.id
                )[0].valor39a43,
              };
            case idade >= 44 && idade <= 48:
              return {
                ...i,
                valorPremioSeguro: apolice.ClienteApoliceValors.filter(
                  (a) => i.clienteApoliceValor === a.id
                )[0].valor44a48,
              };
            case idade >= 49 && idade <= 53:
              return {
                ...i,
                valorPremioSeguro: apolice.ClienteApoliceValors.filter(
                  (a) => i.clienteApoliceValor === a.id
                )[0].valor49a53,
              };
            case idade >= 54 && idade <= 58:
              return {
                ...i,
                valorPremioSeguro: apolice.ClienteApoliceValors.filter(
                  (a) => i.clienteApoliceValor === a.id
                )[0].valor54a58,
              };
            default:
              return {
                ...i,
                valorPremioSeguro: apolice.ClienteApoliceValors.filter(
                  (a) => i.clienteApoliceValor === a.id
                )[0].valor59a99,
              };
          }
        })
      );
    }
  };

  return (
    <FormBeneficiarioPage
      loading={loading}
      formProps={formProps}
      handleSubmit={handleSubmit}
      handleAddPrecificacao={handleAddPrecificacao}
      buttonTitle={buttonTitle}
      setButtonTitle={setButtonTitle}
      planos={planos}
      handleDeletePlano={handleDeletePlano}
      payload={payload}
      isFileDPS={isFileDPS}
      setIsFileDPS={setIsFileDPS}
      fileDPS={fileDPS}
      setFileDPS={setFileDPS}
      isFileCTPS={isFileCTPS}
      setIsFileCTPS={setIsFileCTPS}
      fileCTPS={fileCTPS}
      setFileCTPS={setFileCTPS}
      isFileFR={isFileFR}
      setIsFileFR={setIsFileFR}
      fileFR={fileFR}
      setFileFR={setFileFR}
      isFileDP={isFileDP}
      setIsFileDP={setIsFileDP}
      fileDP={fileDP}
      setFileDP={setFileDP}
      perfil={perfil}
      editavel={true}
      isFileOD={isFileOD}
      setIsFileOD={setIsFileOD}
      fileOD={fileOD}
      setFileOD={setFileOD}
      setArrayFileOD={setArrayFileOD}
      arrayFileOD={arrayFileOD}
      preencherValorPremio={preencherValorPremio}
      preencherValorPremioPorPlano={preencherValorPremioPorPlano}
    />
  );
};

export default FormBeneficiarioConnected;
