import { useForm } from "react-hook-form";
import validators from "../../../utils/validators";
import FormEditarSinistroPage from "./FormEditarSinistroPage";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { getPayload } from "../../../selectors/routes.selectors";
import { actions } from "../../../reducers/sinistro.actions";
import {
  getSinistro,
  getSinistroBeneficiarios,
  getSinistroOutros,
  getSinistroSituacao,
  getSinistros,
  getBreakeven,
} from "../../../selectors/sinistro.selectors";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";
import formatters from "../../../utils/formatters";

const FormEditarSinistroPageConnected = () => {
  const { data: dataSinistroOutros } = useSelector(getSinistroOutros);
  const {
    data: dataSinistroBeneficiarios,
    planos: planosBeneficiarios,
  } = useSelector(getSinistroBeneficiarios);
  const cliente = useSelector(getPayload);
  const dispatch = useDispatch();
  const [isFileAS, setIsFileAS] = useState(false);
  const [editFileAS, setEditFileAS] = useState(false);
  const [fileAS, setFileAS] = useState([]);
  const { data: sinistro, loading: loadingSinistro } = useSelector(getSinistro);
  const { data: situacao } = useSelector(getSinistroSituacao);
  const listaSinistros = useSelector(getSinistros);
  const breakeven = useSelector(getBreakeven);
  const [existeIndice, setExisteIndice] = useState(false);

  const rules = {
    dataCompetencia: validators.string({ required: true }),
    valorOutros: validators.number({ required: true }),
    tipoValor: validators.string({ required: true }),
    descricao: validators.string({ required: true }),
    observacao: validators.string({ required: false }),
    indice: validators.number({ required: false }),
  };

  const initialValues = {
    dataMesReferencia: "",
    valorSinistro: "0,00",
    valorReceitaLiquida: "0,00",
    apolice: "",
    indice: "",
    reajuste: "",
  };

  const formProps = {
    ...useForm({ defaultValues: initialValues }),
    rules,
  };

  const handleSubmit = (values) => {
    let request = {
      ...values,
      existeIndice,
      editFileAS,
      fileAS,
    };
    dispatch(actions.editarSinistro.request(request));
  };

  const handleDeleteSinistroOutros = (id) => {
    dispatch(actions.deletarSinistroOutros.request({ id }));
  };

  const handleClickVoltar = useCallback(
    () => {
      dispatch(
        routeActions.redirectTo(routes.SINISTROS, {
          idCliente: listaSinistros.cliente,
        })
      );
    },
    //eslint-disable-next-line
    [dispatch, listaSinistros]
  );

  useEffect(() => {
    if (sinistro) {
      if (sinistro?.indice) {
        setExisteIndice(true);
      }
      formProps.reset({
        ...sinistro,
        valorSinistro: Number(sinistro.valorSinistro),
        valorReceitaLiquida: Number(sinistro.valorReceitaLiquida),
        dataMesReferencia: formatters.dates.simpleDateDisplay(
          new Date(sinistro.dataMesReferencia)
        ),
        indice: Number(sinistro?.indice?.valor) ?? "",
        reajuste: sinistro?.reajuste ?? "",
      });

      if (sinistro?.documentoAS) {
        setEditFileAS(sinistro?.documentoAS);
      } else {
        setEditFileAS();
      }
    }
    //eslint-disable-next-line
  }, [sinistro]);

  const handleDownloadArtefato = (values) => {
    dispatch(
      actions.baixarArtefato.request({
        artefato: values.artefato,
        filename: values.filename,
      })
    );
  };

  const handleIncluirBeneficiario = useCallback(
    () => {
      dispatch(
        routeActions.redirectTo(routes.NEW_BENEFICIARIO, {
          idCliente: sinistro.Cliente.id,
          idSinistro: cliente.idSinistro,
        })
      );
    },
    //eslint-disable-next-line
    [dispatch, sinistro]
  );

  const handleAprovar = (values) => {
    dispatch(
      actions.aprovarSituacao.request({
        situacao: "A",
        id: cliente.idSinistro,
      })
    );
  };

  return (
    <FormEditarSinistroPage
      formProps={formProps}
      handleSubmit={handleSubmit}
      isFileAS={isFileAS}
      setIsFileAS={setIsFileAS}
      setFileAS={setFileAS}
      fileAS={fileAS}
      editFileAS={editFileAS}
      setEditFileAS={setEditFileAS}
      dataSinistroOutros={dataSinistroOutros}
      handleDeleteSinistroOutros={handleDeleteSinistroOutros}
      sinistro={sinistro}
      loading={loadingSinistro}
      handleClickVoltar={handleClickVoltar}
      handleDownloadArtefato={handleDownloadArtefato}
      isDocumento={cliente.isDocumento}
      dataSinistroBeneficiarios={dataSinistroBeneficiarios}
      planosBeneficiarios={planosBeneficiarios}
      handleIncluirBeneficiario={handleIncluirBeneficiario}
      situacao={situacao}
      handleAprovar={handleAprovar}
      indice={!!sinistro.indice}
      indiceValor={sinistro.indice}
      breakeven={breakeven}
    />
  );
};

export default FormEditarSinistroPageConnected;
