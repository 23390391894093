import React from "react";
import NumberFormat from "react-number-format";

const CurrencyTextField = React.forwardRef(({ onChange, prefix = "R$ ", ...other }, ref) => {
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.floatValue,
          },
        });
      }}
      thousandSeparator={"."}
      decimalSeparator={","}
      decimalScale={2}
      fixedDecimalScale={true}
      prefix={prefix}
    />
  );
})

export default CurrencyTextField;
