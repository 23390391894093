import React from "react";
import { Box, Card, IconButton, styled } from "@mui/material";
import { Grid } from "@mui/material";
import {
  CloudUpload,
  Delete,
  GetApp,
  InsertDriveFile,
} from "@mui/icons-material";
import formatters from "../../../utils/formatters";
import FileInput from "../../../components/inputs/FileInput";
import OutrosDocumentosConnect from "../../outrosDocumentos/OutrosDocumentos.connect";

const StyledCard = styled(Card)`
  background-color: white;
  width: 100%;
  margin: auto;
  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  box-shadow: 0px 0px !important;
  border: 1.5px solid #b4cfe7ff;
  margin-bottom: 0px;
`;

const StyledGrid = styled(Grid)`
  padding: 0px;
`;

const StyledGridContainer = styled(Grid)`
  margin-top: 0px;
  padding-left: 25px;
  margin-bottom: 20px;
  @media (max-width: 900px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const StyledH2 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.blueTitle};
  letter-spacing: 1.8px;
  font-size: 20px;
  margin-left: 15px;
  margin-bottom: -10px;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
`;

const StyledH4 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  letter-spacing: 1.2px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 12px;
  }
`;

const StyledBox = styled(Box)`
  width: 100% !important;
  height: 200px !important;
  text-align: center;
  background: repeating-linear-gradient(
    135deg,
    #ffffffff,
    #ffffffff 30px,
    #ebf2f8ff 30px,
    #ebf2f8ff 60px
  );

  border: 2px dashed #b4cfe7ff;
  border-radius: 15px;
`;
const StyledBoxFile = styled(Box)`
  width: 100% !important;
  height: 200px !important;
  text-align: center;
  background-color: #ebf2f8ff;

  border: 2px solid #b4cfe7ff;
  border-radius: 15px;
`;

const StyledFileContainer = styled(Box)`
  padding: 20px;
`;

const filesize = (size) => {
  if (size > 1024 * 1024) {
    // Mb
    return `${formatters.numbers.nFixed(
      Math.round(size / (1024 * 1024), 1),
      1
    )} Mb`;
  }
  if (size > 1024) {
    // Mb
    return `${formatters.numbers.nFixed(size / 1024, 1)} Kb`;
  }
  return `${size} bytes`;
};

const FormDocumentosBeneficiario = ({
  formProps,
  isFileDPS,
  setIsFileDPS,
  fileDPS,
  setFileDPS,
  editFileDPS,
  setEditFileDPS,
  isFileCTPS,
  setIsFileCTPS,
  fileCTPS,
  setFileCTPS,
  editFileCTPS,
  setEditFileCTPS,
  isFileFR,
  setIsFileFR,
  fileFR,
  setFileFR,
  editFileFR,
  setEditFileFR,
  isFileDP,
  setIsFileDP,
  fileDP,
  setFileDP,
  editFileDP,
  setEditFileDP,
  handleDownloadArtefato,
  setIsFileOD,
  isFileOD,
  fileOD,
  setFileOD,
  arrayFileOD,
  setArrayFileOD,
}) => {
  return (
    <StyledGridContainer container spacing={2}>
      <StyledGrid item xs={4}>
        <StyledCard>
          <StyledH2>DPS</StyledH2>
          <StyledFileContainer>
            {editFileDPS && (
              <StyledBoxFile
                sx={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <>
                  <StyledH4>
                    <InsertDriveFile
                      style={{
                        fontSize: "60px",
                        marginTop: "15px",
                        color: "#415781ff",
                      }}
                    />
                  </StyledH4>
                  <StyledH4
                    style={{
                      marginLeft: "15px",
                      marginRight: "15px",
                      fontSize: `${
                        editFileDPS.name.length > 25 ? "10px" : "12px"
                      }`,
                    }}
                  >
                    {editFileDPS.name}
                  </StyledH4>
                  <IconButton
                    onClick={(e) => {
                      setEditFileDPS(false);
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <Delete
                      style={{
                        color: "#f00e0e",
                        cursor: "pointer",
                        fontSize: "30px",
                      }}
                    />
                  </IconButton>
                  <IconButton
                    onClick={(e) => {
                      handleDownloadArtefato({
                        artefato: editFileDPS.artefato,
                        filename: editFileDPS.name,
                      });
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <GetApp
                      style={{
                        color: "#33c481",
                        cursor: "pointer",
                        fontSize: "30px",
                      }}
                    />
                  </IconButton>
                </>
              </StyledBoxFile>
            )}
            {!editFileDPS && (
              <FileInput
                name={"fileSeguradoras"}
                formProps={formProps}
                maxFiles={1}
                accept={".pdf"}
                multiple={false}
                onDrop={(acceptedFiles) => {
                  setFileDPS(acceptedFiles);
                  setIsFileDPS(true);
                }}
              >
                {isFileDPS && (
                  <StyledBoxFile
                    sx={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <>
                      <StyledH4>
                        <InsertDriveFile
                          style={{
                            fontSize: "60px",
                            marginTop: "15px",
                            color: "#415781ff",
                          }}
                        />
                      </StyledH4>
                      <StyledH4
                        style={{
                          marginLeft: "15px",
                          marginRight: "15px",
                          fontSize: `${
                            fileDPS[0].name.length > 25 ? "10px" : "12px"
                          }`,
                        }}
                      >
                        {fileDPS[0].name}
                        <br />
                        {filesize(fileDPS[0].size)}
                      </StyledH4>
                      <IconButton
                        onClick={(e) => {
                          setFileDPS([]);
                          setIsFileDPS(false);
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <Delete
                          style={{
                            color: "#f00e0e",
                            cursor: "pointer",
                            fontSize: "30px",
                          }}
                        />
                      </IconButton>
                    </>
                  </StyledBoxFile>
                )}
                {!isFileDPS && (
                  <StyledBox
                    sx={{
                      cursor: "pointer",
                      width: "100%",
                      height: "100%",
                      "&:hover": {
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                  >
                    <StyledH4 style={{ marginTop: "50px" }}>
                      Arraste o arquivo para cá
                    </StyledH4>
                    <StyledH4>ou clique para selecioná-lo</StyledH4>
                    <StyledH4>
                      <CloudUpload
                        style={{ fontSize: "30px", marginTop: "-5px" }}
                      />
                    </StyledH4>
                  </StyledBox>
                )}
              </FileInput>
            )}
          </StyledFileContainer>
        </StyledCard>
      </StyledGrid>
      <StyledGrid item xs={4}>
        <StyledCard>
          <StyledH2>CTPS</StyledH2>
          <StyledFileContainer>
            {editFileCTPS && (
              <StyledBoxFile
                sx={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <>
                  <StyledH4>
                    <InsertDriveFile
                      style={{
                        fontSize: "60px",
                        marginTop: "15px",
                        color: "#415781ff",
                      }}
                    />
                  </StyledH4>
                  <StyledH4
                    style={{
                      marginLeft: "15px",
                      marginRight: "15px",
                      fontSize: `${
                        editFileCTPS.name.length > 25 ? "10px" : "12px"
                      }`,
                    }}
                  >
                    {editFileCTPS.name}
                  </StyledH4>
                  <IconButton
                    onClick={(e) => {
                      setEditFileCTPS(false);
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <Delete
                      style={{
                        color: "#f00e0e",
                        cursor: "pointer",
                        fontSize: "30px",
                      }}
                    />
                  </IconButton>
                  <IconButton
                    onClick={(e) => {
                      handleDownloadArtefato({
                        artefato: editFileCTPS.artefato,
                        filename: editFileCTPS.name,
                      });

                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <GetApp
                      style={{
                        color: "#33c481",
                        cursor: "pointer",
                        fontSize: "30px",
                      }}
                    />
                  </IconButton>
                </>
              </StyledBoxFile>
            )}
            {!editFileCTPS && (
              <FileInput
                name={"fileSeguradoras"}
                formProps={formProps}
                maxFiles={1}
                accept={".pdf"}
                multiple={false}
                onDrop={(acceptedFiles) => {
                  setFileCTPS(acceptedFiles);
                  setIsFileCTPS(true);
                }}
              >
                {isFileCTPS && (
                  <StyledBoxFile
                    sx={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <>
                      <StyledH4>
                        <InsertDriveFile
                          style={{
                            fontSize: "60px",
                            marginTop: "15px",
                            color: "#415781ff",
                          }}
                        />
                      </StyledH4>
                      <StyledH4
                        style={{
                          marginLeft: "15px",
                          marginRight: "15px",
                          fontSize: `${
                            fileCTPS[0].name.length > 25 ? "10px" : "12px"
                          }`,
                        }}
                      >
                        {fileCTPS[0].name}
                        <br />
                        {filesize(fileCTPS[0].size)}
                      </StyledH4>
                      <IconButton
                        onClick={(e) => {
                          setFileCTPS([]);
                          setIsFileCTPS(false);
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <Delete
                          style={{
                            color: "#f00e0e",
                            cursor: "pointer",
                            fontSize: "30px",
                          }}
                        />
                      </IconButton>
                    </>
                  </StyledBoxFile>
                )}
                {!isFileCTPS && (
                  <StyledBox
                    sx={{
                      cursor: "pointer",
                      width: "100%",
                      height: "100%",
                      "&:hover": {
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                  >
                    <StyledH4 style={{ marginTop: "50px" }}>
                      Arraste o arquivo para cá
                    </StyledH4>
                    <StyledH4>ou clique para selecioná-lo</StyledH4>
                    <StyledH4>
                      <CloudUpload
                        style={{ fontSize: "30px", marginTop: "-5px" }}
                      />
                    </StyledH4>
                  </StyledBox>
                )}
              </FileInput>
            )}
          </StyledFileContainer>
        </StyledCard>
      </StyledGrid>
      <StyledGrid item xs={4}>
        <StyledCard>
          <StyledH2>Ficha de Registro</StyledH2>
          <StyledFileContainer>
            {editFileFR && (
              <StyledBoxFile
                sx={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <>
                  <StyledH4>
                    <InsertDriveFile
                      style={{
                        fontSize: "60px",
                        marginTop: "15px",
                        color: "#415781ff",
                      }}
                    />
                  </StyledH4>
                  <StyledH4
                    style={{
                      marginLeft: "15px",
                      marginRight: "15px",
                      fontSize: `${
                        editFileFR.name.length > 25 ? "10px" : "12px"
                      }`,
                    }}
                  >
                    {editFileFR.name}
                  </StyledH4>
                  <IconButton
                    onClick={(e) => {
                      setEditFileFR(false);
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <Delete
                      style={{
                        color: "#f00e0e",
                        cursor: "pointer",
                        fontSize: "30px",
                      }}
                    />
                  </IconButton>
                  <IconButton
                    onClick={(e) => {
                      handleDownloadArtefato({
                        artefato: editFileFR.artefato,
                        filename: editFileFR.name,
                      });

                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <GetApp
                      style={{
                        color: "#33c481",
                        cursor: "pointer",
                        fontSize: "30px",
                      }}
                    />
                  </IconButton>
                </>
              </StyledBoxFile>
            )}
            {!editFileFR && (
              <FileInput
                name={"fileSeguradoras"}
                formProps={formProps}
                maxFiles={1}
                accept={".pdf"}
                multiple={false}
                onDrop={(acceptedFiles) => {
                  setFileFR(acceptedFiles);
                  setIsFileFR(true);
                }}
              >
                {isFileFR && (
                  <StyledBoxFile
                    sx={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <>
                      <StyledH4>
                        <InsertDriveFile
                          style={{
                            fontSize: "60px",
                            marginTop: "15px",
                            color: "#415781ff",
                          }}
                        />
                      </StyledH4>
                      <StyledH4
                        style={{
                          marginLeft: "15px",
                          marginRight: "15px",
                          fontSize: `${
                            fileFR[0].name.length > 25 ? "10px" : "12px"
                          }`,
                        }}
                      >
                        {fileFR[0].name}
                        <br />
                        {filesize(fileFR[0].size)}
                      </StyledH4>
                      <IconButton
                        onClick={(e) => {
                          setFileFR([]);
                          setIsFileFR(false);
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <Delete
                          style={{
                            color: "#f00e0e",
                            cursor: "pointer",
                            fontSize: "30px",
                          }}
                        />
                      </IconButton>
                    </>
                  </StyledBoxFile>
                )}
                {!isFileFR && (
                  <StyledBox
                    sx={{
                      cursor: "pointer",
                      width: "100%",
                      height: "100%",
                      "&:hover": {
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                  >
                    <StyledH4 style={{ marginTop: "50px" }}>
                      Arraste o arquivo para cá
                    </StyledH4>
                    <StyledH4>ou clique para selecioná-lo</StyledH4>
                    <StyledH4>
                      <CloudUpload
                        style={{ fontSize: "30px", marginTop: "-5px" }}
                      />
                    </StyledH4>
                  </StyledBox>
                )}
              </FileInput>
            )}
          </StyledFileContainer>
        </StyledCard>
      </StyledGrid>
      <StyledGrid item xs={6}>
        <StyledCard>
          <StyledH2>Documento Pessoal (RG, CPF ou CNH)</StyledH2>
          <StyledFileContainer>
            {editFileDP && (
              <StyledBoxFile
                sx={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <>
                  <StyledH4>
                    <InsertDriveFile
                      style={{
                        fontSize: "60px",
                        marginTop: "15px",
                        color: "#415781ff",
                      }}
                    />
                  </StyledH4>
                  <StyledH4
                    style={{
                      marginLeft: "15px",
                      marginRight: "15px",
                      fontSize: `${
                        editFileDP.name.length > 25 ? "10px" : "12px"
                      }`,
                    }}
                  >
                    {editFileDP.name}
                  </StyledH4>
                  <IconButton
                    onClick={(e) => {
                      setEditFileDP(false);
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <Delete
                      style={{
                        color: "#f00e0e",
                        cursor: "pointer",
                        fontSize: "30px",
                      }}
                    />
                  </IconButton>
                  <IconButton
                    onClick={(e) => {
                      handleDownloadArtefato({
                        artefato: editFileDP.artefato,
                        filename: editFileDP.name,
                      });

                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <GetApp
                      style={{
                        color: "#33c481",
                        cursor: "pointer",
                        fontSize: "30px",
                      }}
                    />
                  </IconButton>
                </>
              </StyledBoxFile>
            )}
            {!editFileDP && (
              <FileInput
                name={"fileSeguradoras"}
                formProps={formProps}
                maxFiles={1}
                accept={".pdf"}
                multiple={false}
                onDrop={(acceptedFiles) => {
                  setFileDP(acceptedFiles);
                  setIsFileDP(true);
                }}
              >
                {isFileDP && (
                  <StyledBoxFile
                    sx={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <>
                      <StyledH4>
                        <InsertDriveFile
                          style={{
                            fontSize: "60px",
                            marginTop: "15px",
                            color: "#415781ff",
                          }}
                        />
                      </StyledH4>
                      <StyledH4
                        style={{
                          marginLeft: "15px",
                          marginRight: "15px",
                          fontSize: `${
                            fileDP[0].name.length > 25 ? "10px" : "12px"
                          }`,
                        }}
                      >
                        {fileDP[0].name}
                        <br />
                        {filesize(fileDP[0].size)}
                      </StyledH4>
                      <IconButton
                        onClick={(e) => {
                          setFileDP([]);
                          setIsFileDP(false);
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <Delete
                          style={{
                            color: "#f00e0e",
                            cursor: "pointer",
                            fontSize: "30px",
                          }}
                        />
                      </IconButton>
                    </>
                  </StyledBoxFile>
                )}
                {!isFileDP && (
                  <StyledBox
                    sx={{
                      cursor: "pointer",
                      width: "100%",
                      height: "100%",
                      "&:hover": {
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                  >
                    <StyledH4 style={{ marginTop: "50px" }}>
                      Arraste o arquivo para cá
                    </StyledH4>
                    <StyledH4>ou clique para selecioná-lo</StyledH4>
                    <StyledH4>
                      <CloudUpload
                        style={{ fontSize: "30px", marginTop: "-5px" }}
                      />
                    </StyledH4>
                  </StyledBox>
                )}
              </FileInput>
            )}
          </StyledFileContainer>
        </StyledCard>
      </StyledGrid>
      <OutrosDocumentosConnect
        handleDownloadArtefato={handleDownloadArtefato}
        setIsFileOD={setIsFileOD}
        isFileOD={isFileOD}
        fileOD={fileOD}
        setFileOD={setFileOD}
        arrayFileOD={arrayFileOD}
        setArrayFileOD={setArrayFileOD}
      />
    </StyledGridContainer>
  );
};

export default FormDocumentosBeneficiario;
