import { createSelector } from "reselect";

export const getClienteState = (state) => state.cliente;

export const getClientes = createSelector(
  getClienteState,
  (state) => state.clientes
);

export const getCliente = createSelector(
  getClienteState,
  (state) => state.cliente
);

export const getSubestipulantes = createSelector(
  getClienteState,
  (state) => state.cliente.subestipulantes
);

export const getFiltros = createSelector(getClientes, (state) => state.filtros);
