import { Grid, Tooltip } from "@mui/material";
import { styled } from "@mui/system";
import Card from "../../../../components/Card";
import {
  DeleteOutlined,
  ModeEditOutlined,
  PersonOutline,
  AttachFile,
  EditOffOutlined,
} from "@mui/icons-material";
import formatters from "../../../../utils/formatters";
import { ReactComponent as IconRecover } from "../../../../styles/icons/recover.svg";

const StyledGrid = styled(Grid)`
  text-align: left;
`;

const IconsGrid = styled(Grid)`
  text-align: right;
  margin-top: 20px;
  align-items: center;
`;

const StyledCardActive = styled(Card)`
  height: auto;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 25px;
  background-color: #415781ff;

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const StyledCardInactive = styled(Card)`
  height: auto;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 25px;
  background-color: ${({ theme }) => theme.cards.inactive};

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const CardTitle = styled("div")`
  font-family: nunito;
  font-size: 16px;
  font-weight: 700;
  font-spacing: 0.5px;
  color: #ffb59b;
  
`;

const NomeBeneficiario = styled("div")`
  font-family: nunito;
  font-size: 20px;
  font-weight: 300;
  font-spacing: 0.25px;
  color: #ffffff;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  
`;

const CnpjBeneficiario = styled("div")`
  font-family: nunito;
  font-size: 12px;
  font-weight: 400;
  font-spacing: 0.4px;
  color: #ffffff;
`;

const StyledDeleteOutlined = styled(DeleteOutlined)`
  width: 35px;
  height: 35px;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
  cursor: pointer;
`;

const StyledModeEdit = styled(ModeEditOutlined)`
  width: 35px;
  height: 35px;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
  cursor: pointer;
`;

const StyledPersonOutline = styled(PersonOutline)`
  width: 35px;
  height: 35px;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
  cursor: pointer;
`;

const StyledAttachFile = styled(AttachFile)`
  width: 35px;
  height: 32px;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
  cursor: pointer;
`;

const StyledEditOffOutlined = styled(EditOffOutlined)`
  width: 35px;
  height: 32px;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
  cursor: pointer;
`;

const StyledIconRecover = styled(IconRecover)`
  width: 35px;
  height: 32px;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
  cursor: pointer;
`;

const ProdutoChips = styled("div")`
  font-family: Nunito;
  font-size: 14px;
  font-weight: 500;
  font-spacing: 0.5px;
  color: #fff;
  background-color: #f3622c;
  min-width: 70px;
  border-radius: 25px;
  text-align: center;
  margin: auto;
  margin-top: 0px;
  display: block;
  position: absolute;
  right: 20px;
`;

const CardDependente = ({
  beneficiario,
  handleEditBeneficiario,
  handleOpenExclusaoBeneficiario,
  handleOpenReativacaoBeneficiario,
}) => {
  if (beneficiario.situacao === "A" || beneficiario.situacao === "P")
    return (
      <StyledCardActive key={beneficiario.id}>
        <StyledGrid item xs={12} md={12}>
          <CardTitle>{"Dependente"}</CardTitle>
        </StyledGrid>
        <StyledGrid item xs={12} md={12}>
          <NomeBeneficiario>{beneficiario?.nomeBeneficiario}</NomeBeneficiario>
        </StyledGrid>
        <StyledGrid item xs={12} md={12}>
          <CnpjBeneficiario>
            {formatters.strings.cpf(beneficiario?.cpf)}
          </CnpjBeneficiario>
        </StyledGrid>
        <IconsGrid item xs={12} md={12}>
          <Tooltip title={"Documentos"}>
            <StyledAttachFile
              onClick={() => handleEditBeneficiario(beneficiario.id, 2)}
            />
          </Tooltip>
          <Tooltip title={"Depedentes"}>
            <StyledPersonOutline
              onClick={() => handleEditBeneficiario(beneficiario.id, 1)}
            />
          </Tooltip>
          <Tooltip title={"Editar"}>
            <StyledModeEdit
              onClick={() => handleEditBeneficiario(beneficiario.id, 0)}
            />
          </Tooltip>
          <Tooltip title={"Desativar"}>
            <StyledDeleteOutlined
              onClick={() => handleOpenExclusaoBeneficiario(beneficiario)}
            />
          </Tooltip>
        </IconsGrid>
        {beneficiario.ClienteManutencao?.filter((i) => i.situacao !== "F").map(
          (i, index) => (
            <ProdutoChips style={{ top: 23 + 30 * index }}>
              {i.situacao === "N" ? "Novo" : "Aguardando Conclusão"}
            </ProdutoChips>
          )
        )}
      </StyledCardActive>
    );
  else
    return (
      <StyledCardInactive key={beneficiario.id}>
        <StyledGrid item xs={12} md={12}>
          <CardTitle style={{ color: "#3A3005" }}>Dependente</CardTitle>
        </StyledGrid>
        <StyledGrid item xs={12} md={12}>
          <NomeBeneficiario>{beneficiario.nomeBeneficiario}</NomeBeneficiario>
        </StyledGrid>
        <StyledGrid item xs={12} md={12}>
          <CnpjBeneficiario>
            {formatters.strings.cpf(beneficiario.cpf)}
          </CnpjBeneficiario>
        </StyledGrid>
        <IconsGrid item xs={12} md={12}>
          <StyledEditOffOutlined
            onClick={() => handleEditBeneficiario(beneficiario.id)}
          />
          <StyledIconRecover
            onClick={() => handleOpenReativacaoBeneficiario(beneficiario)}
          />
        </IconsGrid>
      </StyledCardInactive>
    );
};

export default CardDependente;
