import React, { useState } from "react";
import { Card, MenuItem, styled } from "@mui/material";
import { Grid } from "@mui/material";
import {
  ControlledAutocompleteCreate,
  ControlledSelect,
  ControlledTextField,
  CurrencyTextField,
} from "../../../components/inputs";
import CustomControlledSwitch from "../../../components/inputs/CustomControlledSwitch";
import OutlinedButton from "../../../components/buttons/OutlinedButton";
import TabelaCriarPrecificacao from "../tables/TabelaCriarPrecificacao";
import { Dialog } from "../../../components";
import CardVida from "./CardVida";
import CardRegras from "../apolice/CardRegras";

const StyledCard = styled(Card)`
  height: auto;
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 25px;
  background-color: #415781ff;

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const StyledGrid = styled(Grid)`
  padding: 5px;
  place-self: flex-start;
`;

const StyledGridContainer = styled(Grid)`
  margin-bottom: 0px;
`;

const StyledH2 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.white};
  text-align: left;
  letter-spacing: 1.8px;
  font-size: 28px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
`;

const StyledControlledCustomTextField = styled(ControlledTextField)`
  .MuiOutlinedInput-input {
    color-scheme: dark;
  }
`;

const StyledControlledSwitch = styled(CustomControlledSwitch)`
  .css-1fzj3j7-MuiFormControl-root .MuiFormControlLabel-label {
    font-size: 15px !important;
  }
`;

const StyledOutlinedButton = styled(OutlinedButton)`
  border-color: #ffffff;
  color: #ffffff;
  margin-top: 25px;

  &:focus {
    outline: 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.buttons.secondary.backgroundHover};
    border-color: #ffffff;
  }
`;

const FormDadosApolice = ({
  formProps,
  handleAddPrecificacao,
  formPropsPrecificao,
  seguradorasDomain,
  loadingSeguradoras,
  handleLoadPlanos,
  planosDomain,
  handleLoadValores,
  clearValores,
  setPrecificacoes,
  precificacoes,
  buttonTitle,
  handleEdit,
  checked,
  handleDeletePrecificacao,
  lastSeguradora,
  setLastSeguradora,
  apolice,
  isVida,
  setIsVida,
}) => {
  const [open, setOpen] = useState(false);

  const handleChangePrecificacao = (e) => {
    setIsVida(e === "V");
  };

  return (
    <>
      <StyledCard>
        <StyledH2>Identificação</StyledH2>
        <StyledGridContainer container>
          <StyledGrid item xs={12} md={6}>
            <ControlledTextField
              formProps={formProps}
              fullWidth
              name="numero"
              label="Número da Apólice"
              inputProps={{ maxLength: 30 }}
              disabled={apolice?.situacao === "I"}
            />
          </StyledGrid>
          <StyledGrid item xs={12} md={6}>
            <ControlledSelect
              disabled={apolice?.situacao === "I"}
              onChange={(e) => handleChangePrecificacao(e.target.value)}
              formProps={formProps}
              fullWidth
              name="tipo"
              label="Produto"
            >
              <MenuItem key={"D"} value={"D"}>
                Dental
              </MenuItem>
              <MenuItem key={"S"} value={"S"}>
                Saúde
              </MenuItem>
              <MenuItem key={"V"} value={"V"}>
                Vida
              </MenuItem>
              <MenuItem key={""} value={""}></MenuItem>
            </ControlledSelect>
          </StyledGrid>
          <StyledGrid item xs={12} md={3}>
            {!isVida && (
              <ControlledSelect
                name="seguradora"
                disabled={apolice?.situacao === "I"}
                onChange={(e) => {
                  if (lastSeguradora === "") {
                    handleLoadPlanos(e.target.value);
                    setLastSeguradora(e.target.value);
                  } else {
                    if (
                      lastSeguradora !== e.target.value &&
                      precificacoes.length > 0
                    ) {
                      setOpen(true);
                    } else {
                      handleLoadPlanos(e.target.value);
                      setLastSeguradora(e.target.value);
                    }
                  }
                }}
                formProps={formProps}
                label={"Seguradora"}
                margin={"none"}
              >
                {seguradorasDomain.map((item) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={item.ID_SEGURADORA}
                    value={item.ID_SEGURADORA}
                  >
                    {item.NM_SEGURADORA}
                  </MenuItem>
                ))}
                <MenuItem key={""} value={""}></MenuItem>
              </ControlledSelect>
            )}
            {seguradorasDomain && isVida && (
              <ControlledAutocompleteCreate
                formProps={formProps}
                fullWidth
                name="seguradora"
                label="Seguradora"
                labelField={"NM_SEGURADORA"}
                idField={"ID_SEGURADORA"}
                domain={seguradorasDomain}
                inputPropMax={10}
              />
            )}
          </StyledGrid>
          <StyledGrid item xs={12} md={3}>
            <StyledControlledCustomTextField
              formProps={formProps}
              disabled={apolice?.situacao === "I"}
              fullWidth
              name="dataAniversario"
              label="Data de Aniversário"
              type="date"
            />
          </StyledGrid>
          <StyledGrid item xs={12} md={3}>
            <StyledControlledCustomTextField
              formProps={formProps}
              disabled={apolice?.situacao === "I"}
              fullWidth
              name="dataVencimento"
              label="Data Vencimento da Fatura"
              type="date"
            />
          </StyledGrid>
          <StyledGrid item xs={12} md={3}>
            <ControlledTextField
              formProps={formProps}
              fullWidth
              disabled={apolice?.situacao === "I"}
              name="diasCorte"
              label="Dia de Corte"
              type="number"
              InputProps={{ max: 31, min: 1 }}
            />
          </StyledGrid>

          <StyledGrid item xs={12} md={6}>
            <StyledControlledCustomTextField
              disabled={apolice?.situacao === "I"}
              formProps={formProps}
              fullWidth
              name="dataContratacao"
              label="Data Contratação"
              type="date"
            />
          </StyledGrid>
          <StyledGrid item xs={12} md={6}>
            <StyledControlledCustomTextField
              disabled={apolice?.situacao === "I"}
              formProps={formProps}
              fullWidth
              name="dataNegociacao"
              label="Data Negociação"
              type="date"
            />
          </StyledGrid>
        </StyledGridContainer>
      </StyledCard>
      <CardRegras formProps={formProps} disabled={apolice?.situacao === "I"} />
      {!isVida && (
        <StyledCard>
          <StyledH2>Precificação</StyledH2>
          <StyledGridContainer container>
            <StyledGrid item xs={12} md={3}>
              <ControlledSelect
                onChange={() => clearValores()}
                disabled={apolice?.situacao === "I"}
                name="plano"
                formProps={formPropsPrecificao}
                label={"Plano"}
                margin={"none"}
              >
                {planosDomain.map((item) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={item.ID_SEGURADORA_PLANO}
                    value={item.ID_SEGURADORA_PLANO}
                  >
                    {item.NM_PLANO}
                  </MenuItem>
                ))}
                <MenuItem key={""} value={""}></MenuItem>
              </ControlledSelect>
            </StyledGrid>
            <StyledGrid item xs={12} md={2}>
              <ControlledSelect
                onChange={() => clearValores()}
                formProps={formPropsPrecificao}
                disabled={apolice?.situacao === "I"}
                fullWidth
                name="tipoAcomodacao"
                label="Acomodação"
              >
                <MenuItem key={"A"} value={"A"}>
                  Apartamento
                </MenuItem>
                <MenuItem key={"E"} value={"E"}>
                  Enfermaria
                </MenuItem>
              </ControlledSelect>
            </StyledGrid>
            <StyledGrid item xs={12} md={2}>
              <ControlledSelect
                onChange={() => clearValores()}
                formProps={formPropsPrecificao}
                disabled={apolice?.situacao === "I"}
                fullWidth
                name="tipoAdesao"
                label="Adesão"
              >
                <MenuItem key={"C"} value={"C"}>
                  Compulsório
                </MenuItem>
                <MenuItem key={"L"} value={"L"}>
                  Livre
                </MenuItem>
              </ControlledSelect>
            </StyledGrid>
            <StyledGrid item xs={12} md={2}>
              <StyledControlledSwitch
                checked={checked}
                onChange={() => clearValores()}
                formProps={formPropsPrecificao}
                disabled={apolice?.situacao === "I"}
                fullWidth
                name="tipoCoparticipacao"
                label="Com Coparticipação?"
              ></StyledControlledSwitch>
            </StyledGrid>
            <StyledGrid item xs={12} md={1}>
              <StyledControlledCustomTextField
                disabled={apolice?.situacao === "I"}
                onChange={() => clearValores()}
                formProps={formPropsPrecificao}
                fullWidth
                name="porcentagemCoparticipacao"
                label="%"
              />
            </StyledGrid>
            <StyledGrid item xs={12} md={2}>
              <StyledOutlinedButton
                onClick={() => handleLoadValores()}
                disabled={apolice?.situacao === "I"}
              >
                Consultar
              </StyledOutlinedButton>
            </StyledGrid>
            <StyledGrid item xs={12} md={2}>
              <ControlledTextField
                disabled={apolice?.situacao === "I"}
                formProps={formPropsPrecificao}
                fullWidth
                name="valor00a18"
                label="0-18"
                InputProps={{
                  inputComponent: CurrencyTextField,
                }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} md={2}>
              <ControlledTextField
                disabled={apolice?.situacao === "I"}
                formProps={formPropsPrecificao}
                fullWidth
                name="valor19a23"
                label="19-23"
                InputProps={{
                  inputComponent: CurrencyTextField,
                }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} md={2}>
              <ControlledTextField
                disabled={apolice?.situacao === "I"}
                formProps={formPropsPrecificao}
                fullWidth
                name="valor24a28"
                label="24-28"
                InputProps={{
                  inputComponent: CurrencyTextField,
                }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} md={2}>
              <ControlledTextField
                disabled={apolice?.situacao === "I"}
                formProps={formPropsPrecificao}
                fullWidth
                name="valor29a33"
                label="29-33"
                InputProps={{
                  inputComponent: CurrencyTextField,
                }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} md={2}>
              <ControlledTextField
                disabled={apolice?.situacao === "I"}
                formProps={formPropsPrecificao}
                fullWidth
                name="valor34a38"
                label="34-38"
                InputProps={{
                  inputComponent: CurrencyTextField,
                }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} md={2}>
              <ControlledTextField
                disabled={apolice?.situacao === "I"}
                formProps={formPropsPrecificao}
                fullWidth
                name="valor39a43"
                label="39-43"
                InputProps={{
                  inputComponent: CurrencyTextField,
                }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} md={2}>
              <ControlledTextField
                disabled={apolice?.situacao === "I"}
                formProps={formPropsPrecificao}
                fullWidth
                name="valor44a48"
                label="44-48"
                InputProps={{
                  inputComponent: CurrencyTextField,
                }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} md={2}>
              <ControlledTextField
                disabled={apolice?.situacao === "I"}
                formProps={formPropsPrecificao}
                fullWidth
                name="valor49a53"
                label="49-53"
                InputProps={{
                  inputComponent: CurrencyTextField,
                }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} md={2}>
              <ControlledTextField
                disabled={apolice?.situacao === "I"}
                formProps={formPropsPrecificao}
                fullWidth
                name="valor54a58"
                label="54-58"
                InputProps={{
                  inputComponent: CurrencyTextField,
                }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} md={2}>
              <ControlledTextField
                disabled={apolice?.situacao === "I"}
                formProps={formPropsPrecificao}
                fullWidth
                name="valor59a99"
                label="59+"
                InputProps={{
                  inputComponent: CurrencyTextField,
                }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} md={4}>
              <StyledOutlinedButton
                onClick={() => handleAddPrecificacao()}
                disabled={apolice?.situacao === "I"}
              >
                {buttonTitle}
              </StyledOutlinedButton>
            </StyledGrid>
          </StyledGridContainer>
          <TabelaCriarPrecificacao
            handleDeletePrecificacao={handleDeletePrecificacao}
            handleEdit={handleEdit}
            data={precificacoes}
            apolice={apolice}
          />
        </StyledCard>
      )}
      {isVida && (
        <CardVida
          formProps={formProps}
          clearValores={clearValores}
          apolice={apolice}
        />
      )}
      <Dialog
        open={open}
        title={"Mudar de seguradora"}
        actionTrue={() => {
          setPrecificacoes([]);
          clearValores();
          handleLoadPlanos(formProps.getValues("seguradora"));
        }}
        actionFalse={() => {
          formProps.setValue("seguradora", lastSeguradora);
        }}
        setOpen={setOpen}
        text={
          "Ao mudar de seguradora você perderá as precificações referentes ao plano da seguradora atual. Deseja continuar com a ação?"
        }
      />
    </>
  );
};

export default FormDadosApolice;
