import React from "react";
import { styled, TextField as MTextField } from "@mui/material";

const StyledTextField = styled(MTextField)`
  margin-top: 20px;

  .MuiInputLabel-root {
    background-color: #415781ff;
    color: #ffffff !important;

    padding: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #fff !important;
  }

  & .MuiFormHelperText-root.Mui-error {
    color: #f0e500ff !important;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #ffffff;
    }
    ,
    &:hover fieldset {
      border-color: #ffffff;
    }
    ,
    &.Mui-focused fieldset {
      border-color: #ffffff;
    }
    ,
    &.Mui-error fieldset {
      border: 2px solid #f0e500ff;
    }
  }

  & .MuiFormLabel-root {
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-size: ${({ theme }) => theme.fonts.size.inputs.title};
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white};
    letter-spacing: 1.4px;
  }
  & .MuiFormLabel-root.Mui-focused {
    color: ${({ theme }) => theme.colors.white};
  }
  & .MuiInputBase-root {
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-size: ${({ theme }) => theme.fonts.size.inputs.content};
    font-weight: 400;
    text-align: left;
    border-radius: 15px;
    color: ${({ theme }) => theme.colors.white};
  }
  & .MuiFilledInput-underline:after {
    border-bottom: 2px solid ${({ theme }) => theme.colors.gray["800"]};
  }
  & .Mui-error:after {
    border-bottom: 2px solid ${({ theme }) => theme.colors.error};
  }
  & .MuiFormLabel-root.Mui-error {
    color: ${({ theme }) => theme.colors.error};
  }
  & .MuiFormHelperText-root.Mui-error {
    color: ${({ theme }) => theme.colors.error};
  }
  & .MuiOutlinedInput-notchedOutline {
    border-bottom-color: #9fb3d9;
    border-left-color: #9fb3d9;
    border-right-color: #9fb3d9;
    border-top-color: #9fb3d9;
  }
  & .MuiSelect-icon {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const TextField = ({
  shrinked = true,
  fullWidth = true,
  InputLabelProps,
  ...otherProps
}) => (
  <StyledTextField
    InputLabelProps={{
      shrink: shrinked,
      ...InputLabelProps,
    }}
    fullWidth={fullWidth}
    {...otherProps}
  />
);

export default TextField;
