import BasePage from "../../BasePage";
import React from "react";
import { Skeleton, styled } from "@mui/material";
import { Grid } from "@mui/material";
import CardClienteIdentificacao from "../cliente/CardClienteIdentificacao";
import CardEndereco from "../cliente/CardEndereco";
import CardContato from "../cliente/CardContato";
import CardUsuario from "../cliente/CardUsuario";

const StyledContainer = styled(Grid)`
  margin-top: 10px;
  padding-left: 25px;
  @media (max-width: 900px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;
const StyledBasePage = styled(BasePage)`
  padding: 3px;
`;

const StyledGrid = styled(Grid)`
  padding: 5px !important;

  .MuiGrid-item {
    padding-top: 5px;
  }
`;

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 500px;
  margin-bottom: -65px;
`;

const FormClientePage = ({ loading, formProps, disabled }) => {
  if (loading) {
    return (
      <StyledBasePage isLoggedIn={true} isLightBlue={true}>
        <StyledSkeleton />
      </StyledBasePage>
    );
  }

  return (
    <StyledContainer container spacing={2}>
      <StyledGrid item xs={12}>
        <CardClienteIdentificacao formProps={formProps} disabled={disabled} />
      </StyledGrid>
      <StyledGrid item xs={12}>
        <CardContato formProps={formProps} disabled={disabled} />
      </StyledGrid>
      <StyledGrid item xs={12}>
        <CardEndereco formProps={formProps} disabled={disabled} />
      </StyledGrid>
      <StyledGrid item xs={12}>
        <CardUsuario formProps={formProps} disabled={disabled} />
      </StyledGrid>
    </StyledContainer>
  );
};

export default FormClientePage;
