import { actions, types } from "./faturamento.actions";

const initialState = {
  faturamentos: {
    loading: false,
    page: 1,
    limite: 9,
    count: 0,
    saude: true,
    odonto: true,
    vida: true,
    cliente: "",
    competenciaInicial: "",
    competenciaFinal: "",
    data: [],
  },
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.obterFaturamentos.REQUEST:
    case actions.obterFaturamentos.SUCCESS:
    case actions.obterFaturamentos.FAILURE:
      return {
        ...state,
        faturamentos: {
          ...state.faturamentos,
          loading: action.type === actions.obterFaturamentos.REQUEST,
          data:
            action.type === actions.obterFaturamentos.SUCCESS
              ? action.payload.response.data.resultado
              : [],
          cliente:
            action.type === actions.obterFaturamentos.SUCCESS
              ? action.payload.response.data.filtro.cliente ?? ""
              : [],
          saude:
            action.type === actions.obterFaturamentos.SUCCESS
              ? action.payload.response.data.filtro.saude ?? true
              : [],
          vida:
            action.type === actions.obterFaturamentos.SUCCESS
              ? action.payload.response.data.filtro.vida ?? true
              : [],
          odonto:
            action.type === actions.obterFaturamentos.SUCCESS
              ? action.payload.response.data.filtro.odonto ?? true
              : [],
          competenciaInicial:
            action.type === actions.obterFaturamentos.SUCCESS
              ? action.payload.response.data.filtro.competenciaInicial ?? ""
              : [],
          competenciaFinal:
            action.type === actions.obterFaturamentos.SUCCESS
              ? action.payload.response.data.filtro.competenciaFinal ?? ""
              : [],
          page:
            action.type === actions.obterFaturamentos.SUCCESS
              ? action.payload.response.data.paginaAtual
              : [],
          count:
            action.type === actions.obterFaturamentos.SUCCESS
              ? action.payload.response.data.total
              : [],
        },
      };
    case types.MUDAR_FILTRO:
      return {
        ...state,
        faturamentos: {
          ...state.faturamentos,
          saude: action.payload.saude,
          vida: action.payload.vida,
          odonto: action.payload.odonto ?? "",
          competenciaInicial: action.payload.competenciaInicial,
          competenciaFinal: action.payload.competenciaFinal,
          cliente: action.payload.cliente,
        },
      };
    default:
      return state;
  }
};

export default reducer;
