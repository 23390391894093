import React from "react";
import {
  Card,
  IconButton,
  Skeleton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Grid,
  InputAdornment,
} from "@mui/material";
import { useDispatch } from "react-redux";

import {
  actions as routeActions,
  types as routes,
} from "../../reducers/rotas.actions";
import { DeleteOutline, Edit, Add, Search } from "@mui/icons-material";
import Button from "../../components/buttons/PrimaryButton";
import { actions } from "../../reducers/usuarioAdm.actions";
import { TextField } from "../../components/inputs";

const StyledH2 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: ${({ theme }) => theme.colors.primary};
  text-align: left;
  letter-spacing: 2px;
  font-size: 20px;
  margin: 25px;
  margin-left: 0px;
  margin-top: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
`;

const StyledCard = styled(Card)`
  background-color: white;
  height: auto;
  width: 100%;
  margin: auto;
  margin-top: -45px;
  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-left: 15px;
  box-shadow: 0px 0px !important;
  border: 1.5px solid #b4cfe7ff;
  padding: 25px;
  @media (max-width: 900px) {
    width: 100wh;
    margin: 0px;
  }
`;

const StyledTable = styled(Table)`
  max-height: 10px !important;
  margin-bottom: 20px;
`;

const StyledTableRow = styled(TableRow)`
  padding: 0px;
  height: 35px;
  &:nth-of-type(odd) {
    background-color: #b4cfe7ff;
    max-height: 10px;
    color: #ffffff !important;
  }
`;
const StyledTableCell = styled(TableCell)`
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 16px;
  font-family: Nunito;
  border-bottom: none !important;
`;
const StyledTableNomeCell = styled(TableCell)`
  padding-top: 0px;
  padding-bottom: 0px;
  min-width: 250px;
  font-size: 16px;
  font-family: Nunito;
  border-bottom: none !important;
`;

const StyledTableCellTitle = styled(TableCell)`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.primary};
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 18px;

  border-bottom: none !important;
`;

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 700px;
  margin-left: 15px;
  margin-top: -200px;
`;

const ButtonContainer = styled("div")`
  display: flex !important;
  justify-content: flex-end !important;

  @media (max-width: 900px) {
    justify-content: center !important;
  }
`;

const StyledButton = styled(Button)`
  width: 200px !important;
  @media (max-width: 900px) {
    margin-right: 0px;
  }
`;

const StyledTextField = styled(TextField)`
    background-color: #ffffff;
    margin-top:4px;
    flex: 1!important;
    font-family: ${({ theme }) => theme.fonts.family.subTitle};
    color: ${({ theme }) => theme.colors.primary};
    font-size: 17px;
    margin-bottom:15px;

    @media (max-width: 900px) {
      margin-bottom:15px;
      margin-top:5px;
    }

    & .MuiInputBase-input{
      font-family: ${({ theme }) => theme.fonts.family.subTitle};
      color: ${({ theme }) => theme.colors.primary};
      font-size: 17px;
      }


  & .MuiInputLabel-root {
    background-color: #ffffff;
    color: #849dce !important;

    padding: 8px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  & .MuiFormHelperText-root.Mui-error {
    color: #D11F44!important;

  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #849dce;
    },
    &:hover fieldset {
      border-color: #849dce;
    },
    &.Mui-focused fieldset {
      border: 3px solid #849dce;
    },
    &.Mui-error fieldset {
    border: 2.5px solid #D11F44;
    },`;

const TabelaUsuarios = ({
  data,
  handlePagination,
  tituloPeriodo,
  loading,
  handleSearch,
  filtro,
  setFiltro,
}) => {
  const dispatch = useDispatch();

  const handleAddClick = () => {
    dispatch(routeActions.redirectTo(routes.CRIAR_USUARIOS));
  };

  if (loading) {
    return <StyledSkeleton />;
  }

  return (
    <StyledCard>
      <Grid container>
        <Grid item xs={12} sm={9} md={9}>
          <StyledH2>Usuários</StyledH2>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <StyledTextField
            fullWidth
            value={filtro}
            onChange={(e) => setFiltro(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
            name="nome"
            label="Nome"
            InputProps={{
              style: {
                textTransform: "lowercase",
                fontFamily: "Droid Sans",
                letterSpacing: "1px",
              },
              endAdornment: (
                <InputAdornment position="end" className={"ml-2 mr-3"}>
                  <IconButton onClick={handleSearch} type="submit">
                    <Search style={{ color: "#3F484A" }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <StyledTable>
        <TableHead>
          <TableRow>
            <StyledTableCellTitle>Usuário</StyledTableCellTitle>
            <StyledTableCellTitle>Nome</StyledTableCellTitle>
            <StyledTableCellTitle>Perfil</StyledTableCellTitle>
            <StyledTableCellTitle>Situação</StyledTableCellTitle>
            <StyledTableCellTitle></StyledTableCellTitle>
          </TableRow>
        </TableHead>

        <TableBody>
          {data &&
            data?.resultado?.map((item, index) => {
              const handleDelete = () => {
                dispatch(actions.deletarUsuario.request({ id: item.id }));
              };

              const handleClick = () => {
                dispatch(
                  routeActions.redirectTo(routes.EDIT_USUARIOS, {
                    id: item.id,
                  })
                );
              };
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell>{item.codigo}</StyledTableCell>
                  <StyledTableNomeCell>{item.nome}</StyledTableNomeCell>
                  <StyledTableCell>
                    {item.perfil === "A"
                      ? "Administrador"
                      : item.perfil === "C"
                      ? "Corretor"
                      : "Cliente"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {item.situacao === "A"
                      ? "Ativo"
                      : item.situacao === "I"
                      ? "Inativo"
                      : item.situacao === "T"
                      ? "Bloqueado por senha"
                      : "Bloqueado"}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Tooltip title="Editar" placement="right-start" arrow>
                      <IconButton onClick={handleClick}>
                        <Edit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Inativar" placement="right-start" arrow>
                      <IconButton
                        disabled={item.situacao === "A" ? false : true}
                        onClick={handleDelete}
                      >
                        <DeleteOutline />
                      </IconButton>
                    </Tooltip>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
        </TableBody>
      </StyledTable>

      <ButtonContainer>
        <StyledButton
          onClick={handleAddClick}
          startIcon={<Add />}
          loading={loading}
        >
          Adicionar
        </StyledButton>
      </ButtonContainer>
    </StyledCard>
  );
};

export default TabelaUsuarios;
