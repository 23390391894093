import { Grid, Tooltip } from "@mui/material";
import { styled } from "@mui/system";
import Card from "../../components/Card";
import {
  DeleteOutlined,
  EditOffOutlined,
  ModeEditOutlined,
} from "@mui/icons-material";
import { ReactComponent as IconRecover } from "../../styles/icons/recover.svg";

const StyledGrid = styled(Grid)`
  text-align: left;
`;

const IconsGrid = styled(Grid)`
  text-align: right;
  margin-top: 20px;
  align-items: center;
`;

const StyledCard = styled(Card)`
  height: auto;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 0px;
  background-color: #415781ff;

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const StyledCardInactive = styled(Card)`
  height: auto;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 25px;
  background-color: ${({ theme }) => theme.cards.inactive};

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const CardTitle = styled("div")`
  font-family: nunito;
  font-size: 16px;
  font-weight: 700;
  font-spacing: 0.5px;
  color: #ffb59b;
`;

const NomeCliente = styled("div")`
  font-family: nunito;
  font-size: 20px;
  font-weight: 300;
  font-spacing: 0.25px;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CnpjCliente = styled("div")`
  font-family: nunito;
  font-size: 12px;
  font-weight: 400;
  font-spacing: 0.4px;
  color: #ffffff;
`;

const StyledDeleteOutlined = styled(DeleteOutlined)`
  width: 35px;
  height: 35px;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
`;

const StyledModeEdit = styled(ModeEditOutlined)`
  width: 35px;
  height: 35px;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
`;

const StyledEditOffOutlined = styled(EditOffOutlined)`
  width: 35px;
  height: 32px;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
  cursor: pointer;
`;

const StyledIconRecover = styled(IconRecover)`
  width: 35px;
  height: 32px;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
  cursor: pointer;
`;

const CardApolice = ({
  item,
  handleClickEdit,
  handleOpenExclusao,
  handleOpenReativacao,
}) => {
  if (item.situacao === "A") {
    return (
      <StyledCard>
        <StyledGrid item xs={12} md={12}>
          <CardTitle>
            {item.tipo === "D"
              ? "Dental"
              : item.tipo === "V"
              ? "Vida"
              : "Saúde"}
          </CardTitle>
        </StyledGrid>
        <StyledGrid item xs={12} md={12}>
          <NomeCliente>{item?.Seguradora?.NM_SEGURADORA}</NomeCliente>
        </StyledGrid>
        <StyledGrid item xs={12} md={12}>
          <CnpjCliente>{item.numero}</CnpjCliente>
        </StyledGrid>
        <IconsGrid item xs={12} md={12}>
          <Tooltip title="Editar">
            <StyledModeEdit
              sx={{ cursor: "pointer" }}
              onClick={() => handleClickEdit(item.id)}
            />
          </Tooltip>
          <Tooltip title="Excluir">
            <StyledDeleteOutlined
              sx={{ cursor: "pointer" }}
              onClick={() => handleOpenExclusao(item)}
            />
          </Tooltip>
        </IconsGrid>
      </StyledCard>
    );
  } else {
    return (
      <StyledCardInactive key={item.id}>
        <StyledGrid item xs={12} md={12}>
          <CardTitle>
            {item.tipo === "D"
              ? "Dental"
              : item.tipo === "V"
              ? "Vida"
              : "Saúde"}
          </CardTitle>
        </StyledGrid>
        <StyledGrid item xs={12} md={12}>
          <NomeCliente>{item?.Seguradora?.NM_SEGURADORA}</NomeCliente>
        </StyledGrid>
        <StyledGrid item xs={12} md={12}>
          <CnpjCliente>{item.numero}</CnpjCliente>
        </StyledGrid>
        <IconsGrid item xs={12} md={12}>
          <Tooltip title="Consultar">
            <StyledEditOffOutlined
              sx={{ cursor: "pointer" }}
              onClick={() => handleClickEdit(item.id)}
            />
          </Tooltip>
          <Tooltip title="Reativar">
            <StyledIconRecover
              sx={{ cursor: "pointer" }}
              onClick={() => handleOpenReativacao(item)}
            />
          </Tooltip>
        </IconsGrid>
      </StyledCardInactive>
    );
  }
};

export default CardApolice;
