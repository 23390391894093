import basicFlow from "./asyncHandler";
import { authenticatedRequest } from "../utils/api";
import { routeWatcher } from "./rotas.saga";
import {
  actions as routeActions,
  types as routes,
} from "../reducers/rotas.actions";
import { actions as rateioActions } from "../reducers/rateio.actions";
import { actions as domainsActions } from "../reducers/dominios.actions";
import { saveAs } from "file-saver";

import { put, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import { getPayload } from "../selectors/routes.selectors";
import { getRateios } from "../selectors/rateio.selectors";
import formatters from "../utils/formatters";

const ListarRateiossApi = (values) => {
  return authenticatedRequest({
    url: `/rateios`,
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const ListarRateios = basicFlow({
  actionGenerator: rateioActions.obterRateios,
  api: ListarRateiossApi,
});

function* ListarRateiosRouteWatcher() {
  yield routeWatcher(routes.RATEIOS, function* () {
    yield put(domainsActions.listarSeguradoras.request());
    yield put(domainsActions.listarClientes.request());

    const cliente = yield select(getPayload);
    yield put(
      rateioActions.obterRateios.request({
        filtro: {
          cliente: cliente.idCliente
            ? {
                id: cliente.idCliente,
              }
            : null,
        },
      })
    );
  });
}

const ListarRateioOutrosApi = (values) => {
  return authenticatedRequest({
    url: `/rateio-outros/${values.id}`,
    isResourceService: true,
    method: "get",
  });
};

const ListarRateioOutros = basicFlow({
  actionGenerator: rateioActions.obterRateioOutros,
  api: ListarRateioOutrosApi,
});

const ListarRateioBeneficiariosApi = (values) => {
  return authenticatedRequest({
    url: `/rateio-beneficiarios/${values.id}`,
    isResourceService: true,
    method: "get",
  });
};

const ListarRateioBeneficiarios = basicFlow({
  actionGenerator: rateioActions.obterRateioBeneficiarios,
  api: ListarRateioBeneficiariosApi,
});

const DeletarRateioOutrosApi = (values) => {
  return authenticatedRequest({
    url: `/rateio-outros/${values.id}`,
    isResourceService: true,
    method: "delete",
  });
};

const DeletarRateioOutros = basicFlow({
  actionGenerator: rateioActions.deletarRateioOutros,
  api: DeletarRateioOutrosApi,
  postSuccess: function* () {
    yield toast.success(`Item excluído com sucesso`, {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    const cliente = yield select(getPayload);
    yield put(
      rateioActions.obterRateioOutros.request({
        id: cliente.idRateio,
      })
    );
    yield put(
      rateioActions.mostrarRateio.request({
        id: cliente.idRateio,
      })
    );
  },
});

function* ListarRateioOutrosRouteWatcher() {
  yield routeWatcher(routes.EDIT_RATEIO, function* () {
    const cliente = yield select(getPayload);
    yield put(
      rateioActions.obterRateioOutros.request({
        id: cliente.idRateio,
      })
    );
    yield put(
      rateioActions.obterRateioBeneficiarios.request({
        id: cliente.idRateio,
      })
    );
  });
}
function* ListarSeguradorasRouteWatcher() {
  yield routeWatcher(routes.NEW_RATEIO, function* () {
    yield put(domainsActions.listarSeguradoras.request());
  });
}

const EditarRateiosApi = (values) => {
  return authenticatedRequest({
    url: `/rateio/${values.get("id")}`,
    isResourceService: true,
    headers: { "Content-Type": "multipart/form-data" },
    method: "put",
    body: values,
  });
};

const EditarRateios = basicFlow({
  actionGenerator: rateioActions.editarRateio,
  transform: function (payload) {
    const form = new FormData();
    const {
      cliente,
      numero,
      seguradora,
      tipo,
      dataAniversario,
      dataVencimento,
      diasCorte,
      dataContratacao,
      dataNegociacao,
      situacao,
      file,
      fileImplantacao,
      precificacoes,
      id,
      editFile,
      editFileImplantacao,
    } = payload;

    form.append("editFile", JSON.stringify(editFile));
    form.append("editFileImplantacao", JSON.stringify(editFileImplantacao));
    form.append("precificacoes", JSON.stringify(precificacoes));
    form.append("id", id);
    form.append("cliente", cliente);
    form.append("numero", numero);
    form.append("seguradora", seguradora);
    form.append("tipo", tipo);
    form.append("dataAniversario", dataAniversario);
    form.append("dataVencimento", dataVencimento);
    form.append("diasCorte", diasCorte);
    form.append("dataContratacao", dataContratacao);
    form.append("dataNegociacao", dataNegociacao);
    form.append("situacao", situacao);
    if (file[0]) {
      form.append("arquivos", file[0], "CG" + file[0].name);
    }
    if (fileImplantacao[0]) {
      form.append(
        "arquivos",
        fileImplantacao[0],
        "CI" + fileImplantacao[0].name
      );
    }

    return form;
  },
  api: EditarRateiosApi,
  postSuccess: function* () {
    yield toast.success("Apólice editada com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    const cliente = yield select(getPayload);
    yield put(
      routeActions.redirectTo(routes.RATEIOS, {
        filtro: {
          cliente: {
            id: cliente.idCliente,
          },
        },
      })
    );
  },
});

const BaixarArtefatoApi = (values) => {
  return authenticatedRequest({
    url: `/documento/artefato/${values.artefato}`,
    isResourceService: true,
    method: "get",
  });
};

const BaixarArtefato = basicFlow({
  actionGenerator: rateioActions.baixarArtefato,
  api: BaixarArtefatoApi,
  postSuccess: ({ response, original, values }) => {
    downloadFile(response.data.file, response.data.type, 512, values.filename);
  },
});

const downloadFile = (b64Data, contentType = "", sliceSize = 512, filename) => {
  const byteCharacters = window.atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  saveAs(blob, filename);
};

const criarRateiosApi = (values) => {
  return authenticatedRequest({
    url: `/rateio`,
    isResourceService: true,
    headers: { "Content-Type": "multipart/form-data" },
    method: "post",
    body: values,
  });
};

const CriarRateios = basicFlow({
  actionGenerator: rateioActions.criarRateio,
  transform: function (payload) {
    const form = new FormData();

    const {
      cliente,
      numero,
      seguradora,
      tipo,
      dataAniversario,
      dataVencimento,
      diasCorte,
      dataContratacao,
      dataNegociacao,
      situacao,
      file,
      fileImplantacao,
      precificacoes,
    } = payload;

    form.append("cliente", cliente);
    form.append("precificacoes", JSON.stringify(precificacoes));
    form.append("numero", numero);
    form.append("seguradora", seguradora);
    form.append("tipo", tipo);
    form.append("dataAniversario", dataAniversario);
    form.append("dataVencimento", dataVencimento);
    form.append("diasCorte", diasCorte);
    form.append("dataContratacao", dataContratacao);
    form.append("dataNegociacao", dataNegociacao);
    form.append("situacao", situacao);
    if (file[0]) {
      form.append("arquivos", file[0], "CG" + file[0].name);
    }
    if (fileImplantacao[0]) {
      form.append(
        "arquivos",
        fileImplantacao[0],
        "CI" + fileImplantacao[0].name
      );
    }

    return form;
  },
  api: criarRateiosApi,
  postSuccess: function* () {
    yield toast.success("Apólice criada com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    const cliente = yield select(getPayload);
    routeActions.redirectTo(routes.RATEIOS, {
      cliente: cliente.idCliente,
      filtro: {
        cliente: {
          id: cliente.idCliente,
        },
      },
    });
  },
});

const uploadDocumentoRateiosApi = (values) => {
  return authenticatedRequest({
    url: `/rateio/upload-documentos`,
    isResourceService: true,
    headers: { "Content-Type": "multipart/form-data" },
    method: "post",
    body: values,
  });
};

const UploadRateioDocumentos = basicFlow({
  actionGenerator: rateioActions.uploadRateioDocumentos,
  transform: function (payload) {
    const form = new FormData();

    const {
      fileNF,
      fileBO,
      fileFA,
      fileCP,
      fileOD,
      editFileNF,
      editFileBO,
      editFileFA,
      editFileCP,
      arrayFileOD,
      id,
    } = payload;

    if (fileNF[0]) {
      form.append("arquivos", fileNF[0], "NF" + fileNF[0].name);
    }
    if (fileBO[0]) {
      form.append("arquivos", fileBO[0], "BO" + fileBO[0].name);
    }
    if (fileFA[0]) {
      form.append("arquivos", fileFA[0], "FA" + fileFA[0].name);
    }
    if (fileCP[0]) {
      form.append("arquivos", fileCP[0], "CP" + fileCP[0].name);
    }
    for (let newFileOD of fileOD) {
      if (newFileOD[0]) {
        form.append("arquivos", newFileOD[0], "OD" + newFileOD[0].name);
      }
    }

    form.append("id", id);
    form.append("editFileNF", JSON.stringify(editFileNF));
    form.append("editFileBO", JSON.stringify(editFileBO));
    form.append("editFileFA", JSON.stringify(editFileFA));
    form.append("editFileCP", JSON.stringify(editFileCP));
    form.append("arrayFileOD", JSON.stringify(arrayFileOD));

    return form;
  },
  api: uploadDocumentoRateiosApi,
  postSuccess: function* ({ original }) {
    yield toast.success("Documentos atualizados com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    const cliente = yield select(getPayload);
    yield put(
      rateioActions.obterRateios.request({
        filtro: {
          cliente: {
            id: cliente.idCliente,
          },
        },
      })
    );
  },
});

const deletarRateiosApi = (values) => {
  return authenticatedRequest({
    url: `/rateio/delete`,
    isResourceService: true,
    method: "post",
    body: {
      id: values,
    },
  });
};

const DeletarRateios = basicFlow({
  actionGenerator: rateioActions.deletarRateio,
  api: deletarRateiosApi,
  postSuccess: function* () {
    yield toast.success("Apólice inativada com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    const cliente = yield select(getPayload);
    yield put(
      rateioActions.obterRateios.request({
        filtro: {
          cliente: {
            id: cliente.idCliente,
          },
        },
      })
    );
  },
});

const criarRateioOutrosApi = (values) => {
  return authenticatedRequest({
    url: `/rateio-outros`,
    isResourceService: true,
    method: "post",
    body: { ...values },
  });
};

const CriarRateioOutros = basicFlow({
  actionGenerator: rateioActions.criarRateioOutros,
  api: criarRateioOutrosApi,
  postSuccess: function* () {
    yield toast.success(`Item adicionado com sucesso`, {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    const cliente = yield select(getPayload);
    yield put(
      rateioActions.obterRateioOutros.request({
        id: cliente.idRateio,
      })
    );
    yield put(
      rateioActions.mostrarRateio.request({
        id: cliente.idRateio,
      })
    );
  },
});

const editarRateioOutrosApi = (values) => {
  return authenticatedRequest({
    url: `/rateio-outros/${values.id}`,
    isResourceService: true,
    method: "put",
    body: { ...values },
  });
};

const EditarRateioOutros = basicFlow({
  actionGenerator: rateioActions.editarRateioOutros,
  api: editarRateioOutrosApi,
  postSuccess: function* () {
    yield toast.success(`Item editado com sucesso`, {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    const cliente = yield select(getPayload);
    yield put(
      rateioActions.obterRateioOutros.request({
        id: cliente.idRateio,
      })
    );
    yield put(
      rateioActions.mostrarRateio.request({
        id: cliente.idRateio,
      })
    );
  },
});
const editarRateioBeneficiariosApi = (values) => {
  return authenticatedRequest({
    url: `/rateio-beneficiarios/${values.id}`,
    isResourceService: true,
    method: "put",
    body: { ...values },
  });
};

const EditarRateioBeneficiarios = basicFlow({
  actionGenerator: rateioActions.editarRateioBeneficiarios,
  api: editarRateioBeneficiariosApi,
  postSuccess: function* () {
    yield toast.success(`Item editado com sucesso`, {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    const cliente = yield select(getPayload);
    yield put(
      rateioActions.obterRateioBeneficiarios.request({
        id: cliente.idRateio,
      })
    );
    yield put(
      rateioActions.mostrarRateio.request({
        id: cliente.idRateio,
      })
    );
  },
});

const ativarRateiosApi = (values) => {
  return authenticatedRequest({
    url: `/rateio/ativar`,
    isResourceService: true,
    method: "post",
    body: {
      id: values,
    },
  });
};

const ativarRateios = basicFlow({
  actionGenerator: rateioActions.ativarRateio,
  api: ativarRateiosApi,
  postSuccess: function* () {
    yield toast.success("Apólice reativada com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    const { filtro } = yield select(getRateios);
    yield put(
      rateioActions.obterRateios.request({
        filtro: filtro,
      })
    );
  },
});

const MostrarRateioApi = (values) => {
  return authenticatedRequest({
    url: `/rateio/${values.id}`,
    isResourceService: true,
    method: "get",
  });
};

const MostrarRateio = basicFlow({
  actionGenerator: rateioActions.mostrarRateio,
  api: MostrarRateioApi,
});

const AtualizarSituacaoRateioApi = (values) => {
  return authenticatedRequest({
    url: `/rateio-situacao/${values.id}`,
    isResourceService: true,
    method: "PUT",
    body: {
      situacao: values.situacao,
    },
  });
};

const AtualizarSituacaoRateio = basicFlow({
  actionGenerator: rateioActions.atualizarSituacao,
  api: AtualizarSituacaoRateioApi,
});

const AprovarSituacaoRateioApi = (values) => {
  return authenticatedRequest({
    url: `/rateio-situacao/${values.id}`,
    isResourceService: true,
    method: "PUT",
    body: {
      situacao: values.situacao,
    },
  });
};

const AprovarSituacaoRateio = basicFlow({
  actionGenerator: rateioActions.aprovarSituacao,
  api: AprovarSituacaoRateioApi,
  postSuccess: function* () {
    yield toast.success("Rateio aprovado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
  },
});

const ListarValoresApi = (values) => {
  return authenticatedRequest({
    url: `/rateio/plano-valor/listar`,
    isResourceService: true,
    method: "get",
    queryParams: {
      ...values,
    },
  });
};

const ListarValores = basicFlow({
  actionGenerator: rateioActions.obterValores,
  api: ListarValoresApi,
});

function* MostrarRateioRouteWatcher() {
  yield routeWatcher(routes.EDIT_RATEIO, function* () {
    let apolice = yield select(getPayload);
    yield put(rateioActions.mostrarRateio.request({ id: apolice.idRateio }));
  });
}

const DownloadPlanilhaRateioApi = (values) => {
  return authenticatedRequest({
    url: `/rateio-exportacao/${values.id}`,
    isResourceService: true,
    method: "get",
    responseType: "arraybuffer",
  });
};

const DownloadPlanilhaRateio = basicFlow({
  actionGenerator: rateioActions.downloadRateio,
  api: DownloadPlanilhaRateioApi,
  postSuccess: ({ response, original }) => {
    downloadPlanilha(response, original);
  },
});

const downloadPlanilha = (response, original) => {
  var bytes = new Uint8Array(response.data);

  var blob = new Blob([bytes], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  saveAs(
    blob,
    `rateio-#${original.numeroApolice}-${original.nomeCliente.replaceAll(
      " ",
      "_"
    )}-${formatters.dates.withHoursNoSpace(new Date())}.xlsx`
  );
};

export const sagas = [
  MostrarRateioRouteWatcher(),
  ListarRateiosRouteWatcher(),
  ListarSeguradorasRouteWatcher(),
  ListarRateioOutrosRouteWatcher(),
  ListarRateios.watcher(),
  MostrarRateio.watcher(),
  EditarRateios.watcher(),
  CriarRateios.watcher(),
  DeletarRateios.watcher(),
  ListarValores.watcher(),
  BaixarArtefato.watcher(),
  ativarRateios.watcher(),
  CriarRateioOutros.watcher(),
  ListarRateioOutros.watcher(),
  DeletarRateioOutros.watcher(),
  EditarRateioOutros.watcher(),
  UploadRateioDocumentos.watcher(),
  EditarRateioBeneficiarios.watcher(),
  ListarRateioBeneficiarios.watcher(),
  DownloadPlanilhaRateio.watcher(),
  AtualizarSituacaoRateio.watcher(),
  AprovarSituacaoRateio.watcher(),
];
