import BasePage from "../BasePage";
import React from "react";
import { Pagination, styled } from "@mui/material";
import { Grid } from "@mui/material";
import TabelaUsuarios from "./TabelaUsuarios";

const StyledContainer = styled(Grid)`
  margin-top: 120px;
`;
const StyledGridPagination = styled(Grid)`
  display: flex;
  justify-content: center;
`;

const StyledPagination = styled(Pagination)`
  .MuiButtonBase-root {
    background-color: #849dce !important;
    color: #ffffff;
  }

  & .Mui-selected {
    background-color: #415781 !important;
  }
`;

const UsuarioPage = ({
  data,
  loading,
  handlePagination,
  handleSearch,
  filtro,
  setFiltro,
}) => {
  return (
    <BasePage isLoggedIn={true} isLightBlue={true}>
      <StyledContainer container spacing={2}>
        <TabelaUsuarios
          data={data}
          loading={loading}
          handlePagination={handlePagination}
          handleSearch={handleSearch}
          filtro={filtro}
          setFiltro={setFiltro}
        />
      </StyledContainer>
      {!loading && (
        <StyledGridPagination item xs={12} md={12}>
          <StyledPagination
            count={Math.ceil(data.total / 10)}
            page={data.paginaAtual}
            variant="outlined"
            shape="rounded"
            onChange={handlePagination}
          />
        </StyledGridPagination>
      )}
    </BasePage>
  );
};

export default UsuarioPage;
