import React from "react";
import { Chip, styled } from "@mui/material";
import { Check } from "@mui/icons-material";

const StyledChip = styled(Chip)`

  background: rgba(65, 87, 129, 0.7);
  margin-right: 12px;
  border-radius: 8px;
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: white;
  
  & .MuiChip-outlined: {
    background: rgba(0, 0, 0, 0.7) !important;
  }
  
  & .MuiChip-icon {
    color: #FFFFFF;
  }
`;

const FilterChip = ({variant="filled", active=false, ...otherProps}) =>
{

  let icon;

  if (active) {
    icon = <Check />;
  }

  return (
    <StyledChip
      icon={icon}
      variant={variant}
      size="medium"
      {...otherProps}
    />
  );
};

export default FilterChip;



