import { createAsyncAction } from "../utils/actionCreators";

export const types = {
  OBTER_USUARIOS: "@@clienteActions/OBTER_USUARIOS",
  MOSTRAR_USUARIO: "@@clienteActions/MOSTRAR_USUARIO",
  EDITAR_USUARIO: "@@clienteActions/EDITAR_USUARIO",
  CRIAR_USUARIO: "@@clienteActions/CRIAR_USUARIO",
  DELETAR_USUARIO: "@@clienteActions/DELETAR_USUARIO",
};

export const actions = {
  obterUsuarios: createAsyncAction(types.OBTER_USUARIOS),
  mostrarUsuario: createAsyncAction(types.MOSTRAR_USUARIO),
  editarUsuario: createAsyncAction(types.EDITAR_USUARIO),
  criarUsuario: createAsyncAction(types.CRIAR_USUARIO),
  deletarUsuario: createAsyncAction(types.DELETAR_USUARIO),
};
