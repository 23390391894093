import BasePage from "../../BasePage";
import React from "react";
import Button from "../../../components/buttons/PrimaryButton";
import OutlinedButton from "../../../components/buttons/OutlinedButton";
import { Grid, styled } from "@mui/material";
import Spinner from "../../../components/spinner/Spinner";
import FormDadosFila from "../tabs/FormDadosFila";

const StyledH1 = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.blueTitle};
  letter-spacing: 4.8px;
  font-size: 45px;
  margin-bottom: -30px;
  @media (max-width: 900px) {
    font-size: 22px;
  }
`;
const StyledH2 = styled("h1")`
  font-family: Nunito;
  font-size: 28px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  margin-top: 30px;
  margin-bottom: -30px;
  color: #ac3300;
  @media (max-width: 900px) {
    font-size: 12px;
  }
`;

const StyledContainer = styled(Grid)`
  margin-top: 10px;
  padding-left: 25px;
  padding-right: 20px;
  @media (max-width: 900px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;
const StyledBasePage = styled(BasePage)`
  padding: 3px;
`;

const StyledSpinner = styled(Spinner)`
  margin-top: 200px !important;
`;

const StyledGrid = styled(Grid)`
  padding: 5px;
`;

const StyledGridContainer = styled(Grid)`
  margin-bottom: 0px;
`;

const FormEditarFilaPage = ({
  loading,
  formProps,
  buttonTitle,
  handleSubmit,
  handleClickVoltar,
  handleSetEdit,
  fila,
  loadingFila,
}) => {
  if (loading) {
    return (
      <StyledBasePage isLoggedIn={true} isLightBlue={true}>
        <div style={{ height: "100vh" }}>
          <StyledSpinner />
        </div>
      </StyledBasePage>
    );
  }

  return (
    <form onSubmit={formProps.handleSubmit(handleSubmit)}>
      <BasePage isLoggedIn={true} isLightBlue={true}>
        <StyledContainer container spacing={4}>
          <Grid item xs={12}>
            <StyledH1>Reajuste</StyledH1>
            <StyledH2>{fila?.cliente}</StyledH2>
          </Grid>
        </StyledContainer>
        <StyledContainer>
          <FormDadosFila
            formProps={formProps}
            handleSetEdit={handleSetEdit}
            fila={fila}
            loadingFila={loadingFila}
            buttonTitle={buttonTitle}
          />
          <StyledGridContainer container>
            <StyledGrid
              sx={{ display: "flex", justifyContent: "flex-end" }}
              item
              xs={6}
            >
              <OutlinedButton
                sx={{ maxWidth: "270px" }}
                onClick={handleClickVoltar}
              >
                Voltar
              </OutlinedButton>
            </StyledGrid>
            <StyledGrid item xs={6}>
              {fila.situacao !== "E" && (
                <Button
                  sx={{ maxWidth: "270px" }}
                  loading={loading}
                  type="submit"
                >
                  {fila.situacao === "A" ? "Editar" : "Aprovar"}
                </Button>
              )}
            </StyledGrid>
          </StyledGridContainer>
        </StyledContainer>
      </BasePage>
    </form>
  );
};

export default FormEditarFilaPage;
