import { types as authTypes } from "./autenticacao.actions";
import { actions } from "./dominios.actions";

const initialState = {
  usuarios: {
    loading: false,
    data: [],
  },
  planos: {
    loading: false,
    data: [],
  },
  seguradoras: {
    loading: false,
    data: [],
  },
  clientes: {
    loading: false,
    data: [],
  },
  manutencoes: {
    loading: false,
    data: [],
  },
  apolices: {
    loading: false,
    data: [],
  },
};

const partial = (state, path, action, actionGenerator) => {
  return {
    ...state,
    [path]: {
      ...state[path],
      loading: action.type === actionGenerator.REQUEST,
      data:
        action.type === actionGenerator.SUCCESS
          ? action.payload.response.data
          : [],
    },
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.LOGOUT:
      return {
        ...initialState,
      };
    case actions.listarUsuarios.REQUEST:
    case actions.listarUsuarios.SUCCESS:
    case actions.listarUsuarios.FAILURE:
      return partial(state, "usuarios", action, actions.listarUsuarios);
    case actions.listarPlanos.REQUEST:
    case actions.listarPlanos.SUCCESS:
    case actions.listarPlanos.FAILURE:
      return partial(state, "planos", action, actions.listarPlanos);
    case actions.listarSeguradoras.REQUEST:
    case actions.listarSeguradoras.SUCCESS:
    case actions.listarSeguradoras.FAILURE:
      return partial(state, "seguradoras", action, actions.listarSeguradoras);
    case actions.listarClientes.REQUEST:
    case actions.listarClientes.SUCCESS:
    case actions.listarClientes.FAILURE:
      return partial(state, "clientes", action, actions.listarClientes);
    case actions.listarManutencaoCliente.REQUEST:
    case actions.listarManutencaoCliente.SUCCESS:
    case actions.listarManutencaoCliente.FAILURE:
      return partial(state, "manutencoes", action, actions.listarManutencaoCliente);
    case actions.listarApolices.REQUEST:
    case actions.listarApolices.SUCCESS:
    case actions.listarApolices.FAILURE:
      return partial(state, "apolices", action, actions.listarApolices);
    default:
      return state;
  }
};

export default reducer;
