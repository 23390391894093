import { createSelector } from "reselect";

export const getDominios = (state) => state.dominios;

export const getUsuarios = createSelector(
  getDominios,
  (state) => state.usuarios
);
export const getPlanos = createSelector(getDominios, (state) => state.planos);
export const getSeguradoras = createSelector(
  getDominios,
  (state) => state.seguradoras
);
export const getClientes = createSelector(
  getDominios,
  (state) => state.clientes
);
export const getManutencoes = createSelector(
  getDominios,
  (state) => state.manutencoes
);
export const getApolices = createSelector(
  getDominios,
  (state) => state.apolices
);
