import React from "react";
import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
} from "@mui/material";
import formatters from "../../../utils/formatters";

const StyledTable = styled(Table)`
  margin-top: 10px;
  margin-bottom: 20px;
  overflow-y: auto !important;
`;

const StyledTableRow = styled(TableRow)`
  padding: 0px;
  height: 35px;
  &:nth-of-type(odd) {
    background-color: #415781ff;
    max-height: 10px;
    color: #ffffff !important;
  }
  &:nth-of-type(even) {
    background-color: #a3abc0;
    max-height: 10px;
  }
`;
const StyledTableCell = styled(TableCell)`
  color: inherit;
  padding-top: 5px;
  padding-bottom: 5px;
  border: none !important;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  cursor: pointer;
`;
const StyledFirstTableCell = styled(TableCell)`
  color: inherit;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  font-family: Open Sans;
  border: none !important;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  cursor: pointer;
`;

const StyledTitleTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
  font-family: nunito;
  border: none !important;
  color: #ffdbce !important;
`;

const StyledTableBody = styled(TableBody)`
  overflow-y: auto !important;
`;

const StyledPaper = styled(Paper)`
  margin-top: 10px;
  overflow-y: auto;
  max-height: 400px;
  background-color: #415781ff;
  box-shadow: none;
`;

const TabelaRateios = ({ data, handleGoToRateios }) => {
  if (data?.length === 0) {
    return <></>;
  }

  return (
    <StyledPaper>
      <StyledTable>
        <StyledTableBody>
          <StyledTableRow>
            <StyledTitleTableCell>Competência</StyledTitleTableCell>
            <StyledTitleTableCell>Cliente</StyledTitleTableCell>
            <StyledTitleTableCell>Apólice</StyledTitleTableCell>
            <StyledTitleTableCell>Produto</StyledTitleTableCell>
          </StyledTableRow>
          {data?.length > 0 &&
            data.map((item, index) => {
              return (
                <StyledTableRow
                  onClick={() => handleGoToRateios(item)}
                  key={index}
                >
                  <StyledFirstTableCell>
                    {formatters.dates.simpleDateDisplay(item.dataMesReferencia)}
                  </StyledFirstTableCell>
                  <StyledTableCell>
                    {item?.ClienteApolice?.Cliente?.nomeCliente}
                  </StyledTableCell>
                  <StyledTableCell>
                    {item?.ClienteApolice?.numero}
                  </StyledTableCell>
                  <StyledTableCell>
                    {item?.ClienteApolice?.tipo === "D"
                      ? "Dental"
                      : item?.ClienteApolice?.tipo === "S"
                      ? "Saúde"
                      : "Vida"}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
        </StyledTableBody>
      </StyledTable>
    </StyledPaper>
  );
};

export default TabelaRateios;
