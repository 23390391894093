import { Grid, Tooltip } from "@mui/material";
import { styled } from "@mui/system";
import Card from "../../components/Card";
import {
  DeleteOutlined,
  ModeEditOutlined,
  PersonOutline,
  AttachFile,
  EditOffOutlined,
  EmojiPeople,
} from "@mui/icons-material";
import formatters from "../../utils/formatters";
import { ReactComponent as IconRecover } from "../../styles/icons/recover.svg";

const StyledGrid = styled(Grid)`
  text-align: left;
`;

const IconsGrid = styled("div")`
  margin-top: 20px;
  align-items: center;
  display: flex;
  width: 100%;
`;

const StyledCardActive = styled(Card)`
  min-height: 160px;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 0px;
  background-color: #415781ff;

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const StyledCardInactive = styled(Card)`
  min-height: 160px;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 0px;
  background-color: ${({ theme }) => theme.cards.inactive};

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const CardTitle = styled("div")`
  font-family: nunito;
  font-size: 18px;
  font-weight: 700;
  font-spacing: 0.5px;
  color: #ffffff;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 70%;
  white-space: nowrap;

  @media (max-width: 400px) {
    max-width: 50%;
  }
`;

const CnpjBeneficiario = styled("div")`
  font-family: nunito;
  font-size: 12px;
  font-weight: 400;
  font-spacing: 0.4px;
  color: #ffffff;
`;

const StyledDeleteOutlined = styled(DeleteOutlined)`
  width: 35px;
  height: 35px;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
  cursor: pointer;
`;

const StyledModeEdit = styled(ModeEditOutlined)`
  width: 35px;
  height: 35px;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
  cursor: pointer;
`;

const StyledPersonOutline = styled(PersonOutline)`
  width: 35px;
  height: 35px;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
  cursor: pointer;
`;

const StyledAttachFile = styled(AttachFile)`
  width: 35px;
  height: 32px;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
  cursor: pointer;
`;

const StyledEditOffOutlined = styled(EditOffOutlined)`
  width: 35px;
  height: 32px;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
  cursor: pointer;
`;

const StyledIconRecover = styled(IconRecover)`
  width: 35px;
  height: 32px;
  fill: ${({ theme }) => theme.colors.iconsFill};
  margin-left: 10px;
  cursor: pointer;
`;

const StyledEmojiPeople = styled(EmojiPeople)`
  width: 24px;
  height: 24px;
  fill: ${({ theme }) => theme.colors.white};
  margin-left: 0px;
`;

const TipoChips = styled("div")`
  font-family: Nunito;
  font-size: 14px;
  font-weight: 500;
  font-spacing: 0.5px;
  background-color: #fff;
  min-width: 90px;
  border-radius: 25px;
  text-align: center;
  margin: auto;
  margin-top: 0px;
  display: block;
`;

const ProdutoChips = styled("div")`
  font-family: Nunito;
  font-size: 14px;
  font-weight: 500;
  font-spacing: 0.5px;
  background-color: #fff;
  min-width: 90px;
  border-radius: 25px;
  text-align: center;
  margin: auto;
  margin-top: 0px;
  display: block;
  position: absolute;
  right: 20px;
`;

const CardBeneficiario = ({
  beneficiario,
  handleEditBeneficiario,
  handleOpenExclusaoBeneficiario,
  handleOpenReativacaoBeneficiario,
}) => {
  if (beneficiario.situacao === "A")
    return (
      <StyledCardActive key={beneficiario.id} style={{ position: "relative" }}>
        <Grid container spacing={1}>
          <StyledGrid
            item
            xs={1}
            style={{ paddingLeft: "5px", paddingTop: "10px" }}
          >
            <StyledEmojiPeople />
          </StyledGrid>
          <StyledGrid item xs={11}>
            <CardTitle>{beneficiario?.nomeBeneficiario}</CardTitle>
          </StyledGrid>
        </Grid>
        <StyledGrid item xs={12} md={12}>
          <CnpjBeneficiario>
            {formatters.strings.cpf(beneficiario?.cpf)}
          </CnpjBeneficiario>
        </StyledGrid>
        <StyledGrid item xs={12} md={12}>
          <CnpjBeneficiario>
            {beneficiario.numero === "" ? "-" : beneficiario?.numero ?? "-"}
          </CnpjBeneficiario>
        </StyledGrid>
        <IconsGrid>
          {!!beneficiario.beneficiarioPrincipal &&
          <div style={{ display: "flex", justifyContent: "left" }}>
            <TipoChips>
              {!!beneficiario.beneficiarioPrincipal
                ? "Dependente"
                : ""}
            </TipoChips>
          </div>
          }
          <div
            style={{ display: "flex", justifyContent: "right", width: "100%" }}
          >
            <Tooltip title={"Documentos"}>
              <StyledAttachFile
                onClick={() => handleEditBeneficiario(beneficiario.id, 2)}
              />
            </Tooltip>
            <Tooltip title={"Dependentes"}>
              <StyledPersonOutline
                onClick={() => handleEditBeneficiario(beneficiario.id, 1)}
              />
            </Tooltip>
            <Tooltip title={"Editar"}>
              <StyledModeEdit
                onClick={() => handleEditBeneficiario(beneficiario.id, 0)}
              />
            </Tooltip>
            <Tooltip title={"Desativar"}>
              <StyledDeleteOutlined
                onClick={() => handleOpenExclusaoBeneficiario(beneficiario)}
              />
            </Tooltip>
          </div>
        </IconsGrid>
        {beneficiario.produtos.length > 0 &&
          beneficiario.produtos.map((i, index) => (
            <ProdutoChips style={{ top: 23 + 30 * index }}>
              {i === "S" ? "Saúde" : i === "D" ? "Dental" : "Vida"}
            </ProdutoChips>
          ))}
      </StyledCardActive>
    );
  else
    return (
      <StyledCardInactive
        key={beneficiario.id}
        style={{ position: "relative" }}
      >
        <Grid container spacing={1}>
          <StyledGrid
            item
            xs={1}
            style={{ paddingLeft: "5px", paddingTop: "10px" }}
          >
            <StyledEmojiPeople />
          </StyledGrid>
          <StyledGrid item xs={11}>
            <CardTitle>{beneficiario?.nomeBeneficiario}</CardTitle>
          </StyledGrid>
        </Grid>
        <StyledGrid item xs={12} md={12}>
          <CnpjBeneficiario>
            {formatters.strings.cpf(beneficiario.cpf)}
          </CnpjBeneficiario>
        </StyledGrid>
        <StyledGrid item xs={12} md={12}>
          <CnpjBeneficiario>
            {beneficiario.numero === "" ? "-" : beneficiario?.numero ?? "-"}
          </CnpjBeneficiario>
        </StyledGrid>
        <IconsGrid>
          <div style={{ display: "flex", justifyContent: "left" }}>
            <TipoChips>
              {beneficiario.situacao === "D"
                ? "Desligado"
                : beneficiario.situacao === "O"
                ? "Opção do Segurado"
                : "Extensão do Beneficiário"}
            </TipoChips>
          </div>
          <div
            style={{ display: "flex", justifyContent: "right", width: "100%" }}
          >
            <StyledEditOffOutlined
              onClick={() =>
                handleEditBeneficiario(beneficiario.id, { tab: 0 })
              }
            />
            <StyledIconRecover
              onClick={() => handleOpenReativacaoBeneficiario(beneficiario)}
            />
          </div>
        </IconsGrid>
        {beneficiario.produtos.length > 0 &&
          beneficiario.produtos.map((i, index) => (
            <ProdutoChips style={{ top: 23 + 30 * index }}>
              {i === "S" ? "Saúde" : i === "D" ? "Dental" : "Vida"}
            </ProdutoChips>
          ))}
      </StyledCardInactive>
    );
};

export default CardBeneficiario;
