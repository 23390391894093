import ClientesPage from "./ClientesPage";
import { getSubestipulantes } from "../../../../selectors/cliente.selectors";
import { getPayload } from "../../../../selectors/routes.selectors";
import { useSelector, useDispatch } from "react-redux";
import {
  actions as routeActions,
  types as routes,
} from "../../../../reducers/rotas.actions";
import { actions } from "../../../../reducers/cliente.actions";
import { useCallback } from "react";

const ClientesPageConnected = () => {
  const dispatch = useDispatch();
  const subestipulante = useSelector(getSubestipulantes);
  const cliente = useSelector(getPayload);

  const handleNewCliente = useCallback(() => {
    dispatch(
      routeActions.redirectTo(routes.NEW_SUB, {
        idCliente: cliente.id,
        clienteMatriz: cliente.id,
        subestipulante: cliente.id,
        idEditSubestipulante: true,
        tab: 0,
      })
    );
  }, [dispatch, cliente]);

  const handleEditCliente = useCallback(
    (id) => {
      dispatch(
        routeActions.redirectTo(routes.EDIT_CLIENTE, {
          id,
          subestipulante: cliente.id,
          isSubestipulante: true,
          tab: 0,
        })
      );
    },
    //eslint-disable-next-line
    [dispatch]
  );

  const handleClickApolice = (id) => {
    dispatch(
      routeActions.redirectTo(routes.APOLICES, {
        idCliente: id,
        subestipulante: cliente.id,
      })
    );
  };

  const handleClickRateio = (id) => {
    dispatch(
      routeActions.redirectTo(routes.RATEIOS, {
        idCliente: id,
        subestipulante: cliente.id,
      })
    );
  };

  const handleClickFaturamentos = (id) => {
    dispatch(
      routeActions.redirectTo(routes.FATURAMENTOS, {
        idCliente: id,
        subestipulante: cliente.id,
      })
    );
  };

  const handleDeleteCliente = useCallback(
    (id) => {
      dispatch(actions.deletarCliente.request(id));
    },
    [dispatch]
  );

  const handleAtivarCliente = useCallback(
    (id) => {
      dispatch(actions.ativarCliente.request(id));
    },
    [dispatch]
  );

  const handleClickBeneficiarios = (id) => {
    dispatch(
      routeActions.redirectTo(routes.BENEFICIARIOS, {
        idCliente: id,
        subestipulante: cliente.id,
      })
    );
  };

  const handleClickEditDocumetos = useCallback(
    (id) => {
      dispatch(
        routeActions.redirectTo(routes.EDIT_CLIENTE, {
          id: id,
          tab: 2,
          subestipulante: cliente.id,
          isSubestipulante: true,
        })
      );
    },
    //eslint-disable-next-line
    [dispatch]
  );

  return (
    <ClientesPage
      data={subestipulante}
      handleNewCliente={handleNewCliente}
      handleEditCliente={handleEditCliente}
      handleDeleteCliente={handleDeleteCliente}
      handleAtivarCliente={handleAtivarCliente}
      handleClickApolice={handleClickApolice}
      handleClickRateio={handleClickRateio}
      handleClickFaturamentos={handleClickFaturamentos}
      handleClickBeneficiarios={handleClickBeneficiarios}
      handleClickEditDocumetos={handleClickEditDocumetos}
    />
  );
};

export default ClientesPageConnected;
