import { authenticatedRequest } from "../utils/api";
import basicFlow from "./asyncHandler";
import { actions } from "../reducers/documentos.actions";
import { saveAs } from "file-saver";

const BaixarArtefatoApi = (values) => {
  return authenticatedRequest({
    url: `/documento/artefato/${values.artefato}`,
    isResourceService: true,
    method: "get",
  });
};

const BaixarArtefato = basicFlow({
  actionGenerator: actions.baixarArtefato,
  api: BaixarArtefatoApi,
  postSuccess: ({ response, original, values }) => {
    downloadFile(response.data.file, response.data.type, 512, values.filename);
  },
});

const downloadFile = (b64Data, contentType = "", sliceSize = 512, filename) => {
  const byteCharacters = window.atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  saveAs(blob, filename);
};

export const sagas = [
  BaixarArtefato.watcher(),
];