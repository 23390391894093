import BasePage from "../../BasePage";
import React, { useEffect, useState } from "react";
import { ClickAwayListener, Skeleton, styled, Tab, Tabs } from "@mui/material";
import { Grid } from "@mui/material";
import Button from "../../../components/buttons/PrimaryButton";
import OutlinedButton from "../../../components/buttons/OutlinedButton";
import FormBeneficiario from "../tabs/FormBeneficiario";
import FormDocumentosCliente from "../tabs/FormDocumentosBeneficiario";
import DependentesPage from "../tabs/dependentes/DependentesPage.connected";
import { useSelector } from "react-redux";
import { getPayload } from "../../../selectors/routes.selectors";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import { MenuItem } from "../../../components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const StyledH1 = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.blueTitle};
  font-weight: 400;
  letter-spacing: 4.8px;
  font-size: 45px;
  @media (max-width: 900px) {
    font-size: 22px;
    margin-bottom: 10px;
  }
`;

const StyledContainer = styled(Grid)`
  margin-top: 10px;
  padding-left: 25px;
  padding-right: 20px;
  @media (max-width: 900px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const StyledGrid = styled(Grid)`
  padding: 5px !important;

  .MuiGrid-item {
    padding-top: 5px;
  }
`;

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 500px;
  margin-bottom: -65px;
`;

const StyledTab = styled(Tabs)`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  & .Mui-selected {
    color: ${({ theme }) => theme.buttons.primary.background};
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;

const StyledTabLabel = styled(Tab)`
  align-items: flex-start;
  min-width: 33%;
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: gray;
  font-weight: bold;
  text-transform: none;
  margin-bottom: -60px;
  font-size: 16px;
`;

const FormEditDependentePage = ({
  loading,
  formProps,
  handleSubmit,
  handleAddPrecificacao,
  buttonTitle,
  handleDeletePlano,
  handleEditPlano,
  planos,
  handleDownloadArtefato,
  isFileDPS,
  setIsFileDPS,
  fileDPS,
  setFileDPS,
  editFileDPS,
  setEditFileDPS,
  isFileCTPS,
  setIsFileCTPS,
  fileCTPS,
  setFileCTPS,
  editFileCTPS,
  setEditFileCTPS,
  isFileFR,
  setIsFileFR,
  fileFR,
  setFileFR,
  editFileFR,
  setEditFileFR,
  isFileDP,
  setIsFileDP,
  fileDP,
  setFileDP,
  editFileDP,
  setEditFileDP,
  situacao,
  editavel,
  handleClickVoltar,
  clienteManutencao,
  isFileOD,
  fileOD,
  setFileOD,
  setIsFileOD,
  arrayFileOD,
  setArrayFileOD,
  isCliente,
  isDependente,
  preencherValorPremio,
  preencherValorPremioPorPlano,
  setButtonTitle,
}) => {
  const [value, setValue] = useState(0);

  const { tab } = useSelector(getPayload);

  useEffect(() => {
    if (tab !== undefined) {
      setValue(tab);
    }
  }, [tab]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleToggle = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMenuItemClick = (event, index) => {
    setOpen(false);
    formProps.setValue(
      "ClienteManutencao[0].situacao",
      event.currentTarget.getAttribute("value")
    );
    if (
      event.currentTarget.getAttribute("value") === "F" &&
      clienteManutencao[0].tipoSolicitacao !== "DB"
    )
      formProps.setValue("situacao", "A");
    if (
      event.currentTarget.getAttribute("value") === "F" &&
      clienteManutencao[0].tipoSolicitacao === "DB"
    )
      formProps.setValue("situacao", "I");
  };

  const options = [
    {
      id: "N",
      descricao: "NOVA",
    },
    {
      id: "A",
      descricao: "AGUARDANDO CONCLUSÃO",
    },
    {
      id: "F",
      descricao: "FINALIZADA",
    },
  ];

  if (loading) {
    return (
      <BasePage isLoggedIn={true} isLightBlue={true}>
        <StyledSkeleton />
      </BasePage>
    );
  }

  return (
    <form onSubmit={formProps.handleSubmit(handleSubmit)}>
      <BasePage isLoggedIn={true} isLightBlue={true}>
        <StyledContainer container spacing={2}>
          <Grid item xs={9}>
            <StyledH1>Cadastro do Dependente</StyledH1>
          </Grid>
          {clienteManutencao?.length > 0 && !isCliente && (
            <Grid item xs={3} style={{ alignItems: "center", display: "flex" }}>
              <Button
                onClick={(event) => handleToggle(event)}
                endIcon={<KeyboardArrowDownIcon />}
              >
                {clienteManutencao &&
                formProps.getValues("ClienteManutencao[0].situacao") === "N"
                  ? "NOVA"
                  : formProps.getValues("ClienteManutencao[0].situacao") === "A"
                  ? "AGUARDANDO CONCLUSÃO"
                  : "FINALIZADA"}
              </Button>
              <Popper
                sx={{
                  zIndex: 1,
                }}
                open={open}
                anchorEl={anchorEl}
                role={undefined}
                transition
                disablePortal
                placement="bottom-start"
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          id="split-button-menu"
                          autoFocusItem
                          anchorEl={anchorEl}
                        >
                          {options
                            .filter(
                              (i) => i.id !== clienteManutencao[0].situacao
                            )
                            .map((option, index) => (
                              <MenuItem
                                className={"select-submenu-item"}
                                key={option.id}
                                onClick={(event) =>
                                  handleMenuItemClick(event, index)
                                }
                                value={option.id}
                              >
                                {option.descricao}
                              </MenuItem>
                            ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Grid>
          )}
          <Grid item xs={12} style={{ marginBottom: "10px" }}>
            <StyledTab
              sx={{ marginBottom: "10px" }}
              value={value}
              onChange={handleChange}
              TabIndicatorProps={{
                style: {
                  background: "#F24F13",
                  color: "#F24F13",
                  minHeight: "6px",
                  marginRight: "20px",
                  borderRadius: "5px",
                },
              }}
              textColor={"inherit"}
            >
              <StyledTabLabel label="Dados do Beneficiário" />
              {<StyledTabLabel label="Dependentes" />}
              <StyledTabLabel label="Documentos" />
            </StyledTab>
          </Grid>
        </StyledContainer>
        <StyledContainer container spacing={2}>
          {value === 0 && (
            <FormBeneficiario
              loading={loading}
              formProps={formProps}
              handleAddPrecificacao={handleAddPrecificacao}
              buttonTitle={buttonTitle}
              handleDeletePlano={handleDeletePlano}
              handleEditPlano={handleEditPlano}
              planos={planos}
              situacao={situacao}
              editavel={editavel}
              isDependente={isDependente}
              preencherValorPremio={preencherValorPremio}
              preencherValorPremioPorPlano={preencherValorPremioPorPlano}
              setButtonTitle={setButtonTitle}
            />
          )}
          {value === 1 && <DependentesPage editavel={editavel} />}
          {value === 2 && (
            <FormDocumentosCliente
              loading={loading}
              formProps={formProps}
              isFileDPS={isFileDPS}
              setIsFileDPS={setIsFileDPS}
              fileDPS={fileDPS}
              setFileDPS={setFileDPS}
              editFileDPS={editFileDPS}
              setEditFileDPS={setEditFileDPS}
              isFileCTPS={isFileCTPS}
              setIsFileCTPS={setIsFileCTPS}
              fileCTPS={fileCTPS}
              setFileCTPS={setFileCTPS}
              editFileCTPS={editFileCTPS}
              setEditFileCTPS={setEditFileCTPS}
              isFileFR={isFileFR}
              setIsFileFR={setIsFileFR}
              fileFR={fileFR}
              setFileFR={setFileFR}
              editFileFR={editFileFR}
              setEditFileFR={setEditFileFR}
              isFileDP={isFileDP}
              setIsFileDP={setIsFileDP}
              fileDP={fileDP}
              setFileDP={setFileDP}
              editFileDP={editFileDP}
              setEditFileDP={setEditFileDP}
              handleDownloadArtefato={handleDownloadArtefato}
              isFileOD={isFileOD}
              setIsFileOD={setIsFileOD}
              fileOD={fileOD}
              setFileOD={setFileOD}
              arrayFileOD={arrayFileOD}
              setArrayFileOD={setArrayFileOD}
            />
          )}
        </StyledContainer>
        <StyledContainer
          container
          spacing={2}
          style={{ display: "flex", justifyContent: "Center" }}
        >
          <StyledGrid item xs={4}>
            <OutlinedButton onClick={handleClickVoltar}>Voltar</OutlinedButton>
          </StyledGrid>
          <StyledGrid item xs={4}>
            <Button type={"submit"} loading={loading} disabled={!editavel}>
              Confirmar
            </Button>
          </StyledGrid>
        </StyledContainer>
      </BasePage>
    </form>
  );
};

export default FormEditDependentePage;
