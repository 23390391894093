import React from "react";
import { Button as MButton, styled } from "@mui/material";
import { Loader } from "../loaders";

const StyledButton = styled(MButton)`

  border-radius: 100px;
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.family.buttons};
  font-size: ${({ theme }) => theme.fonts.size.buttons};
  border-color: ${({ theme }) => theme.buttons.outlined.borderColor};
  color: ${({ theme }) => theme.buttons.outlined.borderColor};
  
  &:focus {
    outline: 0;
  }
  
  &:hover {
    background-color: ${({ theme }) => theme.buttons.outlined.backgroundHover};
    border-color: ${({ theme }) => theme.buttons.outlined.borderColor};
  }
 
`;

const OutlinedButton = ({ children,
                          loading,
                          loaderType = "dots",
                          loaderColor = "white",
                          loaderSize = 24,
                          disableElevation = true,
                          ...otherProps }) => (
  <StyledButton
    {...otherProps}
    disableElevation={disableElevation}
    variant="outlined"
    fullWidth={true}
  >
    {!!loading ? (
      <Loader type={loaderType} stroke={loaderColor} size={loaderSize} />
    ) : (
      children
    )}
  </StyledButton>
);

export default OutlinedButton;
