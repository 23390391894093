import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Button from "../../components/buttons/PrimaryButton";
import { ControlledSelect } from "../../components/inputs";
import { ControlledAutocomplete } from "../../components/inputs";
import { actions } from "../../reducers/relatorios.actions";
import { Card, Grid, styled } from "@mui/material";

import { MenuItem } from "../../components";

const StyledCard = styled(Card)`
  height: auto;
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 15px;
  background-color: #415781ff;
  margin-top: -5px;
  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const StyledGrid = styled(Grid)`
  padding: 5px;
  place-self: flex-start;
`;

const StyledGridContainer = styled(Grid)`
  margin-bottom: 0px;
`;

const StyledH2 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.white};
  text-align: left;
  letter-spacing: 1.8px;
  font-size: 28px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
`;

const relatorios = [
  { nome: "Clientes", id: 1 },
  { nome: "Seguradoras", id: 2 },
  { nome: "Apólices", id: 3 },
  { nome: "Beneficiários", id: 4 },
];

const situacao = [
  {
    id: "A",
    nome: "Ativo",
  },
  {
    id: "I",
    nome: "Inativo",
  },
];

const Filtros = ({ loading, loadingFile, formProps, clientes }) => {
  const [isBeneficiario, setBeneficiario] = useState(false);

  const verificaFiltro = (v) => {
    if (v === 4) {
      setBeneficiario(true);
    } else {
      setBeneficiario(false);
    }
  };

  const dispatch = useDispatch();

  const handleSubmit = () => {
    let relatorio = formProps.getValues("relatorio");

    if (!relatorio) {
      toast.error("É obrigatória a escolha do tipo de relatório.");
    } else {
      let relatorio = formProps.getValues("relatorio");
      dispatch(
        actions.obterRelatorio.request({
          nome: relatorio.nome,
          filtros: {
            situacao: formProps.getValues("situacao") ?? null,
            cliente: formProps.getValues("cliente") ?? null,
          },
        })
      );
    }
  };

  return (
    <StyledCard>
      <StyledGridContainer container>
        <StyledGrid item xs={12} md={12}>
          <StyledH2>Filtros:</StyledH2>
        </StyledGrid>
        <StyledGrid item xs={12} md={6}>
          <ControlledAutocomplete
            name={"relatorio"}
            formProps={formProps}
            label={"Relatório"}
            labelField={"nome"}
            domain={relatorios}
            onChange={(e) => verificaFiltro(e.id)}
          />
        </StyledGrid>
        <StyledGrid item xs={12} md={6}>
          <ControlledSelect
            name={"situacao"}
            formProps={formProps}
            label={"Situação"}
          >
            {situacao.map((item) => (
              <MenuItem
                className={"select-submenu-item"}
                key={item?.id}
                value={item?.id}
              >
                {item.nome}
              </MenuItem>
            ))}
            {isBeneficiario && (
              <MenuItem className={"select-submenu-item"} key={"D"} value={"D"}>
                Desligado
              </MenuItem>
            )}
            <MenuItem
              className={"select-submenu-item"}
              key={""}
              value={""}
            ></MenuItem>
          </ControlledSelect>
        </StyledGrid>
        {isBeneficiario && (
          <StyledGrid item xs={12} md={6}>
            <ControlledAutocomplete
              name={"cliente"}
              formProps={formProps}
              label={"Cliente"}
              labelField={"nomeCliente"}
              domain={clientes}
            />
          </StyledGrid>
        )}
      </StyledGridContainer>
      <StyledGridContainer
        container
        style={{ display: "flex", justifyContent: "Center", marginTop: "10px" }}
      >
        <StyledGrid item xs={4}>
          <Button onClick={handleSubmit} loading={loading}>
            Download
          </Button>
        </StyledGrid>
      </StyledGridContainer>
    </StyledCard>
  );
};

export default Filtros;
